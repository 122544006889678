import React,  {useContext} from 'react'
import {NavLink} from 'react-router-dom';
import {Store} from "../../stores/Store";

const SettingsPanel = () => {
    const context = useContext(Store); // This part is important to access context values
    return (
        <NavLink title='Whitelist' className="btn btn-success" exact to={"/product/"+context.product_id+"/whitelist"} activeClassName="active">
            <span className="small d-none d-md-inline">
                Whitelist
            </span>
        </NavLink>
    )
}
export default SettingsPanel