import React, {Fragment, useContext} from 'react';
import {NavLink} from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faListAlt, faCogs
} from '@fortawesome/free-solid-svg-icons';
import {Store} from "../../../stores/Store";
const Forms = (props)=>{
    const context = useContext(Store); // This part is important to access context values
    return (
        <Fragment>
            <li className="nav-item">
                <NavLink className="nav-link" exact to={"/product/"+context.product_id+'/forms'} activeClassName="active">
                    <FontAwesomeIcon icon={ faListAlt } /><br/>
                    <span className="d-none d-md-inline">
                        Forms
                    </span>
                </NavLink>
            </li>
            <li className="nav-item">
                <NavLink className="nav-link" exact to={"/product/"+context.product_id+'/forms/setup'} activeClassName="active">
                    <FontAwesomeIcon icon={ faCogs } /><br/>
                    <span className="d-none d-md-inline">
                        Forms
                    </span>
                </NavLink>
            </li>
        </Fragment>
    )
}
export default Forms;