const nav2colover = (template)=>{
    return [
        {
            data:[
                {
                    name:"Background Colour",
                    type:"color",
                    value:template.navbarBackground,
                    variableName:"backgroundColor"
                },{
                    name:"Link Colour",
                    type:"color",
                    value:template.navbarColor,
                    variableName:"color"
                }
            ],
            name:"Colors"
        },{
            data:[
                {
                    contains:"links",
                    data:[],
                    name:"Navigation Links",
                    type:"objectarray",
                    variableName:"links"
                }
            ],
            name:"Links"
        },{
            data:[{
                alt:"",
                name:"Logo",
                src:"",
                title:"",
                link:"",
                type:"imagelink",
                variableName:"logo" 
            }],
            name:"Images"
        },{
            data:[
                {
                    name:"Logo Size",
                    type:"number",
                    value:100,
                    variableName:"logosize" 
                },{
                    name:"Link Size",
                    type:"number",
                    value:14,
                    variableName:"linkSize" 
                },{
                    name:"Link Weight",
                    type:"select",
                    data:[{
                        label:'100',
                        value:100
                    },{
                        label:'300',
                        value:300
                    },{
                        label:'500',
                        value:500
                    },{
                        label:'700',
                        value:700
                    },{
                        label:'900',
                        value:900
                    }],
                    value:900,
                    variableName:"linkWeight" 
                }
            ],
            name:"Sizes"
        },{
            data:[
                {
                    data:[
                        {
                            label:"Contained",
                            value:"contained"
                        },{
                            label:"Full",
                            value:"full"
                        }
                    ],
                    name:"Logo Position",
                    type:"select",
                    value:"contained",
                    variableName:"width"
                },{
                    data:[
                        {
                            label:"Right",
                            value:"right"
                        },{
                            label:"Left",
                            value:"left"
                        }
                    ],
                    name:"Logo Position",
                    type:"select",
                    value:"left",
                    variableName:"align"
                },{
                    name:"Padding Top",
                    type:"number",
                    value:40,
                    max:200,
                    variableName:"paddingTop" 
                },{
                    name:"Padding Bottom",
                    type:"number",
                    value:40,
                    max:200,
                    variableName:"paddingBottom" 
                }
            ],
            name:"Layout"
        }
    ]
}
export default nav2colover