import React, {Fragment, Component} from 'react';
//componants
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faBullseye, faEye, faBriefcaseMedical, faSkull
} from '@fortawesome/free-solid-svg-icons';
import {faGoogle} from '@fortawesome/free-brands-svg-icons';
import ModalAlert from '../../Global/ModalAlert';
import ViewProspect from '../ViewProspect';
import {capEveryWord} from '../../../helpers';
import {Store} from '../../../stores/Store';
import AddToPipelineBucket from '../../Pipeline/ProspectCard/AddToPipelineBucket';
import Google from '../../Pipeline/ProspectCard/Google';
class TableButtons extends Component{
    state = {
        processing:false
    }
    render(){
        const itemname = (this.props.data.your_name)?capEveryWord(this.props.data.your_name):(!this.props.data.networkSuppressed)?this.props.data.maxmind.traits.organization:(this.props.data.latest_contact_name)?capEveryWord(this.props.data.latest_contact_name.name):'Unknown Contact';
        return (
            <span className='tableCell'>
                {(!this.state.processing)?
                <Fragment>
                    <ModalAlert 
                                buttonClassName={"btn btn-sm boxbutton "+((this.props.data.pipeline_bucket)?"active":'')}
                                buttonIcon={<FontAwesomeIcon icon={faBullseye} />}
                                title={'Add '+itemname+' to pipeline'}
                                content={<AddToPipelineBucket refresh={this.props.refresh} {...this.props.data} />}
                                size='lg'
                            />
                            <ModalAlert 
                                buttonClassName='btn btn-sm boxbutton' 
                                buttonIcon={<FontAwesomeIcon icon={faEye} />}
                                title={(!this.props.data.save)?'Save "'+itemname+'" to pipeline' :'edit "'+itemname+ '" from pipeline'}
                                content={<ViewProspect l_id={this.props.data.id} />}
                                size='lg'
                            />
                            <ModalAlert 
                                buttonClassName='btn btn-sm boxbutton' 
                                buttonIcon={<FontAwesomeIcon icon={faGoogle} />}
                                title={'Search '+itemname}
                                content={<Google 
                                        search={itemname+' '+((this.props.data.maxmind.city)?this.props.data.maxmind.city.names.en:'')+((this.props.data.maxmind.country)?', '+this.props.data.maxmind.country.names.en:'')} 
                                    />}
                                size='lg'
                            />
                    {((this.context.access === 'super' || this.context.access === 'admin'))&&(this.props.data.networkSuppressed === false)?<button className="boxbutton floatright btn btn-none btn-sm warning" onClick={()=>{this.props.suppress(this.props.data)}}><FontAwesomeIcon icon={faSkull} /></button>:<span className='btn danger btn-sm barbutton floatright'><FontAwesomeIcon icon={faSkull} /></span>}   
                </Fragment>
                :'Processing...'}
            </span>
        );
    }
}
TableButtons.contextType = Store;
export default TableButtons;