import moment from 'moment';

export const formatDate = (date) => {
    return moment.unix(date).calendar();
}
export const fromandto = (start, end) => {
    end = moment.unix(end);
    start = moment.unix(start);
    if(moment.duration(start.diff(end)).seconds() > 60)
    {
        return (moment.duration(end.diff(start)).seconds()/60)+' Minutes';
    }
    else{
        return moment.duration(end.diff(start)).seconds()+' Seconds';
    }
}
export const liveuser = (start) => {
    let now = moment().utc();
    start = moment.unix(start);
    return (now.diff(start) < (180*1000))?true:false;
}
export const humanTime = (date) => {
    let year = date.substring(0, 4);
    let month = date.substring(4, 6);
    let day = date.substring(6, 8);
    return day+'/'+month+'/'+year;
}