import React, {useState, Fragment, useEffect, useContext} from 'react';
import {getSites} from '../../data/sites';
import SiteCard from './SiteCard';
import NoContent from '../Global/NoContent';
import PageTop from '../Global/pagetop';
import Loading from '../Global/Loading';
import {Store} from '../../stores/Store';
import SitesDashboardButtons from './SitesDashboardButtons';
import {
    useParams
  } from "react-router-dom";
const Sites = () => {
    const [sites, setSites] = useState(null);
    const context = useContext(Store);
    let { page_id, product_id} = useParams();
    const refresh = () => {
        getSites(product_id).then(res=>{
            setSites(res.data);
        });
    }
    
    useEffect(()=>{
        if(!context.product_id){
            context.update({
                product_id:product_id
            });
        }
        context.update({
            view:'sites'
        });
        context.update({site:undefined});
        refresh();
    }, [])
    return (
        <Fragment>
            <PageTop name={'Sites'} buttons={<SitesDashboardButtons callback={refresh} />}/>
            <div className='inner'>
                {(sites)?
                    (sites.length > 0)?
                        <ul className="row mb-3 card-list">{sites.map((item, i)=><li className="col-12 col-md-6 col-lg-4 card_object"><SiteCard data={item} refresh={refresh} key={i} /></li>)}</ul>
                    :<NoContent />
                :<Loading/>}
            </div>
        </Fragment>
    )
}
export default Sites;