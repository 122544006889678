import React from 'react';

const TextArea = (props) => {
    return (
        <div className="input-group mb-3">
            <div className="input-group-prepend">
                <div className="input-group-text">{props.name}</div>
            </div>
            <textarea 
                name={'textarea'} 
                className='form-control' 
                placeholder={props.name}
                onChange={(e)=>{props.update(e.target.value)}} 
                value={props.value}
            >
            </textarea>
        </div>
    )
}
export default TextArea;