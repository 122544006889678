import React from 'react';
import {Route, Switch} from 'react-router-dom';
import Signin from '../../components/Loggedout/Signin';
import ForgotPassword from '../../components/Loggedout/ForgotPassword';
import Verify from '../../components/Loggedout/Verify';
const LoggedOut = () => {
    return (
        <div className="container-fluid login_container">
            <div className="row">
                <main role="main" className="col-12">
                    <Switch>
                        <Route path='/' exact component={Signin} />
                        <Route path='/forgotpassword' exact component={ForgotPassword} />
                        <Route path='/verify' exact component={Verify} />
                        <Route path='*' component={Signin} />
                    </Switch>
                </main>
            </div>
        </div>
    );
}
export default LoggedOut;
