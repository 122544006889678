import React, {Component, Fragment} from 'react';
//componants
import {Store} from "../../stores/Store";
import {superUpdateUser, adminGetUser} from "../../data/users";
import moment from 'moment';
import Loading from '../Global/Loading';
import Processing from '../Global/Processing';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faSignInAlt, faIdBadge, faSyncAlt
} from '@fortawesome/free-solid-svg-icons';

class AccountDetails extends Component {
    state = {
      user:null,
      updated:false,
      updating:false
    }
    componentDidMount(){
      adminGetUser(this.context, this.props.uid).then(async (res)=>{
        this.setState({
          user:res.data
        });
      })
    }
    handleChange = (e) => {
      let form = {...this.state.user}
      form[e.target.name] = e.target.value;
      this.setState({
        user:form
      });
    }
    update= async()=>{
      await this.setState(
        {
          updating:true
        }
      );
      superUpdateUser(this.context, this.state.user).then((res)=>{
        if(res.success)
        {
          this.props.close();
          this.props.update();
        }else{
          this.setState(
            {
              emailerror:true
            }
          );
          setTimeout(() => { 
            this.setState(
              {
                emailerror:false
              }
            );
           }, 3000);
        }
      })
    }
    render() {
      return (this.state.user !== null)?(!this.state.updating)?(<Fragment>
        <div className="row">
          <div className="col-12 col-sm-6 col-md-4">
            <div className="card singlestats">
              <div className='row'>
                <div className="col-4 iconholder">
                  <span className='icon'><FontAwesomeIcon icon={faSignInAlt} /></span>
                </div>
                <div className="col-8 text">
                  <span className='title'>Last Login</span>
                  <span className='stat'>{moment(this.state.user.lastSignInTime).fromNow()}</span>
                  <span className='date'>{moment(this.state.user.lastSignInTime).format("MMM Do YY")}</span>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-sm-6 col-md-4">
            <div className="card singlestats">
              <div className='row'>
                <div className="col-4 iconholder">
                  <span className='icon'><FontAwesomeIcon icon={faIdBadge} /></span>
                </div>
                <div className="col-8 text">
                  <span className='title'>Registered</span>
                  <span className='stat'>{moment(this.state.user.creationTime).fromNow()}</span>
                  <span className='date'>{moment(this.state.user.creationTime).format("MMM Do YY")}</span>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-sm-12 col-md-4">
            <div className="card singlestats">
              <div className='row'>
                <div className="col-4 iconholder">
                  <span className='icon'><FontAwesomeIcon icon={faSyncAlt} /></span>
                </div>
                <div className="col-8 text">
                  <span className='title'>Last Profile Update</span>
                  <span className='stat'>{moment(this.state.user.creationTime).fromNow()}</span>
                  <span className='date'>{moment(this.state.user.creationTime).format("MMM Do YY")}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            {(this.state.updated)&&(<div className="alert alert-success" role="alert">
            User Updated!
          </div>)}
          {(this.state.emailerror)&&(<div className="alert alert-warning" role="alert">
            Email already on system!
          </div>)}
                <div className="input-group mb-3">
                      <div className="input-group-prepend">
                        <span className="input-group-text" id="namelabel">First Name</span>
                      </div>
                      <input type="text" name='f_name' onChange={this.handleChange} value={this.state.user.f_name} className="form-control" placeholder="First Name" />
                </div>
                <div className="input-group mb-3">
                      <div className="input-group-prepend">
                        <span className="input-group-text" id="namelabel">Second Name</span>
                      </div>
                      <input type="text" name='l_name' onChange={this.handleChange} value={this.state.user.l_name} className="form-control" placeholder="Second Name" />
                </div>
                <div className="input-group mb-3">
                    <div className="input-group-prepend">
                      <span className="input-group-text" id="namelabel">Email</span>
                    </div>
                    <input type="text" name='email' onChange={this.handleChange} value={this.state.user.email} autoComplete="off" className="form-control" placeholder="Email" />
                </div>
                <div className="input-group mb-3">
                    <div className="input-group-prepend">
                      <span className="input-group-text" id="namelabel">User Type</span>
                    </div>
                    <select className='form-control' name='level' onChange={this.handleChange} defaultValue={this.state.user.level}>
                    <option value='standard'>Standard</option>
                    {(this.context.access === 'super')&&<option value='super'>Super</option>}
                      <option value='admin'>Admin</option>
                    </select>
                </div>
          </div>
        </div>
        <div className="row space top">
          <div className="col-12">
            <button className="btn btn-success nomargin floatright" onClick={this.update}>Save Account Details</button>
          </div>
        </div>
      </Fragment> ):<Processing/>:<Loading />;
    }
}
AccountDetails.contextType = Store; // This part is important to access context values
export default AccountDetails;
