import formandtext from './formandtext';
import formandtextoverimage from './formandtextoverimage';
import formonimage from './formonimage';
import nav2col from './nav2col';
import nav2colover from './nav2colover';
import nav2colsticky from './nav2colsticky';
import formandimage from './formandimage';
import formandimagecover from './formandimagecover';
import form from './form';
import text from './text';
import footer2col from './footer2col';
import hero from './hero';
import custom from './custom';
import heroimage from './heroimage';
import textandimage from './textandimage';
import textandimagearray from './textandimagearray';
import textonimage from './textonimage';
import textandimagecover from './textandimagecover';
import imagelinkarray from './imagelinkarray';
import imagearray from './imagearray';
import externalscript from './externalscript'
import externalscriptanddiv from './externalscriptanddiv';
import feedindex from './feedindex';

const defaulttemplate = {
    navbarColor:{r:254, g:254, b:254, a:254},
    navbarBackground:{r:62, g:62, b:62, a:1},
    mainTransparantColor:{r:62, g:62, b:62, a:0.8},
    primaryBackground:{r:242, g:242, b:242, a:1},
    footerBackground:{r:62, g:62, b:62, a:1},
    footerColor:{r:254, g:254, b:254, a:254},
    titleColor:{r:51, g:51, b:51, a:1},
    leadColor:{r:51, g:51, b:51, a:1},
    bodyColor:{r:51, g:51, b:51, a:1},
    titleSize:30,
    leadSize:20,
    bodySize:18,
    buttonBackgroundColor:{r:254, g:212, b:64, a:1},
    buttonColor:{r:254, g:254, b:254, a:1},
    buttonHoverBackgroundColor:{r:250, g:216, b:139, a:1},
    buttonHoverColor:{r:254, g:254, b:254, a:1},
    buttonPadding:20
}


export const getcomponents = (template = defaulttemplate) => {
    return componentlist(template);
}

const componentlist = (template)=>{
    return [
        {
            description:"Insert HTML CSS and Javascript to create your own components",
            image:"/images/3rdparty.png",
            name:"Custom browser code",
            type:"Custom",
            model:'custom',
            category:'custom',
            data:custom(template)
        },{
            description:"Navigation 2 columns, logo and links",
            image:"/images/navigation.png",
            name:"Navigation 2 column",
            type:"PageHeader",
            model:'nav2col',
            category:'navigation',
            data:nav2col(template)
        },{
            description:"Navigation 2 columns, logo and links hovers over the following component",
            image:"/images/navigation.png",
            name:"Hover Navigation 2 column",
            type:"PageHeaderOver",
            model:'nav2colover',
            category:'navigation',
            data:nav2colover(template)
        },{
            description:"Navigation 2 columns, logo and links - Always at the top",
            image:"/images/navigation.png",
            name:"Sticky Navigation 2 column",
            model:'nav2colsticky',
            type:"PageHeaderSticky",
            category:'navigation',
            data:nav2colsticky(template)
        },{
            description:"Image Background with text and form right and left - reversable",
            image:"/images/textimage.png",
            name:"Text over image with form",
            type:"FormAndTextOverImage",
            model:'formandtextoverimage',
            category:'forms',
            data:formandtextoverimage(template)
        },{
            description:"Image Background with form over",
            image:"/images/floatoneside.png",
            name:"Form over image",
            type:"FormOnImage",
            model:'formonimage',
            category:'forms',
            data:formonimage(template)
        },{
            description:"A form and text on a coloured background",
            image:"/images/textimage.png",
            name:"Form with text",
            type:"FormAndText",
            model:'formandtext',
            category:'forms',
            data:formandtext(template)
        },{
            description:"A form and image on a coloured background",
            image:"/images/textimage.png",
            name:"Form with Image",
            type:"FormAndImage",
            model:'formandimage',
            category:'forms',
            data:formandimage(template)
        },{
            description:"A form on coloured background and image autosizing to the opposite side",
            image:"/images/textimage.png",
            name:"Form with Image",
            type:"FormAndImageCover",
            model:'formandimagecover',
            category:'forms',
            data:formandimagecover(template),
        },{
            description:"A form on coloured background",
            image:"/images/floatoneside.png",
            name:"Form with colored background",
            type:"Form",
            model:'form',
            category:'forms',
            data:form(template)
        },{
            description:"A Text Area with coloured background",
            image:"/images/text.png",
            name:"Text with colored background",
            type:"Text",
            model:'text',
            category:'content',
            data:text(template)
        },{
            description:"Footer with two columns, logo and links",
            image:"/images/navigation.png",
            name:"Footer with two columns",
            type:"Footer",
            model:'footer2col',
            category:'footers',
            data:footer2col(template)
        },{
            description:"Strong component with image in background and to display a strong textual message",
            image:"/images/hero.png",
            name:"Hero with text",
            type:"Hero1",
            model:'hero1',
            category:'hero',
            data:hero(template)
        },{
            description:"Strong component with large image background and image in forground",
            image:"/images/hero.png",
            name:"Hero with image",
            type:"Heroimage",
            model:'heroimage',
            category:'hero',
            data:heroimage(template)
        },{
            description:"Image and text in row - reversable",
            image:"/images/textimage.png",
            name:"Text with Image",
            type:"TextAndImage",
            model:'textandimage',
            category:'content',
            data:textandimage(template)
        },{
            description:"text and multiple images - reversable",
            image:"/images/textimage.png",
            name:"Text with multiple images",
            type:"TextAndImageArray",
            model:'textandimagearray',
            category:'content',
            data:textandimagearray(template)
        },{
            description:"Image and text in row - image autosizing to the opposite side- reversable",
            image:"/images/textimage.png",
            name:"Text with cover image",
            type:"TextAndImageCover",
            model:'textandimagecover',
            category:'content',
            data:textandimagecover(template)
        },{
            description:"Text over an image background",
            image:"/images/textimage.png",
            name:"Text over an image background",
            type:"TextOnImage",
            model:'textonimage',
            category:'content',
            data:textonimage(template)
        },{
            description:"An array of images with links for each image",
            image:"/images/textimage.png",
            name:"An array of images with links",
            type:"ImageLinkArray",
            model:'imagelinkarray',
            category:'content',
            data:imagelinkarray(template)
        },{
            description:"An array of images",
            image:"/images/textimage.png",
            name:"An array of images",
            type:"ImageArray",
            model:'imagearray',
            category:'content',
            data:imagearray(template)
        },{
            description:"External scripts and a div, used when injecting 3rd party elements",
            image:"/images/3rdparty.png",
            name:"External scripts and an empty div",
            type:"ExternalScriptAndDiv",
            model:'externalscriptanddiv',
            category:'external',
            data:externalscriptanddiv(template)
        },{
            description:"External scripts, used when injecting 3rd party code such as tracking code and analytics",
            image:"/images/3rdparty.png",
            name:"External scripts",
            type:"ExternalScriptAndDiv",
            model:'externalscriptanddiv',
            category:'external',
            data:externalscript(template)
        },{
            description:"External scripts, used when injecting 3rd party code such as tracking code and analytics",
            image:"/images/textimage.png",
            name:"Feed Index",
            type:"FeedIndex",
            model:'feedindex',
            category:'content',
            data:feedindex(template)
        }
    ];
}