import React, {useState} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
faGlobe, faPager, faAddressCard
} from '@fortawesome/free-solid-svg-icons';
import Processing from '../../Global/Processing';
import ButtonBar from './ButtonBar';
const FeedCard = (props) => {
    let [removing, setRemoving] = useState(false);

    return (
        <div className={"card "+((!props.active)&&'inactive')}>
            <div className="card-header card-header-borderless d-flex justify-content-between">
                <div>
                    <FontAwesomeIcon icon={faGlobe} />
                    {' '+props.name}
                </div>
            </div>
            <div className="card-body">
                <div className="media">
                    <div className="media-body siteCard">
                        {(removing)?<Processing />:(
                            <ul className="list-group">
                                <li className="list-group-item">
                                    <FontAwesomeIcon icon={faAddressCard} />
                                    <span> {props.slug}</span>
                                </li>
                            </ul>
                        )}
                    </div>
                </div>
            </div>
            {(!removing)
                &&<ButtonBar
                setRemoving={setRemoving}
                    {...props}
                />}
        </div>
    );
}
export default FeedCard;