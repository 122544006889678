import React, {useContext} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {NavLink} from 'react-router-dom';
import {
    faUser,
    faSignOutAlt,
    faUsers,
    faCogs,
    faProjectDiagram
} from '@fortawesome/free-solid-svg-icons';
import {Store} from "../../stores/Store";
import SettingsPanel from './SettingsPanel';
import ModalAlert from '../Global/ModalAlert';
const TopNav = (props) => {
        const context = useContext(Store);
        return(
            <div className='row topnav'>
                <div className='col-5'>
                    {(context.product)&&<NavLink to="/" className='btn btn-clear-space' title='Projects'><FontAwesomeIcon icon={ faProjectDiagram  } /></NavLink>}
                    {(context.product)?<span className='welcome d-none d-md-inline'>{context.product.name}</span>:<span className='welcome d-none d-md-inline'>Welcome</span>}
                </div>
                <div className='col-7 textright'>
                    {((context.access === 'super'))&&<NavLink to="/users" title='Users' className='btn btn-clear'><FontAwesomeIcon icon={ faUsers  } /></NavLink>}
                    <NavLink to="/account" className='btn btn-clear' title='My Account'><FontAwesomeIcon icon={ faUser  } /></NavLink>
                    {((context.access === 'super' || context.access === 'admin') && context.product_id)&&<ModalAlert 
                                buttonClassName='btn btn-clear' 
                                buttonIcon={<FontAwesomeIcon icon={ faCogs } />}
                                title={'Settings'}
                                content={<SettingsPanel />}
                                size='lg'
                                span={true}
                            />
                    }
                    <NavLink to="/" onClick={props.logout} className='btn btn-clear' title='Sign Out'><FontAwesomeIcon icon={ faSignOutAlt  } /></NavLink>
                </div>
            </div>
        );

}
export default TopNav;
