import React, {useState, useContext, Fragment} from "react";
import {Store} from '../../stores/Store';
import {createChatModule} from '../../data/chats';
const CreateChatModule = (props) => {
    const [chatmodulename, setChatModuleName] = useState('');
    const [error, setError] = useState(false);
    const context = useContext(Store);
    const create = () => {
        createChatModule(context.product_id, {name:chatmodulename}).then(()=>{
            props.update();
            props.close();
        });
    }
    return (
        <Fragment>
            <div className="input-group mb-3">
                <div className="input-group-prepend">
                    <span className="input-group-text" id="namelabel">Chat Module Name</span>
                </div>
                <input type="text" name='name' required={true} onChange={(e)=>{setChatModuleName(e.target.value)}} value={chatmodulename} className={(error && chatmodulename.length < 3)?"form-control formerror":"form-control"} placeholder="Chat Module Name" />
            </div>
            <button className="btn btn-success nomargin floatright" onClick={(!error)?create:void(0)}>Create</button>
        </Fragment>
    );
}
export default CreateChatModule;