export const checkSlug = (inputtxt) =>{
 var letters = /^[A-Za-z\-\_\/]+$/;
 if(inputtxt.match(letters))
   {
    return true;
   }
 else
   {
   return false;
   }
}