import {db} from '../firebase';
import axios from 'axios';
import {getHeaders} from '../helpers/getHeaders';

export const LeadAmount = async (product_id, back, context) => {
    let responce = {
        success:true,
        data:{
            u_visits:0,
            leads:0
        }
    }
    await db.collection('products').doc(product_id).collection('leads')
    .where('last_updated', '>', (Math.floor(Date.now() / 1000) - back))
    .get().then(async snapshot => {
        if(!snapshot.empty)
        {
            
            let leads = 0;
           snapshot.forEach(item=>{
            let Lead = item.data();
            if(Lead.networkSuppressed === false || Lead.formAmount > 0){
                leads++;
            }
            }); 
            responce.data.u_visits = snapshot.size;
            responce.data.leads =  leads;
            ;
        }
        return;
    }).catch((error)=>{
        responce.success = false;
        responce.error = error;
        delete responce.data;
        return 
    });
    return responce;
}
export const getStats = async (product_id, days, context) => {
    let uri = '/timestats?productid='+product_id+'&days='+days;
    let res = await axios.get(process.env.REACT_APP_API_URL+uri, {headers:getHeaders(context.token)}).catch((error)=>{
        context.reset();
        return null;
    });
    return await res.data;
}
export const getRealtimeStats = async (product_id, days, context) => {
    let uri = '/realtimestats?productid='+product_id;
    let res = await axios.get(process.env.REACT_APP_API_URL+uri, {headers:getHeaders(context.token)}).catch((error)=>{
        context.reset();
        return null;
    });
    return await res.data;
}