const form = (template)=>{
    return [
        {
            data:[
                {
                    name:"Background Colour",
                    type:"color",
                    value:template.primaryBackground,
                    variableName:"backgroundColor" 
                }
            ],
            name:'colours'
        },{
            data:[
                {
                    name:"Title Font Size",
                    type:"number",
                    value:30,
                    variableName:"titlefontSize" 
                },{
                    name:"Lead Font Size",
                    type:"number",
                    value:21,
                    variableName:"leadfontSize" 
                },{
                    name:"Body Font Size",
                    type:"number",
                    value:18,
                    variableName:"bodyfontSize" 
                }
            ],
            name:'Sizes',
        },{
            data:[
                {
                    type:'text',
                    value:'Main Title',
                    variableName:'title',
                    name:'Title Text'
                },{
                    type:'text',
                    value:'Lead text is kind of like a sub title - the lead in to the next section',
                    variableName:'lead',
                    name:'Lead Text'
                },{
                    type:'wysiwyg',
                    value:'The main body of text in your component.',
                    variableName:'body',
                    name:'Body Text'
                }
            ],
            name:'Content',
        },{
            data:[{
                data:[
                    {
                        label:"Right",
                        value:"right"
                    },{
                        label:"Center",
                        value:"center"
                    },{
                        label:"Left",
                        value:"left"
                    }
                ],
                type:'select',
                value:false,
                variableName:'align',
                name:'Align Text'
            },{
                    name:"Space Top",
                    type:"number",
                    value:80,
                    max:200,
                    variableName:"paddingTop" 
                },{
                    name:"Space Bottom",
                    type:"number",
                    value:80,
                    max:200,
                    variableName:"paddingBottom" 
                }
            ],
            name:'Layout',
        }
    ]
}
export default form;