import React from 'react';
import {fromandto, liveuser} from '../../../helpers/time';

const Journey = (props) => {
    return (
        <ul className='list-group'>
            {props.visit.journey.map((page,i) =>  {
                        if(props.visit.journey[(i+1)])
                        {
                            return (
                                <li key={i} className="list-group-item">
                                    <b>Page {(i+1)}:</b> {page.url}
                                    <span className='floatright' >{fromandto(page.date, props.visit.journey[(i+1)].date)}</span>
                                </li>
                            );
                        }else{
                            return (
                                <li key={i} className="list-group-item">
                                    Page <span className='dot'>{(i+1)}</span>: {page.url}
                                    {(liveuser(props.visit.journey[i].date))?(
                                            <span className='floatright' >Still On Page</span>
                                        ):(
                                            <span className='floatright' >Exit Page</span>
                                        )
                                    }
                                </li>
                            )
                        }
                    }
                )
            }
        </ul>
    )                      
}
export default Journey;
