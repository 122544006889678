import React, { useContext, useEffect, useState } from 'react';
import {getForms} from '../../../data/forms';
import { Store } from '../../../stores/Store';
const Select = (props) => {
    let context = useContext(Store);
    let [forms, setForms] = useState([]);
    useEffect(()=>{
        getForms(context.product_id).then((res)=>{
            setForms(res.data);
        });
    }, []);
    return (
        <div className="input-group mb-3">
            <div className="input-group-prepend">
                <div className="input-group-text">{props.name}</div>
            </div>
            <select name={'select'} required className='form-control' onChange={(e)=>{props.update(e.target.value)}} value={props.value}>
                <option value=''>not selected</option>
                {forms.map((item, i)=>{
                    return(
                        <option key={i} value={item.id}>
                            {item.name}
                        </option>
                    )
                })}
            </select>
        </div>
    )
}
export default Select;