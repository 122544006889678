import React from 'react';
import { Editor } from '@tinymce/tinymce-react';

const Wysiwyg = (props) => {
    return (
        <div className='space top bottom'>
            <Editor
                onBlur={(e) => {
                    props.update(e.target.getContent());
                }}
                initialValue={props.value}
                init={{
                    height: 500,
                    menubar:false,
                    plugins:[
                        'advlist autolink lists link image charmap print preview anchor',
                        'searchreplace visualblocks code fullscreen',
                        'insertdatetime media table paste code help wordcount'
                    ],
                    toolbar:'undo redo | formatselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat'
                }}
            />
        </div>
    )
}
export default Wysiwyg;