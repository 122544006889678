import React, {Component} from 'react';
// Import React Table
import ReactTable from "react-table";

import "react-table/react-table.css";
import {formatDate} from '../../../helpers/time';
import {capEveryWord} from '../../../helpers';
import {Store} from '../../../stores/Store';

import {deleteLead, pinLead, saveLead} from '../../../data/leads';
import {suppressNetwork, suppressLead} from '../../../data/suppress'; 
import TableButtons from './TableButtons';
class Table extends Component{
   state = {
      processing:false
   }
   toggleDeleteProspect = (id, value) => {
      this.setState({
          processing:true
      },()=>{
          deleteLead(this.context.product_id, id, value).then(async ()=>{
              await this.setState({
                  processing:!value
              });
              this.props.refresh();
          });
      });
  }
  togglePinProspect = (id, value) => {
      pinLead(this.context.product_id, id, value).then(()=>{
         this.props.refresh();
      });
  }
  toggleSave = (id, value) => {
      saveLead(this.context.product_id, id, value).then(()=>{
         this.props.refresh();
      });
  }
  suppress = async (lead) => {
      await this.setState({
          processing:true
      });
      await suppressNetwork(lead);
      await suppressLead(this.context.product_id, lead);
      this.props.refresh();
  }
   render(){
    return <ReactTable
               data={this.props.data}
                     columns={[
                        {
                           Header: "Name",
                           accessor: "id",
                        Cell: row => (<span className='tableCell'><span>{row.original.your_name !== null?capEveryWord(row.original.your_name):(!row.original.networkSuppressed)?row.original.maxmind.traits.organization:(row.original.latest_contact_name)?capEveryWord(row.original.latest_contact_name.name):'Unknown Contact'}</span></span>)
                        },{
                           Header: "Location",
                           accessor: "id",
                        Cell: row => (<span className='tableCell'><span>{(row.original.maxmind.city)?row.original.maxmind.city.names.en:'Unknown City'}{(row.original.maxmind.country)&&', '+row.original.maxmind.country.names.en}</span></span>)
                        },
                        {
                           Header: "Date",
                           accessor: "id",
                        Cell: row => (<span className='tableCell'><span>{formatDate(row.original.last_updated)}</span></span>)
                        },
                        {
                            Header: "Actions",
                            accessor: "id",
                            Cell: row => (
                                 <TableButtons 
                                    data={row.original}
                                    suppress={this.suppress} 
                                    toggleSave={this.toggleSave} 
                                    togglePinProspect={this.togglePinProspect} 
                                    toggleDeleteProspect={this.toggleDeleteProspect}
                                 />
                              )
                        }
                     ]}
               defaultPageSize={10}
               className="spacetop -striped -highlight"
             />;
      }
}
Table.contextType = Store; // This part is important to access context values
export default Table;
