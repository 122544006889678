import React from 'react';
import {formatDate, liveuser} from '../../../helpers/time';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faCalendar, faLocationArrow,faGlobe
} from '@fortawesome/free-solid-svg-icons';
//componants

const Stats = (props) => {
    return (
        <div className="row lozcards">
            <div className='col-12 card'>
                <div className='card-body'>
                    <div className="row no-gutters">
                        <div className="col-12 col-md-4 morespace bottom">
                            <div className="textcenter click">
                                <FontAwesomeIcon icon={faGlobe} /> <br/>{(props.prospect.maxmind.continent)?props.prospect.maxmind.continent.names.en:"Unknown Continent"}
                            </div>
                        </div>
                        <div className="col-12 col-md-4 morespace bottom">
                            <div className="textcenter click">
                                <FontAwesomeIcon icon={faLocationArrow} /> <br/>{(props.prospect.maxmind.city)?props.prospect.maxmind.city.names.en:'Unknown City'}{(props.prospect.maxmind.country)&&', '+props.prospect.maxmind.country.names.en}
                            </div>
                        </div>
                        <div className="col-12 col-md-4 morespace bottom">
                            <div className="textcenter click">
                                <FontAwesomeIcon icon={faCalendar} /> <br/>{(liveuser(props.prospect.last_updated))
                                                                        ?<span className='live'>On Site Now</span>
                                                                        :<span>{formatDate(props.prospect.last_updated)}</span>}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Stats;
