import React, {useState, Fragment, useEffect, useContext} from 'react';
import {getFeedRoutes} from '../../data/sites';
import FeedCard from './FeedCard';
import NoContent from '../Global/NoContent';
import PageTop from '../Global/pagetop';
import {Store} from '../../stores/Store';
import FeedsDashboardButtons from './FeedsDashboardButtons';
import Loading from '../Global/Loading';

const Feeds = () => {
    const [feeds, setFeeds] = useState(null);
    const context = useContext(Store);
    useEffect(()=>{
        context.update({
            view:'sites'
        });
        refresh()
    }, []);
    const refresh = () => {
        getFeedRoutes(context.product_id, context.site.site_id).then((result)=>{
            setFeeds(result.data);
        });
    }
    return (
        <Fragment>
            <PageTop name={'Sites'} buttons={<FeedsDashboardButtons callback={refresh} />}/>
            <div className='inner'>
                {(feeds)?(feeds.length > 0)?<ul className="row mb-3 card-list">
                    {feeds.map((feed, i)=>{
                        return <li key={i} className="col-12 col-md-6 col-lg-4 card_object">
                            <FeedCard 
                                {...feed}
                                refresh={refresh}
                            /></li>
                    
                    })}
                </ul>:<NoContent title='No Feeds Yet!' text='Create your first feed by clicking new page button above' />:<Loading/>}
            </div>
        </Fragment>
    )
}
export default Feeds;