import React, {Component, Fragment} from 'react';
import {createProduct} from '../../data/products';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faTimesCircle
} from '@fortawesome/free-solid-svg-icons';
import {Store} from '../../stores/Store';
class NewProduct extends Component {
    state = {
        form:{
            name:'',
            secondaryUrls:[]
        },
        url:''
    }
    handleChange = (e) => {
        let form = {...this.state.form}
        form[e.target.name] = e.target.value;
        this.setState({
          form:form
        });
    }
    handleurl = (e) => {
        this.setState({
          url:e.target.value
        });
    }
    extractHostname = (url) => {
        let hostname;
        if (url.indexOf("//") > -1) {
            hostname = url.split('/')[2];
        }
        else {
            hostname = url.split('/')[0];
        }
        hostname = hostname.split(':')[0];
        hostname = hostname.split('?')[0];
        return hostname;
    }
    addSecondaryUrl = () =>{
        let newarray = this.state.form.secondaryUrls;
        newarray.push(this.extractHostname(this.state.url));
        this.setState({
            form:{
                ...this.state.form,
                secondaryUrl:newarray
            },
            url:''
        });
    }
    removeSecondaryUrl = (key) =>{
        let newarray = this.state.form.secondaryUrls;
        newarray.splice(key, 1);
        this.setState({
            form:{
                ...this.state.form,
                secondaryUrl:newarray
            }
        });
    }
    create = async() =>{
        let error = false;
        error = (this.state.form.name.length > 3 && error === false)?false:(error !== false)?error:'Name needs to be over 3 charactors';
        error = (this.state.form.name.length < 24 && error === false)?false:(error !== false)?error:'Name needs to be less than 24 charactors';
        if(error === false)
        {
            createProduct(this.state.form).then(()=>{
                this.props.update();
                this.props.close();
            });
        }else{
            this.context.alert(error)
        }
    }
    render(){
        return (
            <Fragment>
                <div className="input-group mb-3">
                    <div className="input-group-prepend">
                        <span className="input-group-text" id="namelabel">Project Name</span>
                    </div>
                    <input type="text" name='name' required={true} onChange={this.handleChange} value={this.state.form.name} className={(this.state.error && this.state.form.name.length < 3)?"form-control formerror":"form-control"} placeholder="Site Name" />
                </div>
                <div className="input-group mb-3">
                    <div className="input-group-prepend">
                        <span className="input-group-text" id="namelabel">Domain</span>
                    </div>
                    <input type="text" name='secondaryUrl' required={true} onChange={this.handleurl} value={this.state.url} className={(this.state.error && this.state.url.length < 3)?"form-control formerror":"form-control"} placeholder='Site URL' />
                    <div class="input-group-append">
                        <button class="btn btn-outline-secondary" onClick={(this.state.url.length > 3)?this.addSecondaryUrl:null} type="button">Add Domain</button>
                    </div>
                </div>
                {(this.state.form.secondaryUrls.length > 0)&&<table>
                    <thead><td>Current Domains</td><td></td></thead>
                    <tbody>
                        {this.state.form.secondaryUrls.map((item, i)=>{
                            return <tr key={i}><td>{item}</td><td><span onClick={()=>{this.removeSecondaryUrl(i)}}><FontAwesomeIcon icon={faTimesCircle} /></span></td></tr>
                        })}
                    </tbody>
                </table>}
                <button className="btn btn-success nomargin floatright" onClick={this.create}>Create</button>
            </Fragment>
        )
    }
}
NewProduct.contextType = Store; // This part is important to access context values
export default NewProduct;