import React, {Component, Fragment} from 'react';
import {Route, Switch} from 'react-router-dom';
/// Componants
import Sidebar from "../../components/Global/Sidebar/";
import TopNav from '../../components/Global/TopNav';
import Dashboard from '../../components/Dashboard/';
import Products from '../../components/Products/';
import Account from '../../components/Account';
import Users from '../../components/Users';
import Content from '../../components/Content';
import Product from '../../components/Research';
import Forms from '../../components/Forms';
import FormContacts from '../../components/Forms/FormContacts';
import Pipeline from '../../components/Pipeline';
import EditForm from '../../components/Forms/Editform/';
import EditPage from '../../components/Sites/EditPage/';
import Chats from '../../components/Chats';
import ClosedChats from '../../components/Chats/ClosedChats';
import ChatSettings from '../../components/Chats/Settings';
import GlobalComponents from '../../components/Sites/GlobalComponents';
import Pages from '../../components/Sites/Pages';
import PageTemplates from '../../components/Sites/PageTemplates';
import Routes from '../../components/Sites/Routes';
import Feeds from '../../components/Sites/Feeds';
import Sites from '../../components/Sites';
import Whitelist from '../../components/Global/Whitelist';
import {Store} from "../../stores/Store";
import {Logout} from "../../data/users";
import NoMatch from '../../components/NoMatch';
import {auth} from '../../firebase';
import Loading from '../../components/Global/Loading';

import OverView from '../../components/OverView/';
import FeedContent from '../../components/Sites/FeedContent';
class LoggedIn extends Component{
    state = {
        auth:false,
        product:null
    }
    componentDidMount(){
        auth.onAuthStateChanged((user) => {
            if (user) {
                this.setState({auth:true});
            } else {
                this.setState({auth:false})
                Logout(this.context);
            }
        });
    }

    render(){
        return (this.state.auth)?(
            <div className="container-fluid">
                <div className="row">
                    <Sidebar />
                    <main role="main" className="col-10">
                        <TopNav logout={() => {
                            Logout(this.context);
                        }}/>
                        <Switch>
                            <Route path='/product/:product_id/' exact component={Dashboard} />
                            <Route path='/product/:product_id/overview' exact component={OverView} />
                            <Route path='/product/:product_id/content' exact component={Content} />
                            <Route path='/product/:product_id/research/' exact component={Product} />
                            <Route path='/product/:product_id/research/whitelist' exact component={Whitelist} />
                            <Route path='/product/:product_id/pipeline' exact component={Pipeline} />
                            <Route path='/product/:product_id/sites' exact component={Sites} />
                            <Route path='/product/:product_id/site/routes' exact component={Routes} />
                            <Route path='/product/:product_id/site/pages' exact component={Pages} />
                            <Route path='/product/:product_id/site/templates' exact component={PageTemplates} />
                            <Route path='/product/:product_id/site/feeds' exact component={Feeds} />
                            <Route path='/product/:product_id/site/feeds/:feed_id' exact component={FeedContent} />
                            <Route path='/product/:product_id/site/globalcomponents' exact component={GlobalComponents} />
                            <Route path='/product/:product_id/site/pages/:page_id' exact component={EditPage} />
                            <Route path='/product/:product_id/chats' exact component={Chats} />
                            <Route path='/product/:product_id/chats/closed' exact component={ClosedChats} />
                            <Route path='/product/:product_id/chats/settings' exact component={ChatSettings} />
                            <Route path='/product/:product_id/forms' exact component={FormContacts} />
                            <Route path='/product/:product_id/forms/setup' exact component={Forms} />
                            <Route path='/product/:product_id/forms/:form_id' exact component={EditForm} />
                            <Route path='/account' exact component={Account} />
                            <Route path='/users' exact component={Users} />
                            <Route path='/' exact component={Products} />
                            <Route path='*' component={NoMatch} />
                        </Switch>
                    </main>
                </div>
            </div>
        ):(
            <div className="container-fluid">
                <div className="row">
                    <Sidebar />
                    <main role="main" className="col-10">
                        <TopNav logout={() => {
                            Logout(this.context);
                        }}/>
                            <div className='inner'>
                            <Loading />
                            </div>
                    </main>
                </div>
            </div>
        );
    }
}
LoggedIn.contextType = Store; // This part is important to access context values
export default LoggedIn;

