import React, {useState, Fragment, useEffect, useContext} from 'react';
import {getForms} from '../../data/forms';
import FormSettingsCard from './FormSettingsCard';
import NoContent from '../Global/NoContent';
import PageTop from '../Global/pagetop';
import {Store} from '../../stores/Store';
import FormDashboardButtons from './FormDashboardButtons';
const Forms = (props) => {
    const [forms, setForms] = useState(null);
   
    const context = useContext(Store);
    useEffect(()=>{
        context.update({
            view:'forms'
        });
        if(!context.product_id && props.match.params.product_id)
        {
            context.update({
                product_id:props.match.params.product_id
            });
        }
    },[]);
    useEffect(()=>{
        if(context.product_id)
        {
            refresh();
        }
    }, [context.product_id]);
    const refresh = () => {
        getForms(context.product_id).then((result)=>{
            setForms(result.data);
        });
    }
    
    return (
        <Fragment>
            <PageTop name={'Forms'} buttons={<FormDashboardButtons callback={refresh} />}/>
            <div className='inner'>
                {(forms && forms.length > 0)?<ul className="row mb-3 card-list">
                    {forms.map((Form, i)=>{
                        return <li className="col-12 col-md-6 col-lg-4 card_object"><FormSettingsCard 
                                    data={Form}
                                    key={i}
                                    refresh={refresh}
                                /></li>
                    })}
                </ul>:<NoContent title='No Forms Yet!' text='Create your first form by clicking new form above' />}
            </div>
        </Fragment>
    )
}
export default Forms;