import React, {useState, useContext, useEffect} from 'react';
import {Store} from '../../stores/Store';
import ChatCard from './ChatCard';
import NoContent from '../Global/NoContent';
import {db} from '../../firebase';
import { Fragment } from 'react';
import PageTop from '../Global/pagetop';
import {getSavedLeads} from '../../data/leads';
const Chats = (props) => {
    const [chats, setChats] = useState(null);
    const [prospectids, setProspectids] = useState([]);
    const context = useContext(Store);
    useEffect(()=>{
    }, [chats]);
    useEffect(()=>{
        context.update({
            view:'chats'
        });
        if(context.product_id){
            subscribe();
        }else{
            context.update({
                product_id:props.match.params.product_id,
                view:'chats'
            });
        }
    }, [context.product_id]);
    const subscribe = () =>{
        getSavedLeads(context.product_id, context).then((result)=>{
            let prosids = [];
            for(let prospect of result.data){
                prosids.push(prospect.id);
            }
            setProspectids(prosids);
        });
        return new Promise((resolve, reject) => {
            db.collectionGroup('contacts')
            .where('type', '==', 'chat')
            .where('productId', '==', context.product_id)
            .where('closed', '==', false)
            .onSnapshot((snapshot) => {
                let Chats = [];
                if(!snapshot.empty)
                {
                    snapshot.forEach(async doc => {
                        let Chat = doc.data();
                        Chat.id = doc.id;
                        Chats.push(Chat);
                    });
                }
                setChats(Chats);
                resolve(Chats)
            }, reject)
          });
          
    }
    return (
        <Fragment>
            <PageTop name={"Chats"} />
            <div className='inner'>
                {(chats && chats.length > 0)?<ul className="row mb-3 card-list">
                {(chats)?chats.map((item, i)=>{
                                return (<li className="col-12 col-md-6 col-lg-4 card_object">
                                    <ChatCard refresh={subscribe} prospectids={prospectids} key={i} accessIds={{l_id:item.leadId, c_id:item.id}} data={item} />
                                    </li>)
                            }):null}
                </ul>:<NoContent title='No Current Chats!' text='When chats start they will appear here' />}
            </div>
        </Fragment>
    )
}
export default Chats;