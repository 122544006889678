import React, {useEffect, useState, useContext} from 'react';
import {updateBucket, deleteBucket} from '../../data/pipelines';
import {db} from '../../firebase';
import Loading from '../Global/Loading';
import ProspectCard from './ProspectCard';
import NoContent from '../Global/NoContent';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faTimes
} from '@fortawesome/free-solid-svg-icons';
import { Store } from '../../stores/Store';
const Bucket = (props) => {
    let [bucketname, setBucketName] = useState(props.name);
    let [updatebucket, setUpdateBucket] = useState(false);
    let [deleting, setDeleting] = useState(false);
    let [bucketLeads, setBucketLead] = useState([]);
    let context = useContext(Store);
    const updatebucketname = () => {
        setUpdateBucket(false);
        updateBucket(props.product_id, props.id, {name:bucketname});
    }
    const deleteThisBucket = () => {
        setDeleting(true);
        setUpdateBucket(false);
        deleteBucket(props.product_id, props.id);
        props.refresh();
    }
    const subscribe = () =>{
        return new Promise((resolve, reject) => {
            db.collection('products')
            .doc(context.product_id)
            .collection('leads')
            .where('pipeline_bucket', '==', props.id)
            .onSnapshot((snapshot) => {
                let Leads = [];
                if(!snapshot.empty)
                {
                    snapshot.forEach(async doc => {
                        let Lead = doc.data();
                        Lead.id = doc.id;
                        Leads.push(Lead);
                    });
                }
                setBucketLead(Leads);
                resolve(Leads)
            }, reject)
          });
          
    }
    useEffect(()=>{
        subscribe();
    }, [])
    return (
        <div className={'bucket'}>
            <div className={'bucketTitle'} >
                <span onBlur={()=>setUpdateBucket(false)} onClick={()=>setUpdateBucket(true)}>{(!updatebucket)?bucketname:<input onChange={(e)=>setBucketName(e.target.value)} onBlur={updatebucketname} value={bucketname} />}</span>
                {(bucketLeads && bucketLeads.length < 1)&&<span onClick={()=>{deleteThisBucket()}} className={'floatright click delete'}><FontAwesomeIcon icon={faTimes} /></span>}
            </div>
            <div className={'bucketBody'}>
                {(deleting === false)?(bucketLeads)?(bucketLeads.length > 0)
                    ?
                            bucketLeads.map((Lead, i)=>{
                            return <ProspectCard 
                                        data={Lead}
                                        key={i}
                                        hidesuppress={true}
                                        refresh={subscribe}
                                    />
                        })
                    :<NoContent />:<Loading />:<Loading title={'Deleting'} bucketLeadstext={'Please Wait'} />}
            </div>
        </div>
    )
}
export default Bucket;
