import React, {useEffect, useState, useContext} from 'react';
import ChatSetupCard from './ChatSetupCard';
import Loading from '../Global/Loading';
import {getChatModules, deleteChatModule, updateChatModule} from '../../data/chats';
import {Store} from '../../stores/Store';
import NoContent from '../Global/NoContent';
import { Fragment } from 'react';
import PageTop from '../Global/pagetop';
import ChatDashboardButtons from './ChatDashboardButtons';

const ChatSettings = () => {
    let [chats, setChats] = useState(null);
    const context = useContext(Store);
    useEffect(()=>{
        if(chats === null){
            refresh();
        }
    }, [context.product_id]);
    const refresh = () =>{
        getChatModules(context.product_id).then((res)=>{
            setChats(res.data);
        });
    };
    const deleteCM = (chatmodule_id) =>{
        if(window.confirm('Are you sure you want to delete this chat module?')){
            deleteChatModule(context.product_id, chatmodule_id).then(()=>{
                refresh();
            });
        }
    }
    const toggleActive = (chatmodule_id, active) =>{
        if(window.confirm('Are you sure you want to '+((!active)?'deactivate':'activate')+' this chat module?')){
            updateChatModule(context.product_id, chatmodule_id, {active:active}).then(()=>{
                refresh();
            });
        }
    }
    return (chats !== null)?(
        <Fragment>
            <PageTop name={"Chats"} buttons={<ChatDashboardButtons callback={refresh} />}/>
            <div className='inner'>
                    <ul className="row mb-3 card-list">
                        {(chats && chats !== null)?chats.map((item, i)=>{
                            return <li className="col-12 col-md-6 col-lg-4 card_object"
                                        ><ChatSetupCard key={i} delete={deleteCM} refresh={refresh} toggleActive={toggleActive} {...item} />
                                    </li>
                        }):<NoContent/>}
                    </ul>
            </div>
        </Fragment>
    ):<Loading />;
}
export default ChatSettings;