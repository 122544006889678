import React from 'react';
const Bool = (props) => {
    return (
        <div className="input-group mb-3">
            <div className="input-group-prepend">
                <div className="input-group-text">{props.name}</div>
            </div>
            <select 
                name={'bool'} 
                className='form-control' 
                onChange={(e)=>{props.update(e.target.value)}} 
                value={props.value}
            >
                <option value={'false'}>False</option>
                <option value={'true'}>True</option>
            </select>
        </div>
    )
}
export default Bool;