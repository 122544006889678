import React, {Fragment} from 'react';
import { SketchPicker } from 'react-color';


class ColourSwatch extends React.Component {
  state = {
    displayColorPicker: false,
  };
  
  handleClick = () => {
    this.setState({displayColorPicker: !this.state.displayColorPicker});
  };

  handleClose = () => {
    this.setState({ displayColorPicker: false })
  };

  handleChange = (color) => {
    this.props.update(color.rgb);
  };
  checkColor(){
    if(this.props.color.g > 200){
      return (((this.props.color.r+this.props.color.g)+this.props.color.b)>=250)?'black':'white'
    }else{
      return (((this.props.color.r+this.props.color.g)+this.props.color.b)>=400)?'black':'white'
    }
    
  }
  render() {
    
    const styles =  {
        color: {
          lineHeight:'14px',
          color:this.checkColor(),
          fontSize:'10px',
          padding:'5px',
          borderRadius: '2px',
          background: `rgba(${ this.props.color.r }, ${ this.props.color.g }, ${ this.props.color.b }, ${ this.props.color.a })`,
          display: 'inline-block',
          boxShadow: '0 0 0 1px rgba(0,0,0,.1)'
        },
        swatch: {
          margin:'5px',
          padding: '5px',
          background: '#fff',
          borderRadius: '5px',
          boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
          display: 'inline-block',
          cursor: 'pointer'
        },
        popover: {
          position: 'absolute',
          zIndex: '2',
        },
        cover: {
          position: 'fixed',
          top:'0px',
          right:'0px',
          bottom:'0px',
          left:'0px'
        },
      };

    return (
      <Fragment>
        
        <div style={ styles.swatch }>
          <div style={ styles.color } onClick={ this.handleClick }>{this.props.name}</div>
          { this.state.displayColorPicker ? <div style={ styles.popover }>
            <div style={ styles.cover } onClick={ this.handleClose }/>
            <SketchPicker color={ this.props.color } onChange={ this.handleChange } />
          </div> : null }
        </div>
      </Fragment>
    )
  }
}

export default ColourSwatch