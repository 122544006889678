import React, {Component, Fragment} from 'react';
import {Store} from "../../stores/Store";
import {getUser} from '../../data/users';
import PageTop from '../Global/pagetop';
import Loading from '../Global/Loading';
import AccountDetails from './AccountDetails';

//componants

class Account extends Component {
    state = {
        user:null,
        super:false
      }
    componentDidMount(){
        getUser().then(async (res) => {
            await this.setState({
                    user:res
                }
            );
            if(this.props.match.params.user && this.context.user.access === 'super' && this.props.match.params.user !== this.context.user._id) /// last section of this if is to ensure the super user still has to manage his own account just like a normal user
            {
                this.setState({
                    super:true
                })
            }
        });
    }
    render() {
        return (
            <Fragment>
                <PageTop name='My Account' />
                <div className='inner'>
                    <div className='row'>
                        {(this.state.user !== null && this.state.user !== undefined)?
                            (
                                <div className='col-12'>
                                    <AccountDetails user={this.state.user} super={this.state.level} updateUserDetails={this.updateUserDetails} />
                                </div>
                            ):(
                                <Loading />
                            )
                        }
                    </div>
                </div>
            </Fragment>
        )
    }
}
Account.contextType = Store; 
export default Account;