import React, {Fragment, useEffect, useState, useContext} from 'react';
import PageTop from '../../Global/pagetop';
import EditPageButtons from './EditPageButtons';
import {componentUpdate, getComponents, deleteComponent, getPage, getGlobalComponents, getSite} from "../../../data/sites";
import VerticalTabs from '../../Global/VerticalTabs';
import PageTemplateElements from '../PageTemplateElements/';
import {Store} from '../../../stores/Store';
import PageComponent from './PageComponent';
import {
    useParams
  } from "react-router-dom";
import {getcomponents} from '../../../data/Models/components/';
import NoContent from '../../Global/NoContent';
import Loading from '../../Global/Loading';
import {SortableContainer, SortableElement} from 'react-sortable-hoc';
import {arrayMoveImmutable} from 'array-move';

const EditPage = (props) => {
    const context = useContext(Store);
    const [page, setPage] = useState(null);
    const [sortnum, setSortNum] = useState(0);
    const [components, setComponents] = useState([]);
    const [templateComponents, setTemplateComponents] = useState([]);
    const [globalComponents, setGlobalComponents] = useState([]);
    let {page_id, product_id} = useParams();
    const getCanvas = async () => {
        setComponents([]);
        setGlobalComponents([]);
        await getComponents(context.product_id, context.site.site_id, page_id).then((res)=>{
            setComponents(res.data);
        });
        await getGlobalComponents(context.product_id, context.site.site_id).then((res)=>{
            setGlobalComponents(res.data);
        });
        return
    }
    const deleteCanvasElement = (item_id) => {
        deleteComponent(context.product_id, context.site.site_id, page_id, item_id).then(()=>{
            getCanvas();
        });
    }
    const SortableItem = SortableElement(({value}) => <PageComponent refresh={getCanvas} globalcomponents={globalComponents} item={value} page_id={page_id} deleteCanvasElement={deleteCanvasElement} />);
    const SortableList = SortableContainer(({items}) => {
        return (
          <ul>
            {items.map((value, index) => (
              <SortableItem key={`item-${value.id}`} index={index} value={value} />
            ))}
          </ul>
        );
      });
    const onSortEnd = async ({oldIndex, newIndex}) => {
        setComponents(arrayMoveImmutable(components, oldIndex, newIndex));
        setSortNum(sortnum+1);
      };
    useEffect(()=>{
        let i = 0;
        for(let newpos of components){
            componentUpdate(context.product_id, context.site.site_id, page_id, newpos.id, {order:i+1});
            i++;
        }
        getCanvas();
    }, [sortnum]);
    useEffect(()=>{
        context.update({
            view:'sites'
        });
        getPage(context.product_id, context.site.site_id, page_id).then((res)=>{
            setPage(res.data);
        });
        getSite(context.product_id, context.site.site_id).then(res=>{
            let globalTemplateVariables = {};
            for(let variable of res.data.globalTemplateVariables.data){
                    globalTemplateVariables[variable.variableName] = variable.value;
            }
            setTemplateComponents(getcomponents(globalTemplateVariables));
        });
        getCanvas();
    }, []);
    return (page && globalComponents)?(
        <Fragment>
            <PageTop name={'Sites'} buttons={<EditPageButtons page={page} addBlock={()=>{}} />}/>
            <div className='inner'>
               <div className='row'>
                <div className='col-md-9'>
                    {(components && components.length > 0)?<SortableList helperClass={'sorting'} pressDelay={200} items={components} onSortEnd={onSortEnd} />:<NoContent />}
                </div>
                <div className='col-md-3'>
                    <VerticalTabs data={
                            [
                                {
                                    name:"Global Components",
                                    content:<PageTemplateElements getCanvas={getCanvas} page_id={page_id} global={true} elements={globalComponents} />
                                },{
                                    name:"Page Headers",
                                    content:<PageTemplateElements getCanvas={getCanvas} page_id={page_id} elements={templateComponents.filter((item)=> item.category === "navigation")} />
                                },
                                {
                                    name:"Hero",
                                    content:<PageTemplateElements getCanvas={getCanvas} page_id={page_id} elements={templateComponents.filter((item)=> item.category === "hero")} />
                                },
                                {
                                    name:"Content",
                                    content:<PageTemplateElements getCanvas={getCanvas} page_id={page_id} elements={templateComponents.filter((item)=> item.category === "content")} />
                                },
                                {
                                    name:"Forms",
                                    content:<PageTemplateElements getCanvas={getCanvas} page_id={page_id} elements={templateComponents.filter((item)=> item.category === "forms")} />
                                },
                                {
                                    name:"Footers",
                                    content:<PageTemplateElements getCanvas={getCanvas} page_id={page_id} elements={templateComponents.filter((item)=> item.category === "footers")} />
                                },
                                {
                                    name:"Custom",
                                    content:<PageTemplateElements getCanvas={getCanvas} page_id={page_id} elements={templateComponents.filter((item)=> item.category === "custom")} />
                                },
                                {
                                    name:"External",
                                    content:<PageTemplateElements getCanvas={getCanvas} page_id={page_id} elements={templateComponents.filter((item)=> item.category === "external")} />
                                }
                            ]
                        } 
                    />
                </div>
               </div>
            </div>
        </Fragment>
    ):<Loading />;
}
export default EditPage;