import React, {useEffect, useState, useContext} from 'react';
import ModalAlert from '../../Global/ModalAlert'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faEdit, faMinusSquare
} from '@fortawesome/free-solid-svg-icons';
import Files from '../../Content/Files';
import {getRoutes} from '../../../data/sites';
import {Store} from '../../../stores/Store';
const ImageLink = (props) => {
    let context = useContext(Store);
    let [src ,setSrc] = useState(props.src?props.src:'');
    let [altValue ,setAltValue] = useState(props.alt?props.alt:'');
    let [routes ,setRoutes] = useState([]);
    let [titleValue ,setTitleValue] = useState(props.title?props.title:'');
    let [link ,setLink] = useState(props.link);
    let [freetype ,setFreeType] = useState((props.freetype)?props.freetype:false);
    useEffect(()=>{
        props.update({
            src:src,
            alt:altValue,
            title:titleValue,
            type:'imagelink',
            link:link,
            freetype:freetype,
        });
    }, [src, altValue, titleValue, link, freetype]);
    useEffect(()=>{
        ///get routes
        getRoutes(context.product_id, context.site.site_id).then((res)=>{
            setRoutes(res.data);
        });
    }, []);
    return (
        <div className='card subcard'>
            <div className='card-header'>
                {props.name}
            </div>
            <div className='card-body'>
                <div className="input-group mb-3">
                    <div className="input-group-prepend">
                        <div className="input-group-text">Image</div>
                    </div>
                    <input 
                        type='text' 
                        name={'image'}
                        className='form-control'
                        readOnly
                        placeholder={'...image'}
                        value={src}
                    />
                    <button className='btn boxbutton warning nomargin' onClick={()=>{setSrc('')}}><FontAwesomeIcon icon={faMinusSquare} /></button>
                    <ModalAlert 
                        buttonClassName=' btn btn-success nomargin' 
                        buttonType='none'
                        buttonIcon={<FontAwesomeIcon icon={faEdit} />} 
                        title={'Select'} 
                        content={<Files use={setSrc} filter='image/' />}
                        size='lg'
                    />
                </div>
                <div className="input-group mb-3">
                    <div className="input-group-prepend">
                        <div className="input-group-text">Alt Text</div>
                    </div>
                    <input 
                        type='text'
                        onChange={(e)=>setAltValue(e.target.value)}
                        name={'altValue'}
                        className='form-control'
                        placeholder={'...Alt Text'}
                        value={altValue}
                    />
                </div>
                <div className="input-group mb-3">
                    <div className="input-group-prepend">
                        <div className="input-group-text">Title Text</div>
                    </div>
                    <input 
                        type='text' 
                        name={'titleValue'}
                        onChange={(e)=>setTitleValue(e.target.value)}
                        className='form-control'
                        placeholder={'...Title Text'}
                        value={titleValue}
                    />
                </div>
                <div className="input-group mb-3">
                <div className="input-group-prepend">
                    <div className="input-group-text">Image Link</div>
                </div>
                    {(freetype)?<input 
                        type='text' 
                        name={'link'}
                        onChange={(e)=>setLink(e.target.value)}
                        className='form-control'
                        placeholder={'...Link'}
                        value={link}
                    />:<select
                        className='form-control'
                        name={'link'}
                        onChange={(e)=>setLink(e.target.value)}
                        value={link}
                    >
                        <option value={''}>No Button - Select/Add a link to display a button</option>
                        {routes.map((item, i )=>{
                            return <option value={item.slug}>{item.slug} {item.name}</option>
                        })}
                    </select>}
                    <span onClick={()=>{setFreeType(!freetype); setLink('')}} className={'btn boxbutton'}>{(freetype)?'Choose Page':'Free Type'}</span>
                </div>
            </div>
        </div>
    )
}
export default ImageLink;