import React, {Component} from 'react';
import {Store} from "../../stores/Store";
import { Alert } from 'reactstrap';
import {NavLink} from 'react-router-dom';
import {ResetPassword} from '../../data/users';

class ForgotPassword extends Component {
    state = {
        email:'',
        sent:false,
        error:false,
        loginclass:''
    }

    submit = (e) => {
        e.preventDefault();
        let form ={
            email:this.state.email
        }
        ResetPassword(form).then(async (res)=>{
            if(res.success === false){
                await this.setState({
                    error:true
                });
                this.clear();
            }else{
                this.setState({
                    sent:true
                });
            }
        });
    }
    clear = async () => {
        await this.setState({
            loginclass:'addshake',
            email:'',
            sent:false
        });
        setTimeout(()=>{ 
            this.setState(
                {
                    loginclass:''
                }
            )
        }, 1000);
        setTimeout(()=>{ 
            this.setState(
                {
                    error:false
                }
            )
        }, 5000);
    }
    handleEmailChange = (e) => {
        this.setState({email: e.target.value});
    }
    render(){
        return (
            <section>
                <div className="container align-self-start ">
                    <div className="row justify-content-center">
                        <div className="col-12 col-md-8 col-lg-7">
                            <div className={"card card-lg text-center "+this.state.loginclass}>
                                <div className="card-body">
                                    <div className="mb-5">
                                        <div>
                                            <img alt='logo' style={{width:'30%'}} src={process.env.REACT_APP_LOGO} />
                                        </div>
                                        <br/>
                                        <p>Enter your email address and we will send you a temporary password</p>
                                        <p><NavLink to="/">Back to sign in</NavLink></p>
                                    </div>
                                    {(this.state.sent !== false)&&(
                                        <div className='col-12'>
                                            <div className="alert alert-warning" role="alert">
                                                <p>We have sent you an email to reset your paswword!</p>
                                            </div>
                                        </div>
                                    )}
                                    {(this.state.error !== false) && <Alert color="danger">We can't update this entry at this time</Alert>}
                                    <div className="row no-gutters justify-content-center">
                                        <form className="form-horizontal appform " onSubmit={this.submit}>
                                            <div className="form-group">
                                                <label>Email Address</label>
                                                <input type="email" value={this.state.email} onChange={this.handleEmailChange} className="form-control form-control-lg"  required />
                                            </div>
                                            <div className="text-center mt-3">
                                                <button type="submit" className="btn btn-lg btn-primary">Submit</button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}
ForgotPassword.contextType = Store; // This part is important to access context values
export default ForgotPassword;
