import {db} from '../firebase';
export const getBuckets = async (product_id) => {
    let responce = {
        success:true,
        data:[]
    }
    await db.collection('products').doc(product_id).collection('pipeline_buckets').orderBy("order")
    .get().then(async snapshot => {
        let buckets = [];
        if(!snapshot.empty)
        {
            snapshot.forEach(async doc => {
                let bucket = doc.data();
                bucket.id = doc.id;
                buckets.push(bucket);
            });
        }
        responce.data = buckets;
        return
    }).catch((error)=>{
        responce.success = false;
        responce.error = error;
        delete responce.data;
        return 
    });
    return responce;
}
export const addBucket = async (product_id, bucket) => {
    let responce = {
        success:true,
        data:[]
    }
    let buckets = await getBuckets(product_id);
    let order = 1;
    if(buckets.data.length > 0){
        let order = buckets.data[buckets.data.length-1].order+1;
    }
    
    /// get last one added 
    await db.collection('products').doc(product_id).collection('pipeline_buckets').add(
        {
            ...bucket,
            product_id:product_id,
            order:order
        }
    ).then(ref => {
        responce.data = ref
    }).catch((error)=>{
        responce.success = false;
        responce.error = error;
        delete responce.data;
        return 
    });
    return responce;
}
export const deleteBucket = async (product_id, bucket_id) => {
    let responce = {
        success:true,
        data:[]
    }
    responce = await db.collection("products").doc(product_id).collection("pipeline_buckets").doc(bucket_id).delete().then(() => {
        return responce;
    }).catch((error) => {
        responce.success = false;
        return responce;
    });
    return responce;
}
export const updateBucket = async (product_id, bucket_id, bucket) => {
    let responce = {
        success:true,
        data:[]
    }
    await db.collection('products').doc(product_id).collection('pipeline_buckets').doc(bucket_id).update(
        {
            ...bucket
        }
    ).then(ref => {
        responce.data = ref
    }).catch((error)=>{
        responce.success = false;
        responce.error = error;
        delete responce.data;
        return 
    });
    return responce;
}
