import React, {useState, useContext, Fragment, useEffect} from 'react';
import {updateRoute, getPages, getRoutes} from '../../data/sites';
import {Store} from '../../stores/Store';
import SingleSelect from '../Global/SingleSelect';
import { checkSlug } from '../../helpers/slug';
import MultiSelect from '../Global/MultiSelect';
const EditRoute = (props) => {
    const [slug, setSlug] = useState(props.slug);
    const [pages, setPages] = useState(null);
    const [slugs, setSlugs] = useState([]);
    const [cats, setCats] = useState([]);
    const [selectedcats, setSlectedCats] = useState([]);
    const [name, setName] = useState(props.name);
    const [page, setPage] = useState('');
    const [error, setError] = useState(false);
    const context = useContext(Store);
    const update = () => {
        if(cansubmit(true)){
            let data = {name:name, slug:slug, page:(page && page.value)?page.value:null};
            if(props.feed && props.type !== 'feedcat'){
                data.cats = selectedcats.map((item) =>{ return item.value });
            }
            updateRoute(context.product_id, context.site.site_id, props.id, data).then(()=>{
                props.close();
                props.refresh();
            });
        }
    }
    const cansubmit = (feedback = false) => {
        let submit = true;
        if(!name || name.length < 1){ submit = false; (feedback)&&alert('name needs to be al least 1 charactor')}
        if(!slug || slug.length < 1){ submit = false; (feedback)&&alert('slug needs to be al least 1 charactor')}
        if(slugs.includes(slug)){ submit = false; (feedback)&&alert('slug exists')}
        return submit;
    }
    const checkslug = (value) =>{
        if(checkSlug(value))
        {
            value = value.replace(/\s/g, '');
            if(value.includes("//") ){
                value = value.replace('//', '/')
            }
            
            if(value.charAt(0) !== '/')
            {
                value = '/'+value;
            }
            setSlug(value.toLowerCase());
        }
    }
    useEffect(()=>{
        getRoutes(context.product_id, context.site.site_id).then((res)=>{
            let slugArray = [];
            let catsArray = [];
            catsArray.push({ label:"No Category", value:null});
            for(let item of res.data){
                if(item.slug !== slug){slugArray.push(item.slug);}
                if(props.feed){
                    if(item.type === 'feedcat'){
                        catsArray.push({ label: item.name, value:item.id});
                    }
                }
            }
            setCats(catsArray);
            setSlugs(slugArray);
        });
        getPages(context.product_id, context.site.site_id).then((res)=>{
            let pageArray = [{ label: "Don't Link Page Yet", value:''}];
            for(let item of res.data){
                pageArray.push({label:item.name, value:item.id});
                if(props.page === item.id){
                    setPage({label:item.name, value:item.id});
                }
            }
            setPages(pageArray);
        });
    }, []);
    return (
        <Fragment>
            <div className="input-group mb-3">
                <div className="input-group-prepend">
                    <span className="input-group-text" id="namelabel">name</span>
                </div>
                <input type="text" name='name' required={true} onChange={(e)=>{setName(e.target.value)}} value={name} className={(error && name.length < 1)?"form-control formerror":"form-control"} placeholder="Name" />
            </div>
            <div className="input-group mb-3">
                <div className="input-group-prepend">
                    <span className="input-group-text" id="namelabel">Slug</span>
                </div>
                <input type="text" name='slug' required={true} onChange={(e)=>{checkslug(e.target.value)}} value={slug} className={(error && slug.length < 3)?"form-control formerror":"form-control"} placeholder="Slug" />
            </div>
            {(!props.feed)&&<SingleSelect 
                selected={page}
                dateSource={pages} 
                title={'Connected Page'} 
                titleBackgroundColor={"#5785da"}  
                titleColor={"#fff"}
                handleChange={setPage}
            />}
            {(!props.isCat)&&<MultiSelect
                dateSource={cats} 
                title={'In Category'} 
                titleBackgroundColor={"#5785da"}  
                titleColor={"#fff"}
                handleChange={setSlectedCats}
            />}
            <button 
                className={(cansubmit())?"btn btn-success nomargin floatright":"btn btn-success disabled nomargin floatright"}
                onClick={update}
            >
                Update
            </button>
        </Fragment>
    );
}
export default EditRoute;