import React, {Component} from 'react';
import Loading from "../../Global/Loading";
import NoContent from '../../Global/NoContent';
import {getNotes, newNote, deleteNote} from '../../../data/leads';
import {Store} from '../../../stores/Store';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faEdit, faTimes
} from '@fortawesome/free-solid-svg-icons';
import {formatDate} from '../../../helpers/time';
class Notes extends Component {
    state = {
        newnote:false,
        notes:null,
        form:{
            body:''
        }
    }
    componentDidMount(){
        this.refresh();
    }
    handleChange = (e) => {
        let form = {...this.state.form}
        form[e.target.name] = e.target.value;
        this.setState({
          form:form
        });
    }
    submit = () =>{
        newNote(this.context.product_id, this.props.prospect, this.state.form).then(()=>{
            this.setState({newnote:false});
            this.refresh();
            this.props.refreshStats();
        })
    }
    delete = (note_id) =>{
        deleteNote(this.context.product_id, this.props.prospect, note_id).then(()=>{
            this.refresh();
            this.props.refreshStats();
        })
    }
    refresh = () => {
        getNotes(this.context.product_id, this.props.prospect.id).then(async (res)=>{
            await this.setState({
                notes:res.data
            });
        });
    }
    render() {
    
        return (<div className="card">
                <div className="card-header">
                    Notes for this prospect <button className='floatright btn btn-none' onClick={()=>{this.setState({newnote:!this.state.newnote})}}><FontAwesomeIcon icon={faEdit} /></button>
                </div>
                {(this.state.newnote)&&(
                    <div className='newnote'>
                        <div className="input-group">
                            <textarea name='body' placeholder='New Note' className='form-control' onChange={this.handleChange} value={this.state.form.body}></textarea>   
                            <button className='input-group-addon btn btn-success' onClick={()=>{
                                this.submit();
                            }}>Submit</button>
                        </div>
                        <hr/>
                    </div>
                )}
                {(this.state.notes !== null)?(this.state.notes.length > 0)?(
                    <div className="card-body">
                        {this.state.notes.map((note, i)=>{
                            return (
                                <div key={i} className='note'>
                                    <span className='delete btn btn-none' onClick={()=>{window.confirm('Are you sure you would like to delete this note?', this.delete(note.id))}}>
                                        <FontAwesomeIcon icon={faTimes} />
                                    </span>
                                    {note.body}
                                    <div className='date'>{formatDate(note.date)}</div>
                                </div>
                            )
                        })}
                    </div>):<NoContent title='No Notes' text='No Notes Added Yet. Add new notes useig the buttons above' />
                :<Loading />}
            </div>)
    } 
}
Notes.contextType = Store; // This part is important to access context values
export default Notes;