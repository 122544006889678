import React, {Component, Fragment} from 'react';
//componants
import {Store} from "../../../stores/Store";
import {getLead} from '../../../data/leads';
import Visits from './Visits';
import Forms from './Forms';
import Notes from './Notes';
import Stats from './Stats';
import Tabs from './Tabs';
import Loading from '../../Global/Loading';
import Chats from './Chats';

class ViewProspect extends Component {
    state = {
        visitors:null,
        open:(this.props.open)?this.props.open:null,
        prospect:null
    }
    componentDidMount(){
        this.refresh();
    }
    refresh = async () => {
        getLead(this.context.product_id, this.props.l_id).then((res)=>{
            this.setState({
                prospect:res.data
            });
        });
    }
    openPayne = (payne) => {
        this.setState({
            open:(this.state.open === payne)?null:payne
        });
    }
    render() {
        return (this.state.prospect && this.state.prospect !== null)?(
            <Fragment>
                <Stats  prospect={this.state.prospect} />
                <Tabs open={this.openPayne} prospect={this.state.prospect} isopen={this.state.open} />
                {(this.state.open === 'visitors')&&<Visits refreshStats={this.refresh} prospect={this.state.prospect} />}
                {(this.state.open === 'forms')&&<Forms refreshStats={this.refresh} prospect={this.state.prospect} />}
                {(this.state.open === 'notes')&&<Notes refreshStats={this.refresh} prospect={this.state.prospect} />}
                {(this.state.open === 'chats')&&<Chats refreshStats={this.refresh} prospect={this.state.prospect} />}
            </Fragment>
        ):<Loading />
    }
}
ViewProspect.contextType = Store; // This part is important to access context values
export default ViewProspect;
