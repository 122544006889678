import React from 'react';
import {formatDate} from '../../helpers/time';
const ViewFormContact = (props) => {
    let jsonobject = JSON.parse(props.form);
    let objectkeys =  Object.keys(jsonobject);
    return (
    <div className='col-12'>
        <div className="card">
            <div className='card-body'>
                <div>
                    <ul className="list-group">
                        {
                            (jsonobject['form_name'])&&(
                                <li className="list-group-item">
                                    <span className="badge badge-secondary">FORM NAME</span> 
                                    <span className="floatright">{jsonobject['form_name']}</span>
                                </li>
                            )
                        }
                        <li className="list-group-item">
                            <span className="badge badge-secondary">DATE</span> 
                            <span className="floatright">{formatDate(props.date)}</span>
                        </li>
                        {objectkeys.map((item, i)=>{
                            return (
                                <li class="list-group-item">
                                    <span className="badge badge-secondary">{item.replace("_", " ").toUpperCase()}</span> 
                                    <span className="floatright">{jsonobject[item]}</span>
                                </li>
                            )
                        })}
                    </ul>
                </div>
            </div>
        </div>
    </div>)
}
export default ViewFormContact