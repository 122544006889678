import React, {Component, Fragment} from 'react';
import { Button, Modal, ModalHeader, ModalBody } from 'reactstrap';

class ModalAlert extends Component {
  state = {
      modal: false
  }
  toggle = () => {
    this.setState({
      modal: (this.state.modal)?false:true
    });
  }
  render() {
      return(
          <Fragment>
            {(this.props.span !== true)?(
              <Button
                  className={this.props.buttonClassName} 
                  style={this.props.buttonStyle && this.props.buttonStyle}
                  color={(this.props.buttonType)?this.props.buttonType:'success'}
                  size={this.props.buttonSize ? this.props.buttonSize:'sm' }
                  onClick={this.toggle}
                  title={this.props.title}
              >{this.props.buttonIcon && this.props.buttonIcon} {this.props.buttonLabel && this.props.buttonLabel}
              </Button>
            ):(
              <span
                  className={this.props.buttonClassName} 
                  onClick={this.toggle}
                  title={this.props.title}
              >{this.props.buttonIcon && this.props.buttonIcon} {this.props.buttonLabel && this.props.buttonLabel}
              </span>
            )}
              <Modal size={(this.props.size)?this.props.size:''} isOpen={this.state.modal} toggle={this.toggle}>
                <ModalHeader toggle={this.toggle}>{this.props.title}</ModalHeader>
                <ModalBody>
                  {React.cloneElement(
                      this.props.content, 
                      {
                        close:this.toggle,
                        callback:this.props.callback
                      }
                    )
                  }
                </ModalBody>
              </Modal>
          </Fragment>
      );
  }
}
export default ModalAlert;
