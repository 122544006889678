import React, {Fragment, useContext, useState} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faTrash, faPowerOff, faEdit
} from '@fortawesome/free-solid-svg-icons';
import Processing from '../../Global/Processing';
import {Store} from "../../../stores/Store";
import {deleteGlobalComponent, componentGlobalUpdate} from '../../../data/sites';
import ModalAlert from '../../Global/ModalAlert';
import EditComponent from '../EditComponent/';
const ButtonBar = (props) =>{
    let context = useContext(Store);
    let deletecomp = () => {
        let objdelete = window.prompt("This will remove the component from every page it is used in!! /n Please enter 'delete' to confirm", "");
        if(objdelete === 'delete'){
            props.setRemoving(true); 
            deleteGlobalComponent(context.product_id, context.site.site_id, props.item.id).then(
                props.refresh()
            );
        }
    }
    const update = (data)=>{
        componentGlobalUpdate(context.product_id, context.site.site_id, props.item.id, data);
    }
    let [processing, setProcessing] = useState(false);
        return (!processing)?(
            <div className="card-footer d-flex bg-gradient">
                <Fragment>
                    <div>
                        <ModalAlert 
                            buttonClassName='btn btn-sm barbutton floatright' 
                            buttonType='none'
                            buttonIcon={<FontAwesomeIcon  icon={faEdit} />} 
                            title={'Edit Global Component'}
                            content={<EditComponent item ={props.item} update={update} />}
                            size='lg'
                        />
                    </div>
                    <div>
                            <button 
                                className="btn btn-sm barbutton floatright warning"
                                onClick={deletecomp}
                            >  
                                <FontAwesomeIcon icon={faTrash} />
                            </button>
                    </div>
                    
                </Fragment>
            </div>        
        ):<Processing />
}

export default ButtonBar;