import React, {useState, useContext, Fragment, useEffect} from 'react';

import {addRoute, getPages, createPage, getRoutes, clonePageTemplate, getFeedCatRoutes} from '../../data/sites';
import {Store} from '../../stores/Store';
import SingleSelect from '../Global/SingleSelect';
import MultiSelect from '../Global/MultiSelect';
import {checkSlug} from '../../helpers/slug';
const CreateRoute = (props) => {
    const [slug, setSlug] = useState('/');
    const [slugs, setSlugs] = useState([]);
    const [cats, setCats] = useState([]);
    const [selectedcats, setSlectedCats] = useState([]);
    const [name, setName] = useState('');
    const [pages, setPages] = useState(null);
    const [page, setPage] = useState(null);
    const [feed, setFeed] = useState(null);
    const [error, setError] = useState(false);
    const context = useContext(Store);
    const create = async () => {
        if(cansubmit(true)){
            let newpage = null;
            if(page.value === 'flg-create'){
                let createpage = await createPage(context.product_id, context.site.site_id, {name:name});
                newpage = {label:name, value:createpage.data.id};
            }else if(page.value.includes('flg-template')){
                let pg_id = page.value.split(":");
                let createpage = await clonePageTemplate(context.product_id, context.site.site_id, pg_id[1], name, (props.feed)?props.feed:null); 
                newpage = {label:name, value:createpage.data.id};
            }
            //// create a page from template then set a mocked page object to the page state to connect he new page to the route in the same way as current 
            let routeobject = {slug:slug, name:name, page:(page && page.value)?(page.value === 'flg-create' || page.value.includes('flg-template'))?newpage.value:page.value:null, active:true};
            if(props.feed){
                routeobject.feed = props.feed;
            }
            if(props.isCat === true){
                routeobject.type = 'feedcat';
                routeobject.cats = selectedcats.map((item) =>{ return item.value });
            }
            addRoute(context.product_id, context.site.site_id, routeobject).then(()=>{
                props.close();
                props.update();
            });
        }
    }
    const cansubmit = (feedback = false) => {
        let submit = true;
        if(!name || name.length < 1){ submit = false; (feedback)&&alert('name needs to be al least 1 charactor')}
        if(!slug || slug.length < 1){ submit = false; (feedback)&&alert('slug needs to be al least 1 charactor')}
        if(slugs.includes(slug)){ submit = false; (feedback)&&alert('slug exists')}
        return submit;
    }
    const checkslug = (value) =>{
        if(checkSlug(value))
        {
            value = value.replace(/\s/g, '');
            if(value.includes("//") ){
                value = value.replace('//', '/')
            }
            
            if(value.charAt(0) !== '/')
            {
                value = '/'+value;
            }
            if(value.substr(-1) === '/' && value !== ((feed)?feed.slug+'/':'')){
                value= value.slice(0, -1);
            }
            if(feed && !value.includes(feed.slug+'/')){
                value = ((feed)?feed.slug+'/':'');
            }
            setSlug(value.toLowerCase());
        }
    }
    useEffect(()=>{
        getRoutes(context.product_id, context.site.site_id).then((res)=>{
            let slugArray = [];
            let catsArray = [];
            catsArray.push({ label:"No Category", value:null});
            for(let item of res.data){
                if(props.feed && props.feed === item.id && item.type === 'feed'){
                    setFeed(item);
                    setSlug(((item)?item.slug+'/':''));
                }
                if(props.isCat){
                    if(item.type === 'feedcat'){
                        catsArray.push({ label: item.name, value:item.id});
                    }
                }
                slugArray.push(item.slug);
            }
            setCats(catsArray);
            setSlugs(slugArray);
        });
        getPages(context.product_id, context.site.site_id).then((res)=>{
            
            let pageArray = [];
            if(!props.feed){
                pageArray.push({ label: "Don't Link Page Yet", value:null});
                pageArray.push({ label: "CREATE: empty page of same name", value:'flg-create'});
            }
            for(let item of res.data){
                console.log(item);
                if(item.template === true){
                    pageArray.push({label:"CREATE FROM TEMPLATE: "+item.name, value:"flg-template:"+item.id});
                }
            }
            if(!props.feed){
                for(let item of res.data){
                    if(item.template !== true){
                        pageArray.push({label:"LINK:"+item.name, value:item.id});
                    }
                }
            }
            
            setPages(pageArray);
        });
    },[]);
    return (
        <Fragment>
            <div className="input-group mb-3">
                <div className="input-group-prepend">
                    <span className="input-group-text" id="namelabel">Name</span>
                </div>
                <input type="text" name='name' required={true} onChange={(e)=>{setName(e.target.value)}} value={name} className={(error && name.length < 3)?"form-control formerror":"form-control"} placeholder="Name" />
            </div>
            <div className="input-group mb-3">
                <div className="input-group-prepend">
                    <span className="input-group-text" id="namelabel">Slug</span>
                </div>
                <input type="text" name='slug' required={true} onChange={(e)=>{checkslug(e.target.value)}} value={slug} className={(error && slug.length < 1)?"form-control formerror":"form-control"} placeholder="Slug" />
            </div>
            <SingleSelect 
                dateSource={pages} 
                title={'Connected Page'} 
                titleBackgroundColor={"#5785da"}  
                titleColor={"#fff"}
                handleChange={setPage}
            />
            {(!props.isCat)&&<MultiSelect
                dateSource={cats}
                titleBackgroundColor={"#5785da"}  
                titleColor={"#fff"}
                handleChange={setSlectedCats}
            />}<br/>
            <button 
                className={(cansubmit())?"btn btn-success nomargin floatright":"btn btn-success disabled nomargin floatright"}
                onClick={create}
            >
                Create
            </button>
        </Fragment>
    );
}
export default CreateRoute;