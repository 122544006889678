import React, {Fragment, useContext, useState} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faTrash, faPowerOff, faEdit, faEye
} from '@fortawesome/free-solid-svg-icons';
import Processing from '../../Global/Processing';
import {Store} from "../../../stores/Store";
import {deleteRoute, updateRoute, deletePage} from '../../../data/sites';
import ModalAlert from '../../Global/ModalAlert';
import EditRoute from '../EditRoute';
import { useHistory } from "react-router-dom";

const ButtonBar = (props) =>{
    let context = useContext(Store);
    const history = useHistory();
    let routedelete = () => {
        let objdelete = window.prompt("Please enter 'delete' to confirm", "");
        let deletepage = window.confirm("would you like to delete the connected page");
        if(objdelete === 'delete'){
            props.setRemoving(true); 
            deleteRoute(context.product_id, context.site.site_id, props.id).then(
                ()=>{
                    props.refresh()
                }
            );
            if(deletepage){
                deletePage(context.product_id, context.site.site_id, props.page);
            }
        }
    }
    let [processing, setProcessing] = useState(false);
        return (!processing)?(
            <div className="card-footer d-flex bg-gradient">
                <Fragment>
                    <div>
                        
                        <ModalAlert 
                            buttonClassName='btn btn-sm barbutton floatright' 
                            buttonType='none'
                            buttonIcon={<FontAwesomeIcon  icon={faEdit} />} 
                            title={'Edit Route'}
                            content={<EditRoute {...props} />}
                            size='lg'
                        />
                        <button
                        title='View Feed Content' 
                            className="btn btn-sm barbutton floatright"
                            onClick={()=>{ history.push("/product/"+context.product_id+"/site/feeds/"+props.id);}}
                        >
                            <FontAwesomeIcon icon={faEye} />
                        </button>
                    </div>
                    <div>
                        <button 
                            className="btn btn-sm barbutton floatright warning"
                            onClick={()=>{updateRoute(context.product_id, context.site.site_id, props.id, {active:!props.active}).then(()=>{props.refresh()})}}
                        >
                            <FontAwesomeIcon icon={faPowerOff} />
                        </button>
                        <button 
                            className="btn btn-sm barbutton floatright warning"
                            onClick={routedelete}
                        >  
                            <FontAwesomeIcon icon={faTrash} />
                        </button>
                    </div>
                </Fragment>
            </div>        
        ):<Processing />
}

export default ButtonBar;