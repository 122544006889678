import React, {useState, useContext, Fragment, useEffect} from 'react';

import {updateSite, getDomains} from '../../data/sites';
import {Store} from '../../stores/Store';
import SingleSelect from '../Global/SingleSelect';
const EditSite = (props) => {
    const [sitename, setSiteName] = useState(props.name);
    const [domains, setDomains] = useState([]);
    const [https, setHTTPS] = useState(props.https);
    const [domain, setDomain] = useState({label:props.domain, value:props.domain});
    const [error, setError] = useState(false);
    const context = useContext(Store);
    useEffect(()=>{
        getDomains(context.product_id, true).then((res)=>{
            setDomains(res.data);
        });
    },[])
    const update = () => {
        updateSite(context.product_id, props.id, {name:sitename, domain:domain.value, https:https}).then(()=>{
            props.close();
            props.refresh();
        });
    }
    const check = () => {
        return ((domain && (domain.value !=='' && domain.value !== undefined)) && (sitename && sitename.length > 3))
    }
    return (
        <Fragment>
            <div className="input-group mb-3">
                <div className="input-group-prepend">
                    <span className="input-group-text" id="namelabel">Site Name</span>
                </div>
                <input type="text" name='name' required={true} onChange={(e)=>{setSiteName(e.target.value)}} value={sitename} className={(error && sitename.length < 3)?"form-control formerror":"form-control"} placeholder="Site Name" />
                
            </div>
            <SingleSelect 
                dateSource={[{label:'http', value:false}, {label:'https', value:true}]} 
                title={'Protocol'}
                selected={{label:(https)?'https':'http', value:https}}
                titleBackgroundColor={"#5785da"}  
                titleColor={"#fff"}
                handleChange={(ob)=>setHTTPS(ob.value)}
            />
            <SingleSelect 
                selected={domain}
                dateSource={[{label:props.domain, value:props.domain}, ...domains]} 
                title={'Domain'} 
                titleBackgroundColor={"#5785da"}  
                titleColor={"#fff"}
                handleChange={setDomain}
            />
            <div >
                    <p><b>Tracking Code:</b></p>
                        <div className='codebox' dangerouslySetInnerHTML={{__html: '&lt;script type="text/javascript" src="https://flg.onl/fledge?productId='+props.product_id+'&siteId='+props.id+'"&gt;&lt;/script&gt;'}} />
                </div>
            <button className={(check())?"btn btn-success nomargin floatright":"btn btn-success disabled nomargin floatright"} onClick={(check())&&update}>Save</button>
        </Fragment>
    );
}
export default EditSite;