
import React from 'react';
import Select from 'react-select';

const SingleSelect = (props) =>{
    let style={
        container:{
            width:'100%',
            display:'flex',
            flexDirection:'row',
            justifyContent:'flex-start',
            alignContent:'center',
            marginBottom:"20px",
        },
        select:{
            display:"flex",
            flex: 1,
        },
        title:{
            display:"flex",
            alignItems:"center",
            justifyContent:"center",
            borderRadius:"5px 0 0 5px",
            padding:"5px",
            background:"#ccc",
            color:"#fff"
        }
    }
    if(props.titleColor){ style.title.color = props.titleColor}
    if(props.titleBackgroundColor){ style.title.background = props.titleBackgroundColor}
    return (!props.simple)?(
        <div style={style.container}>
            
            <div style={style.title}>{props.title}</div>
            <div style={style.select}>
                <div style={{width:"100%"}}>
                <Select
                    defaultValue={props.selected}
                    value={props.selected}
                    onChange={props.handleChange}
                    closeMenuOnSelect={true}
                    options={props.dateSource}
                />
                </div>
            </div>
        </div>
    ):(
        <Select
            defaultValue={props.selected}
            value={props.selected}
            onChange={props.handleChange}
            closeMenuOnSelect={true}
            options={props.dateSource}
            placeholder={props.title}
        />
    )
}
export default SingleSelect;