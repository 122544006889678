import React, {useEffect, useState } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faPlus, faTrash
} from '@fortawesome/free-solid-svg-icons';
import {capEveryWord} from '../../../helpers/index';
import Link from './Link';
import Image from './Image';
import ImageLink from './ImageLink';
const Element = (type) => {
    switch (type) {
        case 'images':
        return {
                type:'image',
                src:'',
                title:'',
                alt:'',
                name:'Image Element'
            }
        case 'imagelinks':
            return {
                type:'imagelink',
                src:'',
                title:'',
                alt:'',
                link:'',
                name:'Image With Link Element'
            }
        case 'links':
            return {
                type:'link',
                text:'',
                title:'',
                link:'',
                name:'Link Element',
                freetype:false
            }
    }
}

const ObjectArray = (props) => {
    let [data , setData] = useState(props.data);
    const updateDataObject = (i, object) => {
        let tempdata = data;
        tempdata[i] = object;
        setData([...tempdata]);
    }
    const removeDataObject = (i) => {
        let tempdata = data;
        tempdata.splice(i,1);
        setData([...tempdata]);
    }
    const addElement = (type) => {
        let tempdata = data;
        let el = Element(type);
        tempdata.push(el);
        setData([...tempdata])
    }
    useEffect(()=>{
        props.update(data);
    }, [data]);
    return (
        <div className='card containergroup'>
            <div className="card-header">
                {props.name}
            </div>
            <div className="card-body">
                <div className="col-12">
                    {data.map((item, i)=>{
                        return (
                            <div className="card subcard">
                                <div className='card-header'>
                                    {capEveryWord(item.type)}
                                    <span className='btn boxbutton smaller floatright' onClick={()=>removeDataObject(i)}><FontAwesomeIcon icon={faTrash} /></span>
                                </div>
                                <div className='card-body'>
                                    {(props.contains === 'links')&&<Link {...item} update={(object)=>{updateDataObject(i, object)}} />}
                                    {(props.contains === 'imagelinks')&&<ImageLink {...item} update={(object)=>{updateDataObject(i, object)}} />}
                                    {(props.contains === 'images')&&<Image {...item} update={(object)=>{updateDataObject(i, object)}} />}
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>
            <div className="col-12 space bottom">
                <div className='btn boxbutton fullwidth' onClick={()=>addElement(props.contains)}><FontAwesomeIcon icon={faPlus} /> Add</div>
            </div>
        </div>
    );
}
export default ObjectArray