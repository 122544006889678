export const applyFilters = (leads, context) => {
    let filters = context.filters;
    let showcountry = [];
    if(filters.country.selected !== null && filters.country.selected.length > 0)
    {
        filters.country.selected.forEach((item)=>{
            if(!showcountry.includes(item.value))
            {
                showcountry.push(item.value.toUpperCase())
            }
        });
    }
    
    let showcity = [];
    if(filters.city.selected !== null && filters.city.selected.length > 0)
    {
        filters.city.selected.forEach((item)=>{
            if(!showcity.includes(item.value))
            {
                showcity.push(item.value.toUpperCase())
            }
        });
    }
    return leads.filter(
        lead=>(showcountry.length > 0)?(lead.maxmind.country)?showcountry.includes(lead.maxmind.country.names.en.toUpperCase()):false:true
    ).filter(
        lead=>(showcity.length > 0)?(lead.maxmind.city)?showcity.includes(lead.maxmind.city.names.en.toUpperCase()):false:true
    );
}
export const sortContacts = (contacts, context) => {
    if(context.sort.sort_date)
    {
        return (context.sort.sort_down)
        ?contacts.sort((a, b) => (b.date > a.date) ? 1 : -1)
        :contacts.sort((a, b) => (a.date > b.date) ? 1 : -1)
    }else{
        return (context.sort.sort_down)
        ?contacts.sort((a, b) => {
            let textA = (a.name !== null)?a.name.toUpperCase():(a.networkSuppressed)?a.maxmind.traits.organization.toUpperCase():(a.latest_contact_name)?a.latest_contact_name.name.toUpperCase():'UNKNOWN CONTACT';
            let textB = (a.name !== null)?b.name.toUpperCase():(b.networkSuppressed)?b.maxmind.traits.organization.toUpperCase():(b.latest_contact_name)?b.latest_contact_name.name.toUpperCase():'UNKNOWN CONTACT';
            return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
        })
        :contacts.sort((a, b) => {
            let textA = (a.name !== null)?a.name.toUpperCase():(a.networkSuppressed)?a.maxmind.traits.organization.toUpperCase():(a.latest_contact_name)?a.latest_contact_name.name.toUpperCase():'UNKNOWN CONTACT';
            let textB = (a.name !== null)?b.name.toUpperCase():(b.networkSuppressed)?b.maxmind.traits.organization.toUpperCase():(b.latest_contact_name)?b.latest_contact_name.name.toUpperCase():'UNKNOWN CONTACT';
            return (textA > textB) ? -1 : (textA < textB) ? 1 : 0;
        })
    }
}