import React, {useState, useContext, useEffect} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faTrash, faSpinner, faEdit, faChevronUp, faChevronDown
} from '@fortawesome/free-solid-svg-icons';
import ModalAlert from '../../Global/ModalAlert';
import EditComponent from '../EditComponent/';
import { componentUpdate, componentGlobalUpdate } from '../../../data/sites';
import { Store } from '../../../stores/Store';
const PageComponent = (props) => {
    let [item, setItem] = useState(props.item);
    let [deleting, setDeleting] = useState(false);
    let context = useContext(Store);
    const deleteThis = () => {
       if(window.confirm('Are you sure you would like to delete this element from the canvas')){
            setDeleting(true);
            props.deleteCanvasElement(item.id);
       }
    }
    const update = async (data)=>{
        await componentUpdate(context.product_id, context.site.site_id, props.page_id, item.id, data);
        props.refresh();
    }
    const globalupdate = (data)=>{
        componentGlobalUpdate(context.product_id, context.site.site_id, item.global, data);
        props.refresh();
    }
    useEffect(()=>{
        if(item.global){
            for(let comp of props.globalcomponents){
                if(comp.id === item.global){
                    setItem({...comp, ...item, component_id:item.id});
                }
            }
        }
    }, [])
    return (
        <div className={(props.item.global)?"canvasComponent global":"canvasComponent"}>
            <div className='name'>
                {(deleting)&&<div><FontAwesomeIcon spin={true} icon={faSpinner} /> Deleting</div>}
                {(!deleting)&&<div>{(props.item.global)&&"Global:"} {item.name}</div>}
            </div>
            <div className='buttons'>
                {(!deleting)&&<div className='btn btn-sm boxbutton warning' onClick={()=>{deleteThis()}}><FontAwesomeIcon icon={faTrash} /></div>}
                
                {(!deleting)&&<ModalAlert 
                            buttonClassName='btn btn-sm boxbutton white floatright' 
                            buttonType='none'
                            buttonIcon={<FontAwesomeIcon icon={faEdit} />} 
                            title={'Edit Canvas Component'}
                            content={<EditComponent page_id={props.page_id} update={(data)=>(item.global && props.globalcomponents)?globalupdate(data):update(data)} item={item} />}
                            size='lg'
                        />}
            </div>
        </div>
    );
}
export default PageComponent;