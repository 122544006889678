import React from "react";
import PageTemplateElement from "./PageTemplateElement";
import NoContent from '../../Global/NoContent';
const PageContentElements = (props) => {
    return <div className='templatePageComponents'>
        {(props.elements.length > 0)
            ?props.elements.map(
                (item, i)=><PageTemplateElement getCanvas={props.getCanvas} global={(props.global)?true:false} page_id={(props.page_id)?props.page_id:false} key={i} item={item} />)
            :<NoContent/>}</div>
}
export default PageContentElements;