import React,  {Fragment, useContext, useEffect} from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {useHistory} from 'react-router-dom';
import {
    faGlobe, faListAlt, faBullseye, faChartLine, faCommentDots, faNetworkWired, faImages, faGripHorizontal, faRandom, faProjectDiagram
} from '@fortawesome/free-solid-svg-icons';
import {Store} from "../../stores/Store";
import PageTop from '../Global/pagetop';
const Dashboard = (props) =>{
    const context = useContext(Store); // This part is important to access context values
    const history = useHistory();
    useEffect(()=>{
        context.update({
            view:''
        });
        if(!context.product_id && props.match.params.product_id)
        {
            context.update({
                product_id:props.match.params.product_id
            });
        }
    },[]);
    const switchview = (view) =>{
        context.update({view:view});
        history.push("/product/"+context.product_id+"/"+view);
    }
    return (
        <Fragment>
            <PageTop name={'Dashboard'} />
            <div className='inner'>
                <div class='row'>
                    <div className="col-12">
                        <div class="alert alert-primary" role="alert">
                            <span className='text'>You can quickly switch products and projects any time using the <FontAwesomeIcon icon={faRandom} /> and <FontAwesomeIcon icon={faProjectDiagram} /> in the nav bars.</span>
                        </div>
                    </div>
                    <div className="col-6 col-sm-4 col-md-3">
                        <div className={'card singleswitch'} onClick={()=>{switchview('');}}>
                            <div className='row'>
                                <div className="col-12 title">
                                    <span className='icon'><FontAwesomeIcon icon={faGripHorizontal} /></span>
                                    <span className='text'>Dashboard</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-6 col-sm-4 col-md-3">
                        <div className={'card singleswitch'} onClick={()=>{switchview('overview');}}>
                            <div className='row'>
                                <div className="col-12 title">
                                    <span className='icon'><FontAwesomeIcon icon={faChartLine} /></span>
                                    <span className='text'>Analytics</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-6 col-sm-4 col-md-3">
                        <div className={'card singleswitch'} onClick={()=>{switchview('pipeline');}}>
                        <div className='row'>
                            <div className="col-12 title">
                                <span className='icon'><FontAwesomeIcon icon={faBullseye} /></span>
                                <span className='text'>Pipeline</span>
                            </div>
                        </div>
                        </div>
                    </div>
                    
                    <div className="col-6 col-sm-4 col-md-3">
                        <div className={'card singleswitch'} onClick={()=>{switchview('research');}}>
                        <div className='row'>
                            <div className="col-12 title">
                                <span className='icon'><FontAwesomeIcon icon={faNetworkWired} /></span>
                                <span className='text'>Network</span>
                            </div>
                        </div>
                        </div>
                    </div>
                    <div className="col-6 col-sm-4 col-md-3">
                        <div className={'card singleswitch'} onClick={()=>{switchview('sites');}}>
                        <div className='row'>
                            <div className="col-12 title">
                                <span className='icon' onClick={()=>{context.update({view:'pages'})}}><FontAwesomeIcon icon={faGlobe} /></span>
                                <span className='text'>Sites</span>
                            </div>
                        </div>
                        </div>
                    </div>
                    <div className="col-6 col-sm-4 col-md-3">
                        <div className={'card singleswitch'} onClick={()=>{switchview('content');}}>
                        <div className='row'>
                            <div className="col-12 title">
                                <span className='icon'><FontAwesomeIcon icon={faImages} /></span>
                                <span className='text'>Content</span>
                            </div>
                        </div>
                        </div>
                    </div>
                    <div className="col-6 col-sm-4 col-md-3">
                        <div className={'card singleswitch'} onClick={()=>{switchview('forms');}}>
                        <div className='row'>
                            <div className="col-12 title">
                                <span className='icon'><FontAwesomeIcon icon={faListAlt} /></span>
                            
                                <span className='text'>Forms</span>
                            </div>
                        </div>
                        </div>
                    </div>
                    <div className="col-6 col-sm-4 col-md-3">
                        <div className={'card singleswitch'} onClick={()=>{switchview('chats');}}>
                            <div className='row'>
                                <div className="col-12 title">
                                    <span className='icon'><FontAwesomeIcon icon={faCommentDots} /></span>
                                    <span className='text'>Chat</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
}

export default Dashboard;
