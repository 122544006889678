import React, {useState, useContext, Fragment, useEffect} from 'react';

import {addFeed, getPages, createPage, getRoutes, clonePageTemplate} from '../../data/sites';
import {Store} from '../../stores/Store';
import SingleSelect from '../Global/SingleSelect';
import {checkSlug} from '../../helpers/slug';
const CreateFeedRoute = (props) => {
    const [slug, setSlug] = useState('/');
    const [slugs, setSlugs] = useState([]);
    const [name, setName] = useState('');
    const [pages, setPages] = useState(null);
    const [page, setPage] = useState(null);
    const [error, setError] = useState(false);
    const context = useContext(Store);
    const create = async () => {
        if(cansubmit(true)){
            let newpage = null;
            if(page.value === 'flg-create'){
                let createpage = await createPage(context.product_id, context.site.site_id, {name:name});
                newpage = {label:name, value:createpage.data.id};
            }else if(page.value.includes('flg-template')){
                let pg_id = page.value.split(":");
                let createpage = await clonePageTemplate(context.product_id, context.site.site_id, pg_id[1], name); 
                newpage = {label:name, value:createpage.data.id};
            }
            await addFeed(context.product_id, context.site.site_id, {slug:slug, name:name, page:(page && page.value)?(newpage !== null)?newpage.value:page.value:null, active:true}).then(()=>{
                props.close();
                props.update();
            });
        }
    }
    const cansubmit = (feedback = false) => {
        let submit = true;
        if(!name || name.length < 1){ submit = false; (feedback)&&alert('name needs to be al least 1 charactor')}
        if(!slug || slug.length < 1){ submit = false; (feedback)&&alert('slug needs to be al least 1 charactor')}
        if(slugs.includes(slug)){ submit = false; (feedback)&&alert('slug exists')}
        return submit;
    }
    const checkslug = (value) =>{
        if(checkSlug(value))
        {
            value = value.replace(/\s/g, '');
            if(value.includes("//") ){
                value = value.replace('//', '/')
            }
            
            if(value.charAt(0) !== '/')
            {
                value = '/'+value;
            }
            setSlug(value.toLowerCase());
        }
    }
    useEffect(()=>{
        getRoutes(context.product_id, context.site.site_id).then((res)=>{
            let slugArray = [];
            for(let item of res.data){
                slugArray.push(item.slug);
            }
            setSlugs(slugArray);
        });
        getPages(context.product_id, context.site.site_id).then((res)=>{
            let pageArray = [
                { label: "Don't Link Page Yet", value:null},
                { label: "CREATE: empty page of same name", value:'flg-create'},
                
            ];
            for(let item of res.data){
                if(item.template === true){
                    pageArray.push({label:"CREATE FROM TEMPLATE: "+item.name, value:"flg-template:"+item.id});
                }
            }
            for(let item of res.data){
                if(item.template !== true){
                    pageArray.push({label:"LINK:"+item.name, value:item.id});
                }
            }
            setPages(pageArray);
        });
    },[]);
    return (
        <Fragment>
            <div className="input-group mb-3">
                <div className="input-group-prepend">
                    <span className="input-group-text" id="namelabel">Name</span>
                </div>
                <input type="text" name='name' required={true} onChange={(e)=>{setName(e.target.value)}} value={name} className={(error && name.length < 3)?"form-control formerror":"form-control"} placeholder="Name" />
            </div>
            <div className="input-group mb-3">
                <div className="input-group-prepend">
                    <span className="input-group-text" id="namelabel">Slug</span>
                </div>
                <input type="text" name='slug' required={true} onChange={(e)=>{checkslug(e.target.value)}} value={slug} className={(error && slug.length < 1)?"form-control formerror":"form-control"} placeholder="Slug" />
            </div>
            <SingleSelect 
                dateSource={pages} 
                title={'Connected Page'} 
                titleBackgroundColor={"#5785da"}  
                titleColor={"#fff"}
                handleChange={setPage}
            />
            <button 
                className={(cansubmit())?"btn btn-success nomargin floatright":"btn btn-success disabled nomargin floatright"}
                onClick={create}
            >
                Create
            </button>
        </Fragment>
    );
}
export default CreateFeedRoute;