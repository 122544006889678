import React, {useState, useContext, Fragment, useEffect} from 'react';
import {Store} from '../../../stores/Store';
import {addtobucket} from '../../../data/leads';
import { getBuckets } from '../../../data/pipelines';
import SingleSelect from '../../Global/SingleSelect';

const AddToPipelineBucket = (props) => {
    const [error, setError] = useState(false);
    const [buckets, setBuckets] = useState(null);
    const [bucket, setBucket] = useState((props.pipeline_bucket)?props.pipeline_bucket:null);
    const context = useContext(Store);
    const addToBucket = (LeadId) => {
        addtobucket(context.product.id, LeadId, bucket.value).then(()=>{
            props.close();
            (props.refresh)&&props.refresh();
        });
    }
    useEffect(()=>{
        getBuckets(context.product_id).then(res=>{
            let arr = [{value:null, label:"No Pipeline Bucket"}];
            for(let buck of res.data){
                arr.push({value:buck.id, label:buck.name})
                if(props.pipeline_bucket === buck.id){
                    setBucket({value:buck.id, label:buck.name})
                }
            }
            setBuckets(arr);
        });
    }, []);
    return (
        <Fragment>
            <SingleSelect 
                dateSource={buckets}
                selected={bucket}
                title={'Add To Bucket'} 
                titleBackgroundColor={"#5785da"}  
                titleColor={"#fff"}
                handleChange={setBucket}
            />
            <button className="btn btn-success nomargin floatright" onClick={()=>(!props.useLeadId)?addToBucket(props.id):addToBucket((props.leadId)?props.leadId:props.data.leadId)}>Save</button>
        </Fragment>
    );
}
export default AddToPipelineBucket; 
