import React, {Component, Fragment} from 'react';
import PageTop from "../Global/pagetop/";
import {Store} from '../../stores/Store';
import {getProduct} from '../../data/products';
import {getLeads} from '../../data/leads';
import Loading from '../Global/Loading';
import ResearchCard from './ResearchCard';
import NoContent from '../Global/NoContent';
import ResearchTable from './ResearchTable/';
import {applyFilters} from '../../helpers/filters';
import FilterButtons from '../Global/FilterButtons';
class Product extends Component {
    state = {
        product:this.context.product?this.context.product:null,
        leads:null,
        cards:this.context.cards,
    }
    componentDidMount(){
        this.context.update({
            product_id:this.props.match.params.product_id
        });
        getProduct(this.props.match.params.product_id).then(async (res)=>{
            await this.setState({
                product:res.data
            });
            this.context.update({
                product:res.data
            });
        });
        this.refresh();
    }
    refresh = async () => {
        await this.context.update({
            leads:null
        });
        getLeads(this.props.match.params.product_id, this.context.back, this.context).then(async (res)=>{
            this.context.update({
                leads:res.data
            });
        });
    }
    toggleView = () => {
        this.context.update({
            cards:!this.context.cards
        });
    }
    filterLeads = () => {
        return applyFilters(this.context.leads.filter(lead=>lead.deleted === false), this.context);
    }
    suppressSearch=(suppressname)=>{
        let newleadlist = this.context.leads.map((lead, i)=>{
            if(lead.maxmind.traits.organization === suppressname){
                lead.networkSuppressed = true;
            }
            return lead
        });
        this.context.update({
            leads:newleadlist
        });
    }
    render(){
        return (this.state.product)?(
            <Fragment>
                <PageTop name={'Network'} buttons={<FilterButtons refresh={this.refresh} toggleView={this.toggleView} update={this.update} />} />
                <div className='inner'>
                    {(this.context.leads)?
                        (this.filterLeads().length > 0)?(this.context.cards === true)?(
                            <ul className="row mb-3 card-list">
                                {this.filterLeads().map((Lead, i)=>{
                                    return <li className="col-12 col-md-6 col-lg-4 card_object"><ResearchCard 
                                                data={Lead}
                                                key={i}
                                                suppressSearch={this.suppressSearch}
                                                refresh={this.refresh}
                                            /></li>
                                })}
                            </ul>
                      ):(
                            <div className='row'>
                                <div className='col-12'>
                                    <div className='card'>
                                        <div className="card-body">
                                            <ResearchTable data={this.filterLeads()} refresh={this.refresh}/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ):<NoContent />
                    :<Loading />}
                </div>
            </Fragment>
        ):(
            <Fragment>
                <PageTop name={'Loading Network'} />
                <div className='inner'>
                    <Loading />
                </div>
            </Fragment>
        );
    }
}
Product.contextType = Store; // This part is important to access context values
export default Product;
