import React, { useState, useContext, Fragment } from 'react';
import { Store } from '../../stores/Store';
import {pageUpdate} from '../../data/sites';
import Text from './Types/Text';
import Color from './Types/Color';
import Image from './Types/Image';
import ImageLink from './Types/ImageLink';
import ObjectArray from './Types/ObjectArray';
import Bool from './Types/Bool';
import TextArea from './Types/TextArea';
import Wysiwyg from './Types/Wysiwyg';
import Form from './Types/Form';
import Number from './Types/Number';
import Select from './Types/Select';
import SimpleImage from './Types/SimpleImage';
const PageVariables = (props) => {
    let context = useContext(Store);
    let [variables, setVariable] = useState(props.pageVariables);
    let [name, setName] = useState(props.name);
    const setDataArray = (i, array) => {
        let tempdata = variables;
        tempdata.data[i].data = array;
        setVariable({...tempdata});
    }
    const setDataObject= (i, object) => {
        let tempdata = variables;
        tempdata.data[i] = {
            ...tempdata.data[i],
            ...object
        }
        setVariable({...tempdata});
    }
    const setDataPoint = (i, value) => {
        let tempdata = variables;
        tempdata.data[i].value = value;
        setVariable({...tempdata});
    }
    const updateVariables = () => {
        pageUpdate(context.product_id, context.site.site_id, props.id, {
            pageVariables:variables,
            name:name
        
        });
        props.close();
    }
    return (
        <div>
            <div className={'card'}>
                <div className={'card-header'}>
                    Global Page Variables Affect All Components in This Page
                </div>
                <div className={'card-body'}>
                <div className="input-group mb-3">
                    <div className="input-group-prepend">
                        <span className="input-group-text" id="namelabel">name</span>
                    </div>
                    <input type="text" name='name' required={true} onChange={(e)=>{setName(e.target.value)}} value={name} className={(name.length < 1)?"form-control formerror":"form-control"} placeholder="Name" />
                </div>
                {variables.data.map((item,i)=>{
                        return (
                            <Fragment key={i}>
                                {(item.type === 'select')&&<Select {...item} update={(value)=>{setDataPoint(i, value)}} />}
                                {(item.type === 'number')&&<Number {...item} update={(value)=>{setDataPoint(i, value)}} />}
                                {(item.type === 'color')&&<Color {...item} update={(value)=>{setDataPoint(i, value)}} />}
                                {(item.type === 'bool')&&<Bool {...item} update={(value)=>{setDataPoint(i, value)}} />}
                                {(item.type === 'textarea')&&<TextArea {...item} update={(value)=>{setDataPoint(i, value)}} />}
                                {(item.type === 'text')&&<Text {...item} update={(value)=>{setDataPoint(i, value)}} />}
                                {(item.type === 'wysiwyg')&&<Wysiwyg {...item} update={(value)=>{setDataPoint(i, value)}} />}
                                {(item.type === 'imagelink')&&<ImageLink {...item} update={(val)=>{setDataObject(i, val)}} />}
                                {(item.type === 'objectarray')&&<ObjectArray {...item} update={(val)=>{setDataArray(i, val)}} />}
                                {(item.type === 'image')&&<Image {...item} update={(val)=>{setDataObject(i, val)}} />}
                                {(item.type === 'simpleimage')&&<SimpleImage {...item} update={(val)=>{setDataPoint(i, val)}} />}
                                {(item.type === 'form')&&<Form {...item} update={(val)=>{setDataPoint(i, val)}} />}
                            </Fragment>
                        );
                    })}
                    
                </div>
                <div className={'card-footer'}>
                    <button className={'btn btn-success floatright'} onClick={updateVariables}>Save</button>
                </div>
            </div>
        </div>
    )
}
export default PageVariables;