import React, {Component, Fragment} from 'react';

class Tabs extends Component {
    state = {
        active:0
    }
    clickTab = (key) =>{
        this.setState({
            active:key
        });
    }
    render(){
        return (
            <Fragment>
                <div className='row'>
                    <div className='col-12 '>
                        <ul className="nav nav-tabs">
                        {this.props.tabs.map((item, key)=>{
                            if((item.super && item.super === true) || !item.super){
                                return (
                                    <li key={key} className="nav-item">
                                        <span onClick={()=>{this.clickTab(key)}} className={"nav-link " + ((this.state.active === key)&&' active')}>{item.name}</span>
                                    </li>
                                )
                            }
                            return null;
                            })}
                        </ul>
                    </div>
                    <br/>
                </div>
                <div className='row'>
                    <div className='col-12 '>
                        {this.props.tabs.map((item, key)=>{
                            if((item.super && item.super === false) || !item.super){
                                return (this.state.active === key)&&(
                                    <div key={key} className='tabContainer card'>
                                        {(this.props.header)&&<div className='card-header'>
                                            {item.name}
                                        </div>}
                                        <div className='card-body'>
                                            {item.content}
                                        </div>
                                    </div>
                                )
                            }
                            return null;
                        })}
                    </div>
                </div>
            </Fragment>
        )
    }
}
export default Tabs;