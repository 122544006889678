import React, {useState, useContext, Fragment} from 'react';

import {createPage} from '../../data/sites';
import {Store} from '../../stores/Store';

const CreatePage = (props) => {
    const [pagename, setPageName] = useState('');
    const [pagetemplate, setPageTemplate] = useState((props.template)?props.template:false);
    const [error, setError] = useState(false);
    const context = useContext(Store);
    const create = () => {
        createPage(context.product_id, context.site.site_id, {
            name:pagename,
            template:(pagetemplate === 'true')
        }).then(()=>{
            props.close();
            props.update();
        });
    }
    const check = () => {
        return ((pagename && pagename.length > 3))
    }
    return (
        <Fragment>
            <div className="input-group mb-3">
                <div className="input-group-prepend">
                    <span className="input-group-text" id="namelabel">Page Name</span>
                </div>
                <input type="text" name='name' required={true} onChange={(e)=>{setPageName(e.target.value)}} value={pagename} className={(error && pagename.length < 3)?"form-control formerror":"form-control"} placeholder="Page Name" />
            </div>
            <div className="input-group mb-3">
                <div className="input-group-prepend">
                    <span className="input-group-text" id="namelabel">Template?</span>
                </div>
                <select
                    className="form-control"
                    value={pagetemplate} 
                    name='template' 
                    onChange={(e)=>{setPageTemplate(e.target.value)}}
                >
                    <option value={false}>False</option>
                    <option value={true}>True</option>
                </select>
            </div>
            <button className={(check())?"btn btn-success nomargin floatright":"btn btn-success disabled nomargin floatright"} onClick={(check())&&create}>Create</button>
        </Fragment>
    );
}
export default CreatePage;