import React from 'react';
import {
  PieChart, Pie, Tooltip, Cell, ResponsiveContainer, Legend
} from 'recharts';


const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];

const renderCustomizedLabel = (value, name)=>{
  return [value, name.replace("_", " ").toUpperCase()]
};
const renderLedgend = (value)=>{
    return [value.replace("_", " ").toUpperCase()]
  };
const MultiPieChart = (props) => {
    return (
        <ResponsiveContainer width="100%" aspect={4.0/3.0}>
      <PieChart>
        <Pie
          data={props.data}
          fill="#8884d8"
          dataKey={(props.dataKey)?props.dataKey:"value"}
          isAnimationActive={false}
          margin={{
            top: 0, right: 0, left: -30, bottom: 0,
          }}
        >
          {
            props.data.map((entry, index) => <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />)
          }
        </Pie>
        <Tooltip formatter={renderCustomizedLabel} />
        <Legend formatter={renderLedgend} />
      </PieChart>
      </ResponsiveContainer>
    );
}
export default MultiPieChart;