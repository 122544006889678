import React, {useState, Fragment} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faChevronLeft, faChevronDown
} from '@fortawesome/free-solid-svg-icons';
const VerticalTabs = (props) =>{
    const [open, setOpen] = useState(null);
    const close = (item) => {
        if(item === open){
            setOpen(null);
        }else{
            setOpen(item);
        }
    }
    return props.data.map((item, i)=>{
            return (
                <Fragment key={i}>
                    <div className="verticalTab" >
                        <div 
                            className="vtab-header" 
                            onClick={()=>{
                                close(i);
                            }}
                        >
                            {item.name} <span className='chev' >{(open === i)?<FontAwesomeIcon icon={faChevronDown} />:<FontAwesomeIcon icon={faChevronLeft} />}</span>
                        </div>
                        {(open === i)&&<div className="vtab-body">
                            {item.content}
                        </div>}
                    </div>
                </Fragment>
            );
        }
    );
}
export default VerticalTabs;