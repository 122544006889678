import React, {Component, Fragment} from 'react';
import {updateFolder} from '../../data/content';
import {Store} from '../../stores/Store';
import {deleteFolder} from '../../data/content';
class EditFolder extends Component {
    state = {
        form:{
            name:(this.props.name)?this.props.name:''
        },
        add:false
    }
    handleChange = (e) => {
        let form = {...this.state.form}
        form[e.target.name] = e.target.value;
        this.setState({
          form:form
        });
    }
    deletefilesandcontent = () => {
        if(window.prompt("Do you want to delete this folder and all of its content? type 'delete' to confirm") === 'delete'){
            deleteFolder(this.context.product_id, this.props.id).then((res)=>{
                if(res.success === true){
                    
                    this.props.update();

                    this.props.close();
                }
            });
        }
    }
    update = async() =>{
        let error = false;
        error = (this.state.form.name.length > 3 && error === false)?true:(error !== false)?error:'Name needs to be over 3 charactors';
        error = (this.state.form.name.length < 24 && error === false)?true:(error !== false)?error:'Name needs to be less than 24 charactors';
        error = (this.state.useposterimage && this.state.form.posterimage.length < 3 && error === false)?true:(error !== false)?error:'please set your poster image';
        if(error)
        {
            updateFolder(this.context.product_id, this.props.id, this.state.form).then(()=>{
                this.props.update();
                this.props.close();
            });
        }
    }
    render(){
        return (
            <Fragment>
                <div className='row'>
                    <div className='col-12'>
                        <div className="input-group mb-3">
                            <div className="input-group-prepend">
                                <span className="input-group-text" id="namelabel">Folder Name</span>
                            </div>
                            <input type="text" name='name' required={true} onChange={this.handleChange} value={this.state.form.name} className={(this.state.error && this.state.form.name.length < 3)?"form-control formerror":"form-control"} placeholder="Feed Name" />
                        </div>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-12'>
                        <button className="btn btn-danger  floatleft" onClick={this.deletefilesandcontent}>Delete Folder</button>
                        <button className="btn btn-success floatright" onClick={this.update}>Save</button>
                    </div>
                </div>
            </Fragment>
        )
    }
}
EditFolder.contextType = Store; // This part is important to access context values
export default EditFolder;