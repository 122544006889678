import React, {Fragment} from 'react';
//componants
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faPlus
} from '@fortawesome/free-solid-svg-icons';
import ModalAlert from '../Global/ModalAlert';
import CreateRoute from './CreateRoute';
const RoutesDashboardButtons = (props) => {
    return (
        <Fragment>
            <ModalAlert 
                buttonClassName='boxbutton floatright' 
                buttonType='none'
                buttonIcon={<Fragment><FontAwesomeIcon icon={faPlus} /> <span>Route</span></Fragment>} 
                title={'Create route'} 
                content={<CreateRoute update={props.callback} />}
                size='lg'
                callback={props.callback}
            />
        </Fragment>
    );
}
export default RoutesDashboardButtons;
