import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faFolderOpen
} from '@fortawesome/free-solid-svg-icons';

const NoFolders = (props) => {
    return (
    <div className='row'>
        <div className='col-12 no_stuff'>
            <h1 className='largeIcon'><FontAwesomeIcon icon={faFolderOpen} /></h1>
                <h1>No Folders</h1>
                {(props.text)?<p>{props.text}</p>:<p>There is no folders to be displayed.</p>}
        </div>
    </div>
    )
}

export default NoFolders;