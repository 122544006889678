import React, { useState } from 'react';
import SingleSelect from '../Global/SingleSelect';
import ProspectCard from '../Research/ResearchCard';
const ChooseDays = (props) => {
    let [days, setDays] = useState(props.days);
    const save = () => {
        props.handleChange(days);
    }
    return (
        <div>
            <SingleSelect 
                dateSource={[
                    {label:'7 days', value:7},
                    {label:'14 days', value:14},
                    {label:'28 days', value:28},
                    {label:'56 days', value:56}
                ]}
                title={'Choose Days'}
                selected={days}
                handleChange={selected=>setDays(selected)}
            />
            <button className={'btn btn-success floatright'} onClick={(selected)=>{save(selected); props.close()}}>Save</button>
        </div>
    )
}
export default ChooseDays;