import React, {useState, useContext, Fragment, useEffect} from 'react';
import {Store} from '../../stores/Store';
import {addBucket} from '../../data/pipelines';
const CreateBucket = (props) => {
    const [bucketName, setBucketName] = useState('');
    const [error, setError] = useState(false);
    const context = useContext(Store);
    const create = () => {
        addBucket(context.product_id, {name:bucketName}).then(()=>{
            props.close();
            props.refresh();
        });
    }
    return (
        <Fragment>
            <div className="input-group mb-3">
                <div className="input-group-prepend">
                    <span className="input-group-text" id="namelabel">Bucket Name</span>
                </div>
                <input type="text" name='name' required={true} onChange={(e)=>{setBucketName(e.target.value)}} value={bucketName} className={(error && bucketName.length < 3)?"form-control formerror":"form-control"} placeholder="Bucket Name" />
            </div>
            <button className="btn btn-success nomargin floatright" onClick={create}>Create</button>
        </Fragment>
    );
}
export default CreateBucket;