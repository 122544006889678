import {db} from '../firebase';

export const getChats = async (product_id, prospect_id) => {
    let responce = {
        success:true,
        data:[]
    }
    await db.collection('products').doc(product_id).collection('leads').doc(prospect_id).collection('contacts')
    .where('type', '==', 'chat')
    .where('closed', '==', true)
    .orderBy("date", "desc").get()
    .then(async snapshot => {
        let Chats = [];
        if(!snapshot.empty)
        {
            snapshot.forEach(async doc => {
                let Chat = doc.data();
                Chat.id = doc.id;
                Chats.push(Chat);
            });
        }
        responce.data = Chats;
        return
    }).catch((error)=>{
        responce.success = false;
        responce.error = error;
        delete responce.data;
        return 
    });
    return responce;
}
export const getChatModules = async (product_id) => {
    let responce = {
        success:true,
        data:[]
    }
    await db.collection('products').doc(product_id).collection('chats').get()
    .then(async snapshot => {
        let ChatContainers = [];
        if(!snapshot.empty)
        {
            snapshot.forEach(async doc => {
                let ChatContainer = doc.data();
                ChatContainer.id = doc.id;
                ChatContainers.push(ChatContainer);
            });
        }
        responce.data = ChatContainers;
        return
    }).catch(()=>{

    });
    return responce;
}
export const createChatModule = async (product_id, chatSettings) => {
    let responce = {
        success:true,
        data:[]
    }
    await db.collection('products').doc(product_id).collection('chats').add(
        {
            name:chatSettings.name,
            startTitle:'Start Chatting',
            chatButtonText:'Send',
            startButtonText:'Start',
            titleSize:21,
            inputSize:12,
            inputPaddingSize:10,
            labelSize:12,
            labelLineHeight:14,
            active:false,
            initialMessage:'How can we Help',
            needEmail:true,
            needTel:false,
            backgroundColour:{r: 242, g: 242, b: 242, a: 1},
            inputLabelColour:{r: 51, g: 51, b: 51, a: 1},
            inputColour:{r: 255, g: 255, b: 255, a: 1},
            inputBorderColour:{r: 204, g: 204, b: 204, a: 1},
            inputTextColour:{r: 51, g: 51, b: 51, a: 1},
            inputPlaceholderColour:{r: 218, g: 218, b: 218, a: 1},
            ButtonColour:{r: 76, g: 175, b: 80, a: 1},
            ButtonTextColour:{r: 255, g: 255, b: 255, a: 1},
            ButtonHoverColour:{r: 89, g: 216, b: 94, a: 1},
            ButtonTextHoverColour:{r: 255, g: 255, b: 255, a: 1},
            buttonPadding:5,
            buttonTextSize:12,
            UserTextBackgroundColour:{r: 76, g: 175, b: 79, a: 1},
            AgentTextBackgroundColour:{r: 125, g: 153, b: 255, a: 1},
            UserTextColour:{r: 255, g: 255, b: 255, a: 1},
            AgentTextColour:{r: 255, g: 255, b: 255, a: 1},
            iconBackgroundColour:{r: 76, g: 175, b: 79, a: 1},
            iconColour:{r: 255, g: 255, b: 255, a: 1},
            closeChatBackgroundColour:{r: 242, g: 242, b: 242, a: 1},
            closeChatTextColour:{r: 51, g: 51, b: 51, a: 1},
            titleTextColour:{r: 51, g: 51, b: 51, a: 1},
            titleBackgroundGradientA:{r: 115, g: 118, b: 225, a: 1},
            titleBackgroundGradientB:{r: 208, g: 210, b: 225, a: 1},
            loadingTextColour:{r: 51, g: 51, b: 51, a: 1}
            
        }
    ).then(ref => {
        responce.data = ref
    }).catch((error)=>{
        responce.success = false;
        responce.error = error;
        delete responce.data;
        return 
    });
    return responce;
}
export const getChatModule = async (product_id, chatmodule_id) => {
    let responce = {
        success:true,
        data:[]
    }
    await db.collection('products').doc(product_id).collection('chats').doc(chatmodule_id)
    .get().then(async ref => {
        let chatmodule = ref.data();
        chatmodule.id = ref.id;
        responce.data = chatmodule;
        return
    })
    return responce;
}
export const deleteChatModule  = async (product_id, chatmodule_id) => {
    let responce = {
        success:true
    }
    responce = await db.collection("products").doc(product_id).collection("chats").doc(chatmodule_id).delete().then(() => {
        return responce;
    }).catch((error) => {
        responce.success = false;
        return responce;
    });
    return responce;
}
export const updateChatModule = async (product_id, chatmodule_id, updateobject) => {
    let responce = {
        success:true
    }
    await db.collection("products").doc(product_id).collection("chats").doc(chatmodule_id).set(
        updateobject,{ merge: true }
    ).catch((error) => {
        responce.success = false;
    });
    return responce;
}