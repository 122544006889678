import {db} from '../firebase';

export const getForms = async (product_id) => {
    let responce = {
        success:true,
        data:[]
    }
    await db.collection('products').doc(product_id).collection('forms')
    .get().then(async snapshot => {
        let Forms = [];
        if(!snapshot.empty)
        {
            snapshot.forEach(async doc => {
                let Form = doc.data();
                Form.id = doc.id;
                Forms.push(Form);
            });
        }
        responce.data = Forms;
        return
    }).catch((error)=>{
        responce.success = false;
        responce.error = error;
        delete responce.data;
        return 
    });
    return responce;
}
export const getForm = async (product_id, form_id) => {
    let responce = {
        success:true,
        data:[]
    }
    await db.collection('products').doc(product_id).collection('forms').doc(form_id)
    .get().then(async ref => {
        let Form = ref.data();
        Form.id = ref.id;
        responce.data = Form;
        return
    })
    return responce;
}
export const createForm = async (product_id, form) => {
    let responce = {
        success:true,
        data:[]
    }
    await db.collection('products').doc(product_id).collection('forms').add(
        {
            name:form.name,
            backgroundColor:{r: 242, g: 242, b: 242, a: 1},
            inputLabelColour:{r: 51, g: 51, b: 51, a: 1},
            inputColor:{r: 255, g: 255, b: 255, a: 1},
            inputBorderColour:{r: 204, g: 204, b: 204, a: 1},
            inputTextColour:{r: 51, g: 51, b: 51, a: 1},
            inputPlaceholderColour:{r: 218, g: 218, b: 218, a: 1},
            ButtonColour:{r: 76, g: 175, b: 80, a: 1},
            ButtonHoverColour:{r: 89, g: 216, b: 94, a: 1},
            ButtonTextColour:{r: 255, g: 255, b: 255, a: 1},
            inputBoxTextSize:12,
            HasSubmittedTextColour:{r: 51, g: 51, b: 51, a: 1},
            inputLabelTextSize:12,
            inputPadding:5,
            buttonTextSize:12,
            buttonPadding:7,
            formPadding:5,
            recaptchaKey:'',
            title:'',
            titleColour:{r: 51, g: 51, b: 51, a: 1},
            titleSize:22,
            active:false,
            linkColor:{r: 51, g: 51, b: 51, a: 1},
            linkFontSize:12,
            inputs:[]
        }
    ).then(ref => {
        responce.data = ref
    }).catch((error)=>{
        responce.success = false;
        responce.error = error;
        delete responce.data;
        return 
    });
    return responce;
}
export const formUpdate = async (product_id, form_id, form) => {
    let responce = {
        success:true
    }
    await db.collection('products').doc(product_id).collection('forms').doc(form_id).update(form).then(ref => {
        responce.data = ref
    }).catch((error)=>{
        responce.success = false;
        responce.error = error;
        delete responce.data;
        return 
    });
    return responce;
}
export const deleteForm = async (product_id, form_id) => {
    let responce = {
        success:true
    }
    responce = await db.collection("products").doc(product_id).collection("forms").doc(form_id).delete().then(() => {
        return responce;
    }).catch((error) => {
        responce.success = false;
        return responce;
    });
    return responce;
}
export const createItem = async (product_id, form_id, items) => {

    let responce = {
        success:true
    }
    await db.collection("products").doc(product_id).collection("forms").doc(form_id).set({items:items},
        { merge: true }).catch((error) => {
        responce.success = false;
    });
    return responce;
}
export const updateItems = async (product_id, form_id, items) => {
    /// this just recives a whole items array so can be used for creating deleting or updating 
    /// - i have left the createItem in place in case a seperation is required

    let responce = {
        success:true
    }
    await db.collection("products").doc(product_id).collection("forms").doc(form_id).set({items:items},
        { merge: true }).catch((error) => {
        responce.success = false;
    });
    return responce;
}