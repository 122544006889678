import React from 'react';

const Google = (props) => {
    let search = encodeURI(props.search.split(' ').join('+'));
    return (
        <div>
            <iframe title='google' src={'https://www.google.co.uk/search?q='+search+'&igu=1'} style={{width:'100%', minHeight:'400px'}} />
        </div>        
    )
}
export default Google;