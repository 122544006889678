const heroimage = (template) => {
    return [
        {
            data:[
                {
                    name:"Background Image",
                    value:"",
                    type:"simpleimage",
                    variableName:"backgroundimage" 
                },{
                    alt:"",
                    name:"Forground Image",
                    src:"",
                    type:"image",
                    variableName:"forgroundimage" 
                }
            ],
            name:'images',
        },{
            data:[
                {
                    name:"Button",
                    text:"Go",
                    title:"Button Title",
                    link:"",
                    type:"link",
                    variableName:"button" 
                },{
                    data:[
                        {
                            label:"Full Width",
                            value:"90%"
                        },{
                            label:"Half Width",
                            value:"50%"
                        },{
                            label:"Dynamic",
                            value:"auto"
                        }
                    ],
                    name:"Button Width",
                    type:"select",
                    value:'auto',
                    variableName:"buttonWidth" 
                },{
                    name:"Button Text Colour",
                    type:"color",
                    value:template.buttonColor,
                    variableName:"buttonColor" 
                },{
                    name:"Button Background Colour",
                    type:"color",
                    value:template.buttonBackgroundColor,
                    variableName:"buttonBackgroundColor" 
                },{
                    name:"Button Hover Text Colour",
                    type:"color",
                    value:template.buttonHoverColor,
                    variableName:"buttonHoverColor" 
                },{
                    name:"Button Hover Background Colour",
                    type:"color",
                    value:template.buttonHoverBackgroundColor,
                    variableName:"buttonHoverBackgroundColor" 
                },{
                    name:"Button Padding",
                    type:"number",
                    value:template.buttonPadding,
                    variableName:"buttonPadding" 
                }
            ],
            name:'Buttons',
        },{
            data:[
                {
                    data:[
                        {
                            label:"Full",
                            value:"full"
                        },{
                            label:"Center",
                            value:"center"
                        },{
                            label:"Top",
                            value:"botopttom"
                        },{
                            label:"Bottom",
                            value:"bottom"
                        }
                    ],
                    type:'select',
                    value:false,
                    variableName:'overlay',
                    name:'Message Style'
                },{
                    data:[
                        {
                            label:"Full Height of initial window",
                            value:"true"
                        },{
                            label:"Content Specific",
                            value:"false"
                        }
                    ],
                    name:"Inital Height",
                    type:"select",
                    value:'true',
                    variableName:"initalFullScreen" 
                },{
                    data:[
                        {
                            label:"Top",
                            value:"top"
                        },{
                            label:"Center",
                            value:"center"
                        },{
                            label:"Bottom",
                            value:"bottom"
                        }
                    ],
                    type:'select',
                    value:'center',
                    variableName:'positionbackgroundimage',
                    name:'Position Background Image'
                },{
                    name:"Space Top",
                    type:"number",
                    value:80,
                    max:200,
                    variableName:"paddingTop" 
                },{
                    name:"Space Bottom",
                    type:"number",
                    value:80,
                    max:200,
                    variableName:"paddingBottom" 
                }
            ],
            name:'Layout',
        }
    ];
}
export default heroimage