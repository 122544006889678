import React, {Component} from 'react';
import {formatDate, liveuser} from '../../../helpers/time';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faChevronDown, faChevronRight
} from '@fortawesome/free-solid-svg-icons';
import Loading from "../../Global/Loading";
import NoContent from '../../Global/NoContent';
import Journey from './Journey';
import {getVisits} from '../../../data/leads';
import {Store} from '../../../stores/Store';

class Visits extends Component {
    state = {
        viewjourney:null,
        visitors:null
    }
    componentDidMount(){
        this.refresh();
    }
    refresh = () =>{
        getVisits(this.context.product_id, this.props.prospect.id).then(res =>{
            this.setState({visitors:res.data});
        })
    }
    openJourney = (id) => {
        this.setState({
            viewjourney:(this.state.viewjourney === id)?null:id
        });
    }
    render() {
        return (this.state.visitors !== null)?(this.state.visitors.length > 0)?(
            <div className="card">
                <div className="card-header">
                    Visits from this prospect
                </div>
                <div className="card-body">
                    {this.state.visitors.map((Visit,i) =>  {
                        return (
                        <div key={i} className='col-12'>
                            <div className='chattitle'>
                                <div key={i} className="card" onClick={() => {this.openJourney(Visit.id)}}>
                                    <div className='card-body'>
                                        {formatDate(Visit.last_updated)} {(liveuser(Visit.journey[(Visit.journey.length -1)].date))&&<span className='live'>On Site Now</span>}
                                        <span className='click floatright'>{(this.state.viewjourney === Visit.id)?<FontAwesomeIcon icon={faChevronDown} />:<FontAwesomeIcon icon={faChevronRight} />}</span>
                                    </div>
                                </div>
                            </div>  
                            {(this.state.viewjourney === Visit.id)&&(
                                <div key={i} className="card" onClick={() => {this.openJourney(Visit.id)}}>
                                    <div className='card-body'>
                                        <Journey visit={Visit} />
                                    </div>
                                </div>
                            )}
                        </div>
                    )})}
                </div>
            </div>
        ):<NoContent/>:<Loading />;
    }
}
Visits.contextType = Store;
export default Visits;
