const text = (template) => {
    return [{
            data:[
                {
                    type:'text',
                    value:'',
                    variableName:'script',
                    name:'Script tag'
                },{
                    type:'text',
                    value:'',
                    variableName:'classes',
                    name:'Div class/s'
                },{
                    type:'text',
                    value:'',
                    variableName:'id',
                    name:'Div id/s'
                }
            ],
            name:'Content',
        }
    ];
}
export default text;