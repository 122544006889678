import React, {useState, Fragment, useEffect, useContext} from 'react';
import {getPages} from '../../data/sites';
import PageCard from './PageCard';
import NoContent from '../Global/NoContent';
import PageTop from '../Global/pagetop';
import {Store} from '../../stores/Store';
import PageDashboardButtons from './PageDashboardButtons';
import Loading from '../Global/Loading';

const Pages = () => {
    const [pages, setPages] = useState(null);
    const context = useContext(Store);
    useEffect(()=>{
        context.update({
            view:'sites'
        });
        refresh();
    }, []);
    const refresh = () => {
        getPages(context.product_id, context.site.site_id).then((result)=>{
            setPages(result.data);
        });
    }
    return (
        <Fragment>
            <PageTop name={'Sites'} buttons={<PageDashboardButtons templates={false} callback={refresh} />}/>
            <div className='inner'>
                {(pages)?(pages.length > 0)?<ul className="row mb-3 card-list">
                    {pages.map((page, i)=>{
                         if(!page.template && !page.feed_id){
                        return <li key={i} className="col-12 col-md-6 col-lg-4 card_object">
                            <PageCard 
                                    data={page}
                                    refresh={refresh}
                            /></li>
                         }
                    })}
                </ul>:<NoContent title='No Pages Yet!' text='Create your first Page by clicking new page button above' />:<Loading/>}
            </div>
        </Fragment>
    )
}
export default Pages;