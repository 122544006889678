


import React, {useState, useContext} from 'react';
import Processing from '../../Global/Processing';
import {Store} from "../../../stores/Store";
import ButtonBar from './ButtonBar';
import {addComponent, addGlobalComponent} from '../../../data/sites';
const PageTemplateElement = (props) =>{
    let context = useContext(Store);
    let [adding, setAdding] = useState(false);
    const addToCanvas = () => {
        setAdding(true);
        if(props.page_id && props.page_id !== false){
            addComponent(context.product_id, context.site.site_id, props.page_id, (props.global === true)?{global:props.item.id}:{...props.item}).then(()=>{
                setTimeout(function(){ setAdding(false); }, 500);
                props.getCanvas();
            });
        }else{
            addGlobalComponent(context.product_id, context.site.site_id,{...props.item}).then(()=>{
                setTimeout(function(){ setAdding(false); }, 500);
                props.getCanvas();
            });
        }
    }
        return (
            <div className="card_object">
                <div className={"card "}>
                    <div className="card-header card-header-borderless d-flex justify-content-between">
                        <div>
                            <img src={props.item.image} style={{width:'30px'}} />
                            {' '+props.item.name}
                        </div>
                    </div>
                    <div className="card-body">
                        <div className="media">
                            <div className="media-body siteCard">
                                {(adding)?<Processing />:(
                                    <span>{props.item.description}</span>
                                )}
                            </div>
                        </div>
                    </div>
                    {(!adding)&&<ButtonBar
                            addToCanvas={addToCanvas}
                            {...props}
                        />}
                </div>
            </div>
        )
}
export default PageTemplateElement;