import React from 'react';
import AceEditor from "react-ace";

import "ace-builds/src-noconflict/mode-javascript";
import "ace-builds/src-noconflict/theme-twilight";
import "ace-builds/src-noconflict/ext-language_tools";
const JavaScriptArea = (props) => {
    return (
        <div  className={'positionRelative'}>
            <AceEditor
                placeholder="Add Javascript Code Here (don't use script tags)"
                mode="javascript"
                theme="twilight"
                name="javascripteditor"
                onChange={props.update}
                fontSize={14}
                showPrintMargin={true}
                showGutter={true}
                highlightActiveLine={true}
                value={props.value}
                setOptions={{
                enableBasicAutocompletion: true,
                enableLiveAutocompletion: true,
                enableSnippets: true,
                showLineNumbers: true,
                tabSize: 4,
            }}/>
        </div>
    )
}
export default JavaScriptArea;