import React, {Component, Fragment} from 'react';
import PageTop from "../pagetop/";
import {Store} from '../../../stores/Store';
import {getProduct} from '../../../data/products';
import {getSuppressed, removeFromWhitelist, addToWhitelist, deletefromsuppression} from '../../../data/suppress';
import Loading from '../../Global/Loading';
import WhitelistTable from './WhitelistTable';
import SuppressionTable from './SuppressionTable';
import Tabs from '../Tabs';
class Whitelist extends Component {
    state = {
        product:this.context.product?this.context.product:null,
        suppressed:null,
        whitelist:null
    }
    componentDidMount(){
        this.context.update({
            product_id:this.props.match.params.product_id
        });
        getProduct(this.props.match.params.product_id).then((res)=>{
            this.setState({
                product:res.data
            });
            this.context.update({
                product:res.data
            });
        });
        this.refresh();
    }
    refresh = async () => {
        let whitelist = await getSuppressed(this.props.match.params.product_id);
        let whitelistarray = [];
        whitelist.forEach((item)=>{
            whitelistarray.push(item.hostname)
        });
        let suppressed = await getSuppressed();
        let filteredsuppressed = suppressed.filter((item)=>{
            return !whitelistarray.includes(item.hostname);
        });
        this.setState({
            whitelist:whitelist,
            suppressed:filteredsuppressed
        });
    }
    add = async (product_id, hostname) =>{
        await addToWhitelist(product_id, hostname);
        this.refresh();
    }
    delete = async (product_id, id) =>{
        await removeFromWhitelist(product_id, id);
        this.refresh();
    }
    superdelete = (id) =>{
        var r = window.confirm('This is removing from the global suppression list??? are you sure???');
        if(r === true){
            deletefromsuppression(id);
            this.refresh();
        }
    }
    render(){
        return (this.state.product)?(
            <Fragment>
                <PageTop 
                    name={'White List'}
                    breadcrumbs={[
                        {
                            name:this.state.product.name, 
                            uri:'/product/'+this.state.product.id
                        }, 
                        {
                            name:'Whitelist', 
                            uri:'/product/'+this.state.product.id+'/whitelist'
                        }
                    ]}  
                />
                <div className='inner'>
                    <div className='row'>
                        <div className='col-12'>
                            {(this.context.access === 'super')?
                                <Tabs tabs={
                                        [
                                            {name:'Whitelist', content:(
                                                    (this.state.whitelist && this.state.whitelist !== null)?<WhitelistTable data={this.state.whitelist} delete={this.delete} />:<Loading title='Getting Whitelist' />
                                            )},
                                            {name:'Master Suppression', content:(
                                                    (this.state.whitelist && this.state.whitelist !== null)?<SuppressionTable super={(this.context.access === 'super')} superdelete={this.superdelete} productId={this.state.product.id} data={this.state.suppressed} add={this.add} />:<Loading title='Getting Suppressed Networks' />
                                            )}
                                        ]
                                    } 
                                />:
                                <div className='productContainer card'>
                                    <div className='card-header'>
                                        Whitelist
                                    </div>
                                    <div className='card-body'>
                                        {(this.state.whitelist && this.state.whitelist !== null)?<WhitelistTable data={this.state.whitelist} delete={this.delete} />:<Loading title='Getting Whitelist' />}
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </Fragment>
        ):(
            <Fragment>
                <PageTop name={'Loading'} />
                <div className='inner'>
                    <Loading />
                </div>
            </Fragment>
        );
    }
}
Whitelist.contextType = Store; // This part is important to access context values
export default Whitelist;
