import React, {useEffect, useState} from 'react';
import ModalAlert from '../../Global/ModalAlert'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faEdit, faMinusSquare
} from '@fortawesome/free-solid-svg-icons';
import Files from '../../Content/Files';
const Image = (props) => {
    let [src ,setSrc] = useState(props.src?props.src:'');
    let [altValue ,setAltValue] = useState(props.alt?props.alt:'');
    let [titleValue ,setTitleValue] = useState(props.title?props.title:'');
    useEffect(()=>{
        props.update({
            src:src,
            alt:altValue,
            type:'image',
            title:titleValue
        });
    }, [src, altValue, titleValue]);
    return (
        <div className='card subcard'>
            <div className='card-header'>
                {props.name}
            </div>
            <div className='card-body'>
                <div className="input-group mb-3">
                    <div className="input-group-prepend">
                        <div className="input-group-text">Image</div>
                    </div>
                    <input 
                        type='text' 
                        name={'image'}
                        className='form-control'
                        readOnly
                        placeholder={'...image'}
                        value={src}
                    />
                    <button className='btn boxbutton warning nomargin' onClick={()=>{setSrc('')}}><FontAwesomeIcon icon={faMinusSquare} /></button>
                    <ModalAlert 
                        buttonClassName=' btn boxbutton nomargin ' 
                        buttonType='none'
                        buttonIcon={<FontAwesomeIcon icon={faEdit} />} 
                        title={'Select'} 
                        content={<Files use={setSrc} filter='image/' />}
                        size='lg'
                    />
                </div>
                <div className="input-group mb-3">
                    <div className="input-group-prepend">
                        <div className="input-group-text">Alt Text</div>
                    </div>
                    <input 
                        type='text'
                        onChange={(e)=>setAltValue(e.target.value)}
                        name={'altValue'}
                        className='form-control'
                        placeholder={'...Alt Text'}
                        value={altValue}
                    />
                </div>
                <div className="input-group mb-3">
                    <div className="input-group-prepend">
                        <div className="input-group-text">Title Text</div>
                    </div>
                    <input 
                        type='text' 
                        name={'titleValue'}
                        onChange={(e)=>setTitleValue(e.target.value)}
                        className='form-control'
                        placeholder={'...Title Text'}
                        value={titleValue}
                    />
                </div>
            </div>
        </div>
    )
}
export default Image;