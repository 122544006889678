import React, {Fragment, useState, useContext} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faCode,
    faEye, faPowerOff, faTrash
} from '@fortawesome/free-solid-svg-icons';
import Processing from '../../Global/Processing';
import {Store} from "../../../stores/Store";
import ModalAlert from '../../Global/ModalAlert';
import ViewChatSettings from '../ViewChatSettings';
import EmbedCode from '../EmbedCode';
const ButtonBar =(props)=>{
    let [processing, setProcessing] = useState(false);
    const context = useContext(Store);
    return (!processing)?(
        <div className="card-footer d-flex bg-gradient">
            <div>
                <ModalAlert 
                    buttonClassName='btn btn-sm barbutton floatright' 
                    buttonType='none'
                    buttonIcon={<Fragment><FontAwesomeIcon icon={faCode} /></Fragment>} 
                    title={'Embed'}
                    content={<EmbedCode {...props} />}
                    size='lg'
                />
                <ModalAlert 
                    buttonClassName='btn btn-sm barbutton floatright' 
                    buttonType='none'
                    buttonIcon={<Fragment><FontAwesomeIcon icon={faEye} /></Fragment>} 
                    title={'View'}
                    content={<ViewChatSettings {...props} />}
                    size='lg'
                />
            </div>
            <div>
                <button onClick={()=>{props.toggleActive(props.id, !props.active)}} className="btn btn-sm barbutton floatright warning"><FontAwesomeIcon icon={faPowerOff} /></button>
                <button onClick={()=>props.delete(props.id)} className="btn btn-sm barbutton floatright warning"><FontAwesomeIcon icon={faTrash} /></button>
            </div>
        </div>        
    ):<Processing />
}
export default ButtonBar;