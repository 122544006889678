import React, {Fragment} from 'react';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';

class ButtonSlider extends React.Component {
  state = {
    displaySlider: false
  };

  handleClick = () => {
    this.setState({ displaySlider: !this.state.displaySlider })
  };

  handleClose = () => {
    this.setState({ displaySlider: false })
  };

  handleChange = (num) => {
    this.props.update(num);
  };
  render() {
    const styles =  {
        container:{
          
        },
        color: {
          lineHeight:'14px',
          color:'#fff',
          fontSize:'10px',
          padding:'5px 10px',
          borderRadius: '2px',
          background: `rgba(0,0,0,1)`,
          display: 'inline-block'
        },
        swatch: {
          margin:'5px',
          padding: '5px',
          background: '#fff',
          borderRadius: '1px',
          boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
          display: 'inline-block',
          cursor: 'pointer',
          display: 'inline-block',
        },
        popover: {
          position: 'absolute',
          zIndex: '2',
          boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
          padding:'5px',
          width:'300px',
          background:'#fff'
        },
        cover: {
          position: 'fixed',
          top: '0px',
          right: '0px',
          bottom: '0px',
          left: '0px',
        },
      };

    return (
      <Fragment>
        <div style={ styles.swatch } >
          <div style={ styles.color } onClick={ this.handleClick }>{this.props.name}: {this.props.value}</div>
          { this.state.displaySlider ? <div style={ styles.popover }>
          <div style={ styles.cover } onClick={ this.handleClose }/>
          <Slider onChange={this.handleChange} value={this.props.value} min={(typeof this.props.min !== 'undefined')?this.props.min:0} max={(this.props.max)?this.props.max:100} />
        </div> : null }
        </div>
        
      </Fragment>
    )
  }
}

export default ButtonSlider