import React,  {useContext} from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {useHistory} from 'react-router-dom';
import {
    faGlobe, faListAlt, faBullseye, faChartLine, faCommentDots, faNetworkWired, faImages, faProjectDiagram, faGripHorizontal
} from '@fortawesome/free-solid-svg-icons';
import {Store} from "../../stores/Store";
const createclass = (contextview, view) => {
    let active = (contextview === view)?"active":"";
    return "card singleswitch "+active
}
const SwitchPanel = (props) => {
    const context = useContext(Store); // This part is important to access context values
    const history = useHistory();
    const switchview = (view) =>{
        context.update({view:view});
        history.push("/product/"+context.product_id+"/"+view);
        props.close();
    }

    return (
        <div class='row'>
            <div className="col-6 col-sm-4 col-md-3">
                <div className={createclass(context.view, '')} onClick={()=>{switchview('');}}>
                    <div className='row'>
                        <div className="col-12 title">
                            <span className='icon'><FontAwesomeIcon icon={faGripHorizontal} /></span>
                            <span className='text'>Dashboard</span>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-6 col-sm-4 col-md-3">
                <div className={createclass(context.view, 'overview')} onClick={()=>{switchview('overview');}}>
                    <div className='row'>
                        <div className="col-12 title">
                            <span className='icon'><FontAwesomeIcon icon={faChartLine} /></span>
                            <span className='text'>Analytics</span>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-6 col-sm-4 col-md-3">
                <div className={createclass(context.view, 'pipeline')} onClick={()=>{switchview('pipeline');}}>
                <div className='row'>
                    <div className="col-12 title">
                        <span className='icon'><FontAwesomeIcon icon={faBullseye} /></span>
                        <span className='text'>Pipeline</span>
                    </div>
                </div>
                </div>
            </div>
            
            <div className="col-6 col-sm-4 col-md-3">
                <div className={createclass(context.view, 'research')} onClick={()=>{switchview('research');}}>
                <div className='row'>
                    <div className="col-12 title">
                        <span className='icon'><FontAwesomeIcon icon={faNetworkWired} /></span>
                        <span className='text'>Network</span>
                    </div>
                </div>
                </div>
            </div>
            <div className="col-6 col-sm-4 col-md-3">
                <div className={createclass(context.view, 'sites')} onClick={()=>{switchview('sites');}}>
                <div className='row'>
                    <div className="col-12 title">
                        <span className='icon' onClick={()=>{context.update({view:'pages'})}}><FontAwesomeIcon icon={faGlobe} /></span>
                        <span className='text'>Sites</span>
                    </div>
                </div>
                </div>
            </div>
            <div className="col-6 col-sm-4 col-md-3">
                <div className={createclass(context.view, 'content')} onClick={()=>{switchview('content');}}>
                <div className='row'>
                    <div className="col-12 title">
                        <span className='icon'><FontAwesomeIcon icon={faImages} /></span>
                        <span className='text'>Content</span>
                    </div>
                </div>
                </div>
            </div>
            <div className="col-6 col-sm-4 col-md-3">
                <div className={createclass(context.view, 'forms')} onClick={()=>{switchview('forms');}}>
                <div className='row'>
                    <div className="col-12 title">
                        <span className='icon'><FontAwesomeIcon icon={faListAlt} /></span>
                    
                        <span className='text'>Forms</span>
                    </div>
                </div>
                </div>
            </div>
            <div className="col-6 col-sm-4 col-md-3">
                <div className={createclass(context.view, 'chats')} onClick={()=>{switchview('chats');}}>
                    <div className='row'>
                        <div className="col-12 title">
                            <span className='icon'><FontAwesomeIcon icon={faCommentDots} /></span>
                            <span className='text'>Chat</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default SwitchPanel