import React, {Fragment, useContext} from 'react';
//componants
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faPlus
} from '@fortawesome/free-solid-svg-icons';

import {Store} from '../../stores/Store';
import ModalAlert from '../Global/ModalAlert';
import CreateBucket from './CreateBucket';
const PipelineButtons = (props) => {
    const context = useContext(Store); // This part is important to access context values
    return <ModalAlert 
    buttonClassName='btn btn-sm barbutton floatright' 
    buttonIcon={<span>Pipeline Bucket <FontAwesomeIcon icon={faPlus} /></span>}
    title={'Add Bucket'}
    content={<CreateBucket refresh={props.refresh} />}
    size='lg'
/>;
}
export default PipelineButtons;
