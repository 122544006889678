import React from 'react';
// Import React Table
import ReactTable from "react-table";
import "react-table/react-table.css";

const WhitelistTable = (props) => {
    return <ReactTable
        data={props.data}
        columns={[
            {
                Header: "Network Name",
                accessor: "hostname",
                Cell: row => (<span className='tableCell'><span title={row.value}>{row.value}</span></span>)
            },
            {
                Header: "Actions",
                accessor: "id",
                width: 200,
                Cell: row => (
                <span className='tableCell'>
                    <span>
                        <button onClick={()=>{props.delete(row.original.productId, row.value)}} type="button" title="Filter" className=" floatright btn btn-danger btn-sm">Remove</button>
                    </span>
                </span>)
            }
        ]}
        defaultPageSize={10}
        showPagination={true}
        showPageSizeOptions={false}
        className="spacetop -striped -highlight"
        />;
}
export default WhitelistTable;
