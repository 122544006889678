import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faChevronRight, faChevronDown, faSitemap, faEdit, faListAlt, faComments
} from '@fortawesome/free-solid-svg-icons';
//componants
const Stats = (props) => {
    return (
        <div className="row loztabs">
            <div className="col-6 col-sm-3 card">
                <div className="card-body textcenter click" onClick={() => {props.open('visitors')}} >
                    <FontAwesomeIcon icon={faSitemap} /> {props.prospect.visitAmount} <br/>Visit{(props.prospect.visitAmount !== 1)&&'s'} <span>{(props.isopen === 'visitors')?<FontAwesomeIcon icon={faChevronDown} />:<FontAwesomeIcon icon={faChevronRight} />}</span>
                </div>
            </div>
            <div className="col-6 col-sm-3 card">
                <div className="card-body textcenter click" onClick={() => {props.open('forms')}}>
                    <FontAwesomeIcon icon={faListAlt} /> {props.prospect.formAmount} <br/>Form{(props.prospect.formAmount !== 1)&&'s'} <span>{(props.isopen === 'forms')?<FontAwesomeIcon icon={faChevronDown} />:<FontAwesomeIcon icon={faChevronRight} />}</span>
                </div>
            </div>
            <div className="col-6 col-sm-3 card">
                <div className="card-body textcenter click" onClick={() => {props.open('notes')}}>
                    <FontAwesomeIcon icon={faEdit} /> {props.prospect.noteAmount} <br/>Note{(props.prospect.noteAmount !== 1)&&'s'} <span>{(props.isopen === 'notes')?<FontAwesomeIcon icon={faChevronDown} />:<FontAwesomeIcon icon={faChevronRight} />}</span>
                </div>
            </div>
            <div className="col-6 col-sm-3 card">
                <div className="card-body textcenter click" onClick={() => {props.open('chats')}}>
                    <FontAwesomeIcon icon={faComments} /> {props.prospect.chatAmount} <br/>Chat{(props.prospect.chatamount !== 1)&&'s'} <span >{(props.isopen === 'chats')?<FontAwesomeIcon icon={faChevronDown} />:<FontAwesomeIcon icon={faChevronRight} />}</span>
                </div>
            </div>
        </div>
    )
}
export default Stats;
