import React, {Fragment} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faEdit
} from '@fortawesome/free-solid-svg-icons';
import ModalAlert from '../../Global/ModalAlert';
import GlobalVariables from '../GlobalVariables';
import PageVariables from '../PageVariables';
const EditPageButtons = (props) => {
    return (<Fragment><ModalAlert 
        buttonClassName='btn btn-sm boxbutton  floatright' 
        buttonType='none'
        buttonIcon={<Fragment><FontAwesomeIcon icon={faEdit} /> Page Variables</Fragment>} 
        title={'Edit Site Variables'}
        content={<PageVariables {...props.page} />}
        size='lg'
    /></Fragment>);
}
export default EditPageButtons;
