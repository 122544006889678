import React, {Component} from 'react';
//componants
import {Store} from "../../stores/Store";

class DeleteAccount extends Component {
    state = {
        form:{
            delete:''
        }
    }
    handleChange = (e) => {
        let form = {...this.state.form}
        form[e.target.name] = e.target.value;
        this.setState({
          form:form,
          passworderror:false,
          newpassworderror:false,
        });
    }
    delete = () => {
        if(this.state.form.delete.toLowerCase() === 'delete')
        {
            if(window.confirm('You are about to delete your account - please click to confirm')){
                this.props.callback(this.props.close);
            }
        }else{
            alert("the word 'delete' was not entered correctly");
            this.props.close();
        }
    }
    render() {
      return (
            <div>
                <h3>All Data Will Be Perminently Deleted!!!</h3>
                <p>Type "delete" in the box below to delete your account</p>
                <div className="input-group mb-3">
                    <div className="input-group-prepend">
                    <span className="input-group-text" id="namelabel">Confirm</span>
                    </div>
                    <input type="text" name='delete' onChange={this.handleChange} value={this.state.form.delete} className="form-control" placeholder="Type 'delete'" />
                </div>
                <p>Once you click confirm you will be logged out and your data deleted - you will recive an email confirmation.</p>
                <button className="btn btn-success nomargin floatright" onClick={this.delete}>Confirm</button>
            </div>
      );
    }
}
DeleteAccount.contextType = Store; // This part is important to access context values
export default DeleteAccount;
