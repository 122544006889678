import React, {Fragment, useEffect, useState, useContext} from 'react';
import PageTop from '../../Global/pagetop';
import NoContent from '../../Global/NoContent';
import EditFormButtons from './EditFormButtons';
import {Store} from '../../../stores/Store';
import {getForm, updateItems, formUpdate} from '../../../data/forms';
import ColourSwatch from '../../Global/ColourSwatch';
import ButtonSlider from '../../Global/ButtonSlider';
import Tabs from '../../Global/Tabs';
import {
    useParams
  } from "react-router-dom";
import Item from './Item';

const EditForm = (props) => {
    const [form, setForm] = useState('');
    const context = useContext(Store);
    let { form_id } = useParams();
    const removeItem = (itemIndex) => {
        let items = form.items;
        items = items.filter((item, i) => i !== itemIndex);
        updateItems(context.product_id, form_id, items);
    }
    const updateItem = (itemIndex, item) => {
        let items = form.items;
        items[itemIndex] = item;
        setForm({
            ...form,
            items:items
        })
    }
    const saveform = async () => {
        let items = form.items;
        setForm(
            {
                ...form,
                items:items
            },formUpdate(context.product_id, form_id, form)
        )
    }
    const update = () => {
        getForm(context.product_id, form_id).then((res)=>{
            setForm(res.data);
        }, [context.product_id, form_id]);
    }
    const updateposition = (itemPosition, newPosition)=>{
        let items = form.items;
        let removeitem = items.splice(itemPosition, 1);
        items.splice(newPosition, 0, removeitem[0]);
        setForm({...form,items:items});
    }
    let Items = (form && form.items)?form.items.map((item, i)=>{
        return (<Item 
                    key={i} 
                    position={i}
                    last={((form.items.length-1) > i)?false:true}
                    updateposition={updateposition}
                    removeItem={removeItem} 
                    updateItem={updateItem} 
                    data={item} 
                    objects={form.items}
                    update={update} 
                />);
    }):[];
    useEffect(()=>{
        if(!context.product_id && props.match.params.product_id)
        {
            context.update({
                product_id:props.match.params.product_id
            });
        }
    },[]);
    useEffect(()=>{
        update();
    },[context.product_id]);
    return (
        <Fragment>
            <PageTop name={'Edit Form: '+form.name} buttons={<EditFormButtons saveform={saveform} update={update} form={form} />}/>
            <div className='inner'>
                <Tabs tabs={
                    [{name:'Form Settings', content:(
                        <div className='row'>
                            <div className='col-12'>
                            <div className='space top bottom'>
                                <div className="input-group mb-3">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text" id="namelabel">Form Name (internal)</span>
                                    </div>
                                    <input type='text' value={form.name} onChange={(e)=>setForm({...form, name:e.target.value})} className='form-control' />
                                </div>
                                <div className="input-group mb-3">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text" id="namelabel">Form Title</span>
                                    </div>
                                    <input type='text' value={form.title} onChange={(e)=>setForm({...form, title:e.target.value})} className='form-control' />
                                </div>
                                <hr />
                                <ColourSwatch name='Background' update={(val)=>setForm({...form, backgroundColor:val})} color={(form.backgroundColor)?form.backgroundColor:{r: 242, g: 242, b: 242, a: 1}} />
                                <ColourSwatch name='Title Colour' update={(val)=>setForm({...form, titleColour:val})} color={(form.titleColour)?form.titleColour:{r: 51, g: 51, b: 51, a: 1}} />
                                <ButtonSlider value={(form.titleSize !== 'undefined')?form.titleSize:22} update={(val)=>setForm({...form, titleSize:val})} name='Title Size' />
                                <ButtonSlider value={(form.formPadding !== 'undefined')?form.formPadding:5} update={(val)=>setForm({...form, formPadding:val})} name='Form Padding' />
                                <ColourSwatch name='Link Colour' update={(val)=>setForm({...form, linkColor:val})} color={(form.linkColor)?form.linkColor:{r: 51, g: 51, b: 51, a: 1}} />
                                <ButtonSlider value={(form.linkFontSize !== 'undefined')?form.linkFontSize:12} update={(val)=>setForm({...form, linkFontSize:val})} name='Link Size' />
                            </div>
                            </div>
                        </div>
                    )},{name:'Input Settings', content:(
                        <div className='row'>
                            <div className='col-12'>
                            <div className='space top bottom'>
                                <ColourSwatch name='Input Label Colour' update={(val)=>setForm({...form, inputLabelColour:val})} color={(form.inputLabelColour)?form.inputLabelColour:{r: 51, g: 51, b: 51, a: 1}} />
                                <ButtonSlider value={(form.inputLabelTextSize !== 'undefined')?form.inputLabelTextSize:12} update={(val)=>setForm({...form, inputLabelTextSize:val})} name='Input Label Text Size ' />
                                <ColourSwatch name='Input Colour' update={(val)=>setForm({...form, inputColor:val})} color={(form.inputColor)?form.inputColor:{r: 255, g: 255, b: 255, a: 1}} />
                                <ColourSwatch name='Input Border Colour' update={(val)=>setForm({...form, inputBorderColour:val})} color={(form.inputBorderColour)?form.inputBorderColour:{r: 204, g: 204, b: 204, a: 1}} />
                                <ColourSwatch name='Input Text Colour' update={(val)=>setForm({...form, inputTextColour:val})} color={(form.inputTextColour)?form.inputTextColour:{r: 51, g: 51, b: 51, a: 1}} />
                                <ColourSwatch name='Placeholder Colour' update={(val)=>setForm({...form, inputPlaceholderColour:val})} color={(form.inputPlaceholderColour)?form.inputPlaceholderColour:{r: 218, g: 218, b: 218, a: 1}} />
                                <ButtonSlider value={(form.inputBoxTextSize !== 'undefined')?form.inputBoxTextSize:12} update={(val)=>setForm({...form, inputBoxTextSize:val})} name='Input Box Text Size ' />
                                <ButtonSlider value={(form.inputPadding !== 'undefined')?form.inputPadding:5} update={(val)=>setForm({...form, inputPadding:val})} name='Input Padding' />
                            </div>
                            </div>
                        </div>
                    )},{name:'Submit Settings', content:(
                        <div className='row'>
                            <div className='col-12'>
                            <div className='space top bottom'>
                                <div className="input-group mb-3">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text" id="namelabel">Submit Text</span>
                                    </div>
                                    <input type='text' value={form.submitText} onChange={(e)=>setForm({...form, submitText:e.target.value})} className='form-control' />
                                </div>
                                
                                <ColourSwatch name='Button Colour' update={(val)=>setForm({...form, ButtonColour:val})} color={(form.ButtonColour)?form.ButtonColour:{r: 76, g: 175, b: 80, a: 1}} />
                                <ColourSwatch name='Button Hover Colour' update={(val)=>setForm({...form, ButtonHoverColour:val})} color={(form.ButtonHoverColour)?form.ButtonHoverColour:{r: 89, g: 216, b: 94, a: 1}} />
                                <ColourSwatch name='Button Text Colour' update={(val)=>setForm({...form, ButtonTextColour:val})} color={(form.ButtonTextColour)?form.ButtonTextColour:{r: 255, g: 255, b: 255, a: 1}} />
                                <ButtonSlider value={(form.buttonTextSize !== 'undefined')?form.buttonTextSize:12} update={(val)=>setForm({...form, buttonTextSize:val})} name='Button Text Size ' />
                                <ButtonSlider value={(form.buttonPadding !== 'undefined')?form.buttonPadding:10} update={(val)=>setForm({...form, buttonPadding:val})} name='Button Padding' />
                                <ColourSwatch name='Has Submitted Text Colour' update={(val)=>setForm({...form, HasSubmittedTextColour:val})} color={(form.HasSubmittedTextColour)?form.HasSubmittedTextColour:{r: 51, g: 51, b: 51, a: 1}} />
                            </div>
                            </div>
                        </div>
                    )},{name:'Security', content:(
                        <div className='row'>
                            <div className='col-12'>
                                <div className='space top bottom'>
                                <div className="input-group mb-3">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text" id="namelabel">Google Recaptcha</span>
                                    </div>
                                    <input type='text' value={form.recaptchaKey} onChange={(e)=>setForm({...form, recaptchaKey:e.target.value})} className='form-control' />
                                </div>
                                </div>
                            </div>
                        </div>
                    )},
                ]
                } />
                {(Array.isArray(form.items) && form.items.length > 0)
                    ?
                        Items
                    :
                        <NoContent 
                            title='Let Start Building Your Form!' 
                            text='Create your first form Item by clicking new item above' 
                        />
                }
            </div>
        </Fragment>
    );
}
export default EditForm;