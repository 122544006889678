import React, {Fragment, Component, useContext} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faTrash, faEye, faPowerOff, faCode
} from '@fortawesome/free-solid-svg-icons';
import {useHistory} from 'react-router-dom';
import Processing from '../../Global/Processing';
import {Store} from "../../../stores/Store";
import {deleteForm, formUpdate} from '../../../data/forms';
import EmbedCode from '../EmbedCode';
import ModalAlert from '../../Global/ModalAlert';
const ViewFormsButton = (props) => {
    let context = useContext(Store);
    let history = useHistory();
    let setproduct = () =>{
        history.push("/product/"+context.product_id+"/forms/"+props.data.id);
    }
    return (<button className="btn btn-sm barbutton floatright" onClick={()=>{setproduct()}}>
        <FontAwesomeIcon icon={faEye} />
    </button>);
}
class ButtonBar extends Component{
    state = {
        processing:false
    }
    deleteForm= async() => {
        await this.setState({
            processing:true
        })
        ///await the form to be removed
        let objdelete = window.prompt("Please enter 'delete' to confirm", "");
        if (objdelete === 'delete') {
            deleteForm(this.context.product_id, this.props.data.id)
        }
        await this.setState({
            processing:false
        })
        await this.props.refresh(); 
    }
    toggleActive= async() => {
        await this.setState({
            processing:true
        })
        formUpdate(this.context.product_id, this.props.data.id, {active:!this.props.data.active});
        await this.setState({
            processing:false
        })
        await this.props.refresh(); 
    }
    render(){
        return (!this.state.processing)?(
            <div className="card-footer d-flex bg-gradient">
                <Fragment>
                    <div>
                        <ModalAlert 
                            buttonClassName='btn btn-sm barbutton floatright' 
                            buttonType='none'
                            buttonIcon={<Fragment><FontAwesomeIcon icon={faCode} /></Fragment>} 
                            title={'Embed'}
                            content={<EmbedCode {...this.props} />}
                            size='lg'
                        />
                        <ViewFormsButton data={this.props.data}/>
                    </div>
                    <div>
                        <button onClick={this.toggleActive}  className="btn btn-sm barbutton floatright warning"><FontAwesomeIcon icon={faPowerOff} /></button>
                        <button onClick={this.deleteForm} className="btn btn-sm barbutton floatright warning"><FontAwesomeIcon icon={faTrash} /></button>
                    </div>
                </Fragment>
            </div>        
        ):<Processing />
    }  
}
ButtonBar.contextType = Store; // This part is important to access context values
export default ButtonBar;