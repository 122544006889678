import React, { Fragment, useContext, useEffect, useState } from 'react';
import {Store} from '../../stores/Store';
import Number from './Types/Number';
import Select from './Types/Select';
import Color from './Types/Color';
import Image from './Types/Image';
import ImageLink from './Types/ImageLink';
import ObjectArray from './Types/ObjectArray';
import Bool from './Types/Bool';
import TextArea from './Types/TextArea';
import Wysiwyg from './Types/Wysiwyg';
import Text from './Types/Text';
import Form from './Types/Form';
import Chat from './Types/Chat';
import Font from './Types/Font';
import {getSite, updateSite} from '../../data/sites';
import Loading from '../Global/Loading';
const TemplateVariables = (props) => {
    let context = useContext(Store)
    let [globalTemplate, setGlobalTemplate] = useState(null);
    const setDataArray = (i, array) => {
        let tempdata = globalTemplate;
        tempdata.data[i].data = array;
        setGlobalTemplate({...tempdata});
    }
    const setDataObject= (i, object) => {
        let tempdata = globalTemplate;
        tempdata.data[i] = {
            ...tempdata.data[i],
            ...object
        }
        setGlobalTemplate({...tempdata});
    }
    const setDataPoint = (i, value) => {
        let tempdata = globalTemplate;
        tempdata.data[i].value = value;
        setGlobalTemplate({...tempdata});
    }
    useEffect(() => {
        //get global variables
        getSite(context.product_id, context.site.site_id).then((res)=>{
            (res.data.globalTemplateVariables)&&setGlobalTemplate(res.data.globalTemplateVariables);
        });
    },[]);
    const updateTemplate = () => {
        //update global variables
        updateSite(context.product_id, context.site.site_id, {globalTemplateVariables:globalTemplate});
        props.close();
    }
    return (globalTemplate)?(
        <div>
            <div className={'card'}>
                <div className={'card-header'}>
                    Initial Variables Help you create pages quicker by apply the correct colour and font sizes to components when they are created.
                </div>
                <div className={'card-body'}>
                    {globalTemplate.data.map((item,i)=>{
                        return (
                            <Fragment key={i}>
                                {(item.type === 'select')&&<Select {...item} update={(value)=>{setDataPoint(i, value)}} />}
                                {(item.type === 'number')&&<Number {...item} update={(value)=>{setDataPoint(i, value)}} />}
                                {(item.type === 'color')&&<Color {...item} update={(value)=>{setDataPoint(i, value)}} />}
                                {(item.type === 'bool')&&<Bool {...item} update={(value)=>{setDataPoint(i, value)}} />}
                                {(item.type === 'textarea')&&<TextArea {...item} update={(value)=>{setDataPoint(i, value)}} />}
                                {(item.type === 'text')&&<Text {...item} update={(value)=>{setDataPoint(i, value)}} />}
                                {(item.type === 'wysiwyg')&&<Wysiwyg {...item} update={(value)=>{setDataPoint(i, value)}} />}
                                {(item.type === 'imagelink')&&<ImageLink {...item} update={(val)=>{setDataObject(i, val)}} />}
                                {(item.type === 'objectarray')&&<ObjectArray {...item} update={(val)=>{setDataArray(i, val)}} />}
                                {(item.type === 'image')&&<Image {...item} update={(val)=>{setDataObject(i, val)}} />}
                                {(item.type === 'form')&&<Form {...item} update={(val)=>{setDataPoint(i, val)}} />}
                                {(item.type === 'chat')&&<Chat {...item} update={(val)=>{setDataPoint(i, val)}} />}
                                {(item.type === 'font')&&<Font {...item} update={(val)=>{setDataPoint(i, val)}} />}
                            </Fragment>
                        );
                    })}
                </div>
                <div className={'card-footer'}>
                    <button className={'btn btn-success floatright'} onClick={updateTemplate}>Save</button>
                </div>
            </div>
        </div>
    ):<Loading />
}
export default TemplateVariables;