import React, {Fragment, useContext} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faTrash, faBullseye, faEye, faBriefcaseMedical, faHeart, faSkull
} from '@fortawesome/free-solid-svg-icons';
import {faGoogle} from '@fortawesome/free-brands-svg-icons';
import {capEveryWord} from '../../../helpers';
import ModalAlert from '../../Global/ModalAlert';
import ViewProspect from '../ViewProspect';
import Google from './Google';
import {Store} from "../../../stores/Store";
import AddToPipelineBucket from './AddToPipelineBucket';
const ButtonBar = (props) => {
    const context = useContext(Store); // This part is important to access context values
    const itemname = (props.data.your_name)?capEveryWord(props.data.your_name):(!props.data.networkSuppressed)?props.data.maxmind.traits.organization:(props.data.latest_contact_name)?capEveryWord(props.data.latest_contact_name.name):'Unknown Contact';
    return (
        <div className="card-footer d-flex bg-gradient">
                {(props.suppressed)?null:(
                    <Fragment>
                        <div>
                            <ModalAlert 
                                buttonClassName={"btn btn-sm barbutton floatright "+((props.data.pipeline_bucket)?"active":'')}
                                buttonIcon={<FontAwesomeIcon icon={faBullseye} />}
                                title={'Add '+itemname+' to pipeline'}
                                content={<AddToPipelineBucket refresh={props.refresh} {...props.data} />}
                                size='lg'
                            />
                            <ModalAlert 
                                buttonClassName='btn btn-sm barbutton' 
                                buttonIcon={<FontAwesomeIcon icon={faEye} />}
                                title={(!props.data.save)?'Save "'+itemname+'" to pipeline' :'edit "'+itemname+ '" from pipeline'}
                                content={<ViewProspect l_id={props.data.id} />}
                                size='lg'
                            />
                            <ModalAlert 
                                buttonClassName='btn btn-sm barbutton' 
                                buttonIcon={<FontAwesomeIcon icon={faGoogle} />}
                                title={'Search '+itemname}
                                content={<Google 
                                        search={itemname+' '+((props.data.maxmind.city)?props.data.maxmind.city.names.en:'')+((props.data.maxmind.country)?', '+props.data.maxmind.country.names.en:'')} 
                                    />}
                                size='lg'
                            />
                        </div>
                        <div>
                            
                        </div>
                    </Fragment>
                )}
        </div>        
    )
}
export default ButtonBar;