import React, {Fragment} from 'react';
//componants
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faPlus
} from '@fortawesome/free-solid-svg-icons';
import ModalAlert from '../Global/ModalAlert';
import NewProduct from './NewProduct';
const DashboardButtons = (props) => {
    return (
        <Fragment>
            <ModalAlert 
                buttonClassName='boxbutton floatright' 
                buttonType='none'
                buttonIcon={<Fragment><FontAwesomeIcon icon={faPlus} /> <span>Project</span></Fragment>} 
                title={'New Project'} 
                content={<NewProduct update={props.update} />}
                size='lg'
                callback={props.callback}
            />
        </Fragment>
    );
}
export default DashboardButtons;
