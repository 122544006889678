import {db} from '../firebase';
import {sortContacts} from '../helpers/filters'

export const getFormContacts = async (product_id, context) => {
    return await db.collectionGroup('contacts')
    .where("productId", '==', product_id).where("type", '==', 'form')
    .where('date', '>', (Math.floor(Date.now() / 1000) - context.back))
    .get().then(async snapshot => {
        let Forms = [];
        if(!snapshot.empty)
        {
            snapshot.forEach(async doc => {
                let Form = doc.data();
                Form.id = doc.id;
                Forms.push(Form);
            });
        }
        return sortContacts(Forms, context);
    });
}
export const deleteFormContact = async (product_id, lead_id, contact_id) => {
    return await db.collection('products').doc(product_id).collection('leads').doc(lead_id).collection('contacts').doc(contact_id).delete();
}
export const deleteChatContact = async (product_id, lead_id, contact_id) => {
    return await db.collection('products').doc(product_id).collection('leads').doc(lead_id).collection('contacts').doc(contact_id).delete();
}