import React, { Fragment, useContext, useEffect, useState } from 'react';
import {Store} from '../../stores/Store';
import Number from './Types/Number';
import Select from './Types/Select';
import Color from './Types/Color';
import Image from './Types/Image';
import SimpleImage from './Types/SimpleImage';
import ImageLink from './Types/ImageLink';
import ObjectArray from './Types/ObjectArray';
import Bool from './Types/Bool';
import TextArea from './Types/TextArea';
import Wysiwyg from './Types/Wysiwyg';
import Text from './Types/Text';
import Form from './Types/Form';
import Chat from './Types/Chat';
import Font from './Types/Font';
import JavaScriptArea from './Types/JavaScriptArea';
import CSSArea from './Types/CSSArea';
import {getSite, updateSite} from '../../data/sites';
const GlobalVariables = (props) => {
    let context = useContext(Store)
    let [global, setGlobal] = useState({
        data:[]
    });
    let [active, setActive] = useState('var');
    const setDataArray = (i, array) => {
        let tempdata = global;
        tempdata.data[i].data = array;
        setGlobal({...tempdata});
    }
    const setDataObject= (i, object) => {
        let tempdata = global;
        tempdata.data[i] = {
            ...tempdata.data[i],
            ...object
        }
        setGlobal({...tempdata});
    }
    const setDataPoint = (i, value) => {
        let tempdata = global;
        tempdata.data[i].value = value;
        setGlobal({...tempdata});
    }
    useEffect(() => {
        //get global variables
        getSite(context.product_id, context.site.site_id).then((res)=>{
            setGlobal(res.data.globalVariables);
        });
    },[]);
    const updateGlobal = () => {
        //update global variables
        updateSite(context.product_id, context.site.site_id, {globalVariables:global});
        props.close();
    }
    return (
        <div>
            <ul className="nav nav-tabs">
                <li className="nav-item">
                    <span onClick={()=>setActive('var')} className={(active === 'var')?'nav-link active':'nav-link'}>Global Variables</span>
                </li>
                <li className="nav-item">
                    <span onClick={()=>setActive('js')} className={(active === 'js')?'nav-link active':'nav-link'}>Global JavaScript</span>
                </li>
                <li className="nav-item">
                    <span onClick={()=>setActive('css')} className={(active === 'css')?'nav-link active':'nav-link'}>Global CSS</span>
                </li>
            </ul>
            {(active === 'var')&&<div className={'tabContainer card'}>
                <div className={'card-header'}>
                    Global Variables Affect All Pages and Components Site Wide
                </div>
                <div className={'card-body'}>
                    {global.data.map((item,i)=>{
                        return (
                            <Fragment key={i}>
                                {(item.type === 'select')&&<Select {...item} update={(value)=>{setDataPoint(i, value)}} />}
                                {(item.type === 'number')&&<Number {...item} update={(value)=>{setDataPoint(i, value)}} />}
                                {(item.type === 'color')&&<Color {...item} update={(value)=>{setDataPoint(i, value)}} />}
                                {(item.type === 'bool')&&<Bool {...item} update={(value)=>{setDataPoint(i, value)}} />}
                                {(item.type === 'cache')&&<Bool {...item} update={(value)=>{setDataPoint(i, value)}} />}
                                {(item.type === 'textarea')&&<TextArea {...item} update={(value)=>{setDataPoint(i, value)}} />}
                                {(item.type === 'text')&&<Text {...item} update={(value)=>{setDataPoint(i, value)}} />}
                                {(item.type === 'wysiwyg')&&<Wysiwyg {...item} update={(value)=>{setDataPoint(i, value)}} />}
                                {(item.type === 'imagelink')&&<ImageLink {...item} update={(val)=>{setDataObject(i, val)}} />}
                                {(item.type === 'objectarray')&&<ObjectArray {...item} update={(val)=>{setDataArray(i, val)}} />}
                                {(item.type === 'image')&&<Image {...item} update={(val)=>{setDataObject(i, val)}} />}
                                {(item.type === 'simpleimage')&&<SimpleImage {...item} update={(val)=>{setDataPoint(i, val)}} />}
                                {(item.type === 'form')&&<Form {...item} update={(val)=>{setDataPoint(i, val)}} />}
                                {(item.type === 'chat')&&<Chat {...item} update={(val)=>{setDataPoint(i, val)}} />}
                                {(item.type === 'font')&&<Font {...item} update={(val)=>{setDataPoint(i, val)}} />}
                            </Fragment>
                        );
                    })}
                </div>
            </div>}
            {(active === 'js')&&<div className={'tabContainer card'}>
                <div className={'card-header'}>
                    Global JavaScript appears on every page
                </div>
                <div className='card-body'>
                    {global.data.map((item,i)=>{
                        return (
                            <Fragment key={i}>
                                {(item.type === 'javascript')&&<JavaScriptArea {...item} update={(val)=>{setDataPoint(i, val)}} />}
                            </Fragment>
                        );
                    })}
                </div>
            </div>}
            {(active === 'css')&&<div className={'tabContainer card'}>
                <div className={'card-header'}>
                    Global CSS appears on every page
                </div>
                <div className='card-body'>
                    {global.data.map((item,i)=>{
                        return (
                            <Fragment key={i}>
                                {(item.type === 'css')&&<CSSArea {...item} update={(val)=>{setDataPoint(i, val)}} />}
                            </Fragment>
                        );
                    })}
                </div>
            </div>}
            <div className={'row'}>
                <div className={'col-12'}>
                    <button className={'btn btn-success floatright'} onClick={()=>updateGlobal()}>Save</button>
                </div>
            </div>
        </div>
    )
}
export default GlobalVariables;