import React, {Component, Fragment} from 'react';
import PageTop from "../Global/pagetop/";
import DashboardButtons from './DashboardButtons';
import NoContent from '../Global/NoContent';
import Loading from '../Global/Loading';
import {getProducts, getProductsSharedWithMe, getAllProducts} from '../../data/products';
import ProductCard from './ProductCard/';
import {Store} from '../../stores/Store';
import Tabs from '../Global/Tabs';
import  {db, auth} from '../../firebase';
class Products extends Component {
    state = {
        products:null,
        shared_products:null,
        all_products:null
    }
    componentDidMount(){
        if(this.state.products === null || this.state.shared_products === null){
            this.update();
        }
        if((this.context.access === 'super' || this.context.access === 'admin') && this.state.all_products === null)
        {
            this.update();
        }
        this.context.update({
            product:null,
            product_id:undefined,
            view:"overview"
        });
    }
    update = async () => {
        getProducts().then((res)=>{
            this.setState({
                products:(res.success === true)?res.data:[]
            });
        }).catch(error =>{
            this.setState({
                products:[]
            });
        });
        getProductsSharedWithMe().then(async(res)=>{
            this.setState({
                shared_products:(res.success === true)?res.data:[]
            });
        }).catch(error =>{
            this.setState({
                products:[]
            });
        });
        if((this.context.access === 'super' || this.context.access === 'admin'))
        {
            await auth.currentUser.getIdToken(true)
            .then(async (idToken) => {
                await this.context.update({
                    token:idToken,
                });
            });
            getAllProducts(this.context).then((res)=>{
                this.setState({
                    all_products:(res.success === true)?res.data:[]
                });
            }).catch(error =>{
                this.setState({
                    all_products:[]
                });
            });
        }else{
            this.setState({
                all_products:[]
            });
        }
    }
    buildtabs = () =>{
        let tabs = [
            {name:'My Projects', content:(
                (this.state.products !== null && this.state.products !== undefined && this.state.products !== false)
                        ?
                            (this.state.products.length > 0)?(
                                <Fragment>
                                    <ul className="row mb-3 card-list">
                                        {this.state.products.map((item, i)=>{
                                            return (<li className="col-12 col-md-6 col-lg-4 card_object">
                                                <ProductCard update={this.update} key={i} data={item} />
                                                </li>)
                                        })}
                                    </ul>
                                </Fragment>
                            ):<NoContent />
                        :<Loading />
            )},
            {name:'Shared With Me', content:(
                (this.state.shared_products !== null && this.state.shared_products !== undefined && this.state.shared_products !== false)
                    ?
                        (this.state.shared_products.length > 0)?(
                            <Fragment>
                                <ul className="row mb-3 card-list">
                                    {this.state.shared_products.map((item, i)=>{
                                        return (<li className="col-12 col-md-6 col-lg-4 card_object">
                                            <ProductCard update={this.update} key={i} data={item} />
                                            </li>)
                                    })}
                                </ul>
                            </Fragment>
                        ):<NoContent />
                    :<Loading />
            )}
        ]
        if((this.context.access === 'super' || this.context.access === 'admin')){
            tabs.push({name:'All Projects', content:(
                (this.state.all_products !== null && this.state.all_products !== undefined && this.state.all_products !== false)
                    ?
                        (this.state.all_products.length > 0)?(
                            <Fragment>
                                <ul className="row mb-3 card-list">
                                    {this.state.all_products.map((item, i)=>{
                                        return (<li className="col-12 col-md-6 col-lg-4 card_object">
                                            <ProductCard update={this.update} key={i} data={item} />
                                            </li>)
                                    })}
                                </ul>
                            </Fragment>
                        ):<NoContent />
                    :<Loading />
            )})
        }
        return tabs;
    }
    render(){
        return (
            <Fragment>
                <PageTop name='Dashboard' buttons={((this.context.access === 'super' || this.context.access === 'admin'))&&<DashboardButtons update={this.update} />} />
                <div className='inner'>
                    <Tabs tabs={this.buildtabs()} />
                </div>
            </Fragment>
        );
    }
}
Products.contextType = Store; // This part is important to access context values
export default Products;
