const html = (template) => {
    return [
        {
            data:[
                {
                    type:'html',
                    value:'',
                    variableName:'html',
                    name:'HTML Code'
                }
            ],
            stacked:'Code',
            name:'HTML',
        },{
            data:[
                {
                    type:'css',
                    value:'',
                    variableName:'css',
                    name:'CSS Code'
                }
            ],
            stacked:'Code',
            name:'CSS',
        },{
            data:[
                {
                    type:'javascript',
                    value:'',
                    variableName:'javascript',
                    name:'Javascript Code'
                }
            ],
            stacked:'Code',
            name:'JavaScript',
        }
    ];
}
export default html;