import React, { useContext, useEffect, useState } from 'react';
import {getChatModules} from '../../../data/chats';
import { Store } from '../../../stores/Store';
const Chat = (props) => {
    let context = useContext(Store);
    let [Chats, setChats] = useState([]);
    useEffect(()=>{
        getChatModules(context.product_id).then((res)=>{
            setChats(res.data);
        });
    }, []);
    return (
        <div className="input-group mb-3">
            <div className="input-group-prepend">
                <div className="input-group-text">{props.name}</div>
            </div>
            <select name={'select'} required className='form-control' onChange={(e)=>{props.update(e.target.value)}} value={props.value}>
                <option value=''>not selected</option>
                {Chats.map((item, i)=>{
                    return(
                        <option key={i} value={item.id}>
                            {item.name}
                        </option>
                    )
                })}
            </select>
        </div>
    )
}
export default Chat;