import React, {Fragment, useContext, useEffect, useState} from 'react';
import Loading from "../../Global/Loading";
import NoContent from '../../Global/NoContent';
import {Store} from '../../../stores/Store';
import { getChats } from '../../../data/chats';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faChevronRight, faChevronDown
} from '@fortawesome/free-solid-svg-icons';
import {formatDate} from '../../../helpers/time';
const Chats = (props) => {
    const [chats, setChats] = useState(null);
    const [openchat, setOpenChats] = useState(null);
    const context = useContext(Store);
    useEffect(()=>{
        getChats(context.product_id, props.prospect.id).then((responce)=>{
            setChats(responce.data);
        })
    }, [context.product_id, props.prospect.id]);
        return (<div className="card">
                <div className="card-header">
                    Chats from this prospect
                </div>
                {(chats && chats !== null)?(chats.length > 0)?(
                    <div className="card-body">
                        {chats.map((chat, i)=>{
                            return (chat.chat)?(
                                <Fragment key={i}>
                                    <div className='chattitle'>
                                        <div className='card' onClick={()=>setOpenChats((openchat === i)?null:i)}>
                                            <div className='card-body'>
                                                {chat.name}
                                                <span className='openindi'>{(openchat === i)?<FontAwesomeIcon icon={faChevronDown} />:<FontAwesomeIcon icon={faChevronRight} />}</span> 
                                                <span className='chatdate'>{formatDate(chat.date)}</span> 
                                            </div>
                                        </div>
                                    </div>
                                    {(openchat === i)?<div key={i} className='chat'>
                                        <div className='card'>
                                            <div className='card-body'>
                                                {chat.chat.map((item, b)=>{
                                                    return <div key={b} className={(item.own === true)?'chatitem me':'chatitem'}><span className='chatname'>{item.name.toUpperCase()}</span> {item.message}</div>
                                                })}
                                            </div>
                                        </div>
                                    </div>:null}
                                </Fragment>):null;
                            
                        })}
                    </div>):<NoContent title='No Chats' text='No Chats Yet. add chat to a landing page or your website to start seeing chat interactions here' />
                :<Loading />}
            </div>);
}
export default Chats;