import React, { Fragment, useContext, useEffect, useState } from 'react';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { docco } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import {Store} from "../../stores/Store";
const EbedCode = (props) => {
    let context = useContext(Store);
    let code = "<div data-chat-id='"+props.id+"' data-product-id='"+context.product_id+"' class='flgchat'></div>";
    let include = "<script type='text/javascript' src='https://flg.onl/chat.js'></script>";
    let [tab, setTab] = useState('fledge');
    return (
        <Fragment>
            {(tab === 'external')&&<div class="alert alert-secondary" role="alert">
                To install Chat on your <b>external</b> website you must add both blocks of code into your code!
            </div>}
            <ul class="nav nav-tabs">
                <li onClick={()=>setTab('fledge')} class="nav-item">
                    <span className={(tab === 'fledge')?"nav-link active":"nav-link"}>Fledge Site</span>
                </li>
                <li onClick={()=>setTab('external')} class="nav-item">
                    <span className={(tab === 'external')?"nav-link active":"nav-link"}>External Site</span>
                </li>
            </ul>
            {(tab === 'fledge')&&<div className='card'>
                <div className='card-body'>
                    <h3>Adding chat to a Fledge site</h3>
                    <h4>Step 1</h4>
                    <p>Build the chat element you want in the Chat section.</p>
                    <h4>Step 2</h4>
                    <p>Build the site you want the chat to be on.</p>
                    <h4>Step 3</h4>
                    <p>Select your chat element in the edit site varibale panel.</p>
                </div>
            </div>}
            {(tab === 'external')&&<div className='card'>
                <div className='card-body'>
                    <div class="alert alert-warning" role="alert"><p>You can only currently embed on the following sites. To add more either create new projects or add the domain to the project in edit project.</p>
                        <ul>
                            {context.product.secondaryUrls.map((item, i)=>{
                                return <li key={i}>{item}</li>
                            })}
                        </ul>
                    </div>
                    <h3>HTML Include</h3>
                    <p>Embed this code at the place at the bottom of your body tag. if you wish for it to appear on every page you may want to include it as a global include</p>
                    <SyntaxHighlighter language="javascript" style={docco}>
                        {code}
                    </SyntaxHighlighter>
                    <h3>Javascript Include</h3>
                    <p>Embed this in the footer of your website, again global include will be needed if you wish for it to appear on every page.</p>
                    <SyntaxHighlighter language="javascript" style={docco}>
                        {include}
                    </SyntaxHighlighter>
                </div>
            </div>}
        </Fragment>
    );
}
export default EbedCode;