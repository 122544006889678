import React, {useState, Fragment, useEffect} from 'react';
import Color from '../Types/Color';
import Number from '../Types/Number';
import Image from '../Types/Image';
import SimpleImage from '../Types/SimpleImage';
import ImageLink from '../Types/ImageLink';
import ObjectArray from '../Types/ObjectArray';
import Select from '../Types/Select';
import Bool from '../Types/Bool';
import TextArea from '../Types/TextArea';
import HTMLArea from '../Types/HTMLArea';
import JavaScriptArea from '../Types/JavaScriptArea';
import CSSArea from '../Types/CSSArea';
import Wysiwyg from '../Types/Wysiwyg';
import Text from '../Types/Text';
import Form from '../Types/Form';
import Chat from '../Types/Chat';
import Link from '../Types/Link';
import Stacks from './Stacks';
import FeedId from '../Types/FeedId';
import FeedCat from '../Types/FeedCat';
const EditComponent = (props) =>{
    let [item, setData] = useState(props.item);
    let [stacked, setStacked] = useState([]);
    const setDataArray = (i, b, array) => {
        let tempdata = item;
        tempdata.data[i].data[b].data = array;
        setData({...tempdata});
    }
    const setDataObject= (i, b, object) => {
        let tempdata = item;
        tempdata.data[i].data[b] = {
            ...tempdata.data[i].data[b],
            ...object
        }
        setData({...tempdata});
    }
    const setName = (value) => {
        let tempdata = item;
        tempdata.name = value;
        setData({...tempdata});
    }
    const setDescription = (value) => {
        let tempdata = item;
        tempdata.description = value;
        if(value.length < 100){
            setData({...tempdata});
        }
    }
    const setDataPoint = (i, b, value) => {
        let tempdata = item;
        tempdata.data[i].data[b].value = value;
        setData({...tempdata});
    }
    useEffect(() => {
        let stacks = [];
        let filtered = item.data.filter((a)=>a.stacked);
        for(let stack of filtered){
            if(!stacks.includes(stack.stacked)){
                stacks.push(stack.stacked);
            }
        }
        setStacked(stacks);
    }, [item]);
    const GetComponent = (item, i, b) => {
            return(
                <Fragment key={b}>
                    {(item.type === 'color')&&<Color key={b} {...item} update={(val)=>{setDataPoint(i, b, val)}} />}
                    {(item.type === 'number')&&<Number key={b}{...item} update={(val)=>{setDataPoint(i, b, val)}} />}
                    {(item.type === 'image')&&<Image key={b} {...item} update={(val)=>{setDataObject(i, b, val)}} />}
                    {(item.type === 'simpleimage')&&<SimpleImage key={b} {...item} update={(val)=>{setDataPoint(i, b, val)}} />}
                    {(item.type === 'imagelink')&&<ImageLink key={b} {...item} update={(val)=>{setDataObject(i, b, val)}} />}
                    {(item.type === 'objectarray')&&<ObjectArray key={b} {...item} update={(val)=>{setDataArray(i, b, val)}} />}
                    {(item.type === 'select')&&<Select key={b} {...item} update={(val)=>{setDataPoint(i, b, val)}} />}
                    {(item.type === 'bool')&&<Bool key={b} {...item} update={(val)=>{setDataPoint(i, b, val)}} />}
                    {(item.type === 'textarea')&&<TextArea key={b} {...item} update={(val)=>{setDataPoint(i, b, val)}} />}
                    {(item.type === 'html')&&<HTMLArea key={b} {...item} update={(val)=>{setDataPoint(i, b, val)}} />}
                    {(item.type === 'javascript')&&<JavaScriptArea key={b} {...item} update={(val)=>{setDataPoint(i, b, val)}} />}
                    {(item.type === 'css')&&<CSSArea key={b} {...item} update={(val)=>{setDataPoint(i, b, val)}} />}
                    {(item.type === 'wysiwyg')&&<Wysiwyg key={b} {...item} update={(val)=>{setDataPoint(i, b, val)}} />}
                    {(item.type === 'text')&&<Text key={b} {...item} update={(val)=>{setDataPoint(i, b, val)}} />}
                    {(item.type === 'form')&&<Form key={b} {...item} update={(val)=>{setDataPoint(i, b, val)}} />}
                    {(item.type === 'chat')&&<Chat key={b} {...item} update={(val)=>{setDataPoint(i, b, val)}} />}
                    {(item.type === 'link')&&<Link key={b} {...item} update={(val)=>{setDataObject(i, b, val)}} />}
                    {(item.type === 'feedid')&&<FeedId key={b} {...item} update={(val)=>{setDataPoint(i, b, val)}} />}
                    {(item.type === 'feedcat')&&<FeedCat key={b} {...item} update={(val)=>{setDataPoint(i, b, val)}} />}
                </Fragment>
            );
    }
    return <div className='card popup-card'>
        <div className='card-header'>
            <span>{item.name}</span>
            <span className='btn btn-success floatright' onClick={()=>{props.update(item); props.close();}}>Save</span>
        </div>
        <div className='card-body'>
             <div className="input-group mb-3">
                <div className="input-group-prepend">
                    <div className="input-group-text">Name</div>
                </div>
                <input 
                    className="form-control" 
                    name={'text'} 
                    onChange={(e)=>{setName(e.target.value)}} 
                    value={item.name}
                    placeholder={'Component Name'} 
                />
            </div>
            <div className="input-group mb-3">
                <div className="input-group-prepend">
                    <div className="input-group-text">Description</div>
                </div>
                <input 
                    className="form-control" 
                    name={'text'} 
                    onChange={(e)=>{setDescription(e.target.value)}} 
                    value={item.description}
                    placeholder={'Component Description'} 
                />
            </div>
            {stacked.map((stack, i)=>{
                return <Stacks key={i} stack={stack} item={item} GetComponent={GetComponent} />
            })}
            {item.data.filter(a=>!a.stacked).map((item, i)=>{
                return (
                    <div key={i} className='card'>
                        <div className='card-header'>{item.name}</div>
                        <div className='card-body'>
                            {item.data.map((datatype, b)=>{
                                return GetComponent(datatype, i, b);
                            })}
                        </div>
                    </div>
                )
            })}
        </div>
        <div className='card-footer'>
            <span className='btn btn-success floatright' onClick={()=>{props.update(item); props.close();}}>Save</span>
        </div>
    </div>
}
export default EditComponent;