import React, {useState, Fragment, useEffect, useContext} from 'react';
import { getFeedContentRoutes, getRoutes} from '../../data/sites';
import RouteCard from './RouteCard';
import FeedCatCard from './FeedCatCard';
import NoContent from '../Global/NoContent';
import PageTop from '../Global/pagetop';
import {Store} from '../../stores/Store';
import PageDashboardButtons from './PageDashboardButtons';
import Loading from '../Global/Loading';
import Tabs from '../Global/Tabs';
import {
    useParams
  } from "react-router-dom";
const FeedContent = () => {
    const [routes, setRoutes] = useState(null);
    const context = useContext(Store);
    let {feed_id} = useParams();
    useEffect(()=>{
        context.update({
            view:'sites'
        });
        refresh();
    }, []);
    const refresh = () => {
        
        /// get the routes and show only the routes that relate to the feed - also filter these out on the normal routes page 
        getFeedContentRoutes(context.product_id, context.site.site_id, feed_id).then((result)=>{
            setRoutes(result.data);
        });
    }
    return (
        <Fragment>
            <PageTop name={'Sites'} buttons={<PageDashboardButtons feed={feed_id} templates={false} callback={refresh} />}/>
            <div className='inner'>
            <Tabs tabs={[
                
                {name:'Content', content:(routes)?(routes.filter(route=>(feed_id && route.feed === feed_id && route.type !== 'feedcat')).length > 0)?<ul className="row mb-3 card-list">
            {routes.map((route, i)=>{
                if(feed_id && route.feed === feed_id && route.type !== 'feedcat'){
                return <li key={i} className="col-12 col-md-6 col-lg-4 card_object">
                    <RouteCard 
                        {...route}
                        refresh={refresh}
                    /></li>
                }
            })}
        </ul>:<NoContent title='No Feed Content Yet!' text='Create your first feed content by clicking new page button above' />:<Loading/>},
                {name:'Categories', content:(routes)?(routes.filter((route, i)=>(feed_id && route.feed === feed_id && route.type === 'feedcat')).length > 0)?<ul className="row mb-3 card-list">
                            {routes.map((route, i)=>{
                                if(feed_id && route.feed === feed_id && route.type === 'feedcat'){
                                return <li key={i} className="col-12 col-md-6 col-lg-4 card_object">
                                    <FeedCatCard 
                                        {...route}
                                        refresh={refresh}
                                    /></li>
                                }
                            })}
                        </ul>:<NoContent title='No Feed Categories Yet!' text='Create your first feed category by clicking new page button above' />:<Loading/>}
                    ]}/>
            </div>
        </Fragment>
    )
}
export default FeedContent;