export const pagedefault = {
    pageVariables:{
        data:[{
            name:'Page Title Tag',
            value:"My New Page",
            variableName:'title',
            type:'text'
        },{
            name:'Meta Description',
            value:'',
            variableName:'metaDescription',
            type:'text'
        },{
            name:'Listing Title',
            value:'',
            variableName:'ListingTitle',
            type:'text'
        },{
            name:'Listing Description',
            value:'',
            variableName:'ListingDescription',
            type:'text'
        },{
            name:'Listing Image',
            value:'',
            variableName:'ListingImage',
            type:'simpleimage'
        }]
    }
}