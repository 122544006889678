import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faSpinner
} from '@fortawesome/free-solid-svg-icons';
const Processing = (props) => {
    return(
        <div className='loading'>
            <FontAwesomeIcon icon={faSpinner} spin /><br/>
            <span>Processing...</span>
        </div>
    )
}
export default Processing;