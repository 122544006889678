import React, {useState, Fragment, useEffect, useContext} from 'react';
import {getFormContacts} from '../../data/contacts';
import {getSavedLeads} from '../../data/leads';
import FormCard from './FormCard';
import NoContent from '../Global/NoContent';
import PageTop from '../Global/pagetop';
import {Store} from '../../stores/Store';
import FilterButtons from '../Global/FilterButtons';
import Table from './Table';
const FormContacts = (props) => {
    const [forms, setForms] = useState(null);
    const [prospectids, setProspectids] = useState([]);
    const context = useContext(Store);
    useEffect(()=>{
        if(!context.product_id && props.match.params.product_id)
        {
            context.update({
                product_id:props.match.params.product_id
            });
        }
    },[]);
    useEffect(()=>{
        if(context.product_id)
        {
            refresh();
        }
    }, [context.product_id]);
    const refresh = () => {
        getFormContacts(context.product_id, context).then((result)=>{
            setForms(result);
        });
        getSavedLeads(context.product_id, context).then((result)=>{
            let prosids = [];
            for(let prospect of result.data){
                prosids.push(prospect.id);
            }
            setProspectids(prosids);
        });
    }
    const toggleView = () => {
        context.update({
            cards:!context.cards
        });
    }
    return (
        <Fragment>
            <PageTop name={'Form Contact Events'} buttons={<FilterButtons toggleView={toggleView} refresh={refresh} />}/>
            <div className='inner'>
                {(forms && forms.length > 0)?(context.cards === true)?<ul className="row mb-3 card-list">
                    {forms.map((Form, i)=>{
                        return <li className="col-12 col-md-6 col-lg-4 card_object"><FormCard 
                                    {...Form}
                                    key={i}
                                    refresh={refresh}
                                    prospectids={prospectids}
                                /></li>
                    })}
                </ul>:<div className='row'>
                                <div className='col-12'>
                                    <div className='card'>
                                        <div className="card-body">
                                            <Table data={forms} refresh={refresh} prospectids={prospectids}/>
                                        </div>
                                    </div>
                                </div>
                            </div>:<NoContent title='No Forms Yet!' text='Create your first form by clicking new form above' />}
            </div>
        </Fragment>
    )
}
export default FormContacts;