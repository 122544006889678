import {db} from '../firebase';
const sortLeads = (Leads, context) => {
    if(context.sort.sort_date)
    {
        return (context.sort.sort_down)
        ?Leads.sort((a, b) => (b.last_updated > a.last_updated) ? 1 : -1)
        :Leads.sort((a, b) => (a.last_updated > b.last_updated) ? 1 : -1)
    }else{
        return (context.sort.sort_down)
        ?Leads.sort((a, b) => {
            let textA = (a.your_name !== null)?a.your_name.toUpperCase():(a.networkSuppressed)?a.maxmind.traits.organization.toUpperCase():(a.latest_contact_name)?a.latest_contact_name.name.toUpperCase():'UNKNOWN CONTACT';
            let textB = (a.your_name !== null)?b.your_name.toUpperCase():(b.networkSuppressed)?b.maxmind.traits.organization.toUpperCase():(b.latest_contact_name)?b.latest_contact_name.name.toUpperCase():'UNKNOWN CONTACT';
            return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
        })
        :Leads.sort((a, b) => {
            let textA = (a.your_name !== null)?a.your_name.toUpperCase():(a.networkSuppressed)?a.maxmind.traits.organization.toUpperCase():(a.latest_contact_name)?a.latest_contact_name.name.toUpperCase():'UNKNOWN CONTACT';
            let textB = (a.your_name !== null)?b.your_name.toUpperCase():(b.networkSuppressed)?b.maxmind.traits.organization.toUpperCase():(b.latest_contact_name)?b.latest_contact_name.name.toUpperCase():'UNKNOWN CONTACT';
            return (textA > textB) ? -1 : (textA < textB) ? 1 : 0;
        })
    }
}
const setupfilters = (Leads, context) => {
    let citys = [];
    let countrys = [];
    Leads.forEach((lead)=>{
        if(lead.maxmind.city && !citys.includes(lead.maxmind.city.names.en)){
            citys.push(lead.maxmind.city.names.en);
        }
        if(lead.maxmind.country && !countrys.includes(lead.maxmind.country.names.en)){
            countrys.push(lead.maxmind.country.names.en);
        }
        return
    });
    let filters = context.filters;
    filters.city.options = citys.map(city=>{return {label:city, value:city}});
    filters.country.options = countrys.map(country=>{return {label:country, value:country}});
    context.update({
        filters:filters
    });
    return true;
}
export const getLeads = async (product_id, back, context) => {
    let responce = {
        success:true,
        data:[]
    }
    await db.collection('products').doc(product_id).collection('leads')
    .where('last_updated', '>', (Math.floor(Date.now() / 1000) - back))
    .where('formAmount', '==', 0)
    .where('chatAmount', '==', 0)
    .get().then(async snapshot => {
        let Leads = [];
        if(!snapshot.empty)
        {
            snapshot.forEach(async doc => {
                let Lead = doc.data();
                if(Lead.networkSuppressed === false || Lead.formAmount > 0 || Lead.chatAmount > 0){
                    Lead.id = doc.id;
                    Leads.push(Lead);
                }
            });
        }
        Leads = sortLeads(Leads, context);
        setupfilters(Leads, context);
        responce.data = Leads;
        return
    }).catch((error)=>{
        responce.success = false;
        responce.error = error;
        delete responce.data;
        return 
    });
    return responce;
}
export const getPinnedLeads = async (product_id, back, context) => {
    let responce = {
        success:true,
        data:[]
    }
    await db.collection('products').doc(product_id).collection('leads')
    .where('last_updated', '>', (Math.floor(Date.now() / 1000) - back))
    .get().then(async snapshot => {
        let Leads = [];
        if(!snapshot.empty)
        {
            snapshot.forEach(async doc => {
                let Lead = doc.data();
                if(Lead.pin === true && (Lead.networkSuppressed === false || Lead.formAmount > 0)){
                    Lead.id = doc.id;
                    Leads.push(Lead);
                }
            });
        }
        Leads = sortLeads(Leads, context);
        setupfilters(Leads, context);
        responce.data = Leads;
        return
    }).catch((error)=>{
        responce.success = false;
        responce.error = error;
        delete responce.data;
        return 
    });
    return responce;
}
export const getSavedLeads = async (product_id, context) => {
    let responce = {
        success:true,
        data:[]
    }
    await db.collection('products').doc(product_id).collection('leads')
    .where('pipeline_bucket', '!=', null)
    .get().then(async snapshot => {
        let Leads = [];
        if(!snapshot.empty)
        {
            snapshot.forEach(async doc => {
                let Lead = doc.data();
                Lead.id = doc.id;
                Leads.push(Lead);
            });
        }
        Leads = sortLeads(Leads, context);
        setupfilters(Leads, context);
        responce.data = Leads;
        return
    }).catch((error)=>{
        responce.success = false;
        responce.error = error;
        delete responce.data;
        return 
    });
    return responce;
}
export const getLead = async (product_id, lead_id) => {
    let responce = {
        success:false,
        data:[]
    }
    return await db.collection('products').doc(product_id).collection('leads').doc(lead_id)
    .get().then(async ref => {
        let Lead = ref.data();
        Lead.id = ref.id;
        responce.success = true;
        responce.data = Lead;
        return responce;
    }).catch((err)=>{
        return responce;
    });
}
export const getVisits = async (product_id, lead_id, length = false) => {
    let responce = {
        success:true,
        data:[]
    }
    await db.collection('products').doc(product_id).collection('leads').doc(lead_id).collection('visits').orderBy("last_updated", "desc").get().then(async snapshot => {
        let Leads = [];
        if(!snapshot.empty)
        {
            snapshot.forEach(async doc => {
                let Lead = doc.data();
                Lead.id = doc.id;
                Leads.push(Lead);
            });
        }
        responce.data = Leads;
        return
    }).catch((error)=>{
        responce.success = false;
        responce.error = error;
        delete responce.data;
        return 
    });
    return (length === false)?responce:responce.data.length;
}
export const getNotes = async (product_id, lead_id, length = false) => {
    let responce = {
        success:true,
        data:[]
    }
    await db.collection('products').doc(product_id).collection('leads').doc(lead_id).collection('notes').orderBy("date", "desc").get().then(async snapshot => {
        let Notes = [];
        if(!snapshot.empty)
        {
            snapshot.forEach(async doc => {
                let Note = doc.data();
                Note.id = doc.id;
                Notes.push(Note);
            });
        }
        responce.data = Notes;
        return
    }).catch((error)=>{
        responce.success = false;
        responce.error = error;
        delete responce.data;
        return 
    });
    return (length === false)?responce:responce.data.length;
}
export const newNote = async (product_id, lead, form) => {
    await db.collection('products').doc(product_id).collection('leads').doc(lead.id).collection('notes').add({
        body:form.body,
        date:Math.floor(Date.now() / 1000)
    }).then(async ()=>{
        let noteAmount = 0;
        await db.collection('products').doc(product_id).collection('leads').doc(lead.id).collection('notes').get().then(snapshot => {
            if(!snapshot.empty)
            {
                snapshot.forEach(async () => {
                    noteAmount++;
                });
            }
        });
        await db.collection('products').doc(product_id).collection('leads').doc(lead.id).update({
            noteAmount:(noteAmount)
        });
    });
    return;
}
export const deleteNote = async (product_id, lead, note_id) => {
    await db.collection('products').doc(product_id).collection('leads').doc(lead.id).collection('notes').doc(note_id).delete().then(async ()=>{
        let noteAmount = 0;
        await db.collection('products').doc(product_id).collection('leads').doc(lead.id).collection('notes').get().then(snapshot => {
            if(!snapshot.empty)
            {
                snapshot.forEach(async doc => {
                    noteAmount++;
                });
            }
        });
        await db.collection('products').doc(product_id).collection('leads').doc(lead.id).update({
            noteAmount:(noteAmount > 0)?noteAmount:0
        });
    });
    return;
}
export const getForms = async (product_id, lead_id, length = false) => {
    let responce = {
        success:true,
        data:[]
    }
    await db.collection('products').doc(product_id).collection('leads').doc(lead_id).collection('contacts').orderBy("date", "desc").get().then(async snapshot => {
        let Forms = [];
        if(!snapshot.empty)
        {
            snapshot.forEach(async doc => {
                let Form = doc.data();
                Form.id = doc.id;
                Forms.push(Form);
            });
        }
        responce.data = Forms;
        return
    }).catch((error)=>{
        responce.success = false;
        responce.error = error;
        delete responce.data;
        return 
    });
    return (length === false)?responce:responce.data.length;
}
export const deleteForms = async (product_id, lead_id, from_id) => {
    let responce = {
        success:true,
        data:[]
    }
    await db.collection('products').doc(product_id).collection('leads').doc(lead_id).collection('contacts').doc(from_id).delete().catch((error)=>{
        responce.success = false;
        responce.data = error;
    });
    if(responce.success === true)
    {
        let lead = await db.collection('products').doc(product_id).collection('leads').doc(lead_id).get().then((res)=>{
            return res.data()
        });
        await db.collection('products').doc(product_id).collection('leads').doc(lead_id).update({
            formAmount:(lead.formAmount - 1)
        });
    }
    
    return responce;
}
export const deleteLead = async (product_id, lead_id, value) => {
    let responce = {
        success:true
    }
    await db.collection('products').doc(product_id).collection('leads').doc(lead_id).update({
        deleted:value?false:true
    }).catch((error)=>{
        responce.success = false;
        responce.error = error;
    })
    return responce;
}
export const pinLead = async (product_id, lead_id, value) => {
    let responce = {
        success:true
    }
    await db.collection('products').doc(product_id).collection('leads').doc(lead_id).update({
        pin:value?false:true
    }).catch((error)=>{
        responce.success = false;
        responce.error = error;
    })
    return responce;
}
export const saveLead = async (product_id, lead_id, value) => {
    let responce = {
        success:true
    }
    await db.collection('products').doc(product_id).collection('leads').doc(lead_id).update({
        save:value?false:true
    }).catch((error)=>{
        responce.success = false;
        responce.error = error;
    })
    return responce;
}
export const addtobucket = async (product_id, lead_id, value) => {
    let responce = {
        success:true
    }
    await db.collection('products').doc(product_id).collection('leads').doc(lead_id).update({
        pipeline_bucket:value
    }).catch((error)=>{
        responce.success = false;
        responce.error = error;
    })
    return responce;
}