import React from 'react';
// Import React Table
import ReactTable from "react-table";
import "react-table/react-table.css";

const StatsTable = (props) => {
    return <ReactTable
        data={props.data}
            columns={[
                {
                    Header: "#",
                    accessor: "key",
                    width: 50,
                    Cell: row => (<span className='tableCell'><span>{row.value}</span></span>)
                },{
                    Header: "Page",
                    accessor: "url",
                    Cell: row => (<span className='tableCell'><span title={row.value}>{row.value}</span></span>)
                },
                {
                    Header: "Visits",
                    accessor: "number",
                    width: 50,
                    Cell: row => (<span className='tableCell'><span>{row.value}</span></span>)
                }
            ]
        }
        defaultPageSize={10}
        showPagination={true}
        showPageSizeOptions={false}
        className="spacetop -striped -highlight"
        />;
}
export default StatsTable;
