import React, {Fragment, Component} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faEye, faTrash, faBullseye, faTimesCircle
} from '@fortawesome/free-solid-svg-icons';
import Processing from '../../Global/Processing';
import {Store} from "../../../stores/Store";
import ModalAlert from '../../Global/ModalAlert';
import ViewChat from '../ViewChat';
import ViewClosedChat from '../ViewChat';
import AddToPipelineBucket from '../../Pipeline/ProspectCard/AddToPipelineBucket';
class ButtonBar extends Component{
    state = {
        processing:false
    }
    
    render(){
        return (!this.state.processing)?(
            <div className="card-footer d-flex bg-gradient">
                <div>
                    <ModalAlert 
                            buttonClassName={"btn btn-sm barbutton floatright "+((this.props.prospectids.includes(this.props.data.leadId))?'active':'')}
                            buttonIcon={<FontAwesomeIcon icon={faBullseye} />}
                            title={'Add to pipeline'}
                            content={<AddToPipelineBucket useLeadId={true} refresh={this.props.refresh} {...this.props} />}
                            size='lg'
                    />
                    <ModalAlert 
                        buttonClassName='btn btn-sm barbutton floatright' 
                        buttonType='none'
                        buttonIcon={<Fragment><FontAwesomeIcon icon={faEye} /></Fragment>} 
                        title={'View'}
                        content={(!this.props.closed)?<ViewChat {...this.props} />:<ViewClosedChat {...this.props} />}
                        size='lg'
                    />
                </div>
                <div>
                    
                    <button title={(!this.props.data.closed)?'Close Chat':'Delete Chat'} className="btn btn-sm barbutton floatright warning" onClick={(!this.props.data.closed)?this.props.closechat:this.props.deleteChat}>
                        {(this.props.data.closed)?<FontAwesomeIcon icon={faTrash} />:<FontAwesomeIcon icon={faTimesCircle} />}
                    </button>
                </div>
                
            </div>        
        ):<Processing />
    }  
}
ButtonBar.contextType = Store; // This part is important to access context values
export default ButtonBar;