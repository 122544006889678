import React, {Fragment, useState, useContext} from 'react';
//componants
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faTrash, faBullseye, faEye
} from '@fortawesome/free-solid-svg-icons';
import ModalAlert from '../../Global/ModalAlert';
import ViewFormContact from '../ViewFormContact';
import {capEveryWord} from '../../../helpers';
import {Store} from '../../../stores/Store';
import {deleteFormContact} from '../../../data/contacts';
import AddToPipelineBucket from '../../Pipeline/ProspectCard/AddToPipelineBucket';
const TableButtons =(props)=>{
    const context = useContext(Store); // This part is important to access context values
    const deleteContact = async() => {
        setProcessing(true);
        ///await the form to be removed
        let objdelete = window.prompt("Please enter 'delete' to confirm", "");
        if (objdelete === 'delete') {
            deleteFormContact(context.product_id, props.data.leadId, props.data.id)
        }
        await props.refresh(); 
    }
    let [processing, setProcessing] = useState(false);
        return (
            <span className='tableCell'>
                {(!processing)?(
                <Fragment>
                    <ModalAlert 
                            buttonClassName={((props.prospectids.includes(props.data.leadId))?'boxbutton floatleft btn btn-none btn-sm active':'boxbutton floatleft btn btn-none btn-sm ')}
                            buttonIcon={<FontAwesomeIcon icon={faBullseye} />}
                            title={'Add to pipeline'}
                            content={<AddToPipelineBucket useLeadId={true} refresh={props.refresh} {...props} />}
                            size='lg'
                        />
                    <ModalAlert 
                        buttonClassName='boxbutton floatleft btn btn-none btn-sm ' 
                        buttonIcon={<FontAwesomeIcon icon={faEye} />}
                        title={props.data.name !== null?capEveryWord(props.data.name):'Unknown Contact'}
                        content={<ViewFormContact {...props.data} />}
                        size='lg'
                    />
                    <button className="boxbutton floatleft btn btn-none btn-sm " onClick={()=>{setProcessing(true); deleteContact()}}><FontAwesomeIcon icon={faTrash} /></button>
                </Fragment>):'processing'}
            </span>
        );

}
export default TableButtons;