import React, {useContext, Fragment} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
faListAlt, faCalendar
} from '@fortawesome/free-solid-svg-icons';
import {Store} from "../../../stores/Store";
import ButtonBar from './ButtonBar';
import {liveuser, formatDate} from '../../../helpers/time';
const FormCard = (props) =>{
    const context = useContext(Store);
        return (
                <div className={"card "}>
                    <div className="card-header card-header-borderless d-flex justify-content-between">
                        <div>
                            <FontAwesomeIcon icon={faListAlt} />
                            {' '+props.name}
                        </div>
                    </div>
                    <div className="card-body">
                        <div className="media">
                            <div className="media-body siteCard">
                            <ul className="list-group">
                                    <li className="list-group-item">
                                        {(liveuser(props.date))?(
                                            <Fragment>
                                                <FontAwesomeIcon icon={faCalendar} /> <span className='live'>On Site Now</span>
                                            </Fragment>
                                        ):(
                                            <Fragment>
                                                <FontAwesomeIcon icon={faCalendar} /> {formatDate(props.date)}
                                            </Fragment>
                                        )}
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <ButtonBar
                        {...props}
                    />
                </div>
        )

}
export default FormCard;