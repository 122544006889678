import React, {useEffect, useState, useContext} from 'react';
import {getRoutes} from '../../../data/sites';
import {Store} from '../../../stores/Store';
const Link = (props) => {
    let context = useContext(Store);
    let [link ,setLink] = useState(props.link?props.link:'');
    let [routes ,setRoutes] = useState([]);
    let [freetype ,setFreeType] = useState(props.freetype?props.freetype:false);
    let [title ,setTitle] = useState(props.title?props.title:'');
    let [text ,setText] = useState(props.text?props.text:'');
    let [classes ,setClasses] = useState(props.classes?props.classes:'');
    useEffect(()=>{
        ///get routes
        getRoutes(context.product_id, context.site.site_id).then((res)=>{
            setRoutes(res.data);
        });
    }, []);
    useEffect(()=>{
        props.update({
                link:link,
                classes:classes,
                text:text,
                title:title,
                type:'link',
                title:title,
                freetype:freetype
            });
    }, [link, text, title, classes]);
    return (
        <div>
            <div className="input-group mb-3">
                <div className="input-group-prepend">
                    <div className="input-group-text">Title Text</div>
                </div>
                <input 
                    type='text' 
                    name={'link'}
                    onChange={(e)=>setTitle(e.target.value)}
                    className='form-control'
                    placeholder={'...Title Text'}
                    value={title}
                />
            </div>
            <div className="input-group mb-3">
                <div className="input-group-prepend">
                    <div className="input-group-text">Additional Classes</div>
                </div>
                <input 
                    type='text' 
                    name={'classes'}
                    onChange={(e)=>setClasses(e.target.value)}
                    className='form-control'
                    placeholder={'...Classes'}
                    value={classes}
                />
            </div>
            <div className="input-group mb-3">
                <div className="input-group-prepend">
                    <div className="input-group-text">Link Text</div>
                </div>
                <input 
                    type='text' 
                    name={'text'}
                    onChange={(e)=>setText(e.target.value)}
                    className='form-control'
                    placeholder={'... Text'}
                    value={text}
                />
            </div>
            <div className="input-group mb-3">
                <div className="input-group-prepend">
                    <div className="input-group-text">Link</div>
                </div>
                {(freetype)?<input 
                    type='text' 
                    name={'link'}
                    onChange={(e)=>setLink(e.target.value)}
                    className='form-control'
                    placeholder={'...Link'}
                    value={link}
                />:<select
                    className='form-control'
                    name={'link'}
                    onChange={(e)=>setLink(e.target.value)}
                    value={link}
                >
                    <option value={''}>No Button - Select/Add a link to display a button</option>
                    {routes.map((item, i )=>{
                        return <option key={i} value={item.slug}>{item.slug} {item.name}</option>
                    })}
                </select>}
                <span onClick={()=>{setFreeType(!freetype); setLink('')}} className={'btn boxbutton'}>{(freetype)?'Choose Page':'Free Type'}</span>
            </div>
        </div>
    )
}
export default Link;