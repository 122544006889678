import React, {Component, Fragment} from 'react';
import NoFolders from './NoFolders';
import NoContent from './NoContent';
import Loading from '../Global/Loading';
import {Store} from '../../stores/Store';
import PageTop from '../Global/pagetop';
import {getFolders} from '../../data/content';
import FolderButtons from './FolderButtons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ModalAlert from '../Global/ModalAlert';
import EditFolder from './EditFolder';
import {
    faFolderOpen, faFolder, faFileAudio, faFileVideo, faFilePdf, faFileCsv, faTimes, faEdit
} from '@fortawesome/free-solid-svg-icons';
import NewFile from'./NewFile';
import {storage} from "firebase";
const storageRef = storage().ref();
class Content extends Component {
    state = {
        folders:null,
        folder:null,
        content:null
    }
    componentDidMount()
    {
        this.update();
    }
    update = () => {
        getFolders(this.context.product_id).then((res)=>{
            this.setState(
                {
                    folders:res.data
                }
            );
        })
    }
    openFolder = async (folder) =>{
        await this.setState({
            content:null
        });
        await this.setState({
            folder:(this.state.folder)
            ?
                (folder !== this.state.folder.id)
                ?
                    this.state.folders.find((item)=>{ return item.id === folder})
                :
                    null
            :
            this.state.folders.find((item)=>{ return item.id === folder})
        });
        if(this.state.folder)
        {
            this.updateFolderContent();
        }
    }
    updateFolderContent = async () => {
        let FileList = [];
        await storageRef.child(this.context.product_id+'/'+this.state.folder.id).listAll().then(async (res)=>{
            for(let i = 0; res.items.length > i; i++)
            {
                let meta = {};
                await res.items[i].getMetadata().then(function(metadata) {
                    meta = metadata;
                  });
                await res.items[i].getDownloadURL().then((file)=>{
                    FileList.push({
                        url:file,
                        location:res.items[i].location,
                        meta:meta
                    });
                });
            }
            return false;
        });
        await this.setState({
            content:FileList
        });
    }
    deleteFile = (path) => {
        storageRef.child(path).delete().then(() => {
            this.updateFolderContent();
        });
    }
    useFile = (url) =>{
        if(this.props.use){
            this.props.use(url);
        }
    }
    render(){
        
        return (
            <Fragment>
                <PageTop 
                    name={(this.state.folders)?'Content':'Loading Content'}
                    breadcrumbs={[
                        {
                            name:'Content',
                            uri:'/product/'+this.context.product_id+'/content/'
                        }
                    ]}
                    buttons={<FolderButtons update={this.update} />}
                />
                <div className='inner'>
                    {
                        (this.state.folders)?
                            (this.state.folders.length)?(
                                <ul  className="row mb-3 card-list ">
                                    {this.state.folders.map((item, i)=>{
                                        return (
                                            <li key={i} className="col-6 col-md-3 col-lg-2 click card_object folderholder">
                                                <div className="card mastershow">
                                                        <span style={{margin:'2px', position:'absolute', right:'3px'}} className='click hide'>
                                                            <ModalAlert 
                                                                buttonClassName='topboxbutton floatright' 
                                                                buttonType='none'
                                                                buttonIcon={<FontAwesomeIcon icon={faEdit} />} 
                                                                title={'Edit Folder'} 
                                                                content={<EditFolder update={this.update} id={item.id} name={item.name} />}
                                                                size='lg'
                                                                span={true}
                                                            />
                                                        </span>
                                                    <div className="card-body" onClick={()=>{this.openFolder(item.id)}} >
                                                        <span className='icon'>
                                                            {(this.state.folder)
                                                                ?
                                                                    (this.state.folder.id === item.id)
                                                                    ?
                                                                        <FontAwesomeIcon icon={faFolderOpen} />
                                                                    :
                                                                        <FontAwesomeIcon icon={faFolder} />
                                                                :
                                                                <FontAwesomeIcon icon={faFolder} />
                                                            }
                                                        </span><br/>
                                                        {item.name}
                                                    </div>
                                                </div>
                                            </li>
                                        )
                                    })}
                                </ul>
                            ):<NoFolders />
                        :
                            <Loading />
                    }
                    {
                        (this.state.folder)&&(
                            <div className='row'>
                                <div className='col-12'>
                                    <div className='card'>
                                        <div className='card-header'>
                                           {this.state.folder.name}
                                           <NewFile 
                                                text='Image'
                                                update={this.updateFolderContent}
                                                folder={this.state.folder}
                                                multiple={true}
                                                accept="image/*"
                                            />
                                            <NewFile 
                                                text='csv'
                                                update={this.updateFolderContent}
                                                folder={this.state.folder}
                                                multiple={true}
                                                accept="text/csv"
                                            />
                                            <NewFile 
                                                text='pdf'
                                                update={this.updateFolderContent}
                                                folder={this.state.folder}
                                                multiple={true}
                                                accept="application/pdf"
                                            />
                                            <NewFile 
                                                text='mp3'
                                                update={this.updateFolderContent}
                                                folder={this.state.folder}
                                                multiple={true}
                                                accept="audio/mpeg"
                                            />
                                            <NewFile 
                                                text='mp4'
                                                update={this.updateFolderContent}
                                                folder={this.state.folder}
                                                multiple={true}
                                                accept="video/mp4"
                                            />
                                        </div>
                                    {(this.state.content)?(this.state.content.length > 0)?
                                        (<ul className="row mb-3 card-list click">
                                            {this.state.content.map((item, i)=>{
                                                    return (
                                                        <li key={i} className="col-6 col-md-3 col-lg-2 card_object folderholder">
                                                            <div className="card contentView">
                                                                <div className="card-body">
                                                                    <span className='click floatright' onClick={()=>{this.deleteFile(item.location.path)}}>
                                                                        <FontAwesomeIcon icon={faTimes} />
                                                                    </span>
                                                                    {(item.meta.contentType && item.meta.contentType.includes("image/"))&&<img alt='gallery item' src={item.url} style={{width:'100%'}} />}
                                                                    {(item.meta.contentType && item.meta.contentType.includes("audio/mpeg"))&&<span className='icon'><FontAwesomeIcon icon={faFileAudio} /></span>}
                                                                    {(item.meta.contentType && item.meta.contentType.includes("video/mp4"))&&<span className='icon'><FontAwesomeIcon icon={faFileVideo} /></span>}
                                                                    {(item.meta.contentType && item.meta.contentType.includes("application/pdf"))&&<span className='icon'><FontAwesomeIcon icon={faFilePdf} /></span>}
                                                                    {(item.meta.contentType && item.meta.contentType.includes("text/csv"))&&<span className='icon'><FontAwesomeIcon icon={faFileCsv} /></span>}
                                                                    <div><span>{item.meta.name}</span></div>
                                                                </div>
                                                            </div>
                                                        </li>
                                                    )
                                                })
                                            }
                                        </ul>):<NoContent />:<Loading />
                                    }
                                    </div>
                                </div>
                            </div>
                        )
                    }
                </div>
            </Fragment>
        )
    }
}
Content.contextType = Store; // This part is important to access context values
export default Content;