import React, {Fragment, useContext} from 'react';
import {NavLink} from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faBullseye,faStopCircle
} from '@fortawesome/free-solid-svg-icons';
import {Store} from "../../../stores/Store";
const Pipeline = (props)=>{
    const context = useContext(Store); // This part is important to access context values
    return (
        <Fragment>
            <li className="nav-item">
                <NavLink className="nav-link" exact to={"/product/"+context.product_id+"/pipeline"} activeClassName="active">
                    <FontAwesomeIcon icon={ faBullseye } /><br/>
                    <span className="d-none d-md-inline">
                        Prospects
                    </span>
                </NavLink>
            </li>
        </Fragment>
    )
}
export default Pipeline;

/*<li className="nav-item">
                <NavLink className="nav-link" exact to={"/product/"+context.product_id+"/pipeline/pinned"} activeClassName="active">
                    <FontAwesomeIcon icon={ faMapPin } /><br/>
                    <span className="d-none d-md-inline">
                        Pins
                    </span>
                </NavLink>
            </li>
            <li className="nav-item">
                <NavLink className="nav-link" exact to={"/product/"+context.product_id+"/pipeline/saved"} activeClassName="active">
                    <FontAwesomeIcon icon={ faHeart } /><br/>
                    <span className="d-none d-md-inline">
                        Saved
                    </span>
                </NavLink>
            </li>*/