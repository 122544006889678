import React, {Component} from 'react';
// Import React Table
import ReactTable from "react-table";

import "react-table/react-table.css";
import {formatDate} from '../../../helpers/time';
import {capEveryWord} from '../../../helpers';
import {Store} from '../../../stores/Store';
import TableButtons from './TableButtons';
class Table extends Component{
   state = {
      processing:false
   }
   render(){
    return <ReactTable
               data={this.props.data}
                     columns={[
                        {
                           Header: "Name",
                           accessor: "id",
                        Cell: row => (<span className='tableCell'><span>{row.original.name !== null?capEveryWord(row.original.name):'Unknown Contact'}</span></span>)
                        },
                        {
                           Header: "Date",
                           accessor: "id",
                        Cell: row => (<span className='tableCell'><span>{formatDate(row.original.date)}</span></span>)
                        },
                        {
                            Header: "Actions",
                            accessor: "id",
                            Cell: row => (
                                 <TableButtons 
                                    prospectids={this.props.prospectids}
                                    data={row.original}
                                    deleteContact={this.deleteContact}
                                    refresh={this.props.refresh}
                                 />
                              )
                        }
                     ]}
               defaultPageSize={10}
               className="spacetop -striped -highlight"
             />;
      }
}
Table.contextType = Store; // This part is important to access context values
export default Table;
