import React, { Fragment, useContext, useState} from 'react';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { docco } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import {Store} from "../../stores/Store";
const EbedCode = (props) => {
    let context = useContext(Store);
    let code = "<div data-form-id='"+props.data.id+"' data-product-id='"+context.product_id+"' class='flgform'></div>";
    let include = "<script type='text/javascript' src='https://flg.onl/forms.js'></script>";
    let [tab, setTab] = useState('fledge');
    return (
        <Fragment>
            {(tab === 'external')&&<div class="alert alert-secondary" role="alert">
                To install forms on your <b>external</b> website you must add both blocks of code into your code!
            </div>}
            <ul class="nav nav-tabs">
                <li onClick={()=>setTab('fledge')} class="nav-item">
                    <span className={(tab === 'fledge')?"nav-link active":"nav-link"}>Fledge Site</span>
                </li>
                <li onClick={()=>setTab('external')} class="nav-item">
                    <span className={(tab === 'external')?"nav-link active":"nav-link"}>External Site</span>
                </li>
            </ul>
            {(tab === 'fledge')&&<div className='card'>
                <div className='card-body'>
                    <h3>Adding a form to a Fledge site</h3>
                    <h4>Step 1</h4>
                    <p>Build the form you want on the form section.</p>
                    <h4>Step 2</h4>
                    <p>Build the page you want the form to be on.</p>
                    <h4>Step 3</h4>
                    <p>Select a form component to your liking and add it to your page.</p>
                    <h4>Step 4</h4>
                    <p>Click to edit the component selecting the form you have built.</p>
                </div>
            </div>}
            {(tab === 'external')&&<div className='card'>
                <div className='card-body'>
                <div class="alert alert-warning" role="alert"><p>You can only currently embed on the following sites. To add more either create new projects or add the domain to the project in edit project.</p>
                        <ul>
                            {context.product.secondaryUrls.map((item, i)=>{
                                return <li key={i}>{item}</li>
                            })}
                        </ul>
                    </div>
                    <p>To install Forms on your <b>external</b> website you must add both blocks of code into your code!</p>
                    <h3>HTML Include</h3>
                    <p>Embed this code at the place you wish your form to display. you may need to do a liitle local styling to position correctly however this depends on your local setup. if so you can target the flgform class</p>
                    <SyntaxHighlighter language="html" style={docco}>
                    {code}
                    </SyntaxHighlighter>
                    <h3>Javascript Include</h3>
                    <p>Embed this in the footer of your page, make sure it is on <b>ONLY</b> the page that has the form to avoid un nessecary traffic costs.</p>
                    <SyntaxHighlighter language="html" style={docco}>
                    {include}
                    </SyntaxHighlighter>
                </div>
            </div>}
        </Fragment>
    );
}
export default EbedCode;