import React, {useState, useContext, Fragment, useEffect} from 'react';

import {addSite, getDomains} from '../../data/sites';
import {Store} from '../../stores/Store';
import SingleSelect from '../Global/SingleSelect';

const CreateSite = (props) => {
    const [sitename, setSiteName] = useState('');
    const [domains, setDomains] = useState([]);
    const [https, setHTTPS] = useState(false);
    const [domain, setDomain] = useState(null);
    const [error, setError] = useState(false);
    const context = useContext(Store);
    useEffect(()=>{
        
        if(domain && domain.value === 'generate'){
            
            setHTTPS(false);
        }
        if(domain){
            //console.log(domain);
        }
    },[domain])
    useEffect(()=>{
        getDomains(context.product_id).then((res)=>{
            setDomains(res.data);
        });
    },[])
    const create = () => {
        addSite(context.product_id, {name:sitename, domain:domain.value, active:false, https:https}).then(()=>{
            props.close();
            props.update();
        });
    }
    const check = () => {
        return ((domain && (domain.value !=='' && domain.value !== undefined)) && (sitename && sitename.length > 3))
    }
    return (
        <Fragment>
            <div className="input-group mb-3">
                <div className="input-group-prepend">
                    <span className="input-group-text" id="namelabel">Site Name</span>
                </div>
                <input type="text" name='name' required={true} onChange={(e)=>{setSiteName(e.target.value)}} value={sitename} className={(error && sitename.length < 3)?"form-control formerror":"form-control"} placeholder="Site Name" />
            </div>
            <SingleSelect 
                dateSource={(domain !== null && domain.value === "generate")?[{label:'http', value:false}]:[{label:'http', value:false}, {label:'https', value:true}]} 
                title={'Protocol'}
                selected={(https === true)?{label:'https', value:true}:{label:'http', value:false}}
                titleBackgroundColor={"#5785da"}  
                titleColor={"#fff"}
                handleChange={(ob)=>setHTTPS(ob.value)}
            />
            <SingleSelect 
                dateSource={(domains.length > 0)?[{label:'Generate me a temporary domain', value:'generate'}, ...domains]:[{label:'Generate me a temporary domain', value:'generate'}]} 
                title={'Domain'} 
                titleBackgroundColor={"#5785da"}  
                titleColor={"#fff"}
                handleChange={setDomain}
            />
            <button className={(check())?"btn btn-success nomargin floatright":"btn btn-success disabled nomargin floatright"} onClick={(check())&&create}>Create</button>
        </Fragment>
    );
}
export default CreateSite;