import React, {Fragment} from 'react';
//componants
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faPlus
} from '@fortawesome/free-solid-svg-icons';
import ModalAlert from '../Global/ModalAlert';
import CreateForm from './CreateForm';
const FormDashboardButtons = (props) => {
    return (
        <Fragment>
            <ModalAlert 
                buttonClassName='boxbutton floatright' 
                buttonType='none'
                buttonIcon={<Fragment><FontAwesomeIcon icon={faPlus} /> <span>Form</span></Fragment>} 
                title={'New Form'} 
                content={<CreateForm update={props.callback} />}
                size='lg'
                callback={props.callback}
            />
        </Fragment>
    );
}
export default FormDashboardButtons;
