import React, {useState, useContext, Fragment} from 'react';
import {createItem} from '../../../data/forms';
import {Store} from '../../../stores/Store';
import objects from '../objects';
import {ordinal_suffix_of} from '../../../helpers';
const NewItem = (props) => {
    const [item, setItem] = useState(false);
    const [newname, setNewName] = useState(false);
    const context = useContext(Store);
    const updateItem = (tempitem) => {
        let newString = (newname)?newname.replace(/[^A-Z0-9]+/ig, "_").toLowerCase():tempitem.name;
        let nameExists = false
        let pre = 1;
        for(let object of props.form.items||[]){
            if(object.name === tempitem.name)
            {
                nameExists = true;
                pre++;
            }
        }
        tempitem.name = (nameExists)?(ordinal_suffix_of(pre)+'_'+newString):newString;
        tempitem.label = newname;
        return tempitem;
    }
    const create = () => {
        if(item){
            let newitem = updateItem(objects[item]);
            let items = (Array.isArray(props.form.items))?props.form.items.concat(newitem):[newitem];
            createItem(context.product_id, props.form.id, items).then(()=>{
                props.close();
                props.update();
            });
        }else{
            alert('You must select an item type!');
        }
    }
    return (
        <Fragment>
            <div className="input-group mb-3">
                <div className="input-group-prepend">
                    <span className="input-group-text">Item Type</span>
                </div>
                <select className="form-control" id="additem" value={(item?item:null)} onChange={(e)=>{setItem((e.target.value !== "false")?e.target.value:false); setNewName((e.target.value !== "false")?objects[e.target.value].label:false)}}>
                    <option value={false}>Select...</option>
                    {objects.map((objitem, i)=>{
                        return <option key={i} value={i}>{objitem.label}</option>
                    })}
                </select>
            </div>
            {(item !== false)&&<Fragment>
                <div className="input-group mb-3">
                    <div className="input-group-prepend">
                        <span className="input-group-text">Your Name</span>
                    </div>
                    <input type='text' className='form-control' value={(newname)?newname:(objects[item])?objects[item].label:''} onChange={(e)=>setNewName(e.target.value)} placeholder='Your Name' />
                </div>
                <button className="btn btn-success nomargin floatright" onClick={()=>{
                    create();
                }}>Create</button>
            </Fragment>}
        </Fragment>
    );
}
export default NewItem;