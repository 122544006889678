import React, {useState, useEffect, useContext} from "react";
import {Store} from '../../stores/Store';
import {updateChatModule, getChatModule} from '../../data/chats';
import ColourSwatch from '../Global/ColourSwatch';
import ButtonSlider from '../Global/ButtonSlider';
const ViewChatSettings = (props) => {
    let [chatmodule, setChatModule] = useState(null);
    const context = useContext(Store);
    const [error, setError] = useState(false);
    useEffect(()=>{
        refresh();
    }, [])
    const refresh= () => {
        getChatModule(context.product_id, props.id).then((res)=>{
            setChatModule(res.data);
        });
    }
    const update = () => {
        updateChatModule(context.product_id, props.id, chatmodule).then(()=>{
            props.refresh();
            props.close();
        });
    }
    return (chatmodule && chatmodule !== null)?(
        <div className='row'>
            <div className="col-12 card">
                <div className="card-body">
                    <div className="col-12">
                        <h3>General</h3>
                        <div className="input-group mb-3">
                            <div className="input-group-prepend">
                                <span className="input-group-text" id="namelabel">Chat Module Name</span>
                            </div>
                            <input type="text" name='name' required={true} onChange={(e)=>{setChatModule({...chatmodule, name:e.target.value})}} value={chatmodule.name} className={(error && chatmodule.name.length < 3)?"form-control formerror":"form-control"} placeholder="Chat Module Name" />
                        </div>
                        <div className="input-group mb-3">
                            <div className="input-group-prepend">
                                <span className="input-group-text" id="namelabel">Start Title</span>
                            </div>
                            <input type="text" name='startTitle' required={true} onChange={(e)=>{setChatModule({...chatmodule, startTitle:e.target.value})}} value={chatmodule.startTitle} className={(error && chatmodule.startTitle.length < 3)?"form-control formerror":"form-control"} placeholder="Start Title" />
                        </div>
                        <div className="input-group mb-3">
                            <div className="input-group-prepend">
                                <span className="input-group-text" id="namelabel">Start Button Text</span>
                            </div>
                            <input type="text" name='startButtonText' required={true} onChange={(e)=>{setChatModule({...chatmodule, startButtonText:e.target.value})}} value={chatmodule.startButtonText} className={(error && chatmodule.startButtonText.length < 3)?"form-control formerror":"form-control"} placeholder="Start Button Text" />
                        </div>
                        <div className="input-group mb-3">
                            <div className="input-group-prepend">
                                <span className="input-group-text" id="namelabel">Chat Button Text</span>
                            </div>
                            <input type="text" name='chatButtonText' required={true} onChange={(e)=>{setChatModule({...chatmodule, chatButtonText:e.target.value})}} value={chatmodule.chatButtonText} className={(error && chatmodule.chatButtonText.length < 3)?"form-control formerror":"form-control"} placeholder="Chat Button Text" />
                        </div>
                        
                        <ColourSwatch name='Background Colour' update={(val)=>setChatModule({...chatmodule, backgroundColour:val})} color={(chatmodule.backgroundColour)?chatmodule.backgroundColour:{r: 242, g: 242, b: 242, a: 1}} />
                        <ColourSwatch name='Icon Background Colour' update={(val)=>setChatModule({...chatmodule, iconBackgroundColour:val})} color={(chatmodule.iconBackgroundColour)?chatmodule.iconBackgroundColour:{r: 76, g: 175, b: 79, a: 1}} />
                        <ColourSwatch name='Icon Colour' update={(val)=>setChatModule({...chatmodule, iconColour:val})} color={(chatmodule.iconColour)?chatmodule.iconColour:{r: 255, g: 255, b: 255, a: 1}} />
                        <ColourSwatch name='Close Button Colour' update={(val)=>setChatModule({...chatmodule, closeChatBackgroundColour:val})} color={(chatmodule.closeChatBackgroundColour)?chatmodule.closeChatBackgroundColour:{r: 242, g: 242, b: 242, a: 1}} />
                        <ColourSwatch name='Close Button Icon Colour' update={(val)=>setChatModule({...chatmodule, closeChatTextColour:val})} color={(chatmodule.closeChatTextColour)?chatmodule.closeChatTextColour:{r: 51, g: 51, b: 51, a: 1}} />
                        <ColourSwatch name='Title Text Colour' update={(val)=>setChatModule({...chatmodule, titleTextColour:val})} color={(chatmodule.titleTextColour)?chatmodule.titleTextColour:{r: 51, g: 51, b: 51, a: 1}} />
                        <ColourSwatch name='Title Background Gradient A' update={(val)=>setChatModule({...chatmodule, titleBackgroundGradientA:val})} color={(chatmodule.titleBackgroundGradientA)?chatmodule.titleBackgroundGradientA:{r: 115, g: 118, b: 225, a: 1}} />
                        <ColourSwatch name='Title Background Gradient B' update={(val)=>setChatModule({...chatmodule, titleBackgroundGradientB:val})} color={(chatmodule.titleBackgroundGradientB)?chatmodule.titleBackgroundGradientB:{r: 208, g: 210, b: 225, a: 1}} />
                        <ColourSwatch name='Loading Text Colour' update={(val)=>setChatModule({...chatmodule, loadingTextColour:val})} color={(chatmodule.loadingTextColour)?chatmodule.loadingTextColour:{r: 51, g: 51, b: 51, a: 1}} />
                        <hr/>
                        <h3>Inputs</h3>
                        <div className="input-group mb-3">
                            <div className="input-group-prepend">
                                <span className="input-group-text" id="namelabel">Need Email?</span>
                            </div>
                            <select value={chatmodule.needEmail} onChange={(e)=>{setChatModule({...chatmodule, needEmail:(e.target.value==='true')?true:false})}} className='form-control'>
                                <option value={true}>True</option>
                                <option value={false}n>False</option>
                            </select>
                        </div>
                        <div className="input-group mb-3">
                            <div className="input-group-prepend">
                                <span className="input-group-text" id="namelabel">Need Telephone?</span>
                            </div>
                            <select value={chatmodule.needTel} onChange={(e)=>{setChatModule({...chatmodule, needTel:(e.target.value==='true')?true:false})}} className='form-control'>
                                <option value={true}>True</option>
                                <option value={false}n>False</option>
                            </select>
                        </div>
                        <ColourSwatch name='Label Colour' update={(val)=>setChatModule({...chatmodule, inputLabelColour:val})} color={(chatmodule.inputLabelColour)?chatmodule.inputLabelColour:{r: 51, g: 51, b: 51, a: 1}} />
                        <ColourSwatch name='Input Colour' update={(val)=>setChatModule({...chatmodule, inputColour:val})} color={(chatmodule.inputColour)?chatmodule.inputColour:{r: 255, g: 255, b: 255, a: 1}} />
                        <ColourSwatch name='Input Border Colour' update={(val)=>setChatModule({...chatmodule, inputBorderColour:val})} color={(chatmodule.inputBorderColour)?chatmodule.inputBorderColour:{r: 204, g: 204, b: 204, a: 1}} />
                        <ColourSwatch name='Input Text Colour' update={(val)=>setChatModule({...chatmodule, inputTextColour:val})} color={(chatmodule.inputTextColour)?chatmodule.inputTextColour:{r: 51, g: 51, b: 51, a: 1}} />
                        <ColourSwatch name='Input Placeholder Colour' update={(val)=>setChatModule({...chatmodule, inputPlaceholderColour:val})} color={(chatmodule.inputPlaceholderColour)?chatmodule.inputPlaceholderColour:{r: 218, g: 218, b: 218, a: 1}} />
                        <ButtonSlider value={(chatmodule.labelSize !== 'undefined')?chatmodule.labelSize:12} update={(val)=>setChatModule({...chatmodule, labelSize:val})} name='Input Label Size' />
                        <ButtonSlider value={(chatmodule.labelLineHeight !== 'undefined')?chatmodule.labelLineHeight:12} update={(val)=>setChatModule({...chatmodule, labelLineHeight:val})} name='Input Label Line Height' />
                        <ButtonSlider value={(chatmodule.titleSize !== 'undefined')?chatmodule.titleSize:21} update={(val)=>setChatModule({...chatmodule, titleSize:val})} name='Title Size' />
                        <ButtonSlider value={(chatmodule.inputSize !== 'undefined')?chatmodule.inputSize:12} update={(val)=>setChatModule({...chatmodule, inputSize:val})} name='Input Text Size' />
                        <ButtonSlider value={(chatmodule.inputPaddingSize !== 'undefined')?chatmodule.inputPaddingSize:10} update={(val)=>setChatModule({...chatmodule, inputPaddingSize:val})} name='Input Padding Size' />
                        <br/>
                        
                        <hr/>
                        <h3>Buttons</h3>
                        
                        <ColourSwatch name='Button Colour' update={(val)=>setChatModule({...chatmodule, ButtonColour:val})} color={(chatmodule.ButtonColour)?chatmodule.ButtonColour:{r: 76, g: 175, b: 80, a: 1}} />
                        <ColourSwatch name='Button Text Colour' update={(val)=>setChatModule({...chatmodule, ButtonTextColour:val})} color={(chatmodule.ButtonTextColour)?chatmodule.ButtonTextColour:{r: 255, g: 255, b: 255, a: 1}} />
                        <ColourSwatch name='Button Hover Colour' update={(val)=>setChatModule({...chatmodule, ButtonHoverColour:val})} color={(chatmodule.ButtonHoverColour)?chatmodule.ButtonHoverColour:{r: 89, g: 216, b: 94, a: 1}} />
                        <ColourSwatch name='Button Text Hover Colour' update={(val)=>setChatModule({...chatmodule, ButtonTextHoverColour:val})} color={(chatmodule.ButtonTextHoverColour)?chatmodule.ButtonTextHoverColour:{r: 255, g: 255, b: 255, a: 1}} />
                        <ButtonSlider value={(chatmodule.buttonTextSize !== 'undefined')?chatmodule.buttonTextSize:12} update={(val)=>setChatModule({...chatmodule, buttonTextSize:val})} name='Button Text Size' />
                        <ButtonSlider value={(chatmodule.buttonPadding !== 'undefined')?chatmodule.buttonPadding:5} update={(val)=>setChatModule({...chatmodule, buttonPadding:val})} name='Button Padding Size' />
                        <hr/>
                        <h3>Chat</h3>
                        <div className="input-group mb-3">
                            <div className="input-group-prepend">
                                <span className="input-group-text" id="namelabel">Initial Message</span>
                            </div>
                            <input type="text" name='initialMessage' required={true} onChange={(e)=>{setChatModule({...chatmodule, initialMessage:e.target.value})}} value={chatmodule.initialMessage} className={(error && chatmodule.initialMessage.length < 3)?"form-control formerror":"form-control"} placeholder="Initial Message" />
                        </div>
                        <ColourSwatch name='Agent Text Colour' update={(val)=>setChatModule({...chatmodule, AgentTextColour:val})} color={(chatmodule.AgentTextColour)?chatmodule.AgentTextColour:{r: 255, g: 255, b: 255, a: 1}} />
                        <ColourSwatch name='User Text Colour' update={(val)=>setChatModule({...chatmodule, UserTextColour:val})} color={(chatmodule.UserTextColour)?chatmodule.UserTextColour:{r: 255, g: 255, b: 255, a: 1}} />
                        <ColourSwatch name='Agent Text Background Colour' update={(val)=>setChatModule({...chatmodule, AgentTextBackgroundColour:val})} color={(chatmodule.AgentTextBackgroundColour)?chatmodule.AgentTextBackgroundColour:{r: 125, g: 153, b: 255, a: 1}} />
                        <ColourSwatch name='User Text Background Colour' update={(val)=>setChatModule({...chatmodule, UserTextBackgroundColour:val})} color={(chatmodule.UserTextBackgroundColour)?chatmodule.UserTextBackgroundColour:{r: 76, g: 175, b: 79, a: 1}} />
                        <hr/>
                        
                    </div>
                    <div className="col-12">
                        <button className="btn btn-success nomargin floatright" onClick={(!error)?update:void(0)}>Save</button>
                    </div>
                </div>
            </div>
        </div>
    ):null
};
export default ViewChatSettings;