import React, {useState} from 'react';
const Stack = (props) =>{
    let [open, setOpen] = useState(props.item.data.filter(a=>a.stacked === props.stack)[0].name);
    return (
        <div className='card'>
            <div className='card-header'>{props.stack}</div>
            <div className='card-body'>
                <ul className="nav nav-tabs">
                    {props.item.data.filter(a=>a.stacked === props.stack).map((item, i)=>{
                        return (
                            <li key={i} onClick={()=>setOpen(item.name)} className="nav-item">
                                <span className={(open === item.name)?"nav-link active":"nav-link"}>{item.name}</span>
                            </li>
                        )
                    })}
                </ul>
                {props.item.data.filter(a=>a.stacked === props.stack).map((item, i)=>{
                    return (open === item.name)?item.data.map((datatype, b)=>{
                        return props.GetComponent(datatype, i, b)
                    }):null;
                })}
            </div>
        </div>
    )
}
export default Stack;