import React from 'react';

const Fonts = [
    {
        "fontName":"Poppins",
        "type":"sans-serif",
        "style":"'Poppins', sans-serif",
        "fontWeights":[100,300,500,700,900]
    },{
        "fontName":"Roboto",
        "type":"sans-serif",
        "style":"'Roboto', sans-serif",
        "fontWeights":[100,300,500,700,900]
    },{
        "fontName":"Lato",
        "type":"sans-serif",
        "style":"'Lato', sans-serif",
        "fontWeights":[100,300,500,700,900]
    },{
        "fontName":"Open Sans",
        "type":"sans-serif",
        "style":"'Open Sans', sans-serif",
        "fontWeights":[100,300,500,700,900]
    },{
        "fontName":"Montserrat",
        "type":"sans-serif",
        "style":"'Montserrat', sans-serif",
        "fontWeights":[100,300,500,700,900]
    },{
        "fontName":"Oswald",
        "type":"sans-serif",
        "style":"'Oswald', sans-serif",
        "fontWeights":[100,300,500,700,900]
    },{
        "fontName":"Raleway",
        "type":"sans-serif",
        "style":"'Raleway', sans-serif",
        "fontWeights":[100,300,500,700,900]
    },{
        "fontName":"PT Sans",
        "type":"sans-serif",
        "style":"'PT Sans', sans-serif",
        "fontWeights":[100,300,500,700,900]
    },{
        "fontName":"Glory",
        "type":"sans-serif",
        "style":"'Glory', sans-serif",
        "fontWeights":[100,300,500,700,900]
    },{
        "fontName":"Barlow",
        "type":"sans-serif",
        "style":"'Barlow', sans-serif",
        "fontWeights":[100,300,500,700,900]
    },{
        "fontName":"Abel",
        "type":"sans-serif",
        "style":"'Abel', sans-serif",
        "fontWeights":[100,300,500,700,900]
    },{
        "fontName":"Besley",
        "type":"serif",
        "style":"'Besley', serif",
        "fontWeights":[100,300,500,700,900]
    },{
        "fontName":"Playfair Display",
        "type":"serif",
        "style":"'Playfair Display', serif",
        "fontWeights":[100,300,500,700,900]
    },{
        "fontName":"Lora",
        "type":"serif",
        "style":"'Lora', serif",
        "fontWeights":[100,300,500,700,900]
    },{
        "fontName":"PT Serif",
        "type":"serif",
        "style":"'PT Serif', serif",
        "fontWeights":[100,300,500,700,900]
    },{
        "fontName":"Noto Serif",
        "type":"serif",
        "style":"'Noto Serif', serif",
        "fontWeights":[100,300,500,700,900]
    },{
        "fontName":"Bitter",
        "type":"serif",
        "style":"'Bitter', serif",
        "fontWeights":[100,300,500,700,900]
    },{
        "fontName":"Arvo",
        "type":"serif",
        "style":"'Arvo', serif",
        "fontWeights":[100,300,500,700,900]
    },{
        "fontName":"Nanum Myeongjo",
        "type":"serif",
        "style":"'Nanum Myeongjo', serif",
        "fontWeights":[100,300,500,700,900]
    },{
        "fontName":"Martel",
        "type":"serif",
        "style":"'Martel', serif",
        "fontWeights":[100,300,500,700,900]
    },{
        "fontName":"Cinzel",
        "type":"serif",
        "style":"'Cinzel', serif",
        "fontWeights":[100,300,500,700,900]
    }
];
const Font = (props) => {
    return (
        <div className="input-group mb-3">
            <div className="input-group-prepend">
                <div className="input-group-text">{props.name}</div>
            </div>
            <select name={'select'} required className='form-control' onChange={(e)=>{props.update(e.target.value)}} value={props.value}>
                {Fonts.map((item, i)=>{
                    return(
                        <option key={i} value={item.fontName}>
                            {item.style}
                        </option>
                    )
                })}
            </select>
        </div>
    )
}
export default Font;