import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faSpinner
} from '@fortawesome/free-solid-svg-icons';
const Loading = (props) => {
    return (
    <div className='row'>
        <div className='col-12 no_stuff'>
            <h1 className='largeIcon'><FontAwesomeIcon icon={faSpinner} spin /></h1>
                <h1>{(props.title)?<p>{props.title}</p>:<p>Loading Content</p>}</h1>
                {(props.text)?<p>{props.text}</p>:<p>Please wait while we gather your data.</p>}
        </div>
    </div>
    )
}
export default Loading;