import React, {Fragment, Component, useContext, useState} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faTrash, faEye, faPowerOff, faEdit, faGlobe
} from '@fortawesome/free-solid-svg-icons';
import {useHistory} from 'react-router-dom';
import Processing from '../../Global/Processing';
import {Store} from "../../../stores/Store";
import {deleteSite, updateSite} from '../../../data/sites';
import ModalAlert from '../../Global/ModalAlert';
import EditSite from '../EditSite';
import { faGofore } from '@fortawesome/free-brands-svg-icons';
const ButtonBar = (props) =>{
    let context = useContext(Store);
    let history = useHistory();
    let [processing, setProcessing] = useState(false);
    const toggleActive = () => {
        
    }
    let setsite = () =>{
        context.update({site:{site:props.data.domain, site_id:props.data.id}});
        history.push("/product/"+context.product_id+"/site/pages");
    }
        return (!processing)?(
            <div className="card-footer d-flex bg-gradient">
                <Fragment>
                    <div>
                        
                        <ModalAlert 
                            buttonClassName='btn btn-sm barbutton floatright' 
                            buttonType='none'
                            buttonIcon={<FontAwesomeIcon  icon={faEdit} />} 
                            title={'Edit Site'}
                            content={<EditSite {...props.data} refresh={props.refresh} />}
                            size='lg'
                        />
                        
                        <button className="btn btn-sm barbutton floatright" onClick={()=>{setsite()}}>
                            <FontAwesomeIcon icon={faEye} />
                        </button>
                    </div>
                    <div>
                    <a target="_blank" href={(props.data.https)?("https://"+props.data.domain):("http://"+props.data.domain)} className="btn btn-sm barbutton floatright" >
                            <FontAwesomeIcon icon={faGlobe} />
                        </a>
                        <button 
                            className="btn btn-sm barbutton floatright warning"
                            onClick={()=>{updateSite(context.product_id, props.data.id, {active:!props.data.active}).then(()=>{props.refresh()})}}
                        >
                            <FontAwesomeIcon icon={faPowerOff} />
                        </button>
                        <button 
                            className="btn btn-sm barbutton floatright warning"
                            onClick={()=>{deleteSite(context.product_id, props.data.id).then(()=>{props.refresh()})}}
                        >  
                            <FontAwesomeIcon icon={faTrash} />
                        </button>
                        
                    </div>
                </Fragment>
            </div>        
        ):<Processing />
}

export default ButtonBar;