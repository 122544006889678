import React, {useState, useContext, Fragment} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {db} from '../../../firebase';
import {
    faComments, faCircle
} from '@fortawesome/free-solid-svg-icons';
import {Store} from "../../../stores/Store";
import ButtonBar from './ButtonBar';

const ChatSetupCard = (props)=>{
        const [removing, setRemoving] = useState(null);
        const context = useContext(Store);
        return (
                <div className={"card"}>
                    <div className="card-header card-header-borderless d-flex justify-content-between">
                        <div>
                            <FontAwesomeIcon icon={faComments} />
                            {' '+props.name}
                        </div>
                    </div>
                    <div className="card-body">
                        <div className="media">
                            <div className="media-body siteCard">
                            
                            </div>
                        </div>
                    </div>
                    {(!removing)
                        &&<ButtonBar
                            refresh={props.refresh}
                            {...props}
                        />}
                </div>
        )
}
export default ChatSetupCard;