import React, {Fragment, Component} from 'react';
import {formatDate, liveuser} from '../../../helpers/time';
import {capEveryWord} from '../../../helpers'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faGlobe, faLocationArrow, faCalendar, faEnvelopeOpen, faBullseye, faComments
} from '@fortawesome/free-solid-svg-icons';
import Processing from '../../Global/Processing';
import Suppressed from '../../Global/Suppressed';
import {Store} from "../../../stores/Store";
import ButtonBar from './ButtonBar';
import {deleteLead} from '../../../data/leads';
import {suppressNetwork, suppressLead} from '../../../data/suppress'; 


class ProspectCard extends Component{
    state = {
        processing:false,
        lead:this.props.data,
        networkSuppressed:(this.props.data.networkSuppressed)?true:false,
        formAmount:(this.props.data.formAmount > 0)?true:false,
        suppressed:true
    }
    componentDidMount(){
        this.setState({
            suppressed:(this.state.networkSuppressed && (this.state.formAmount < 1 && this.state.chatAmount < 1))?true:false
        });
    }
    toggleDeleteProspect = (id, value) => {
        this.setState({
            processing:true
        },()=>{
            deleteLead(this.context.product_id, id, value).then(async ()=>{
                await this.setState({
                    processing:!value
                });
                this.props.refresh()
            });
        });
    }
    suppress = async (lead) => {
        await this.setState({
            processing:true
        });
        await suppressNetwork(lead);
        await suppressLead(this.context.product_id, lead);
        this.props.suppressSearch(lead.maxmind.traits.organization);
        this.setState({
            processing:false,
            suppressed:true
        });
    }
    render(){
        const itemname = (this.props.data.your_name)?capEveryWord(this.props.data.your_name):(!this.props.data.networkSuppressed)?this.props.data.maxmind.traits.organization:(this.props.data.latest_contact_name)?capEveryWord(this.props.data.latest_contact_name.name):false;
        return (
            <div className={'card_object'}>
                <div className="card">
                    <div className="card-header card-header-borderless d-flex justify-content-between">
                        <div style={{maxWidth:'100%', textAlign:'right'}}>
                            <span title='Visits' className="numberCircle"><FontAwesomeIcon icon={faGlobe} /> {this.state.lead.visitAmount}</span>
                            <span title='Contact forms submitted' contacts='Contact Forms' className="numberCircle"><FontAwesomeIcon icon={faEnvelopeOpen} /> {this.state.lead.formAmount}</span>
                            <span title='Chats' className="numberCircle"><FontAwesomeIcon icon={faComments} /> {this.state.lead.chatAmount}</span>
                        </div>
                    </div>
                    <div className="card-body">
                        <div className="media">
                            <div className="media-body siteCard">
                                {(this.state.processing)?(
                                    <Processing />
                                ):(this.state.suppressed)?<Suppressed />:(
                                    <ul className="list-group">
                                        <li className="list-group-item">
                                                <Fragment>
                                                    <FontAwesomeIcon icon={faBullseye} /> {capEveryWord((!this.state.lead.networkSuppressed)?this.state.lead.maxmind.traits.organization:(this.state.lead.latest_contact_name && this.state.lead.latest_contact_name.business)?this.state.lead.latest_contact_name.business:(itemname)?itemname:"Unknown Organisation")}
                                                </Fragment>
                                        </li>
                                        <li className="list-group-item">
                                                <Fragment>
                                                    <FontAwesomeIcon icon={faGlobe} /> {(this.state.lead.maxmind.continent)?this.state.lead.maxmind.continent.names.en:"Unknown Continent"}
                                                </Fragment>
                                        </li>
                                        <li className="list-group-item">
                                            {(!this.state.lead.networkSuppressed)?(
                                                <Fragment>
                                                    <FontAwesomeIcon icon={faLocationArrow} /> {(this.state.lead.maxmind.city)?this.state.lead.maxmind.city.names.en:'Unknown City'}{(this.state.lead.maxmind.country)&&', '+this.state.lead.maxmind.country.names.en}
                                                </Fragment>
                                            ):(
                                                <Fragment>
                                                    <FontAwesomeIcon icon={faLocationArrow} /> <span className='badge badge-secondary'>Possible:</span>{this.state.lead.maxmind.city.names.en}, {this.state.lead.maxmind.country.names.en}
                                                </Fragment>
                                            )}
                                        </li>
                                        <li className="list-group-item">
                                            {(liveuser(this.state.lead.last_updated))?(
                                                <Fragment>
                                                    <FontAwesomeIcon icon={faCalendar} /> <span className='live'>On Site Now</span>
                                                </Fragment>
                                            ):(
                                                <Fragment>
                                                    <FontAwesomeIcon icon={faCalendar} /> {formatDate(this.state.lead.last_updated)}
                                                </Fragment>
                                            )}
                                        </li>
                                        
                                    </ul>
                                )}
                            </div>
                        </div>
                    </div>
                    {(!this.state.processing)&&<ButtonBar
                                                toggleDeleteProspect={this.toggleDeleteProspect}
                                                suppress={(this.props.suppressSearch)?this.suppress:false}
                                                hidesuppress={this.props.hidesuppress}
                                                data={this.state.lead}
                                                refresh={this.props.refresh}
                                                suppressed={this.state.suppressed}
                                            />}
                </div>
            </div>
        )
    }
}
ProspectCard.contextType = Store; // This part is important to access context values
export default ProspectCard;