import React, {useState, useContext} from 'react';
//componants
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faSortAlphaDown, faSortAlphaDownAlt, faSortNumericDownAlt, faSortNumericDown
} from '@fortawesome/free-solid-svg-icons';
import { ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import {Store} from '../../stores/Store';
const Sort = (props) => {
    const context = useContext(Store); // This part is important to access context values
    const [dropdownOpen, setOpen] = useState(false);
    const toggle = () => setOpen(!dropdownOpen);
    const sort = async (sortDate, sortDown) => {
        await context.update({
            sort:{
                sort_date:sortDate,
                sort_down:sortDown
            }
        });
        props.refresh();
    };
    return <ButtonDropdown isOpen={dropdownOpen} className="floatright" toggle={toggle}>
                <DropdownToggle className="boxbutton btn btn-none btn-sm" caret>
                    {
                        (context.sort.sort_date)
                        ?
                            (context.sort.sort_down)?<FontAwesomeIcon icon={faSortNumericDown} />:<FontAwesomeIcon icon={faSortNumericDownAlt} />
                        :
                            (context.sort.sort_down)?<FontAwesomeIcon icon={faSortAlphaDown} />:<FontAwesomeIcon icon={faSortAlphaDownAlt} />
                    }
                    
                </DropdownToggle>
                <DropdownMenu>
                    <DropdownItem header>Sort Type </DropdownItem>
                    <DropdownItem onClick={()=>{sort(false, true)}}>A-Z </DropdownItem>
                    <DropdownItem onClick={()=>{sort(false, false)}}>Z-A</DropdownItem>
                    <DropdownItem divider />
                    <DropdownItem onClick={()=>{sort(true, true)}}>Date Latest</DropdownItem>
                    <DropdownItem onClick={()=>{sort(true, false)}}>Date Oldest</DropdownItem>
                </DropdownMenu>
            </ButtonDropdown>
}
export default Sort;