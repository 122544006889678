export const sitedefault = {
    globalVariables:{
        data:[
            {
                data:[{
                    label:"100",
                    value:100 
                },{
                    label:"300",
                    value:300 
                },{
                    label:"500",
                    value:500 
                },{
                    label:"700",
                    value:700 
                },{
                    label:"900",
                    value:900 
                }],
                forceselection:true,
                name:"Titles Font Weight",
                type:"select",
                value:500,
                variableName:"titlesFontWeight" 
            },{
                data:[{
                    label:"100",
                    value:100 
                },{
                    label:"300",
                    value:300 
                },{
                    label:"500",
                    value:500 
                },{
                    label:"700",
                    value:700 
                },{
                    label:"900",
                    value:900 
                }],
                forceselection:true,
                name:"Lead Text Font Weight",
                type:"select",
                value:300,
                variableName:"leadFontWeight" 
            },{
                data:[{
                    label:"100",
                    value:100 
                },{
                    label:"300",
                    value:300 
                },{
                    label:"500",
                    value:500 
                },{
                    label:"700",
                    value:700 
                },{
                    label:"900",
                    value:900 
                }],
                forceselection:true,
                name:"body Text Font Weight",
                type:"select",
                value:300,
                variableName:"bodyFontWeight" 
            },{
                name:"HTTPS redirect",
                type:"bool",
                value:false,
                variableName:"https_redirect" 
            },{
                name:"Add Chat",
                type:"chat",
                value:"",
                variableName:"chatId" 
            },{
                name:"Add Network Research",
                type:"bool",
                value:false,
                variableName:"networkTrace" 
            },{
                name:"Title Font",
                type:"font",
                value:"Glory",
                variableName:"titlefont" 
            },{
                name:"Lead Font",
                type:"font",
                value:"Glory",
                variableName:"leadfont" 
            },{
                name:"Body Font (default)",
                type:"font",
                value:"Glory",
                variableName:"bodyfont" 
            },{
                data:[{
                    label:"Title Font",
                    value:'title' 
                },{
                    label:"Lead Font",
                    value:'lead' 
                },{
                    label:"Body Font",
                    value:'bodyfont' 
                }],
                forceselection:true,
                name:"Navigation Follows Which Font?",
                type:"select",
                value:'title',
                variableName:"navigationFont" 
            },{
                name:"Favicon",
                type:"simpleimage",
                value:"",
                variableName:"favicon" 
            },{
                name:"Turn on Cache",
                type:"cache",
                value:"true",
                variableName:"allowcache" 
            }
        ]
    },
    globalTemplateVariables:{
        data:[
        {
            name:"Navbar Link Color",
            type:"color",
            value:{
                a:1,
                b:254,
                g:254,
                r:254
            },
            variableName:"navbarColor" 
        },{
            name:"Navbar background colour",
            type:"color",
            value:{
                a:1,
                b:51,
                g:51,
                r:51
            },
            variableName:"navbarBackground" 
        },{
            name:"Main Transparant Colour",
            type:"color",
            value:{
                a:1,
                b:51,
                g:51,
                r:51
            },
            variableName:"mainTransparantColor" 
        },{
            name:"Primary Background Colour",
            type:"color",
            value:{
                a:1,
                b:242,
                g:242,
                r:242
            },
            variableName:"primaryBackground" 
        },{
            name:"Footer Backgound Colour",
            type:"color",
            value:{
                a:1,
                b:51,
                g:51,
                r:51
            },
            variableName:"footerBackground" 
        },{
            name:"Footer link and text colour",
            type:"color",
            value:{
                a:1,
                b:254,
                g:254,
                r:254
            },
            variableName:"footerColor" 
        },{
            name:"Title Text Colour",
            type:"color",
            value:{
                a:1,
                b:51,
                g:51,
                r:51
            },
            variableName:"titleColor" 
        },{
            name:"Lead Text Colour",
            type:"color",
            value:{
                a:1,
                b:51,
                g:51,
                r:51
            },
            variableName:"leadColor" 
        },{
            name:"Body Text Colour",
            type:"color",
            value:{
                a:1,
                b:51,
                g:51,
                r:51
            },
            variableName:"bodyColor" 
        },{
            name:"Button Background Colour",
            type:"color",
            value:{
                a:1,
                b:119,
                g:130,
                r:211
            },
            variableName:"buttonBackgroundColor" 
        },{
            name:"Button Text Colour",
            type:"color",
            value:{
                a:1,
                b:254,
                g:254,
                r:254
            },
            variableName:"buttonColor" 
        },{
            name:"Button Hover Background Colour",
            type:"color",
            value:{
                a:1,
                b:150,
                g:159,
                r:228
            },
            variableName:"buttonHoverBackgroundColor" 
        },{
            name:"Button Hover Text Colour",
            type:"color",
            value:{
                a:1,
                b:254,
                g:254,
                r:254
            },
            variableName:"buttonHoverColor" 
        },{
            name:"Title Size",
            type:"number",
            value:30,
            variableName:"titleSize" 
        },{
            name:"Lead Text size",
            type:"number",
            value:20,
            variableName:"leadSize" 
        },{
            name:"Body Text Size",
            type:"number",
            value:18,
            variableName:"bodySize" 
        },{
            name:"Button Padding",
            type:"number",
            value:20,
            variableName:"buttonPadding" 
        },{
            name:"Global CSS",
            type:"css",
            value:'',
            variableName:"globalCSS" 
        },{
            name:"Global JavaScript",
            type:"javascript",
            value:'',
            variableName:"globalJavaScript" 
        }
    ]
    }
}