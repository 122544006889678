import React, {Fragment, Component, useContext} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faTrash, faEye, faBorderNone, faClone
} from '@fortawesome/free-solid-svg-icons';
import {useHistory} from 'react-router-dom';
import Processing from '../../Global/Processing';
import {Store} from "../../../stores/Store";
import {deletePage, clonePage, clonePageTemplate} from '../../../data/sites';
import ViewPagesButton from './ViewPagesButton';
class ButtonBar extends Component{
    state = {
        processing:false
    }
    delete= async() => {
        this.setState({
            processing:true
        })
        ///await the form to be removed
        let objdelete = window.prompt("Please enter 'delete' to confirm", "");
        if (objdelete === 'delete') {
            await deletePage(this.context.product_id, this.context.site.site_id, this.props.data.id);
        }
        this.setState({
            processing:false
        })
        await this.props.refresh(); 
    }
    render(){
        return (!this.state.processing)?(
            <div className="card-footer d-flex bg-gradient">
                <Fragment>
                    <div>
                        {(!this.props.data.template)&&<button title='Copy to Template' onClick={
                        async ()=>{
                            if(window.confirm('Create a Template?: '+this.props.data.name)){
                                await clonePage(this.context.product_id, this.context.site.site_id, this.props.data.id); 
                                this.props.refresh();
                            }
                        }} className="btn btn-sm barbutton floatright "><FontAwesomeIcon icon={faBorderNone} /></button>}
                        {(this.props.data.template)&&<button onClick={
                        async ()=>{
                            if(window.confirm('Clone and create page from this template: '+this.props.data.name)){
                                await clonePageTemplate(this.context.product_id, this.context.site.site_id, this.props.data.id); 
                                this.props.refresh();
                            }
                        }} className="btn btn-sm barbutton floatright "><FontAwesomeIcon icon={faClone} /></button>}
                        <ViewPagesButton data={this.props.data}/>
                    </div>
                    <div>
                        <button onClick={this.delete} className="btn btn-sm barbutton floatright warning"><FontAwesomeIcon icon={faTrash} /></button>
                    </div>
                </Fragment>
            </div>        
        ):<Processing />
    }  
}
ButtonBar.contextType = Store; // This part is important to access context values
export default ButtonBar;