
 export const cidrToRange = (str) => {
    var part = str.split("/"); // part[0] = base address, part[1] = netmask
    var ipaddress = part[0].split('.');
    if(ipaddress.length > 1)
    {
      var netmaskblocks = ["0","0","0","0"];
      if(!/\d+\.\d+\.\d+\.\d+/.test(part[1])) {
        // part[1] has to be between 0 and 32
        netmaskblocks = ("1".repeat(parseInt(part[1], 10)) + "0".repeat(32-parseInt(part[1], 10))).match(/.{1,8}/g);
        netmaskblocks = netmaskblocks.map(function(el) { return parseInt(el, 2); });
      } else {
        // xxx.xxx.xxx.xxx
        netmaskblocks = part[1].split('.').map(function(el) { return parseInt(el, 10) });
      }
      // invert for creating broadcast address (highest address)
      var invertedNetmaskblocks = netmaskblocks.map(function(el) { return el ^ 255; });
      var baseAddress = ipaddress.map(function(block, idx) { return block & netmaskblocks[idx]; });
      var broadcastaddress = baseAddress.map(function(block, idx) { return block | invertedNetmaskblocks[idx]; });
      return [baseAddress.join('.'), broadcastaddress.join('.')];
    }else{
      return null;
    }
  }
export const convertIpToNumeric = (ipAddress) => {
    let arrIp = ipAddress.split(".");
    let segment1 = parseInt(arrIp[0]);
    let segment2 = parseInt(arrIp[1]);
    let segment3 = parseInt(arrIp[2]);
    let segment4 = parseInt(arrIp[3]);
    return segment4 + (segment3 * 256) + (segment2 * 65536) + (segment1 * 16777216);
}
export const convertNumericToIp = (bigNumber) => {
    let Octet1 = Math.floor(bigNumber / 16777216);
    let RestOfIP = bigNumber - (Octet1 * 16777216);
    let Octet2 = Math.floor(RestOfIP / 65536);
    RestOfIP = RestOfIP - (Octet2 * 65536);
    let Octet3 = Math.floor(RestOfIP / 256);
    let Octet4 = RestOfIP - (Octet3 * 256);
    return Octet1 + "." + Octet2 + "." + Octet3 + "." + Octet4;
}
