const feedindex = (template) => {
    return [
        {
            data:[
                {
                    type:'text',
                    value:'',
                    variableName:'title',
                    name:'Feed Title'
                },{
                    type:'text',
                    value:'',
                    variableName:'lead',
                    name:'Lead Text'
                },{
                    type:'feedid',
                    value:'',
                    variableName:'feed_id',
                    name:'Feed Id'
                },{
                    type:'feedcat',
                    value:'',
                    variableName:'feed_cat_id',
                    name:'Feed Category'
                },{
                    data:[
                        {
                            label:"Left",
                            value:"left"
                        },{
                            label:"Center",
                            value:"center"
                        },{
                            label:"Right",
                            value:"right"
                        }
                    ],
                    type:'select',
                    value:'center',
                    variableName:'textAlign',
                    name:'Title Text Align'
                }
            ],
            name:'Feed Details'
        },{
            data:[
                {
                    name:"Main Background Colour",
                    type:"color",
                    value:template.primaryBackground,
                    variableName:"backgroundColor" 
                },{
                    name:"Title Text Colour",
                    type:"color",
                    value:template.titleColor,
                    variableName:"titleColor" 
                },{
                    name:"Lead Text Colour",
                    type:"color",
                    value:template.leadColor,
                    variableName:"leadColor" 
                }
            ],
            name:'colours'
        },{
            data:[
                {
                    name:"Item Title Text Colour",
                    type:"color",
                    value:template.titleColor,
                    variableName:"itemTitleColor" 
                },{
                    name:"Item Lead Text Colour",
                    type:"color",
                    value:template.leadColor,
                    variableName:"itemLeadColor" 
                },{
                    name:"Item Title Size",
                    type:"number",
                    value:20,
                    variableName:"itemTitleSize" 
                },{
                    name:"Item Lead Size",
                    type:"number",
                    value:15,
                    variableName:"itemLeadSize" 
                },{
                    name:"Item Title Overlay Background Colour",
                    type:"color",
                    value:template.mainTransparantColor,
                    variableName:"itembackgroundColor" 
                },{
                    name:"Box Border Radius",
                    type:"number",
                    value:5,
                    variableName:"borderRadius" 
                },{
                    data:[
                        {
                            label:"Full",
                            value:"full"
                        },{
                            label:"Center",
                            value:"center"
                        },{
                            label:"Top",
                            value:"top"
                        },{
                            label:"Bottom",
                            value:"bottom"
                        }
                    ],
                    type:'select',
                    value:'bottom',
                    variableName:'itemOverlayStyle',
                    name:'Item Overlay Style'
                },{
                    data:[
                        {
                            label:"Left",
                            value:"left"
                        },{
                            label:"Center",
                            value:"center"
                        },{
                            label:"Right",
                            value:"right"
                        }
                    ],
                    type:'select',
                    value:'center',
                    variableName:'itemTextAlign',
                    name:'Item Text Align'
                }
            ],
            name:'Item Design'
        },{
            data:[{
                    name:"Background Image",
                    value:"",
                    type:"simpleimage",
                    variableName:"backgroundimage" 
                }
            ],
            name:'images'
        },{
            data:[
                {
                    name:"Title Font Size",
                    type:"number",
                    value:template.titleSize,
                    variableName:"titlefontSize" 
                },{
                    name:"Lead Font Size",
                    type:"number",
                    value:template.leadSize,
                    variableName:"leadfontSize" 
                },{
                    name:"Padding Top",
                    type:"number",
                    value:80,
                    max:200,
                    variableName:"paddingTop" 
                },{
                    name:"Padding Bottom",
                    type:"number",
                    value:80,
                    max:200,
                    variableName:"paddingBottom" 
                }
            ],
            name:'Sizes'
        }
    ];
}
export default feedindex;