import React, {Component} from 'react';
import NoContent from '../../Global/NoContent';
import Loading from '../../Global/Loading';
import {getForms, deleteForms} from '../../../data/leads';
import {Store} from '../../../stores/Store';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faChevronDown, faChevronRight, faTrash
} from '@fortawesome/free-solid-svg-icons';
import {formatDate} from '../../../helpers/time';

class Forms extends Component {
    state = {
        viewform:null,
        forms:null
    }
    componentDidMount(){
        this.refresh();
    }
    refresh = () => {
        getForms(this.context.product_id, this.props.prospect.id).then((res)=>{
            this.setState({
                forms:res.data
            });
        });
    }
    toggleform = (key) => {
        this.setState({
            viewform:(this.state.viewform === key)?null:key
        });
    }
    delete = (form_id) => {
        deleteForms(this.context.product_id, this.props.prospect.id, form_id).then((res)=>{
            if(res.success === true){
                this.refresh();
                this.props.refreshStats();
            }
        })
    }
    render() {
        return (
            <div className="card">
                    <div className="card-header">
                        Forms from this prospect
                    </div>
                    {(this.state.forms !== null)?(this.state.forms.length > 0)?(
                    <div className="card-body">
                        
                            {this.state.forms.map((form,i) =>  {
                                let jsonobject = JSON.parse(form['form']);
                                let objectkeys =  Object.keys(jsonobject);
                                return (
                                <div key={i} className='col-12'>
                                    <div className='formtitle'>
                                        <div key={i} className="card">
                                            <div className='card-body' onClick={()=>{this.toggleform(form.id)}}>
                                                {(jsonobject.name)?jsonobject.name:(jsonobject.f_name && jsonobject.l_name)?jsonobject.f_name+' '+jsonobject.l_name:(jsonobject.email)?jsonobject.email:'Unknown form'}
                                                <span className='click floatright'>{(this.state.viewform === form.id)?<FontAwesomeIcon icon={faChevronDown} />:<FontAwesomeIcon icon={faChevronRight} />}</span>
                                                <span className='formdate floatright'>{formatDate(form['date'])}</span> 
                                            </div>
                                        </div>
                                    </div>
                                        {(this.state.viewform === form.id)&&(
                                            <div key={i} className="card">
                                                <div className='card-body'>
                                                    <div>
                                                        <ul className="list-group">
                                                        <li className="list-group-item">
                                                                <span className="floatright"><span className='floatright click' onClick={()=>{ this.delete(form.id)}}><FontAwesomeIcon icon={faTrash} /></span> </span>
                                                            </li>
                                                            {
                                                                (jsonobject['form_name'])&&(
                                                                    <li className="list-group-item">
                                                                        <span className="badge badge-secondary">FORM NAME</span> 
                                                                        <span className="floatright">{jsonobject['form_name']}</span>
                                                                    </li>
                                                                )
                                                            }
                                                            <li className="list-group-item">
                                                                <span className="badge badge-secondary">DATE</span> 
                                                                <span className="floatright">{formatDate(form['date'])}</span>
                                                            </li>
                                                            {objectkeys.map((item, i)=>{
                                                                return (
                                                                    <li class="list-group-item">
                                                                        <span className="badge badge-secondary">{item.replace("_", " ").toUpperCase()}</span> 
                                                                        <span className="floatright">{jsonobject[item]}</span>
                                                                    </li>
                                                                )
                                                            })}
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                
                            )})}
                    </div>
                ):<NoContent title='No Forms' text='No form submissions - try again later' />
            :<Loading />}
            </div>
        )
    }
}
Forms.contextType = Store;
export default Forms;
