const imagelinkarray = (template) => {
    return [
        {
            data:[
                {
                    name:"Background Colour",
                    type:"color",
                    value:template.primaryBackground,
                    variableName:"backgroundColor" 
                }
            ],
            name:'colours'
        },{
            data:[
                {
                    contains:"imagelinks",
                    data:[],
                    name:"Image Array",
                    type:"objectarray",
                    variableName:"items"
                }
            ],
            name:'Content',
        },{
            data:[{
                    name:"Space Top",
                    type:"number",
                    value:80,
                    max:200,
                    variableName:"paddingTop" 
                },{
                    name:"Space Bottom",
                    type:"number",
                    value:80,
                    max:200,
                    variableName:"paddingBottom" 
                },{
                    name:"Spacing",
                    type:"select",
                    data:[{
                        label:'center',
                        value:'center'
                    },{
                        label:'space-between',
                        value:'space-between'
                    },{
                        label:'space-evenly',
                        value:'space-evenly'
                    },{
                        label:'start',
                        value:'flex-start'
                    },{
                        label:'end',
                        value:'flex-end'
                    }],
                    value:'center',
                    variableName:"spacecontent" 
                }
            ],
            name:'Layout',
        }
    ];
}
export default imagelinkarray;