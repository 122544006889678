import React from 'react';

const Select = (props) => {
    return (
        <div className="input-group mb-3">
            <div className="input-group-prepend">
                <div className="input-group-text">{props.name}</div>
            </div>
            <select name={'select'} required className='form-control' onChange={(e)=>{props.update(e.target.value)}} value={props.value}>
                {(props.data.forceselection === false)&&<option value=''>not selected</option>}
                {props.data.map((item, i)=>{
                    return(
                        <option key={i} value={item.value}>
                            {item.label}
                        </option>
                    )
                })}
            </select>
        </div>
    )
}
export default Select;