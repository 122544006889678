import React, {Fragment} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faPlus
} from '@fortawesome/free-solid-svg-icons';
const ButtonBar = (props) =>{
        return (
            <div className="card-footer d-flex bg-gradient">
                <Fragment>
                    <div>
                        
                    </div>
                    <div>
                        <button 
                            className="btn btn-sm barbutton floatright warning"
                            onClick={props.addToCanvas}
                        >  
                            <FontAwesomeIcon icon={faPlus} />
                        </button>
                    </div>
                </Fragment>
            </div>        
        )
}

export default ButtonBar;