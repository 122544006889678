import React from 'react';
import {
 ResponsiveContainer, AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, Legend,
} from 'recharts';
const colours = [
    '#0ff19f',
    '#13bd18',
    '#a70acf',
    '#23063d',
    '#b34030',
    '#3820af',
    '#6b4a7d',
    '#58cd8d',
    '#e64838',
    '#f60490',
    '#fa7377'
]
const createXAxis = (obj) => {
    let key, i = 0;
    for (key in obj[0]) {
        if(i === 0)
        {
            return <XAxis dataKey={key} />;
        }
    }
}
const createBars = (obj) => {
    let key, i = 0;
    let returnArray = [];
    for (key in obj[0]) {
        if(i > 0)
        {
            returnArray.push(<Area key={i} dataKey={key} fill={colours[i]} />); 
        }
        i++;
    }
    return returnArray;
}
const MultiAreaChart =(props)=>{
    return (
        <ResponsiveContainer width="100%" aspect={3.0/1.0}>
            <AreaChart
                data={props.data}
                margin={{
                    top: 5, right: (props.hideY)?50:0, left: 0, bottom: 0,
                }}
            >
                <CartesianGrid strokeDasharray="3 3" />
                <Tooltip />
                <Legend />
                {createXAxis(props.data)}
                <YAxis tick={(props.hideY)?false:true}/>
                {createBars(props.data).map((item,i)=>{
                    return item;
                })}
            </AreaChart>
        </ResponsiveContainer>
    )
}
export default MultiAreaChart;
