import React, { useContext } from 'react';
import {NavLink} from 'react-router-dom';
//componants
import ModalAlert from '../ModalAlert';
import SwitchPanel from '../SwitchPanel';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faRandom,
} from '@fortawesome/free-solid-svg-icons';
import { Store } from '../../../stores/Store';
const PageTop = (props) => {
    let context = useContext(Store);
    let breadcrumbLen = (props.breadcrumbs)?props.breadcrumbs.length:0;
        return (
            <div className="row PageTop">
                <div className="col-2">
                {(context.product)&&<ModalAlert 
                                buttonClassName='btn btn-clear-space' 
                                buttonIcon={<FontAwesomeIcon icon={ faRandom } />}
                                title={'Switch Product'}
                                content={<SwitchPanel />}
                                size='lg'
                                span={true}
                            />
                    }
                    <span className={"pageTopTitle"+((props.breadcrumbs)?'up':'')}>{props.name}</span>
                    {(props.breadcrumbs)&&(<div className='breadcrumbs'>{props.breadcrumbs.map((item, i)=>{
                        return (
                            <NavLink key={i} className="nav-link" exact to={item.uri} activeClassName="active">
                                <span>{(item.name)} {(breadcrumbLen > (i + 1))?'> ':''}</span>
                            </NavLink>
                        )
                    })}</div>)}
                </div>
                <div className="col-10">
                    {(props.buttons)&&props.buttons}
                </div>
            </div>

        )
}
export default PageTop;