import React, {Fragment, useContext} from 'react';
import {NavLink} from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faTh, faTrash
} from '@fortawesome/free-solid-svg-icons';
import {Store} from "../../../stores/Store";
const Research = (props)=>{
    const context = useContext(Store); // This part is important to access context values
    return (
        <Fragment>
            <li className="nav-item">
                <NavLink className="nav-link" exact to={"/product/"+context.product_id+"/research"} activeClassName="active">
                    <FontAwesomeIcon icon={ faTh } /><br/>
                    <span className="d-none d-md-inline">
                        Network Visits
                    </span>
                </NavLink>
            </li>
        </Fragment>
    )
}
export default Research;