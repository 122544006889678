import React, {Component, Fragment} from 'react';
//componants
import {Store} from "../../../stores/Store";
import {getLead} from '../../../data/leads';
import Visits from './Visits';
import Stats from './Stats';
import Loading from '../../Global/Loading';

class ViewProspect extends Component {
    state = {
        visitors:null,
        open:(this.props.open)?this.props.open:null,
        prospect:null
    }
    componentDidMount(){
        this.refresh();
    }
    refresh = async () => {
        getLead(this.context.product_id, this.props.l_id).then((res)=>{
            this.setState({
                prospect:res.data
            });
        });
    }
    render() {
        return (this.state.prospect && this.state.prospect !== null)?(
            <Fragment>
                <Stats  prospect={this.state.prospect} />
                {<Visits refreshStats={this.refresh} prospect={this.state.prospect} />}
            </Fragment>
        ):<Loading />
    }
}
ViewProspect.contextType = Store; // This part is important to access context values
export default ViewProspect;
