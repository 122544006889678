import React, {Component, Fragment} from 'react';
import {share, unshare, shared} from '../../data/products';
import {Store} from '../../stores/Store';
import Loading from '../Global/Loading';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faTimes
} from '@fortawesome/free-solid-svg-icons';
import NoContent from '../Global/NoContent';

class Share extends Component {
    state = {
        form:{
            email:''
        },
        error:false,
        shared:null
    }
    handleChange = (e) => {
        let form = {...this.state.form}
        form[e.target.name] = e.target.value;
        this.setState({
          form:form
        });
    }
    componentDidMount(){
        this.getshared();
    }
    getshared = () => {
        shared(this.context, this.props.product_id).then(async (res)=>{
            await this.setState({
                shared:res.data
            });
        });
    }
    share = async() =>{
        let error = false;
        error = (this.state.form.email.length > 3 && error === false)?true:(error !== false)?error:'Name needs to be over 3 charactors';
        if(error)
        {
            share(this.context, this.state.form.email, this.props.product_id).then((res)=>{
                if(res.success === false)
                {
                    this.setState({
                        error:true
                    });
                }else{
                    this.props.update();
                    this.props.close();
                }
            });
        }
    }
    unshareuser = async(email) =>{
        unshare(this.context, email, this.props.product_id).then(()=>{
            this.getshared();
        });
    }
    closeError = () => {
        this.setState({
            error:false
        });
    }
    render(){
        return (
            <Fragment>
                <div className="input-group mb-3">
                    <div className="input-group-prepend">
                        <span className="input-group-text" id="namelabel">Email</span>
                    </div>
                    <input type="text" name='email' required={true} onChange={this.handleChange} value={this.state.form.name} className={(this.state.error)?"form-control formerror":"form-control"} placeholder="Enter Email of the person you wish to share with" />
                    <button className="btn btn-success nomargin floatright" onClick={this.share}>Share</button>
                </div>
                {this.state.error&&(
                    <div className="alert alert-warning alert-dismissible fade show" role="alert">
                        <strong>Error!</strong> Can't share with this user!
                        <button type="button" className="close" onClick={this.closeError}>
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                )}
                {(this.state.shared)?(this.state.shared.length > 0)?(
                <div className='card'>
                    <div className='card-header'>
                        Shared With:
                    </div>
                    <div className='card-body'>
                        <ul className="list-group">
                        {this.state.shared.map((item, key)=>{
                            return (
                                <li key={key} className="list-group-item">{item.email}
                                    <div onClick={()=>{this.unshareuser(item.email)}} className="click floatright false" title="Amount of Pages"><FontAwesomeIcon icon={faTimes} /></div>
                                </li>
                            )
                        })}
                        </ul>
                    </div>
                </div>):<NoContent title='Not Shared' text='This is not currently shared with any other users.' />
                :<Loading title='Finding Current Shares' />}
            </Fragment>
        )
    }
}
Share.contextType = Store; // This part is important to access context values
export default Share;