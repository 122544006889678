const text = (template) => {
    return [{
            data:[
                {
                    type:'text',
                    value:'',
                    variableName:'script',
                    name:'Script Tag'
                }
            ],
            name:'Content',
        }
    ];
}
export default text;