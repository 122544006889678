import React, {useState, useContext, useEffect} from 'react';
import {Store} from '../../stores/Store';
import ChatCard from './ChatCard';
import NoContent from '../Global/NoContent';
import {db} from '../../firebase';
import {sortContacts} from '../../helpers/filters';
import { Fragment } from 'react';
import PageTop from '../Global/pagetop';
import FilterButtons from '../Global/FilterButtons';
import Table from './Table';
import {getSavedLeads} from '../../data/leads';

const ClosedChats = (props) => {
    const [chats, setChats] = useState(null);
    const [prospectids, setProspectids] = useState([]);
    const context = useContext(Store);
    useEffect(()=>{
        context.update({
            view:'chats'
        });
        if(context.product_id){
            refresh();
        }else{
            context.update({
                product_id:props.match.params.product_id,
                view:'chats'
            });
        }
    }, [context.product_id]);
    const toggleView = () => {
        context.update({
            cards:!context.cards
        });
    }
    const refresh = () => {
        db.collectionGroup('contacts')
        .where('type', '==', 'chat')
        .where('productId', '==', context.product_id)
        .where('closed', '==', true)
        .where('date', '>', (Math.floor(Date.now() / 1000) - context.back))
        .get().then(async snapshot => {
            let Chats = [];
            if(!snapshot.empty)
            {
                snapshot.forEach(async doc => {
                    let Chat = doc.data();
                    Chat.id = doc.id;
                    Chats.push(Chat);
                });
            }
            setChats(sortContacts(Chats, context));
        });
        getSavedLeads(context.product_id, context).then((result)=>{
            let prosids = [];
            for(let prospect of result.data){
                prosids.push(prospect.id);
            }
            setProspectids(prosids);
        });
    }
    return (
        <Fragment>
            <PageTop name={'Chats'} buttons={<FilterButtons toggleView={toggleView} refresh={refresh} />}/>
            <div className='inner'>
                {(chats && chats.length > 0)?(context.cards === true)?<ul className="row mb-3 card-list">
                {(chats)?chats.map((item, i)=>{
                    return (<li className="col-12 col-md-6 col-lg-4 card_object">
                        <ChatCard refresh={refresh} prospectids={prospectids} key={i} accessIds={{l_id:item.leadId, c_id:item.id}} data={item} />
                        </li>)
                }):null}
                </ul>:<div className='row'>
                    <div className='col-12'>
                        <div className='card'>
                            <div className="card-body">
                                <Table data={chats} prospectids={prospectids} refresh={refresh}/>
                            </div>
                        </div>
                    </div>
                </div>:<NoContent title='No Current Chats!' text='When chats start they will appear here' />}
            </div>
        </Fragment>
    )
}
export default ClosedChats;