import React, {Fragment, Component, useContext} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faTrash, faEye, faBullseye
} from '@fortawesome/free-solid-svg-icons';
import Processing from '../../Global/Processing';
import {Store} from "../../../stores/Store";
import ViewFormContact from '../ViewFormContact';
import ModalAlert from '../../Global/ModalAlert';
import AddToPipelineBucket from '../../Pipeline/ProspectCard/AddToPipelineBucket';
class ButtonBar extends Component{
    state = {
        processing:false

    }
    render(){
        return (!this.state.processing)?(
            <div className="card-footer d-flex bg-gradient">
                <Fragment>
                    <div>
                        <ModalAlert 
                            buttonClassName={"btn btn-sm barbutton floatright "+((this.props.prospectids.includes(this.props.leadId))?'active':'')}
                            buttonIcon={<FontAwesomeIcon icon={faBullseye} />}
                            title={'Add to pipeline'}
                            content={<AddToPipelineBucket useLeadId={true} refresh={this.props.refresh} {...this.props} />}
                            size='lg'
                        />
                        <ModalAlert 
                            buttonClassName='btn btn-sm barbutton floatright' 
                            buttonType='none'
                            buttonIcon={<Fragment><FontAwesomeIcon icon={faEye} /></Fragment>} 
                            title={'Embed'}
                            content={<ViewFormContact {...this.props} />}
                            size='lg'
                        />
                    </div>
                    <div>
                        <button onClick={this.deleteForm} className="btn btn-sm barbutton floatright warning"><FontAwesomeIcon icon={faTrash} /></button>
                    </div>
                </Fragment>
            </div>        
        ):<Processing />
    }  
}
ButtonBar.contextType = Store; // This part is important to access context values
export default ButtonBar;