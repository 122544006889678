import React, {useEffect, useState} from 'react';
import ModalAlert from '../../Global/ModalAlert'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faEdit, faMinusSquare
} from '@fortawesome/free-solid-svg-icons';
import Files from '../../Content/Files';
const SimpleImage = (props) => {

    return (<div className="input-group mb-3">
                    <div className="input-group-prepend">
                        <div className="input-group-text">{props.name}</div>
                    </div>
                    <input 
                        type='text' 
                        name={'image'}
                        className='form-control'
                        readOnly
                        placeholder={'...image'}
                        value={props.value}
                    />
                    <button className='btn boxbutton warning nomargin' onClick={()=>{props.update('')}}><FontAwesomeIcon icon={faMinusSquare} /></button>
                    <ModalAlert 
                        buttonClassName=' btn boxbutton nomargin ' 
                        buttonType='none'
                        buttonIcon={<FontAwesomeIcon icon={faEdit} />} 
                        title={'Select'} 
                        content={<Files use={props.update} filter='image/' />}
                        size='lg'
                    />
                </div>
    )
}
export default SimpleImage;