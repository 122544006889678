import React from 'react';
// Import React Table
import ReactTable from "react-table";
import "react-table/react-table.css";

const SuppressionTable = (props) => {
    return <ReactTable
        data={props.data}
        columns={[{
                Header: "Network Name",
                accessor: "hostname",
                Cell: row => (<span className='tableCell'><span title={row.value}>{row.value}</span></span>)
            },
            {
                Header: "Actions",
                accessor: "hostname",
                Cell: row => (
                <span className='tableCell'>
                    <span>
                        <button onClick={()=>{props.add(props.productId ,row.value)}} type="button" title="Filter" className="boxbutton floatright btn btn-none btn-sm">Add to Whitelist</button>
                    </span>
                    {
                        (props.super)&&<span>
                            <button onClick={()=>{props.superdelete(row.original.id)}} type="button" title="Filter" className="floatright btn btn-danger btn-sm">Delete</button>
                        </span>
                    }
                    
                </span>)
            }
        ]}
        defaultPageSize={10}
        showPagination={true}
        showPageSizeOptions={false}
        className="spacetop -striped -highlight"
        />;
}
export default SuppressionTable;
