import React from 'react';
//componants
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faPlus
} from '@fortawesome/free-solid-svg-icons';
import NewUser from './NewUser';
import ModalAlert from '../Global/ModalAlert';
const UserButtons = (props) => {
    return (
            <div className='row'>
                <div className='col-12'>
                    <button onClick={()=>{props.toggle(2)}} className={'btn boxbutton floatright'+((props.active === 2)?' active':'')}>Stats</button>
                    <button onClick={()=>{props.toggle(1)}} className={'btn boxbutton floatright'+((props.active === 1)?' active':'')}>Users</button>
                    <ModalAlert 
                        buttonClassName='boxbutton floatright' 
                        buttonType='none'
                        buttonIcon={<FontAwesomeIcon icon={faPlus} />} 
                        title={'New User'} 
                        content={<NewUser />}
                        callback={props.callback}
                        size='lg'
                    />
                </div>
            </div>
    );
}
export default UserButtons;