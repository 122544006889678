import React, {Component, Fragment} from 'react';
//componants
import {Store} from "../../stores/Store";
import {stats} from '../../data/users';
import Loading from '../Global/Loading';
import SingleSelect from '../Global/SingleSelect';
import MultiPieChart from '../Global/MultiPieChart';

class Stats extends Component {
    state = {
      days:30,
      data:null
    }
    componentDidMount(){
      this.update();
    }
    update = async () => {
      stats(this.context, this.state.days).then((res)=>{
        this.setState({
          data:res.data
        })
      });
    }
    changeDate = async (ret) => {
      await this.setState({
        days:ret.value
      })
      this.update();
    }
    datasource = [
      {value:1,label:'Last 1 day'},
      {value:3,label:'Last 3 days'},
      {value:5,label:'Last 5 days'},
      {value:7,label:'Last 7 days'},
      {value:14,label:'Last 14 days'},
      {value:30,label:'Last 30 days'},
      {value:60,label:'Last 2 months'},
      {value:90,label:'Last 3 months'},
      {value:180,label:'Last 6 months'},
      {value:365,label:'Last year'}
    ]
    render() {
      return (this.state.data !== null)?(
        <Fragment>
          <div className='row'>
            <div className="col-12">
              <div className='card spaceBottom'>
                <div className='card-body'>
                  <div className='row'>
                    <div className="col-6" style={{margin:'5px'}}>
                      <SingleSelect selected={this.datasource[5]} simple={true} title='Time span...' handleChange={this.changeDate} dateSource={this.datasource} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='row'>
            <div className="col-md-4 col-sm-12 col-12">
            <div className='row no-gutters'>
              <div className="col-md-12 col-sm-6 col-12">
                <div className='card spaceBottom'>
                  <div className="card-header">
                    New Vs Established Users
                  </div>
                  <div className="card-body">
                    {(this.state.newusers !== null)?<MultiPieChart data={[{ name: 'New Users', value: this.state.data.new_users},{ name: 'Established Users', value:this.state.data.established_users }]} />:<Loading/>}
                  </div>
                </div>
              </div>
              <div className="col-md-12 col-sm-6 col-12">
                <div className='card spaceBottom'>
                  <div className="card-header">
                    Active Vs Inactive Users
                  </div>
                  <div className="card-body">
                    {(this.state.active !== null)?<MultiPieChart data={[{ name: 'Active Users', value: this.state.data.active_users},{ name: 'inactive Users', value:this.state.data.inactive_users }]} />:<Loading/>}
                  </div>
                </div>
              </div>
            </div>
            </div>
            <div className="col-md-8 col-sm-12 col-12">
            <div className='card spaceBottom'>
                <div className="card-header">
                  Statistics
                </div>
                <div className="card-body">
                  <ul class="list-group">
                  <li class="list-group-item d-flex justify-content-between align-items-center">
                      Total Users (All time)
                      <span class="badge badge-primary badge-pill">{this.state.data.total_users}</span>
                    </li>
                    <li class="list-group-item d-flex justify-content-between align-items-center">
                      New Users (last {this.state.days} days)
                      <span class="badge badge-primary badge-pill">{this.state.data.new_users}</span>
                    </li>
                    <li class="list-group-item d-flex justify-content-between align-items-center">
                      Established Users (last {this.state.days} days)
                      <span class="badge badge-primary badge-pill">{this.state.data.established_users}</span>
                    </li>
                    <li class="list-group-item d-flex justify-content-between align-items-center">
                      Active Users (Logins in last {this.state.days} days)
                      <span class="badge badge-primary badge-pill">{this.state.data.active_users}</span>
                    </li>
                    <li class="list-group-item d-flex justify-content-between align-items-center">
                      Inactive Users (last {this.state.days} days)
                      <span class="badge badge-primary badge-pill">{this.state.data.inactive_users}</span>
                    </li>
                    <li class="list-group-item d-flex justify-content-between align-items-center">
                      Not Varified (All Time - Since 30 day perge)
                      <span class="badge badge-primary badge-pill">{this.state.data.not_varified}</span>
                    </li>
                    <li class="list-group-item d-flex justify-content-between align-items-center">
                      Not Varified (last {this.state.days} days)
                      <span class="badge badge-primary badge-pill">{this.state.data.not_varified_days}</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </Fragment>
      ):<Loading />
    }
}
Stats.contextType = Store; // This part is important to access context values
export default Stats;
