import React, {useState, useEffect, useContext, createRef, useRef} from "react";
import {Store} from '../../stores/Store';
import {db} from '../../firebase';
import {getLead} from '../../data/leads';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {formatDate} from '../../helpers/time';
import {
    faEllipsisH, faLocationArrow, faCalendar, faUser 
} from '@fortawesome/free-solid-svg-icons';
import Flip from 'react-reveal/Flip';
import { Fragment } from "react";
const Chat = (props) => {
    let [messages, setMessages] = useState([]);
    let [messageObject, setMessageObject] = useState(null);
    let [message, setMessage] = useState('');
    let [clientWriting, setClientWriting] = useState(false);
    let [lead, setLead] = useState(null);
    const context = useContext(Store);
    let payne = useRef();
    useEffect(()=>{
        let node = payne.current;
        if(node && node.lastElementChild !== 'undefined' && messages.length>0){
            node.lastElementChild.scrollIntoView();
            //payne.lastElementChild.scrollIntoView();
        }
    }, [messages]);
    useEffect(()=>{
        new Promise((resolve, reject) => {
            db.collection('products').doc(context.product_id).collection('leads').doc(props.accessIds.l_id).collection('contacts').doc(props.accessIds.c_id)
            .onSnapshot((snapshot) => {
              let updatedData = snapshot.data();
                setClientWriting(updatedData.userTyping);
                setMessages((updatedData.chat)?updatedData.chat:[]);
              resolve(updatedData)
            }, reject)
          });
    }, [context.product_id, props.accessIds]);
    useEffect(()=>{
        if(messageObject !== null){
            db.collection('products').doc(context.product_id).collection('leads').doc(props.accessIds.l_id).collection('contacts').doc(props.accessIds.c_id).update({
                chat:[...messages, messageObject]
            });
            setMessageObject(null);
        }
    }, [messageObject, context.product_id, props.accessIds]);
    const setAgentTyping = (istyping)=>{
        db.collection('products').doc(context.product_id).collection('leads').doc(props.accessIds.l_id).collection('contacts').doc(props.accessIds.c_id).update({
            agentTyping:istyping
        });
    }
    useEffect(()=>{
        if(lead === null){
            getLead(context.product_id, props.accessIds.l_id).then((res)=>{
                setLead(res.data);
            });
        }
    }, [context.product_id, props.accessIds, lead]);
    const sendMessage = () => {
        setMessageObject({
            own:false,
            message:message,
            name:(context.f_name+' '+context.l_name)
        });
        setMessage('');
    }
    return (
        <Fragment>
            <div className='row'>
            {(lead)&&<div className="col-12 lozcards">
                <div className='card'>
                        <div className='card-body'>
                            <div className="row no-gutters">
                                <div className="col-12 col-md-4 morespace bottom">
                                    <div className="textcenter click">
                                        <FontAwesomeIcon icon={faUser} /> <br/>{JSON.parse(props.data.form).name}
                                    </div>
                                </div>
                                <div className="col-12 col-md-4 morespace bottom">
                                    <div className="textcenter click">
                                        <FontAwesomeIcon icon={faLocationArrow} /> <br/>{(lead.maxmind.city)?lead.maxmind.city.names.en:'Unknown City'}{(lead.maxmind.country)&&', '+lead.maxmind.country.names.en}
                                    </div>
                                </div>
                                <div className="col-12 col-md-4 morespace bottom">
                                    <div className="textcenter click">
                                        <FontAwesomeIcon icon={faCalendar} /> <br/><span>{formatDate(lead.date)}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>}     
                <div className="col-12">
                    <div className='flgChatContainer'>
                        <div className='chatwindow' >
                            <div ref={payne} className='payne'>
                                {(messages && messages !== undefined)?messages.map(
                                    (item, i)=>{
                                        let type = (item.own)?'me':'from';
                                        return (
                                            <div key={i} className={'message '+type}>
                                                {item.message}
                                            </div>
                                        )
                                    }
                                ):<div className='message from'>
                                Waiting for customer message to begin...
                            </div>}
                                {(clientWriting === true)&&<div className={'infobox'}><div className={'info'}>
                                    <Flip><FontAwesomeIcon icon={faEllipsisH} /></Flip>
                                </div></div>}
                            </div>
                        </div>
                        {(!props.data.closed)&&<div className='flgMessageSend'>
                            <input value={message} onFocus={()=>setAgentTyping(true)} onBlur={()=>setAgentTyping(false)} onChange={(e)=>{setMessage(e.target.value)}} type='text' className='messagebox' />
                            <button className='flgSubmit' onClick={sendMessage}>Submit</button>
                        </div>}
                    </div>
                </div>
            </div>
        </Fragment>
    )
};
export default Chat;