import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faTrash, faBan
} from '@fortawesome/free-solid-svg-icons';
const DisableButton = (props) =>{
    return (
        (props.user.disabled)
        ?
            <button 
                className="boxbutton btn btn-sm primary" 
                title='Enable User'
                onClick={()=>{props.enableUser(props.user)}} 
                style={{margin:'0px 5px'}}
            >
                <FontAwesomeIcon icon={faBan} />
            </button>
        :
            <button 
                className="boxbutton btn btn-sm warning" 
                title='Disable User'
                onClick={()=>{props.disableUser(props.user)}} 
                style={{margin:'0px 5px'}}
            >
                <FontAwesomeIcon icon={faBan} />
            </button>
    );
}
const DeleteButton = (props) =>{
        return <button className="boxbutton btn btn-sm warning" title='Delete User' onClick={()=>{props.delete(props.user)}} style={{margin:'0px 5px'}}><FontAwesomeIcon icon={faTrash} /></button>
}
export {
    DisableButton,
    DeleteButton
}