import React, {useState, useContext, Fragment} from 'react';

import {createForm} from '../../data/forms';
import {Store} from '../../stores/Store';

const CreateForm = (props) => {
    const [formname, setFormName] = useState('');
    const [error, setError] = useState(false);
    const context = useContext(Store);
    const create = () => {
        if(formname.length > 3){
            createForm(context.product_id, {name:formname}).then(()=>{
                props.close();
                props.update();
            });
        }else{
            setError(true);
        }
    }
    return (
        <Fragment>
            <div className="input-group mb-3">
                <div className="input-group-prepend">
                    <span className="input-group-text" id="namelabel">Form Name</span>
                </div>
                <input type="text" name='name' required={true} onChange={(e)=>{setFormName(e.target.value)}} value={formname} className={(error && formname.length < 3)?"form-control formerror":"form-control"} placeholder="Form Name" />
            </div>
            <button className="btn btn-success nomargin floatright" onClick={create}>Create</button>
        </Fragment>
    );
}
export default CreateForm;