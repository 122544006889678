import React, {useContext} from 'react';
import {Store} from '../../../stores/Store';
import MultiSelect from '../MultiSelect';
import SingleSelect from '../SingleSelect';
import ModalAlert from '../ModalAlert';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faFilter
} from '@fortawesome/free-solid-svg-icons';
const FilterPage = (props) => {
    const context = useContext(Store); // This part is important to access context values
    const updatedate = async (obj) => {
        await context.update(
            {
                back:obj.value
            }
        )
        props.refresh();
    }
    const updatecountry = async (selectedarray) => {
        let filters = context.filters;
        filters.country.selected = (selectedarray)?selectedarray:[];
        await context.update(
            {
                filters:filters
            }
        )
        props.refresh();
    }
    const updatecity = async (selectedarray) => {
        let filters = context.filters;
        filters.city.selected = (selectedarray)?selectedarray:[];
        await context.update(
            {
                filters:filters
            }
        )
        props.refresh();
    }
    const dates = [
        {label:'1 day', value:'86400'},
        {label:'3 days', value:'259200'},
        {label:'7 days', value:'604800'},
        {label:'14 days', value:'1209600'},
        {label:'28 days', value:'2419200'},
        {label:'56 days', value:'4838400'},
        {label:'84 days', value:'7257600'}
    ];
    const currentdate = dates.filter((date)=>date.value === context.back);
    return (
        <div>
            <MultiSelect
                title={'Filter Countrys'}
                handleChange={updatecountry}
                dateSource={context.filters.country.options}
                selected={context.filters.country.selected}
            />
            <br/>
            <MultiSelect
                title={'Filter Citys'}
                handleChange={updatecity}
                dateSource={context.filters.city.options}
                selected={context.filters.city.selected}
            />
            <br/>
            <SingleSelect 
                title={'Date From'}
                handleChange={updatedate}
                dateSource={dates}
                selected={currentdate}
            />
            <br/>
            <button className='btn btn-success' onClick={props.close}>Close</button>
        </div>
    );
}
const Filter = (props) => {
    const context = useContext(Store); 
    return <ModalAlert 
    buttonClassName={((context.filters.city.selected.length > 0) || context.filters.country.selected.length > 0)?'boxbutton floatright active':'boxbutton floatright'} 
    buttonType='none'
    buttonIcon={<FontAwesomeIcon icon={faFilter} />} 
    title={'Filter'} 
    content={<FilterPage refresh={props.refresh} />}
    size='lg'
/>;
}
export default Filter;