export const numberWithCommas = (x) => {
    return (+x.toFixed(2)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}
export const getFileName = (url) => {
    let filename = url.substring(url.lastIndexOf('/')+1);
    return filename;
}
export function capEveryWord(string) {

    return (string)?string.replace(/(?:^|\s)\S/g, function(a) { return a.toUpperCase(); }):string;
};
export function ucfirst(string) 
{
    return string.charAt(0).toUpperCase() + string.slice(1);
}
export function truncateWords(str, no_words) {
    let wordlength = str.split(' ').length;
    let addDots = (wordlength > no_words)?'...':'';
    return str.split(" ").splice(0,no_words).join(" ")+addDots;
}
export function isNumber(value){
    let validNumber = new RegExp(/^\d*\.?\d*$/);
    return validNumber.test(value);
}
export const ordinal_suffix_of = (i) => {
    var j = i % 10,
        k = i % 100;
    if (j === 1 && k !== 11) {
        return i + "st";
    }
    if (j === 2 && k !== 12) {
        return i + "nd";
    }
    if (j === 3 && k !== 13) {
        return i + "rd";
    }
    return i + "th";
}