import React, { useState, useEffect } from 'react';
import ButtonSlider from '../../Global/ButtonSlider';

const Number = (props) => {
    let [value , setValue] = useState(props.value?props.value:'');
    useEffect(() => {
        props.update(value);
    }, [value]);
    return <ButtonSlider name={props.name} value={value} update={setValue} max={(props.max)?props.max:100} />
}
export default Number;