import React, {useState, useContext, Fragment} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {db} from '../../../firebase';
import {formatDate, liveuser} from '../../../helpers/time';
import {
    faComments, faCircle, faCalendar
} from '@fortawesome/free-solid-svg-icons';
import {Store} from "../../../stores/Store";
import ButtonBar from './ButtonBar';
import {deleteChatContact} from './../../../data/contacts';
const ChatCard = (props)=>{
        const context = useContext(Store);
        const closechat = ()=>{
            if(window.confirm('Do you want to end this chat - this will close the chat to the end user!')){
                db.collection('products').doc(context.product_id).collection('leads').doc(props.accessIds.l_id).collection('contacts').doc(props.accessIds.c_id).update({
                    closed:true
                });
            }
        }
        const deleteChat = async() => {
            ///await the form to be removed
            if (window.prompt("Please enter 'delete' to confirm", "") === 'delete') {
                deleteChatContact(context.product_id, props.accessIds.l_id, props.accessIds.c_id).then(()=>{
                    props.refresh(); 
                });
            }
        }
        return (
                <div className={"card"}>
                    <div className="card-header card-header-borderless d-flex justify-content-between">
                        <div>
                            <FontAwesomeIcon icon={faComments} />
                            {' '+props.data.name}
                        </div>
                    </div>
                    <div className="card-body">
                        <div className="media">
                            <div className="media-body siteCard">
                                <ul className="list-group">
                                    {(!props.data.closed)
                                        ?
                                            <li className="list-group-item">
                                                {(props.data.chat && props.data.chat.length > 0)
                                                    ?  
                                                        (props.data.chat[(props.data.chat.length - 1)].own === true)
                                                            ?<Fragment><span style={{color:'red'}}><FontAwesomeIcon icon={faCircle} /></span>  Customer Waiting For Reply</Fragment>
                                                            :<Fragment><span style={{color:'green'}}><FontAwesomeIcon icon={faCircle} /></span> Waiting For Reply</Fragment>
                                                    :<Fragment><span style={{color:'red'}}><FontAwesomeIcon icon={faCircle} /></span> Chat Not Started</Fragment>}
                                            </li>
                                        :null
                                    }
                                    <li className="list-group-item">
                                        {(liveuser(props.data.date))?(
                                            <Fragment>
                                                <FontAwesomeIcon icon={faCalendar} /> <span className='live'>On Site Now</span>
                                            </Fragment>
                                        ):(
                                            <Fragment>
                                                <FontAwesomeIcon icon={faCalendar} /> {formatDate(props.data.date)}
                                            </Fragment>
                                        )}
                                    </li>
                                    
                                </ul>
                            </div>
                        </div>
                    </div>
                    <ButtonBar
                        deleteChat={deleteChat}
                        refresh={props.refresh}
                        {...props}
                        closechat={closechat}
                    />
                </div>
        )
}
export default ChatCard;