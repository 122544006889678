import React, { useContext, useEffect, Fragment, useState } from 'react';
import { Store } from '../../stores/Store';
import NoContent from '../Global/NoContent';
import PageTop from '../Global/pagetop';
import RoutesDashboardButtons from './RoutesDashboardButtons';
import { getRoutes } from '../../data/sites';
import RouteCard from './RouteCard/';
import Loading from '../Global/Loading';
const Routes = () => {
    let context = useContext(Store)
    let [routes, setRoutes] = useState(null);
    let refresh = () => {
        getRoutes(context.product_id, context.site.site_id).then((res)=>{
            setRoutes(res.data)
        });
    }
    useEffect(()=>{
        refresh();
        context.update({
            view:'sites'
        });
    },[])
    return (
        <Fragment>
            <PageTop name={'Sites'} buttons={<RoutesDashboardButtons callback={refresh} />}/>
            <div className='inner'>
            {(routes)?(routes.length > 0)?<ul className="row mb-3 card-list">
                {routes.map((item, i)=>{ return <li key={item.id} className="col-12 col-md-6 col-lg-4 card_object">
                    <RouteCard refresh={refresh} {...item} /></li>})}
                </ul>:<NoContent/>:<Loading/>}
            </div>
        </Fragment>)
}
export default Routes;