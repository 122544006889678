import React, {Fragment, useContext} from 'react';
import {NavLink} from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faCogs, faCommentDots, faCommentSlash
} from '@fortawesome/free-solid-svg-icons';
import {Store} from "../../../stores/Store";
const Chats = (props)=>{
    const context = useContext(Store); // This part is important to access context values
    return (
        <Fragment>
            <li className="nav-item">
                <NavLink className="nav-link" exact to={"/product/"+context.product_id+'/chats'} activeClassName="active">
                    <FontAwesomeIcon icon={ faCommentDots } /><br/>
                    <span className="d-none d-md-inline">
                        Chats
                    </span>
                </NavLink>
                <NavLink className="nav-link" exact to={"/product/"+context.product_id+'/chats/closed'} activeClassName="active">
                    <FontAwesomeIcon icon={ faCommentSlash } /><br/>
                    <span className="d-none d-md-inline">
                        Closed Chats
                    </span>
                </NavLink>
                <NavLink className="nav-link" exact to={"/product/"+context.product_id+'/chats/settings'} activeClassName="active">
                    <FontAwesomeIcon icon={ faCogs } /><br/>
                    <span className="d-none d-md-inline">
                        Setup
                    </span>
                </NavLink>
            </li>
        </Fragment>
    )
}
export default Chats;