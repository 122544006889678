import React, {Fragment, useState} from 'react';
import { Collapse} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {ordinal_suffix_of} from '../../../helpers';
import {
    faArrowDown,
    faArrowUp,
    faTimes,
    faEllipsisH,
    faEllipsisV
} from '@fortawesome/free-solid-svg-icons';

const Advanced = (props) => {
    const [useRegex, setUseRegex] = useState(false);
    const [useLength, setUseLength] = useState(false);
    const updateValidation = (label, value) => {
        let obj = props.data.validations;
        obj[label] = value;
        props.datainput("validations", obj);
    }
    const updateValidationLength = (label, value) => {
        if(/^\d+$/.test(value) || !value){
            let obj = props.data.validations;
            if(!obj.length){
                obj.length = {maxLength:999, minLength:0}
            }
            obj.length[label] = value;
            props.datainput("validations", obj);
        }
    }
  return (
      <Collapse isOpen={props.isAvancedOpen}>
            <div className="row">
                <div className='col-12'>
                    <div className="form-check form-check-inline">
                        <div class='switchholder'>
                            <label class="switch">
                                <input className="form-check-input" type="checkbox" onChange={(e)=>{setUseLength(e.target.checked)}} id="inlineCheckbox2" value="true" />
                                <span class="slider round"></span>
                            </label>
                            <label className="form-check-label" htmlFor="inlineCheckbox2">Use Length</label>
                        </div>
                    </div>
                    
                    <div className="form-check form-check-inline">
                        <div class='switchholder'>
                            <label class="switch">
                                <input className="form-check-input" type="checkbox" onChange={(e)=>{setUseRegex(e.target.checked)}} id="inlineCheckbox3" value="true" />
                                <span class="slider round"></span>
                            </label>
                            <label className="form-check-label" htmlFor="inlineCheckbox2">Use Regex</label>
                        </div>
                    </div>
                </div>
            </div>

            <div className='row space top'>
                {(useLength || useRegex || props.data.required)&&<div className='col-12 col-sm-12 field'>
                    <div className="input-group mb-3">
                        <div className="input-group-prepend">
                            <span className="input-group-text" >Validation Message</span>
                        </div>
                        <input type='text' className='form-control' value={(props.data.validations.validation_message)?props.data.validations.validation_message:''} onChange={(e)=>updateValidation('validation_message', e.target.value)} placeholder='Validation Message' />
                    </div>
                </div>}
                {(useLength)&&<Fragment>
                    <div className='col-12 col-sm-6 field'>
                        <div className="input-group mb-3">
                            <div className="input-group-prepend">
                                <span className="input-group-text" >Min Length</span>
                            </div>
                            <input type='text' className='form-control' value={(props.data.validations.length)?props.data.validations.length.minLength:''} onChange={(e)=>updateValidationLength('minLength', e.target.value)} placeholder='Max Length' />
                        </div>
                    </div>
                    <div className='col-12 col-sm-6 field'>
                        <div className="input-group mb-3">
                            <div className="input-group-prepend">
                                <span className="input-group-text" >Max Length</span>
                            </div>
                            <input type='text' className='form-control' value={(props.data.validations.length)?props.data.validations.length.maxLength:''} onChange={(e)=>updateValidationLength('maxLength', e.target.value)} placeholder='Min Length' />
                        </div>
                    </div>
                </Fragment>}
                {(useRegex)&&<div className='col-12 field'>
                    <div className="input-group mb-3">
                        <div className="input-group-prepend">
                            <span className="input-group-text" >Regex Validation</span>
                        </div>
                        <input type='text' className='form-control' value={(props.data.validations.regex)?props.data.validations.regex:''} onChange={(e)=>updateValidation('regex', e.target.value)} placeholder='Field Placeholder ' />
                    </div>
                </div>}
                
            </div>
        </Collapse>
    )
}
const Checkbox = (props) => {
    return (
        <Fragment>
            <div className='row form_items'>
                <div className='col-12 col-sm-6 field'>
                    <div className="input-group mb-3">
                        <div className="input-group-prepend">
                            <span className="input-group-text" >Label</span>
                        </div>
                        <input type='text' value={props.data.label} className='form-control' onChange={(e)=>props.datainput('label', e.target.value)} placeholder='Field Label' />
                    </div>
                </div>
                <div className='col-12 col-sm-6 field'>
                    <div className="input-group mb-3">
                        <div className="input-group-prepend">
                            <span className="input-group-text" >Required</span>
                        </div>
                        <select type='text' value={props.data.required}  className='form-control' onChange={(e)=>{props.datainput('required', (e.target.value === "true")?true:false)}}>
                            <option value={true}>true</option>
                            <option value={false}>false</option>
                        </select>
                    </div>
                </div>
                <div className='col-12 col-sm-6 field'>
                    <div className="input-group mb-3">
                        <div className="input-group-prepend">
                            <span className="input-group-text" >Default Value</span>
                        </div>
                        <select type='text' value={props.data.value}  className='form-control' onChange={(e)=>{props.datainput('value', e.target.value)}}>
                            <option value={true}>Selected</option>
                            <option value={false}>unselected</option>
                        </select>
                    </div>
                </div>
                <div className='col-12 col-sm-6 field'>
                    <div className="input-group mb-3">
                        <div className="input-group-prepend">
                            <span className="input-group-text" >Classes</span>
                        </div>
                        <input type='text' value={props.data.classes} className='form-control' onChange={(e)=>props.datainput('classes', e.target.value)} placeholder='Field Classes' />
                    </div>
                </div>
            </div>
        </Fragment>
    );
}
const InputText = (props) => {
    return (
        <Fragment>
            <div className='row form_items'>
                <div className='col-12 col-sm-6 field'>
                    <div className="input-group mb-3">
                        <div className="input-group-prepend">
                            <span className="input-group-text" >Label</span>
                        </div>
                        <input type='text' value={props.data.label} className='form-control' onChange={(e)=>props.datainput('label', e.target.value)} placeholder='Field Label' />
                    </div>
                </div>
                <div className='col-12 col-sm-6 field'>
                    <div className="input-group mb-3">
                        <div className="input-group-prepend">
                            <span className="input-group-text" >Placeholder</span>
                        </div>
                        <input type='text' value={props.data.placeholder}  className='form-control' onChange={(e)=>props.datainput('placeholder', e.target.value)} placeholder='Field Placeholder ' />
                    </div>
                </div>
                <div className='col-12 col-sm-6 field'>
                    <div className="input-group mb-3">
                        <div className="input-group-prepend">
                            <span className="input-group-text" >Required</span>
                        </div>
                        <select type='text' value={props.data.required}  className='form-control' onChange={(e)=>{props.datainput('required', (e.target.value === "true")?true:false)}}>
                            <option value={true}>true</option>
                            <option value={false}>false</option>
                        </select>
                    </div>
                </div>
                <div className='col-12 col-sm-6 field'>
                    <div className="input-group mb-3">
                        <div className="input-group-prepend">
                            <span className="input-group-text" >Default Value</span>
                        </div>
                        <input type='text' value={props.data.value} className='form-control' onChange={(e)=>props.datainput('value', e.target.value)} placeholder='Default Value' />
                    </div>
                </div>
                <div className='col-12 col-sm-6 field'>
                    <div className="input-group mb-3">
                        <div className="input-group-prepend">
                            <span className="input-group-text" >Classes</span>
                        </div>
                        <input type='text' value={props.data.classes} className='form-control' onChange={(e)=>props.datainput('classes', e.target.value)} placeholder='Field Classes' />
                    </div>
                </div>
                <div className='col-12 col-sm-6 field'>
                    <div className="input-group mb-3">
                        <div className="input-group-prepend">
                            <span className="input-group-text" >Question Text</span>
                        </div>
                        <input type='text' className='form-control' value={(props.data.query)?props.data.query:''} onChange={(e)=>props.datainput('query', e.target.value)} placeholder='Question text' />
                    </div>
                </div>
            </div>
        </Fragment>
    );
}
const Select = (props) => {
    const [isOptionsOpen, setIsOptionsOpen] = useState(false);
    const [value, setValue] = useState('');
    const [label, setLabel] = useState('');
    const updateValue = (inputstring) => {
        let newString = inputstring.replace(/[^A-Z0-9]+/ig, "_").toLowerCase();
        setValue(newString);
    }
    const valueexists = (newvalue) => {
        for(let option of props.data.options){
            if(option.value === newvalue)
            {
                return true
            }
        }
        return false;
    }
    const addOption = () => {
        if(value.length > 0 && label.length > 0 && !valueexists(value)){
            let options = props.data.options;
            options.push({value:value,name:label});
            props.datainput('options', options);
            setValue('');
            setLabel('');
        }else{
            alert("You can't have an option with a duplicate value");
        }
    }
    const removeOption = (i) => {
            let options = props.data.options;
            options.splice(i, 1);
            props.datainput('options', options);      
    }
    return (
        <Fragment>
            <div className='row form_items'>
                <div className='col-12 col-sm-6 field'>
                    <div className="input-group mb-3">
                        <div className="input-group-prepend">
                            <span className="input-group-text" >Label</span>
                        </div>
                        <input type='text' value={props.data.label} className='form-control' onChange={(e)=>props.datainput('label', e.target.value)} placeholder='Field Label' />
                    </div>
                </div>
                <div className='col-12 col-sm-6 field'>
                    <div className="input-group mb-3">
                        <div className="input-group-prepend">
                            <span className="input-group-text" >Placeholder</span>
                        </div>
                        <input type='text' value={props.data.placeholder}  className='form-control' onChange={(e)=>props.datainput('placeholder', e.target.value)} placeholder='Field Placeholder ' />
                    </div>
                </div>
                <div className='col-12 col-sm-6 field'>
                    <div className="input-group mb-3">
                        <div className="input-group-prepend">
                            <span className="input-group-text" >Required</span>
                        </div>
                        <select type='text' value={props.data.required}  className='form-control' onChange={(e)=>{props.datainput('required', (e.target.value === "true")?true:false)}}>
                            <option value={true}>true</option>
                            <option value={false}>false</option>
                        </select>
                    </div>
                </div>
                <div className='col-12 col-sm-6 field'>
                    <div className="input-group mb-3">
                        <div className="input-group-prepend">
                            <span className="input-group-text" >Classes</span>
                        </div>
                        <input type='text' value={props.data.classes} className='form-control' onChange={(e)=>props.datainput('classes', e.target.value)} placeholder='Field Classes' />
                    </div>
                </div>
                <div className='col-12 col-sm-12 field'>
                    <div className="input-group mb-3">
                        <div className="input-group-prepend">
                            <span className="input-group-text" >Question Text</span>
                        </div>
                        <input type='text' className='form-control' value={(props.data.query)?props.data.query:''} onChange={(e)=>props.datainput('query', e.target.value)} placeholder='Question text' />
                    </div>
                </div>
                {(props.data.custom)&&<div className='col-12 col-sm-12 field space bottom'><span onClick={()=>setIsOptionsOpen(!isOptionsOpen)} className='boxbutton btn btn-none btn-sm smaller'>
                    {(isOptionsOpen)?<FontAwesomeIcon icon={faEllipsisV} />:<FontAwesomeIcon icon={faEllipsisH} />}{" "}
                    Select Dropdown Options
                </span></div>}
                {(props.data.custom === true && isOptionsOpen)&&(<Fragment>
                <div className='col-12 col-sm-12 field space bottom'>
                        <div className="input-group mb-3">
                            <div className="input-group-prepend">
                                <span className="input-group-text" >Value</span>
                            </div>
                            <input type='text' value={value} className='form-control' onChange={(e)=>updateValue(e.target.value)} placeholder='Value' />
                            <div className="input-group-prepend">
                                <span className="input-group-text" >Label</span>
                            </div>
                            <input type='text' value={label} className='form-control' onChange={(e)=>setLabel(e.target.value)} placeholder='Label' />
                            <button className="btn btn-success" onClick={addOption}>Add</button>
                        </div>
                </div>
                <div className='col-12 col-sm-12 field space bottom'>
                    <table style={{width:'100%'}}>
                    <tr><td>Value</td><td>Label</td><td></td></tr>
                    {props.data.options.map((item, i)=>{
                        return (
                            <tr key={i}>
                                <td>{item.value}</td>
                                <td>{item.name}</td>
                                <td><span className='boxbutton btn btn-none btn-sm smaller' onClick={()=>removeOption(i)}><FontAwesomeIcon icon={faTimes} /></span></td>
                            </tr>
                        )
                    })}
                    </table>
                </div>
                </Fragment>)}
            </div>
        </Fragment>
    );
}
const Link = (props) => {
    return (
        <Fragment>
            <div className='row form_items'>
                <div className='col-12 col-sm-12 field'>
                    <div className="input-group mb-3">
                        <div className="input-group-prepend">
                            <span className="input-group-text" >Link Url</span>
                        </div>
                        <input type='text' value={props.data.value} className='form-control' onChange={(e)=>props.datainput('value', e.target.value)} placeholder='Link Url' />
                    </div>
                </div>
                <div className='col-12 col-sm-6 field'>
                    <div className="input-group mb-3">
                        <div className="input-group-prepend">
                            <span className="input-group-text" >Label</span>
                        </div>
                        <input type='text' value={props.data.label} className='form-control' onChange={(e)=>props.datainput('label', e.target.value)} placeholder='Field Label' />
                    </div>
                </div>
                <div className='col-12 col-sm-6 field'>
                    <div className="input-group mb-3">
                        <div className="input-group-prepend">
                            <span className="input-group-text" >Classes</span>
                        </div>
                        <input type='text' value={props.data.classes} className='form-control' onChange={(e)=>props.datainput('classes', e.target.value)} placeholder='Field Classes' />
                    </div>
                </div>
            </div>
        </Fragment>
    );
}
const Title = (props) => {
    return (
        <Fragment>
            <div className='row form_items'>
                <div className='col-12 col-sm-6 field'>
                    <div className="input-group mb-3">
                        <div className="input-group-prepend">
                            <span className="input-group-text" >Title Text</span>
                        </div>
                        <input type='text' value={props.data.value} className='form-control' onChange={(e)=>props.datainput('value', e.target.value)} placeholder='Title Text' />
                    </div>
                </div>
                <div className='col-12 col-sm-6 field'>
                    <div className="input-group mb-3">
                        <div className="input-group-prepend">
                            <span className="input-group-text" >Classes</span>
                        </div>
                        <input type='text' value={props.data.classes} className='form-control' onChange={(e)=>props.datainput('classes', e.target.value)} placeholder='Field Classes' />
                    </div>
                </div>
            </div>
        </Fragment>
    );
}
const TextArea = (props) => {
    return (
        <Fragment>
            <div className='row form_items'>
                <div className='col-12 col-sm-6 field'>
                    <div className="input-group mb-3">
                        <div className="input-group-prepend">
                            <span className="input-group-text" >Label</span>
                        </div>
                        <input type='text' value={props.data.label} className='form-control' onChange={(e)=>props.datainput('label', e.target.value)} placeholder='Field Label' />
                    </div>
                </div>
                <div className='col-12 col-sm-6 field'>
                    <div className="input-group mb-3">
                        <div className="input-group-prepend">
                            <span className="input-group-text" >Placeholder</span>
                        </div>
                        <input type='text' value={props.data.placeholder}  className='form-control' onChange={(e)=>props.datainput('placeholder', e.target.value)} placeholder='Field Placeholder ' />
                    </div>
                </div>
                <div className='col-12 col-sm-6 field'>
                    <div className="input-group mb-3">
                        <div className="input-group-prepend">
                            <span className="input-group-text" >Required</span>
                        </div>
                        <select type='text' value={props.data.required}  className='form-control' onChange={(e)=>{props.datainput('required', (e.target.value === "true")?true:false)}}>
                            <option value={true}>true</option>
                            <option value={false}>false</option>
                        </select>
                    </div>
                </div>
                
                <div className='col-12 col-sm-6 field'>
                    <div className="input-group mb-3">
                        <div className="input-group-prepend">
                            <span className="input-group-text" >Classes</span>
                        </div>
                        <input type='text' value={props.data.classes} className='form-control' onChange={(e)=>props.datainput('classes', e.target.value)} placeholder='Field Classes' />
                    </div>
                </div>
                <div className='col-12 col-sm-12 field'>
                    <div className="input-group mb-3">
                        <div className="input-group-prepend">
                            <span className="input-group-text" >Question Text</span>
                        </div>
                        <input type='text' className='form-control' value={(props.data.query)?props.data.query:''} onChange={(e)=>props.datainput('query', e.target.value)} placeholder='Question text' />
                    </div>
                </div>
                <div className='col-12 col-sm-12 field'>
                    <div className="input-group mb-3">
                        <div className="input-group-prepend">
                            <span className="input-group-text" >Default Value</span>
                        </div>
                        <textarea className='form-control' onChange={(e)=>props.datainput('value', e.target.value)} placeholder='Default Value'>{props.data.value}</textarea>
                    </div>
                </div>
            </div>
        </Fragment>
    );
}
const Item = (props) => {
    const datainput = (label, value) => {
        let updateobject = props.data;
        updateobject[label] = (typeof value === Boolean)?(value === "true")?true:false:value;
        if(label === 'label'){
            updateobject['name'] = setNameValue(value);
        }
        
        props.updateItem(props.position, updateobject);
    }
    const setNameValue = (labelvalue) => {
        let newString = labelvalue.replace(/[^A-Z0-9]+/ig, "_").toLowerCase();
        let nameExists = false
        let pre = 1;
        for(let object of props.objects){
            if(object.name === newString)
            {
                nameExists = true;
                pre++;
            }
        }
        return (nameExists)?(ordinal_suffix_of(pre)+'_'+newString):newString;
    }
    const [isAvancedOpen, setIsAvancedOpen] = useState(false);
    const toggleAdvanced = () => setIsAvancedOpen(!isAvancedOpen);
    return (
        <div className='row'>
            <div className='col-12'>
                <div className='card'>
                    <div className='card-header'>
                        <div className='row'>
                            <div className='col-6'>
                                <span>Field Type: {props.data.label} </span>
                            </div>
                            <div className='col-6 textright'>
                                {
                                    (props.data.advanced)
                                    &&
                                    <span onClick={toggleAdvanced} className='boxbutton btn btn-none btn-sm smaller'>
                                        {(isAvancedOpen)?<FontAwesomeIcon icon={faEllipsisV} />:<FontAwesomeIcon icon={faEllipsisH} />}{" "}
                                        Advanced
                                    </span>
                                }
                                {
                                    (props.last === false)
                                    &&
                                    <span className='click btn btn-sm boxbutton smaller' onClick={()=>{props.updateposition(props.position, (props.position+1));}}>
                                        <FontAwesomeIcon icon={faArrowDown} />
                                    </span>
                                }
                                {
                                    (props.position > 0)
                                    &&
                                    <span className='click btn btn-sm boxbutton smaller' onClick={()=>{props.updateposition(props.position, (props.position-1));}}>
                                        <FontAwesomeIcon icon={faArrowUp} />
                                    </span>
                                }
                                <span className='click btn btn-sm boxbutton smaller' onClick={()=>{props.removeItem(props.position); props.update();}}>
                                    <FontAwesomeIcon icon={faTimes} />
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className='card-body'>
                        {(props.data.type === 'text')&&<InputText datainput={datainput} {...props} />}
                        {(props.data.type === 'select')&&<Select datainput={datainput} {...props} />}
                        {(props.data.type === 'multiselect')&&<Select datainput={datainput} {...props} />}
                        {(props.data.type === 'title')&&<Title datainput={datainput} {...props} />}
                        {(props.data.type === 'link')&&<Link datainput={datainput} {...props} />}
                        {(props.data.type === 'textarea')&&<TextArea datainput={datainput} {...props} />}
                        {(props.data.type === 'check')&&<Checkbox datainput={datainput} {...props} />}
                        {(props.data.advanced)&&<Advanced isAvancedOpen={isAvancedOpen} datainput={datainput} {...props} />}
                    </div>
                </div>
            </div>
        </div>
    );
}
export default Item