import React, {Component, Fragment} from 'react';
//componants
import {Store} from "../../stores/Store";
import {createUser} from "../../data/users";
import Processing from "../Global/Processing";
class NewUser extends Component {
  state = {
    form:{
        f_name:'',
        l_name:'',
        email:'',
        type:'standard'
    },
    submitting:false,
    error:false
  }
  handleChange = (e) => {
    let form = {...this.state.form}
    form[e.target.name] = e.target.value;
    this.setState({
      form:form
    });
  }
  create = async() =>{
    if(this.state.form.f_name.length > 1 && this.state.form.l_name.length > 1 && this.state.form.email.length > 0)
    {
      await this.setState({
        submitting:true
      });
      createUser(this.context, this.state.form).then((res) => {
        if(res.error){
          this.setState({
            error:res.error
          })
        }else{
          this.props.callback();
          this.props.close();
        }
      });
    }else{
      this.setState({
        error:true
      });
    }
  }
  render() {
    return (!this.state.submitting)?(
        <Fragment>
          <div className="input-group mb-3">
                <div className="input-group-prepend">
                  <span className="input-group-text" id="namelabel">First Name</span>
                </div>
                <input type="text" name='f_name' required={true} onChange={this.handleChange} value={this.state.form.f_name} className={(this.state.error && this.state.form.f_name.length < 1)?"form-control formerror":"form-control"} placeholder="First Name" />
          </div>
          <div className="input-group mb-3">
                <div className="input-group-prepend">
                  <span className="input-group-text" id="namelabel">Second Name</span>
                </div>
                <input type="text" name='l_name' required={true} onChange={this.handleChange} value={this.state.form.l_name} className={(this.state.error && this.state.form.l_name.length < 1)?"form-control formerror":"form-control"} placeholder="Second Name" />
          </div>
          {(this.state.error === "email_exists")&&(<div className="alert alert-warning" role="alert">
            Email already on system!
          </div>)}
          <div className="input-group mb-3">
              <div className="input-group-prepend">
                <span className="input-group-text" id="namelabel">Email</span>
              </div>
              <input type="text" name='email' required={true} onChange={this.handleChange} value={this.state.form.email} className={(this.state.error && this.state.form.email.length < 3)?"form-control formerror":"form-control"} placeholder="Email" />
          </div>
          <div className="input-group mb-3">
              <div className="input-group-prepend">
                <span className="input-group-text" id="namelabel">User Type</span>
              </div>
              <select className='form-control' name='type' onChange={this.handleChange} defaultValue='standard'>
              <option value='standard'>Standard</option>
                <option value='super'>Super</option>
              </select>
          </div>
          <button className="btn btn-success nomargin floatright" onClick={this.create}>Create</button>
        </Fragment>
    ):<Processing />;
  }
}
NewUser.contextType = Store; // This part is important to access context values
export default NewUser;
