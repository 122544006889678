import React, {useContext} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faEye
} from '@fortawesome/free-solid-svg-icons';
import {useHistory} from 'react-router-dom';
import {Store} from "../../../stores/Store";

const ViewPagesButton = (props) => {
    let context = useContext(Store);
    let history = useHistory();
    let setproduct = () =>{
        history.push("/product/"+context.product_id+"/site/pages/"+((props.data.feed)?props.data.page:props.data.id));
    }
    return (<button  title='View Page' className="btn btn-sm barbutton floatright" onClick={()=>{setproduct()}}>
        <FontAwesomeIcon icon={faEye} />
    </button>);
}
export default ViewPagesButton;