import React, { Component } from "react";
import {storage} from "firebase";
import FileUploader from "react-firebase-file-uploader";
import {Store} from '../../stores/Store';

class NewFile extends Component {
  state = {
    isUploading: false,
    progress: 0,
  };
  handleUploadStart = () => this.setState({ isUploading: true, progress: 0 });
  handleProgress = progress => this.setState({ progress });
  handleUploadError = error => {
    this.setState({ isUploading: false });
  };
  handleUploadSuccess = (ref) => {
    this.setState({progress: 100, isUploading: false });
    this.props.update();
  };
  setname = (filename) => {
    return filename.name.replace(/\s/g, '-');
  }
  render() {
    return (
        <form className='btn boxbutton floatright'>
          <label>{this.props.text} {this.state.isUploading && <span> Progress: {this.state.progress}</span>}
          <FileUploader
            hidden
            accept={this.props.accept}
            name='image'
            filename={this.setname}
            storageRef={storage().ref(this.context.product_id+'/'+this.props.folder.id)}
            onUploadStart={this.handleUploadStart}
            onUploadError={this.handleUploadError}
            onUploadSuccess={this.handleUploadSuccess}
            onProgress={this.handleProgress}
            multiple={(this.props.multiple)?true:false}
          />
          </label>
        </form>
    );
  }
}
NewFile.contextType = Store; // This part is important to access context values
export default NewFile;