const objects = [
    {
        custom:false,
        advanced:false,
        useregex:true,
        uselength:true,
        type:"text",
        name:"f_name",
        label:"First Name",
        placeholder:'Enter First Name',
        classes:"",
        required:false,
        value:"",
        query:"Your first name you wish for us to refer to you",
        readonly:false,
        validations:{
            regex:'[a-zA-Z0-9]',
            length:{maxLength:140, minLength:1},
            validation_message:''
        }
    },
    {
        custom:false,
        advanced:false,
        useregex:true,
        uselength:true,
        type:"text",
        name:"l_name",
        label:"Last Name",
        placeholder:'Enter Last Name',
        classes:"",
        required:false,
        value:"",
        query:"Your last name you wish for us to refer to you",
        readonly:false,
        validations:{
            regex:'[a-zA-Z0-9]',
            length:{maxLength:140, minLength:1},
            validation_message:''
        }
    },
    {
        custom:false,
        advanced:false,
        useregex:true,
        uselength:true,
        type:"text",
        name:"full_name",
        label:"Full Name",
        placeholder:'Enter Full Name',
        classes:"",
        required:false,
        value:"",
        query:"Your full name you wish for us to refer to you",
        readonly:false,
        validations:{
            regex:'[a-zA-Z0-9]',
            length:{maxLength:140, minLength:1},
            validation_message:''
        }
    },
    {
        custom:false,
        advanced:false,
        useregex:false,
        uselength:true,
        type:"text",
        name:"business_name",
        label:"Business Name",
        required:false,
        value:"",
        query:"",
        readonly:false,
        placeholder:'The name of the your business',
        classes:"",
        validations:{
            regex:'',
            length:{maxLength:140, minLength:1},
            validation_message:''
        }
    },
    {
        custom:false,
        advanced:false,
        useregex:false,
        uselength:false,
        type:"select",
        name:"business_size",
        label:"Business Size",
        placeholder:"Enter Business Size",
        classes:"",
        query:"The Size of your businness in emploees including directors",
        options:[
            {name:'1-10', value:'1-10'},
            {name:'10-50', value:'10-50'},
            {name:'50-100', value:'50-100'},
            {name:'100-500', value:'100-500'},
            {name:'500-1000', value:'500-1000'},
            {name:'1000+', value:'1000+'}],
        required:false,
        value:"",
        readonly:false,
        validations:{
            regex:'',
            length:{maxLength:0, minLength:0},
            validation_message:''
        }
    },
    {
        custom:false,
        advanced:false,
        useregex:true,
        uselength:true,
        type:"text",
        name:"email",
        label:"Email Address",
        placeholder:'Enter Email Address',
        classes:"",
        required:false,
        value:"",
        query:"The email address you wish for us to contact you with",
        readonly:false,
        validations:{
            regex:"^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$",
            length:{maxLength:999, minLength:5},
            validation_message:'The email does not seem valid'
        }
    },
    {
        custom:false,
        advanced:false,
        useregex:true,
        uselength:true,
        type:"text",
        name:"telephone",
        label:"Telephone",
        placeholder:'Enter Telephone',
        classes:"",
        required:false,
        value:"",
        query:"The telephone number you wish for us to contract you on",
        readonly:false,
        validations:{
            regex:'',
            length:{maxLength:15, minLength:5},
            validation_message:'The landline field must have over 5 numbers and no more than 15'
        }
    },
    {
        custom:false,
        advanced:false,
        useregex:true,
        uselength:true,
        type:"text",
        name:"land_telephone",
        label:"Landline Telephone",
        placeholder:'Enter Land Line',
        classes:"",
        required:false,
        value:"",
        query:"The landline telephone number you wish for us to contract you on",
        readonly:false,
        validations:{
            regex:'',
            length:{maxLength:15, minLength:5},
            validation_message:'The landline field must have over 5 numbers and no more than 15'
        }
    },
    {
        custom:false,
        advanced:false,
        useregex:true,
        uselength:true,
        type:"text",
        name:'mobile_telephone',
        label:"Mobile Telephone",
        placeholder:'Enter Mobile Number',
        classes:"",
        required:false,
        value:"",
        query:"The mobile telephone number you wish for us to contract you on",
        readonly:false,
        validations:{
            regex:'',
            length:{maxLength:15, minLength:5},
            validation_message:'The mobile field must have over 5 numbers and no more than 15'
        }
    },
    {
        custom:false,
        advanced:false,
        useregex:false,
        uselength:true,
        type:"text",
        name:'address1',
        label:"Address Line 1",
        placeholder:'Enter address Line 1',
        classes:"",
        required:false,
        value:"",
        query:"The 1st line of the address for your home or business",
        readonly:false,
        validations:{
            regex:'',
            length:{maxLength:255, minLength:1},
            validation_message:'This address field can have a maximum of 255 charactors and must have at least one charactor'
        }
    },
    {
        custom:false,
        advanced:false,
        useregex:false,
        uselength:true,
        type:"text",
        name:'address2',
        label:"Address Line 2",
        placeholder:'Enter address Line 2',
        classes:"",
        required:false,
        value:"",
        query:"The 2nd line of the address for your home or business",
        readonly:false,
        validations:{
            regex:'',
            length:{maxLength:255, minLength:0},
            validation_message:'This address field can have a maximum of 255 charactors'
        }
    },
    {
        custom:false,
        advanced:false,
        useregex:false,
        uselength:true,
        type:"text",
        name:'address3',
        label:"Address Line 3",
        placeholder:'Enter address Line 3',
        classes:"",
        required:false,
        value:"",
        query:"The 3rd line of the address for your home or business",
        readonly:false,
        validations:{
            regex:'',
            length:{maxLength:255, minLength:0},
            validation_message:'This address field can have a maximum of 255 charactors'
        }
    },
    {
        custom:false,
        advanced:false,
        useregex:false,
        uselength:true,
        type:"text",
        name:'address4',
        label:"Address Line 4",
        placeholder:'Enter address Line 4',
        classes:"",
        required:false,
        value:"",
        query:"The 4th line of the address for your home or business",
        readonly:false,
        validations:{
            regex:'',
            length:{maxLength:255, minLength:0},
            validation_message:'This address field can have a maximum of 255 charactors'
        }
    },
    {
        custom:false,
        advanced:false,
        useregex:false,
        uselength:true,
        type:"text",
        name:'postcode',
        label:"Post Code",
        placeholder:'Enter Post Code',
        classes:"",
        required:false,
        value:"",
        query:"The post code for your home or business address",
        readonly:false,
        validations:{
            regex:'[a-zA-Z0-9]',
            length:{maxLength:140, minLength:1},
            validation_message:'The post code seems invalid'
        }
    },
    {
        custom:false,
        advanced:false,
        useregex:false,
        uselength:true,
        type:"text",
        name:'zip_code',
        label:"Zip Code",
        placeholder:'Enter Zip Code',
        classes:"",
        required:false,
        value:"",
        query:"The zip code for your home or business address",
        readonly:false,
        validations:{
            regex:'[a-zA-Z0-9]',
            length:{maxLength:140, minLength:1},
            validation_message:'The zip code seems invalid'
        }
    },
    {
        custom:true,
        advanced:true,
        useregex:false,
        uselength:false,
        type:"text",
        name:'custom_text',
        label:"Custom Text",
        placeholder:'',
        classes:"",
        required:false,
        value:"",
        query:"",
        readonly:false,
        validations:{
            regex:'',
            length:{maxLength:0, minLength:0},
            validation_message:''
        }
    },
    {
        custom:true,
        advanced:true,
        useregex:false,
        uselength:false,
        type:"textarea",
        name:'custom_text_area',
        label:"Custom Text Area",
        placeholder:'',
        classes:"",
        required:false,
        value:"",
        query:"",
        readonly:false,
        validations:{
            regex:'',
            length:{maxLength:0, minLength:0},
            validation_message:''
        }
    },
    {
        custom:true,
        advanced:true,
        useregex:false,
        uselength:false,
        type:"select",
        name:"custom_select",
        label:"Custom Select",
        placeholder:'',
        classes:"",
        options:[],
        required:false,
        value:"",
        query:"",
        readonly:false,
        validations:{
            regex:'',
            length:{maxLength:0, minLength:0},
            validation_message:''
        }
    },
    {
        custom:true,
        advanced:true,
        useregex:false,
        uselength:false,
        type:"multiselect",
        name:"custom_multi_select",
        label:"Custom Multi Select",
        placeholder:'',
        classes:"",
        value:"",
        query:"",
        options:[],
        readonly:false,
        required:false,
        validations:{
            regex:'',
            length:{maxLength:0, minLength:0},
            validation_message:''
        }
    },
    {
        custom:true,
        advanced:false,
        useregex:false,
        uselength:false,
        type:"check",
        name:"custom_check_boxes",
        label:"Custom Check Boxes",
        placeholder:'',
        classes:"",
        required:false,
        value:"false",
        query:"",
        readonly:false,
        validations:{
            regex:'',
            length:{maxLength:0, minLength:0},
            validation_message:''
        }
    },{
        custom:true,
        advanced:false,
        useregex:false,
        uselength:false,
        type:"link",
        name:"link",
        label:"Custom Link",
        placeholder:'Enter Link',
        classes:"",
        required:false,
        value:"",
        query:"",
        readonly:false,
        validations:{
            regex:'',
            length:{maxLength:0, minLength:0},
            validation_message:''
        }
    },{
        custom:true,
        advanced:false,
        useregex:false,
        uselength:false,
        type:"title",
        name:"Custom_Title",
        label:"Custom Title",
        placeholder:'Enter Title',
        classes:"",
        required:false,
        value:"",
        query:"",
        readonly:false,
        validations:{
            regex:'',
            length:{maxLength:0, minLength:0},
            validation_message:''
        }
    }
];
export default objects;