import React, {Fragment, Component, useContext} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faTrash, faEye, faPowerOff, faHandshake, faEdit
} from '@fortawesome/free-solid-svg-icons';
import {useHistory} from 'react-router-dom';
import {toggleActive, deleteProduct } from "../../../data/products";
import Processing from '../../Global/Processing';
import Share from '../Share';
import EditProduct from '../EditProduct';
import ModalAlert from '../../Global/ModalAlert';
import {Store} from "../../../stores/Store";

const ViewProductButton = (props) => {
    let context = useContext(Store);
    let history = useHistory();
    let setproduct = () =>{
        context.update(
            {
                product:props.data,
                view:(context.view)?context.view:"dashboard"
            }
        );
        history.push("/product/"+props.data.id+"/");
    }
    return (<button className="btn btn-sm barbutton floatright" onClick={()=>{setproduct()}}>
        <FontAwesomeIcon icon={faEye} />
    </button>);
}

class ButtonBar extends Component{
    state = {
        processing:false
    }
    toggleActive = async () => {
        await this.setState({
            processing:true
        })
        await toggleActive(this.props.data.id, this.props.data.active); 
        await this.props.update(); 
        await this.setState({
            processing:false
        })
    }
    deleteProduct = async() => {
        let objdelete = window.prompt("Please enter 'delete' to confirm", "");
        if(objdelete === 'delete'){
            await this.setState({
                processing:true
            })
            await deleteProduct(this.props.data.id, this.context);
            await this.setState({
                processing:false
            })
            await this.props.update(); 
        }
        
    }
    render(){
        return (!this.state.processing)?(
            <div className="card-footer d-flex bg-gradient">
                <Fragment>
                    <div>
                        {(this.props.data.owner === this.context.uid || this.context.access === 'super')&&(
                            <Fragment>
                                <ModalAlert 
                                    buttonClassName='btn btn-sm barbutton floatright' 
                                    buttonType='none'
                                    buttonIcon={<Fragment><FontAwesomeIcon icon={faEdit} /></Fragment>} 
                                    title={'Edit'} 
                                    content={<EditProduct data={this.props.data} update={this.props.update}/>}
                                    size='lg'
                                />
                                <ModalAlert 
                                    buttonClassName='btn btn-sm barbutton floatright' 
                                    buttonType='none'
                                    buttonIcon={<Fragment><FontAwesomeIcon icon={faHandshake} /></Fragment>} 
                                    title={'Share'} 
                                    content={<Share product_id={this.props.data.id} update={this.props.update} />}
                                    size='lg'
                                />
                            </Fragment>
                        )}
                        <ViewProductButton data={this.props.data}/>
                    </div>
                    {((this.context.access === 'super' || this.context.access === 'admin'))&&(<div>
                        <button onClick={this.deleteProduct} className="btn btn-sm barbutton floatright warning"><FontAwesomeIcon icon={faTrash} /></button>
                        <button onClick={this.toggleActive}  className="btn btn-sm barbutton floatright warning"><FontAwesomeIcon icon={faPowerOff} /></button>
                    </div>)}
                </Fragment>
            </div>        
        ):<Processing />
    }  
}
ButtonBar.contextType = Store; // This part is important to access context values
export default ButtonBar;