import React, {useState} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faCube
} from '@fortawesome/free-solid-svg-icons';
import Processing from '../../Global/Processing';
import ButtonBar from './ButtonBar';
const GlobalComponantCard = (props) => {
    let [removing, setRemoving] = useState(false);
    return (
        <div className={"card"}>
            <div className="card-header card-header-borderless d-flex justify-content-between">
                <div>
                    <FontAwesomeIcon icon={faCube} />
                    {' '+props.item.name}
                </div>
            </div>
            <div className="card-body">
                <div className="media">
                    <div className="media-body siteCard">
                        {(removing)?<Processing />:(
                            <ul className="list-group">
                                <li className="list-group-item">
                                    <FontAwesomeIcon icon={faCube} />
                                    <span> {props.item.description}</span>
                                </li>
                            </ul>
                        )}
                    </div>
                </div>
            </div>
            {(!removing)
                &&<ButtonBar
                    setRemoving={setRemoving}
                    item={props.item}
                    refresh={props.refresh}
                />}
        </div>
    );
}
export default GlobalComponantCard;