const textandimage = (template) => {
    return [
        {
            data:[
                {
                    name:"Text Background Colour",
                    type:"color",
                    value:template.primaryBackground,
                    variableName:"backgroundColor" 
                },{
                    name:"Title Text Colour",
                    type:"color",
                    value:template.titleColor,
                    variableName:"titleColor" 
                },{
                    name:"Lead Text Colour",
                    type:"color",
                    value:template.leadColor,
                    variableName:"leadColor" 
                },{
                    name:"Body Text Colour",
                    type:"color",
                    value:template.bodyColor,
                    variableName:"bodyColor" 
                }
            ],
            name:'colours'
        },{
            data:[
                {
                    type:'text',
                    value:'Main Title',
                    variableName:'title',
                    name:'Title Text'
                },{
                    type:'text',
                    value:'Lead text is kind of like a sub title - the lead in to the next section',
                    variableName:'lead',
                    name:'Lead Text'
                },{
                    type:'wysiwyg',
                    value:'The main body of text in your component.',
                    variableName:'body',
                    name:'Body Text'
                }
            ],
            name:'Content',
        },{
            data:[
                {
                    alt:"",
                    name:"Image",
                    src:"",
                    type:"image",
                    variableName:"image" 
                }
            ],
            name:'images',
        },{
            data:[
                {
                    name:"Button Text Colour",
                    type:"color",
                    value:template.buttonColor,
                    variableName:"buttonColor" 
                },{
                    name:"Button Background Colour",
                    type:"color",
                    value:template.buttonBackgroundColor,
                    variableName:"buttonBackgroundColor" 
                },{
                    name:"Button Hover Text Colour",
                    type:"color",
                    value:template.buttonHoverColor,
                    variableName:"buttonHoverColor" 
                },{
                    name:"Button Hover Background Colour",
                    type:"color",
                    value:template.buttonHoverBackgroundColor,
                    variableName:"buttonHoverBackgroundColor" 
                },{
                    name:"Button Padding",
                    type:"number",
                    value:template.buttonPadding,
                    variableName:"buttonPadding" 
                },{
                    data:[
                        {
                            label:"Full Width",
                            value:"90%"
                        },{
                            label:"Half Width",
                            value:"50%"
                        },{
                            label:"Dynamic",
                            value:"auto"
                        }
                    ],
                    name:"Button Width",
                    type:"select",
                    value:'auto',
                    variableName:"buttonWidth" 
                },{
                    name:"Button",
                    text:"Go",
                    title:"Button Title",
                    link:"",
                    type:"link",
                    variableName:"button" 
                }
            ],
            name:'Buttons',
        },{
            data:[
                {
                    name:"Title Font Size",
                    type:"number",
                    value:template.titleSize,
                    variableName:"titlefontSize" 
                },{
                    name:"Lead Font Size",
                    type:"number",
                    value:template.leadSize,
                    variableName:"leadfontSize" 
                },{
                    name:"Body Font Size",
                    type:"number",
                    value:template.bodySize,
                    variableName:"bodyfontSize" 
                }
            ],
            name:'Sizes',
        },{
            data:[
                {
                    type:'bool',
                    value:'false',
                    variableName:'reverse',
                    name:'Reverse Component'
                },{
                    data:[
                        {
                            label:"Right",
                            value:"right"
                        },{
                            label:"Center",
                            value:"center"
                        },{
                            label:"Left",
                            value:"left"
                        }
                    ],
                    type:'select',
                    value:false,
                    variableName:'align',
                    name:'Align Text'
                },{
                    name:"Space Top",
                    type:"number",
                    value:80,
                    max:200,
                    variableName:"paddingTop" 
                },{
                    name:"Space Bottom",
                    type:"number",
                    value:80,
                    max:200,
                    variableName:"paddingBottom" 
                },{
                    name:"Corner Radius",
                    type:"number",
                    value:10,
                    variableName:"borderRadius" 
                },{
                    name:"Text Container Padding %",
                    type:"number",
                    value:5,
                    max:5,
                    variableName:"textPadding" 
                }
            ],
            name:'Layout',
        }
    ];
}
export default textandimage;