import React, {Fragment, useContext} from 'react';
import {NavLink} from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faArrowsAlt, faPager, faGlobe, faCubes, faBorderNone, faRss
} from '@fortawesome/free-solid-svg-icons';
import {Store} from "../../../stores/Store";

const Sites = (props)=>{
    const context = useContext(Store); // This part is important to access context values
    return (
        <Fragment>
            <li className="nav-item">
                <NavLink className="nav-link" exact to={"/product/"+context.product_id+'/sites'} activeClassName="active">
                    <FontAwesomeIcon icon={ faGlobe } /><br/>
                    <span className="d-none d-md-inline">
                        Sites
                    </span>
                </NavLink>
                {(context.site && context.site.site_id)&&<NavLink className="nav-link" exact to={"/product/"+context.product_id+'/site/routes'} activeClassName="active">
                    <FontAwesomeIcon icon={ faArrowsAlt } /><br/>
                    <span className="d-none d-md-inline">
                        Routes
                    </span>
                </NavLink>}
                {(context.site && context.site.site_id)&&<NavLink className="nav-link" exact to={"/product/"+context.product_id+'/site/pages'} activeClassName="active">
                    <FontAwesomeIcon icon={ faPager } /><br/>
                    <span className="d-none d-md-inline">
                        Pages
                    </span>
                </NavLink>}
                {(context.site && context.site.site_id)&&<NavLink className="nav-link" exact to={"/product/"+context.product_id+'/site/templates'} activeClassName="active">
                    <FontAwesomeIcon icon={ faBorderNone } /><br/>
                    <span className="d-none d-md-inline">
                        Page Templates
                    </span>
                </NavLink>}
                {(context.site && context.site.site_id)&&<NavLink className="nav-link" exact to={"/product/"+context.product_id+'/site/feeds'} activeClassName="active">
                    <FontAwesomeIcon icon={ faRss } /><br/>
                    <span className="d-none d-md-inline">
                        Feeds
                    </span>
                </NavLink>}
                {(context.site && context.site.site_id)&&<NavLink className="nav-link" exact to={"/product/"+context.product_id+'/site/globalcomponents'} activeClassName="active">
                    <FontAwesomeIcon icon={ faCubes } /><br/>
                    <span className="d-none d-md-inline">
                        Global Components
                    </span>
                </NavLink>}
            </li>
        </Fragment>
    )
}
export default Sites;