import React from 'react';
import {NavLink} from 'react-router-dom';

const Verify = () => {
    return (
        <section className="space-sm">
            <div className="container align-self-start ">
                <div className="row justify-content-center">
                    <div className="col-12 col-md-8 col-lg-7">
                        <div className={"card card-lg text-center"}>
                            <div className="card-body">
                                <div className="mb-5">
                                    <div>
                                        <img alt='logo' style={{width:'30%'}} src={process.env.REACT_APP_LOGO} />
                                    </div>
                                    <br/>
                                    <h1 className="h2 mb-2">Verify</h1>
                                    <p>Please Verify Your Account</p>
                                </div>
                                <div className="row no-gutters justify-content-center">
                                    <p>You cannot login until you verify your email address!</p>
                                    <p>Please check your inbox and click the link to verify your email address!</p>
                                </div>
                                <div className="row no-gutters justify-content-center">
                                    <NavLink to="/"><button className='btn btn-success'>Back to sign in</button></NavLink>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}
export default Verify;
