import React, {useContext, Fragment} from 'react';
import {NavLink} from 'react-router-dom';
import {Store} from "../../../stores/Store";
import Research from './Research';
import Chats from './Chats';
import Forms from './Forms';
import Sites from './Sites';
import Stats from './Stats';
import Pipeline from './Pipeline';
const Sidebar = (props) => {
    const context = useContext(Store); // This part is important to access context values
    return (
        <nav id='sizesidbar' className="col-2 bg-dark sidebar sidebar-sticky">
            <ul className="nav flex-column ">
                <li className="nav-item brand-item " >
                    <NavLink exact to={"/"} activeClassName="active">
                        <img className="d-md-none" alt='logo' src={process.env.REACT_APP_WHITE_LOGO_SMALL} />
                        <span className="d-none d-md-inline">
                            <img alt='logo' style={{width:'40%'}} src={process.env.REACT_APP_WHITE_LOGO} />
                        </span>
                    </NavLink>
                </li>
                {(!props.nooptions)&&(
                    <Fragment>
                        {(context.product_id)
                            ?
                            <Fragment>
                                {(context.view === 'pipeline')&&<Pipeline />}
                                {(context.view === 'research')&&<Research />}
                                {(context.view === 'sites')&&<Sites />}
                                {(context.view === 'forms')&&<Forms />}
                                {(context.view === 'chats')&&<Chats />}
                            </Fragment>
                            :null}
                    </Fragment>
                )}
            </ul>
        </nav>
    );
}
export default Sidebar;
