import React, {Fragment, useContext, useEffect} from 'react';
//componants
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faList, faTh
} from '@fortawesome/free-solid-svg-icons';
import Filter from './Filter';
import Sort from './Sort';
import {Store} from '../../stores/Store';
const FilterButtons = (props) => {
    const context = useContext(Store); // This part is important to access context values
    return (
        <Fragment>
            <button onClick={props.toggleView} className='boxbutton floatright btn btn-none btn-sm'>{(context.cards)?<FontAwesomeIcon icon={faList} />:<FontAwesomeIcon icon={faTh} />}</button>
            <Filter refresh={props.refresh} />
            <Sort refresh={props.refresh} />
        </Fragment>
    );
}
export default FilterButtons;
