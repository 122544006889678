const formandimage = (template) => {
    return [
        {
            data:[
                {
                    name:"Background Colour",
                    type:"color",
                    value:template.primaryBackground,
                    variableName:"backgroundColor" 
                }
            ],
            name:'colours'
        },{
            data:[{
                    type:'form',
                    value:'',
                    variableName:'formId',
                    name:'Choose Form'
                }
            ],
            name:'Content',
        },{
            data:[
                {
                    alt:"",
                    name:"Image",
                    src:"",
                    type:"image",
                    variableName:"image" 
                }
            ],
            name:'images',
        },{
            data:[{
                    data:[
                        {
                            label:"Right",
                            value:"right"
                        },{
                            label:"Center",
                            value:"center"
                        },{
                            label:"Left",
                            value:"left"
                        }
                    ],
                    type:'select',
                    value:'center',
                    variableName:'position',
                    name:'Position Form'
                },{
                    type:'bool',
                    value:'false',
                    variableName:'reverse',
                    name:'Reverse Component'
                },{
                    name:"Space Top",
                    type:"number",
                    value:80,
                    max:200,
                    variableName:"paddingTop" 
                },{
                    name:"Space Bottom",
                    type:"number",
                    value:80,
                    max:200,
                    variableName:"paddingBottom" 
                }
            ],
            name:'Layout',
        }
    ]
}
export default formandimage;