import React, { useContext, useEffect, useState } from 'react';
import { getFeedRoutes } from '../../../data/sites';
import { Store } from '../../../stores/Store';

const FeedId = (props) => {
    let context = useContext(Store);
    let [feeds ,setFeeds] = useState([]);
    useEffect(()=>{
        getFeedRoutes(context.product_id, context.site.site_id).then((res)=>{
            setFeeds(res.data);
        });
    },[]);
    return (
        <div className="input-group mb-3">
            <div className="input-group-prepend">
                <div className="input-group-text">{props.name}</div>
            </div>
            <select name={'select'} required className='form-control' onChange={(e)=>{props.update(e.target.value)}} value={props.value}>
                <option value=''>No Feed</option>
                {feeds.map((item, i)=>{
                    return(
                        <option key={i} value={item.id}>
                            {item.name}
                        </option>
                    )
                })}
            </select>
        </div>
    )
}
export default FeedId;