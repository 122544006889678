import React, {Fragment} from 'react';
//componants
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faPlus, faEdit
} from '@fortawesome/free-solid-svg-icons';
import ModalAlert from '../Global/ModalAlert';
import CreatePage from './CreatePage';
import CreateRoute from './CreateRoute';
import GlobalVariables from './GlobalVariables';
import TemplateVariables from './TemplateVariables';
const PageDashboardButtons = (props) => {
    return (
        <Fragment>
            <ModalAlert 
                buttonClassName='boxbutton floatright' 
                buttonType='none'
                buttonIcon={<Fragment><FontAwesomeIcon icon={faPlus} /> <span>{(!props.feed)?(!props.templates)?'Page':'Template':'Content'}</span></Fragment>} 
                title={'New'+(props.feed)?(!props.templates)?'Page':'Template':'Content'} 
                content={(props.feed)?<CreateRoute feed={props.feed} update={props.callback} />:<CreatePage feed={props.feed} template={props.templates} update={props.callback} />}
                size='lg'
                callback={props.callback}
            />
            {(props.feed)&&<ModalAlert 
                buttonClassName='boxbutton floatright' 
                buttonType='none'
                buttonIcon={<Fragment><FontAwesomeIcon icon={faPlus} /> <span>Category</span></Fragment>} 
                title={'New Feed Category'} 
                content={<CreateRoute feed={props.feed} isCat={true} update={props.callback} />}
                size='lg'
                callback={props.callback}
            />}
            <ModalAlert 
                buttonClassName='btn btn-sm boxbutton  floatright' 
                buttonType='none'
                buttonIcon={<Fragment>
                    <FontAwesomeIcon icon={faEdit} /> Site Variables
                </Fragment>} 
                title={'Edit Page Variables'}
                content={<GlobalVariables />}
                size='lg'
            />
            <ModalAlert 
                buttonClassName='btn btn-sm boxbutton  floatright' 
                buttonType='none'
                buttonIcon={<Fragment><FontAwesomeIcon icon={faEdit} /> Initial Values</Fragment>} 
                title={'Edit Page Variables'}
                content={<TemplateVariables />}
                size='lg'
            />
        </Fragment>
    );
}
export default PageDashboardButtons;
