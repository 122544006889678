import {db} from '../firebase';
import {sitedefault} from './Models/sitedefault';
import {pagedefault} from './Models/pagedefault';
export const addSite = async (product_id, data) => {
    let responce = {
        success:true,
        data:[]
    }
    let newid = null
    await db.collection('products').doc(product_id).collection('sites').add(
        {...data, ...sitedefault, product_id:product_id}
    ).then(ref => {
        responce.data = ref
        newid = ref.id;
    }).catch((error)=>{
        responce.success = false;
        responce.error = error;
        delete responce.data;
        return 
    });
    if(data.domain === 'generate'){
        await db.collection('products').doc(product_id).collection('sites').doc(newid).update(
            {
                domain:'http://'+newid+'.fledge.app'
            }
        );
    }
    return responce;
}
export const deleteSite = async (product_id, site_id) => {
    let responce = {
        success:true,
        data:[]
    }
    await db.collection('products').doc(product_id).collection('sites').doc(site_id).delete();
    return responce;
}
export const updateSite = async (product_id, site_id, site) => {
    let responce = {
        success:true,
        data:[]
    }
    await db.collection('products').doc(product_id).collection('sites').doc(site_id).update(site);
    return responce;
}
export const getSites = async (product_id) => {
    let responce = {
        success:true,
        data:[]
    }
    await db.collection('products').doc(product_id).collection('sites').get().then(async snapshot => {
        let Sites = [];
        if(!snapshot.empty)
        {
            snapshot.forEach(async doc => {
                let Site = doc.data();
                Site.id = doc.id;
                Sites.push(Site);
            });
        }
        responce.data = Sites;
        return
    }).catch((error)=>{
        responce.success = false;
        responce.error = error;
        delete responce.data;
        return 
    });
    return responce;
}
export const getSite = async (product_id, site_id, page_id) => {
    let responce = {
        success:true,
        data:[]
    }
    await db.collection('products').doc(product_id).collection('sites').doc(site_id)
    .get().then(async ref => {
        let Site = ref.data();
        Site.id = ref.id;
        responce.data = Site;
        return
    })
    return responce;
}

///feeds
export const addFeedContent = async (product_id, site_id, feed_id, data) => {
    let responce = {
        success:true,
        data:[]
    }
    await db.collection('products').doc(product_id).collection('sites').doc(site_id).collection('routes').add(
        {...data, type:'feedcontent', feed_id:feed_id, product_id:product_id, site_id:site_id}
    ).then(ref => {
        responce.data = ref
    }).catch((error)=>{
        responce.success = false;
        responce.error = error;
        delete responce.data;
        return 
    });
    return responce;
}
export const addFeed = async (product_id, site_id, data) => {
    let responce = {
        success:true,
        data:[]
    }
    await db.collection('products').doc(product_id).collection('sites').doc(site_id).collection('routes').add(
        {...data, type:'feed', product_id:product_id, site_id:site_id}
    ).then(ref => {
        responce.data = ref
    }).catch((error)=>{
        responce.success = false;
        responce.error = error;
        delete responce.data;
        return 
    });
    return responce;
}
export const getFeedRoutes = async (product_id, site_id) => {
    let responce = {
        success:true,
        data:[]
    }
    await db.collection('products').doc(product_id).collection('sites').doc(site_id).collection('routes').get().then(async snapshot => {
        let Routes = [];
        if(!snapshot.empty)
        {
            snapshot.forEach(async doc => {
                let Route = doc.data();
                if(Route.type === 'feed'){
                    
                    Route.id = doc.id;
                    Routes.push(Route);
                }
            });
        }
        responce.data = Routes;
        return
    }).catch((error)=>{
        responce.success = false;
        responce.error = error;
        delete responce.data;
        return 
    });
    return responce;
}
export const getFeedCatRoutes = async (product_id, site_id) => {
    let responce = {
        success:true,
        data:[]
    }
    await db.collection('products').doc(product_id).collection('sites').doc(site_id).collection('routes').get().then(async snapshot => {
        let Routes = [];
        if(!snapshot.empty)
        {
            snapshot.forEach(async doc => {
                let Route = doc.data();
                if(Route.feed && (Route.type && Route.type === 'feedcat')){
                    
                    Route.id = doc.id;
                    Routes.push(Route);
                }
            });
        }
        responce.data = Routes;
        return
    }).catch((error)=>{
        responce.success = false;
        responce.error = error;
        delete responce.data;
        return 
    });
    return responce;
}
export const getFeedContentRoutes = async (product_id, site_id, feed_id) => {
    let responce = {
        success:true,
        data:[]
    }
    await db.collection('products').doc(product_id).collection('sites').doc(site_id).collection('routes').get().then(async snapshot => {
        let Routes = [];
        if(!snapshot.empty)
        {
            snapshot.forEach(async doc => {
                let Route = doc.data();
                if(Route.feed === feed_id){
                    
                    Route.id = doc.id;
                    Routes.push(Route);
                }
            });
        }
        responce.data = Routes;
        return
    }).catch((error)=>{
        responce.success = false;
        responce.error = error;
        delete responce.data;
        return 
    });
    return responce;
}
export const getFeedContent = async (product_id, site_id, feed_id) => {
    let responce = {
        success:true,
        data:[]
    }
    await db.collection('products').doc(product_id).collection('sites').doc(site_id).collection('pages')
    .get().then(async snapshot => {
        let Pages = [];
        if(!snapshot.empty)
        {
            snapshot.forEach(async doc => {
                let Page = doc.data();
                if(feed_id && Page.feed === feed_id){
                    Page.id = doc.id;
                    Pages.push(Page);
                }
            });
        }
        responce.data = Pages;
        return
    }).catch((error)=>{
        responce.success = false;
        responce.error = error;
        delete responce.data;
        return 
    });
    return responce;
}
//////routes
export const addRoute = async (product_id, site_id, data) => {
    let responce = {
        success:true,
        data:[]
    }
    await db.collection('products').doc(product_id).collection('sites').doc(site_id).collection('routes').add(
        (data.type)?{...data, product_id:product_id, site_id:site_id}:{...data, type:'pageroute', product_id:product_id, site_id:site_id}
    ).then(ref => {
        responce.data = ref
    }).catch((error)=>{
        responce.success = false;
        responce.error = error;
        delete responce.data;
        return 
    });
    return responce;
}
export const deleteRoute = async (product_id, site_id, route_id) => {
    let responce = {
        success:true,
        data:[]
    }
    await db.collection('products').doc(product_id).collection('sites').doc(site_id).collection('routes').doc(route_id).delete();
    return responce;
}
export const updateRoute = async (product_id, site_id, route_id, route) => {
    let responce = {
        success:true,
        data:[]
    }
    await db.collection('products').doc(product_id).collection('sites').doc(site_id).collection('routes').doc(route_id).update(route);
    return responce;
}
export const getRoute = async (product_id, site_id, route_id) => {

    let responce = {
        success:true,
        data:[]
    }
    await db.collection('products').doc(product_id).collection('sites').doc(site_id).collection('routes').doc(route_id)
    .get().then(async ref => {
        let route = ref.data();
        route.id = ref.id;
        responce.data = route;
        return
    })
    return responce;
}
export const getRoutes = async (product_id, site_id) => {
    let responce = {
        success:true,
        data:[]
    }
    await db.collection('products').doc(product_id).collection('sites').doc(site_id).collection('routes').get().then(async snapshot => {
        let Routes = [];
        if(!snapshot.empty)
        {
            snapshot.forEach(async doc => {
                let Route = doc.data();
                Route.id = doc.id;
                Routes.push(Route);
            });
        }
        responce.data = Routes;
        return
    }).catch((error)=>{
        responce.success = false;
        responce.error = error;
        delete responce.data;
        return 
    });
    return responce;
}

///components
export const addComponent = async (product_id, site_id, page_id, data) => {
    let responce = {
        success:true,
        data:[]
    }
    let number = 0;
    await db.collection('products').doc(product_id).collection('sites').doc(site_id).collection('pages').doc(page_id).collection('components').get().then((snap)=>{
        number = snap.size;
    });
    await db.collection('products').doc(product_id).collection('sites').doc(site_id).collection('pages').doc(page_id).collection('components').add(
        {
            ...data, 
            product_id:product_id,
            site_id:site_id,
            page_id:page_id,
            order:number+1,
        }
    ).then(ref => {
        responce.data = ref
    }).catch((error)=>{
        responce.success = false;
        responce.error = error;
        delete responce.data;
        return 
    });
    return responce;
}
export const deleteComponent = async (product_id, site_id, page_id, component_id) => {
    let responce = {
        success:true
    }
    
    await db.collection('products').doc(product_id).collection('sites').doc(site_id).collection('pages').doc(page_id).collection('components').doc(component_id).delete().then(() => {
        return;
    }).catch((error) => {
        responce.success = false;
        return ;
    });
    reorderComponents(product_id, site_id, page_id);
    return responce;
}

export const getComponents = async (product_id, site_id, page_id) => {
    let responce = {
        success:true,
        data:[]
    }
    await db.collection('products').doc(product_id).collection('sites').doc(site_id).collection('pages').doc(page_id).collection('components').orderBy("order")
    .get().then(async snapshot => {
        let Components = [];
        if(!snapshot.empty)
        {
            snapshot.forEach(async doc => {
                let Component = doc.data();
                Component.id = doc.id;
                Components.push(Component);
            });
        }
        responce.data = Components;
        return
    }).catch((error)=>{
        responce.success = false;
        responce.error = error;
        delete responce.data;
        return 
    });
    return responce;
}
export const componentUpdate = async (product_id, site_id, page_id, component_id, component) => {
    let responce = {
        success:true
    }
    await db.collection('products').doc(product_id).collection('sites').doc(site_id).collection('pages').doc(page_id).collection('components').doc(component_id).update(component).then(ref => {
        responce.data = ref
    }).catch((error)=>{
        responce.success = false;
        responce.error = error;
        delete responce.data;
        return 
    });
    await db.collection('products').doc(product_id).collection('sites').doc(site_id).collection('pages').doc(page_id).update({
        last_modified:Date.now()
    });
    return responce;
}
export const getTemplateComponents = async () => {
    let responce = {
        success:true,
        data:[]
    }
    await db.collection('page_components')
    .get().then(async snapshot => {
        let Components = [];
        if(!snapshot.empty)
        {
            snapshot.forEach(async doc => {
                let Component = doc.data();
                Component.id = doc.id;
                Components.push(Component);
            });
        }
        responce.data = Components;
        return
    }).catch((error)=>{
        responce.success = false;
        responce.error = error;
        delete responce.data;
        return 
    });
    return responce;
}
const reorderComponents = async (product_id, site_id, page_id) => {
    await db.collection('products').doc(product_id).collection('sites').doc(site_id).collection('pages').doc(page_id).collection('components').orderBy("order").get().then((snapshot)=>{
        if(!snapshot.empty)
        {
            let order = 1;
            snapshot.forEach(async doc => {
                let id = doc.id;
                db.collection('products').doc(product_id).collection('sites').doc(site_id).collection('pages').doc(page_id).collection('components').doc(id).update(
                    {
                        order:order
                    }
                )
                order++;
            });
        }
    });
}
////////global comonents
export const addGlobalComponent = async (product_id, site_id, data) => {
    let responce = {
        success:true,
        data:[]
    }
    let number = 0;
    await db.collection('products').doc(product_id).collection('sites').doc(site_id).collection('globalcomponents').get().then((snap)=>{
        number = snap.size;
    });
    await db.collection('products').doc(product_id).collection('sites').doc(site_id).collection('globalcomponents').add(
        {
            ...data, 
            product_id:product_id,
            site_id:site_id,
        }
    ).then(ref => {
        responce.data = ref
    }).catch((error)=>{
        responce.success = false;
        responce.error = error;
        delete responce.data;
        return 
    });
    return responce;
}
export const deleteGlobalComponent = async (product_id, site_id, globalcomponent_id) => {
    let responce = {
        success:true
    }
    await db.collection('products').doc(product_id).collection('sites').doc(site_id).collection('globalcomponents').doc(globalcomponent_id).delete().then(() => {
        return;
    }).catch((error) => {
        responce.success = false;
        return ;
    });
    await db.collection('products').doc(product_id).collection('sites').doc(site_id).collection('pages').get().then((snapshot)=>{
        snapshot.forEach(async doc => {
            let id = doc.id;
            await deleteGlobalComponentFromPage(product_id, site_id, id, globalcomponent_id);
            reorderComponents(product_id, site_id, id);
        });
    });
    return responce;
}
export const deleteGlobalComponentFromPage = async (product_id, site_id, page_id, globalcomponent_id) => {
    db.collection('products').doc(product_id).collection('sites').doc(site_id).collection('pages').doc(page_id).collection('components').where('global', '==', globalcomponent_id).get().then((snapshot)=>{
        snapshot.forEach(globaldoc => {
            let id = globaldoc.id;
            db.collection('products').doc(product_id).collection('sites').doc(site_id).collection('pages').doc(page_id).collection('components').doc(id).delete();
        });
    });
}
export const getGlobalComponents = async (product_id, site_id) => {
    let responce = {
        success:true,
        data:[]
    }
    await db.collection('products').doc(product_id).collection('sites').doc(site_id).collection('globalcomponents')
    .get().then(async snapshot => {
        let Components = [];
        if(!snapshot.empty)
        {
            snapshot.forEach(async doc => {
                let Component = doc.data();
                Component.id = doc.id;
                Components.push(Component);
            });
        }
        responce.data = Components;
        return
    }).catch((error)=>{
        responce.success = false;
        responce.error = error;
        delete responce.data;
        return 
    });
    return responce;
}
export const componentGlobalUpdate = async (product_id, site_id, globalcomponent_id, component) => {
    let responce = {
        success:true
    }
    await db.collection('products').doc(product_id).collection('sites').doc(site_id).collection('globalcomponents').doc(globalcomponent_id).update(component).then(ref => {
        responce.data = ref
    }).catch((error)=>{
        responce.success = false;
        responce.error = error;
        delete responce.data;
        return 
    });
    return responce;
}
/////
export const getPages = async (product_id, site_id) => {
    let responce = {
        success:true,
        data:[]
    }
    await db.collection('products').doc(product_id).collection('sites').doc(site_id).collection('pages')
    .get().then(async snapshot => {
        let Pages = [];
        if(!snapshot.empty)
        {
            snapshot.forEach(async doc => {
                let Page = doc.data();
                if(!Page.feed){
                    Page.id = doc.id;
                    Pages.push(Page);
                }
            });
        }
        responce.data = Pages;
        return
    }).catch((error)=>{
        responce.success = false;
        responce.error = error;
        delete responce.data;
        return 
    });
    return responce;
}
export const getPageTemplates = async (product_id, site_id) => {
    let responce = {
        success:true,
        data:[]
    }
    await db.collection('products').doc(product_id).collection('sites').doc(site_id).collection('pages').where("template", '==', true)
    .get().then(async snapshot => {
        let Pages = [];
        if(!snapshot.empty)
        {
            snapshot.forEach(async doc => {
                let Page = doc.data();
                Page.id = doc.id;
                Pages.push(Page);
            });
        }
        responce.data = Pages;
        return
    }).catch((error)=>{
        responce.success = false;
        responce.error = error;
        delete responce.data;
        return 
    });
    return responce;
}
export const getPage = async (product_id, site_id, page_id) => {

    let responce = {
        success:true,
        data:[]
    }
    await db.collection('products').doc(product_id).collection('sites').doc(site_id).collection('pages').doc(page_id)
    .get().then(async ref => {
        let Page = ref.data();
        Page.id = ref.id;
        responce.data = Page;
        return
    })
    return responce;
}
export const createPage = async (product_id, site_id, page) => {
    let responce = {
        success:true,
        data:[]
    }
    let addObject = {
        ...pagedefault,
        ...page,
        active:true,
        product_id:product_id,
        site_id:site_id,
        last_modified:Date.now()
    }
    await db.collection('products').doc(product_id).collection('sites').doc(site_id).collection('pages').add(addObject).then(ref => {
        responce.data = ref
    }).catch((error)=>{
        responce.success = false;
        responce.error = error;
        delete responce.data;
        return 
    });
    return responce;
}
export const pageUpdate = async (product_id, site_id, page_id, page) => {
    let responce = {
        success:true
    }
    await db.collection('products').doc(product_id).collection('sites').doc(site_id).collection('pages').doc(page_id).update({...page, last_modified:Date.now()}).then(ref => {
        responce.data = ref
    }).catch((error)=>{
        responce.success = false;
        responce.error = error;
        delete responce.data;
        return 
    });
    return responce;
}
export const deletePage = async (product_id,site_id, page_id) => {
    let responce = {
        success:true
    }
    let componentfail = false;
    await db.collection("products").doc(product_id).collection('sites').doc(site_id).collection("pages").doc(page_id).collection('components').get().then(async snapshot => {
        if(!snapshot.empty )
        {
            snapshot.forEach(async doc => {
                let id = doc.id;
                db.collection("products").doc(product_id).collection('sites').doc(site_id).collection("pages").doc(page_id).collection('components').doc(id).delete().catch(()=>{
                    componentfail = true;
                    responce.success = false;
                });
            });
        }
        return
    });
    if(!componentfail){
        await db.collection("products").doc(product_id).collection('sites').doc(site_id).collection("pages").doc(page_id).delete()
        .then((res)=>{
                return
            }).catch((error) => {
                responce.success = false;
        });
    }
    return responce;
}
export const clonePage = async (product_id, site_id, page_id) => {
    let responce = {
        success:true
    }
    let page = null;
    let newpage = {};
    await db.collection("products").doc(product_id).collection('sites').doc(site_id).collection("pages").doc(page_id).get()
    .then((doc)=>{
            page = doc.data();
            page.name = page.name+'_copy';
            page.last_modified = Date.now();
            page.template = true;
            return
        }).catch((error) => {
            responce.success = false;
    });
    await db.collection("products").doc(product_id).collection('sites').doc(site_id).collection("pages").add(page).then(doc=>{
        newpage.id = doc.id;
    });
    await db.collection("products").doc(product_id).collection('sites').doc(site_id).collection("pages").doc(page_id).collection('components').get().then(async snapshot => {
        if(!snapshot.empty )
        {
            snapshot.forEach(async doc => {
                let comp = doc.data();
                comp.page_id = newpage.id;
                db.collection("products").doc(product_id).collection('sites').doc(site_id).collection("pages").doc(newpage.id).collection('components').add(comp).catch(()=>{
                    responce.success = false;
                });
            });
        }
        return
    });
    return responce;
}
export const clonePageTemplate = async (product_id,site_id, page_id, name = null, feed_id = null) => {
    let responce = {
        success:true,
        data:{}
    }
    let page = null;
    await db.collection("products").doc(product_id).collection('sites').doc(site_id).collection("pages").doc(page_id).get()
    .then((doc)=>{
            page = doc.data();
            page.name = (name)?name:page.name+'_copy';
            page.last_modified = Date.now();
            page.template = false;
            if(feed_id){
                page.feed_id = feed_id;
            }
            return
        }).catch((error) => {
            responce.success = false;
    });
    await db.collection("products").doc(product_id).collection('sites').doc(site_id).collection("pages").add(page).then(doc=>{
        responce.data.id = doc.id;
    });
    await db.collection("products").doc(product_id).collection('sites').doc(site_id).collection("pages").doc(page_id).collection('components').get().then(async snapshot => {
        if(!snapshot.empty )
        {
            snapshot.forEach(async doc => {
                let comp = doc.data();
                comp.page_id = responce.data.id;
                db.collection("products").doc(product_id).collection('sites').doc(site_id).collection("pages").doc(responce.data.id).collection('components').add(comp).catch(()=>{
                    responce.success = false;
                });
            });
        }
        return
    });
    return responce;
}
export const getDomains = async (product_id, filter = true) =>{
    let responce = {
        success:true,
        data:[]
    }
    let sites = [];///urls already used
    await db.collection("products").doc(product_id).collection('sites').get().then(async snapshot => {
        if(!snapshot.empty)
        {
            snapshot.forEach(async doc => {
                let Site = doc.data();
                sites.push(Site.domain);
            });
        }
        return
    });
    await db.collection("products").doc(product_id).get().then(async ref => {
        let product = ref.data();
        let urls = [];      
        for(let url of product.secondaryUrls){
            if(filter === true){
                if(!sites.includes(url))
                {
                    urls.push({label:url, value:url});
                }
            }else{
                urls.push({label:url, value:url});
            }
        }
        responce.data = urls;
        return
    });
    return responce;
}