import React, {useState, useEffect, useContext, useRef} from "react";
import {Store} from '../../stores/Store';
import {db} from '../../firebase';
import {getLead} from '../../data/leads';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {formatDate} from '../../helpers/time';
import {
    faLocationArrow, faCalendar, faUser 
} from '@fortawesome/free-solid-svg-icons';
import { Fragment } from "react";
const ViewClosedChat = (props) => {
    let [messages, setMessages] = useState([]);
    let [lead, setLead] = useState(null);

    const context = useContext(Store);
    let payne = useRef();
    useEffect(()=>{
        let node = payne.current;
        if(node && node.lastElementChild !== 'undefined' && messages.length>0){
            node.lastElementChild.scrollIntoView();
            //payne.lastElementChild.scrollIntoView();
        }
    }, [messages]);
    
    useEffect(()=>{
            db.collection('products').doc(context.product_id).collection('leads').doc(props.accessIds.l_id).collection('contacts').doc(props.accessIds.c_id)
            .get().then((snapshot) => {
              let updatedData = snapshot.data();
                setMessages((updatedData.chat)?updatedData.chat:[]);
            }
            );
    }, [context.product_id, props.accessIds]);

    useEffect(()=>{
        if(lead === null){
            getLead(context.product_id, props.accessIds.l_id).then((res)=>{
                setLead(res.data);
            });
        }
    }, [context.product_id, props.accessIds, lead]);

    return (
        <Fragment>
            
            <div className='row'>
            {(lead)&&<div className="col-12 lozcards">
                <div className='card'>
                        <div className='card-body'>
                            <div className="row no-gutters">
                                <div className="col-12 col-md-4 morespace bottom">
                                    <div className="textcenter click">
                                        <FontAwesomeIcon icon={faUser} /> <br/>{JSON.parse(props.data.form).name}
                                    </div>
                                </div>
                                <div className="col-12 col-md-4 morespace bottom">
                                    <div className="textcenter click">
                                        <FontAwesomeIcon icon={faLocationArrow} /> <br/>{(lead.maxmind.city)?lead.maxmind.city.names.en:'Unknown City'}{(lead.maxmind.country)&&', '+lead.maxmind.country.names.en}
                                    </div>
                                </div>
                                <div className="col-12 col-md-4 morespace bottom">
                                    <div className="textcenter click">
                                        <FontAwesomeIcon icon={faCalendar} /> <br/><span>{formatDate(lead.date)}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>}     
                <div className="col-12">
                    <div className='flgChatContainer'>
                        <div className='chatwindow' >
                            <div ref={payne} className='payne'>
                                {(messages && messages !== undefined)?messages.map(
                                    (item, i)=>{
                                        let type = (item.own)?'me':'from';
                                        return (
                                            <div key={i} className={'message '+type}>
                                                {item.message}
                                            </div>
                                        )
                                    }
                                ):<div className='message from'>
                                    Getting Messages...
                                </div>}
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>
        </Fragment>
    )
};
export default ViewClosedChat;