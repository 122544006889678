import React, {Fragment, useEffect, useState, useContext} from 'react';
import PageTop from '../Global/pagetop';
import {getGlobalComponents} from "../../data/sites";
import VerticalTabs from '../Global/VerticalTabs';
import PageContentElements from './PageTemplateElements/';
import {Store} from '../../stores/Store';
import {getcomponents} from '../../data/Models/components/';
import GlobalComponentCard from './GlobalComponentCard';
import NoContent from '../Global/NoContent';
import Loading from '../Global/Loading';
const GlobalComponents = (props) => {
    const context = useContext(Store);
    const [page, setPage] = useState({});
    const [globalComponents, setGlobalComponents] = useState(null);
    const [templateComponents, setTemplateComponents] = useState(getcomponents());
    const getComponents = () => {
        getGlobalComponents(context.product_id, context.site.site_id).then((res)=>{
            setGlobalComponents(res.data);
        });
    }
    useEffect(()=>{
        context.update({
            view:'sites'
        });
        getComponents();
    }, []);
    return (
        <Fragment>
            <PageTop name={'Sites'} />
            <div className='inner'>
               <div className='row'>
                <div className='col-md-9'>
                    {(globalComponents)?(globalComponents.length > 0)?
                    <ul className="row mb-3 card-list">
                        {globalComponents.map((item)=>{
                            return <li className="col-12 col-md-6 col-lg-4 card_object">
                                <GlobalComponentCard key={item.id} item={item} refresh={getComponents} />
                                </li>
                        })}
                    </ul>
                    :<NoContent />:<Loading/>}
                </div>
                <div className='col-md-3'>
                    <VerticalTabs data={
                            [
                                {
                                    name:"Page Headers",
                                    content:<PageContentElements getCanvas={getComponents} elements={templateComponents.filter((item)=> item.category === "navigation")} />
                                },
                                {
                                    name:"Hero",
                                    content:<PageContentElements getCanvas={getComponents} elements={templateComponents.filter((item)=> item.category === "hero")} />
                                },
                                {
                                    name:"Content",
                                    content:<PageContentElements getCanvas={getComponents} elements={templateComponents.filter((item)=> item.category === "content")} />
                                },
                                {
                                    name:"Forms",
                                    content:<PageContentElements getCanvas={getComponents} elements={templateComponents.filter((item)=> item.category === "forms")} />
                                },
                                {
                                    name:"Footers",
                                    content:<PageContentElements getCanvas={getComponents} elements={templateComponents.filter((item)=> item.category === "footers")} />
                                },
                                {
                                    name:"Custom",
                                    content:<PageContentElements getCanvas={getComponents} elements={templateComponents.filter((item)=> item.category === "custom")} />
                                },
                                {
                                    name:"External",
                                    content:<PageContentElements getCanvas={getComponents} elements={templateComponents.filter((item)=> item.category === "external")} />
                                }
                            ]
                        } 
                    />
                </div>
               </div>
            </div>
        </Fragment>
    );
}
export default GlobalComponents;