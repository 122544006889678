import React, {useState} from 'react'
import ColourSwatch from '../../Global/ColourSwatch';
const Color = (props) => {
    let [color, setColor] = useState(props.value);
    const updateColor = (value) => {
        setColor(value);
        props.update(value);
    }
    return <ColourSwatch name={props.name} update={(val)=>{updateColor(val)}} color={(color)?color:{r: 51, g: 51, b: 51, a: 1}} />
};
export default Color;