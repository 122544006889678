import React, {Component} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
faGlobe
} from '@fortawesome/free-solid-svg-icons';
import Processing from '../../Global/Processing';
import {Store} from "../../../stores/Store";
import ButtonBar from './ButtonBar';

class ProductCard extends Component{
    state = {
        removing:false
    }
    render(){
        return (
                <div className={"card "+((!this.props.data.active)&&'inactive')}>
                    <div className="card-header card-header-borderless d-flex justify-content-between">
                        <div>
                            <FontAwesomeIcon icon={faGlobe} />
                            {' '+this.props.data.name}
                        </div>
                    </div>
                    <div className="card-body">
                        <div className="media">
                            <div className="media-body siteCard">
                                {(this.state.removing)&&(
                                    <Processing />
                                )}
                            </div>
                        </div>
                    </div>
                    {(!this.state.removing)
                        &&<ButtonBar
                            update={this.props.update}
                            data={this.props.data}
                        />}
                </div>
        )
    }
}
ProductCard.contextType = Store; // This part is important to access context values
export default ProductCard;