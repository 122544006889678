import React, {Fragment} from 'react';
//componants
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faPlus, faEdit
} from '@fortawesome/free-solid-svg-icons';
import ModalAlert from '../Global/ModalAlert';
import CreateSite from './CreateSite';
const SitesDashboardButtons = (props) => {
    return (
        <Fragment>
            <ModalAlert 
                buttonClassName='boxbutton floatright' 
                buttonType='none'
                buttonIcon={<Fragment><FontAwesomeIcon icon={faPlus} /> <span>Site</span></Fragment>} 
                title={'New Page'} 
                content={<CreateSite update={props.callback} />}
                size='lg'
                callback={props.callback}
            />
        </Fragment>
    );
}
export default SitesDashboardButtons;
