import React, {Component, Fragment} from 'react';
import PageTop from "../Global/pagetop/";
import {Store} from '../../stores/Store';
import {getProduct} from '../../data/products';
import {getLeads} from '../../data/leads';
import {getBuckets} from '../../data/pipelines';
import Loading from '../Global/Loading';
import Bucket from './Bucket';
import NoContent from '../Global/NoContent';
import PipelineButtons from './PipelineButtons';

class Pipeline extends Component {
    state = {
        product:this.context.product?this.context.product:null,
        buckets:null
    }
    componentDidMount(){
        this.context.update({
            product_id:this.props.match.params.product_id,
            view:'pipeline'
        });
        getProduct(this.props.match.params.product_id).then(async (res)=>{
            await this.setState({
                product:res.data
            });
            this.context.update({
                product:res.data
            });
        });
        this.refresh();
    }
    refresh = async () => {
        getBuckets(this.props.match.params.product_id).then(res=>{
            this.setState({
                buckets:[...res.data]
            });
        });
    }
    render(){
        return (this.state.product)?(
            <Fragment>
                <PageTop name={'Pipeline'} buttons={<PipelineButtons refresh={this.refresh} />} />
                <div className='inner'>
                {(this.state.buckets)?(this.state.buckets.length > 0)?
                    <div className={'bucketholder'}>
                        {this.state.buckets.map((item, i)=>{
                            return <Bucket key={i+item.id} refresh={this.refresh} {...item} />
                        })}
                    </div>:<NoContent />:<Loading/>}
                </div>
            </Fragment>
        ):(
            <Fragment>
                <PageTop name={'Loading Pipeline'} />
                <div className='inner'>
                    <Loading />
                </div>
            </Fragment>
        );
    }
}
Pipeline.contextType = Store; // This part is important to access context values
export default Pipeline;
