import React, {Fragment} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faPlus
} from '@fortawesome/free-solid-svg-icons';
import ModalAlert from '../../Global/ModalAlert';
import NewItem from './NewItem';

const EditFormButtons = (props) => {
    return (
        <Fragment>
            <button onClick={()=>props.saveform()} className='btn boxbutton primary floatright'>
                Save
            </button>
            <ModalAlert 
                buttonClassName='boxbutton floatright' 
                buttonType='none'
                buttonIcon={<Fragment><FontAwesomeIcon icon={faPlus} /> <span>Item</span></Fragment>} 
                title={'New Item For '+props.form.name} 
                content={<NewItem update={props.update} form={props.form} />}
                size='lg'
                callback={props.callback}
            />
        </Fragment>
    );
}
export default EditFormButtons;
