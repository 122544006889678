import React from 'react';

const Text = (props) =>{
    return (
        <div className="input-group mb-3">
            <div className="input-group-prepend">
                <div className="input-group-text">{props.name}</div>
            </div>
            <input 
                className="form-control" 
                name={'text'} 
                onChange={(e)=>{props.update(e.target.value)}} 
                value={props.value}
                placeholder={props.name} 
            />
        </div>
    )
}
export default Text;