import React, {useState} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
faGlobe, faPager
} from '@fortawesome/free-solid-svg-icons';
import Processing from '../../Global/Processing';
import ButtonBar from './ButtonBar';
const SiteCard = (props) => {
    let [removing, setRemoving] = useState(false);
    return (
        <div className={"card "+((!props.data.active)&&'inactive')}>
            <div className="card-header card-header-borderless d-flex justify-content-between">
                <div>
                    <FontAwesomeIcon icon={faGlobe} />
                    {' '+props.data.name}
                </div>
            </div>
            <div className="card-body">
                <div className="media">
                    <div className="media-body siteCard">
                        {(removing)&&<Processing />}
                    </div>
                </div>
            </div>
            {(!removing)
                &&<ButtonBar
                    refresh={props.refresh}
                    data={props.data}
                />}
        </div>
    );
}
export default SiteCard;