import axios from 'axios';
import {getHeaders} from '../helpers/getHeaders';
import {db, auth} from '../firebase';
import  moment from 'moment';

const generateSecret = (length) => {
    var result = '';
    var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for ( var i = 0; i < length; i++ ) {
       result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
}

export const getProducts = async () => {
    let uid = auth.currentUser.uid;
    let responce = {
        success:true,
        data:[]
    }
    await db.collection('products').where('owner', '==', uid).get().then(async snapshot => {
        let products = [];
        if(!snapshot.empty)
        {
            snapshot.forEach(async doc => {
                let product =doc.data();
                product.id = doc.id;
                products.push(product)
            });
        }
        responce.data = products;
        return
    }).catch((error)=>{
        responce.success = false;
        responce.error = error;
        delete responce.data;
        return 
    });
    return responce;
}

export const getProduct = async (doc_id) => {
    let responce = {
        success:true,
        data:[]
    }
    await db.collection('products').doc(doc_id).get().then(doc => {
        let data = doc.data();
        data.id = doc_id;
        responce.data = data
        return
    }).catch((error)=>{
        responce.success = false;
        responce.error = error;
        delete responce.data;
        return 
    });
    return responce;
}

export const getAllProducts = async (context) => {
    let uri = '/getallproducts';
    let res = await axios.get(process.env.REACT_APP_API_URL+uri, {headers:getHeaders(context.token)});
    return await res.data;
}

export const getProductsSharedWithMe = async () => {
    let uid = auth.currentUser.uid;
    let responce = {
        success:true,
        data:[]
    }
    await db.collection('products').where('shared', 'array-contains', uid).get().then(snapshot => {
        if(!snapshot.empty)
        {
            snapshot.forEach(doc => {
                let data = doc.data();
                data.id = doc.id;
                if(data.shared.filter((shareduid)=>{return shareduid === uid}).length > 0)
                {
                    responce.data.push(data)
                }
            });
            return
        }
        return
    }).catch((error)=>{
        responce.success = false;
        responce.error = error;
        delete responce.data;
        return 
    });
    if(responce.success === true)
    {
        for(let i = 0; responce.data.length > i; i++){
            await db.collection('products').doc(responce.data[i].id).collection('containers').get().then((snap)=>{
                responce.data[i].container_amount = snap.size;
            });
            await db.collection('products').doc(responce.data[i].id).collection('feeds').get().then((snap)=>{
                responce.data[i].feed_amount = snap.size;
            });
        }
    }
    return responce;
}
export const createProduct = async (form) => {
    let uid = auth.currentUser.uid;
    let responce = {
        success:true,
        data:[]
    }
    await db.collection('products').add(
        {
            name:form.name,
            active:true,
            secret:generateSecret(25),
            shared:[],
            secondaryUrls:form.secondaryUrls,
            created:moment().unix(),
            last_cleared:moment().unix(),
            last_cached:moment().unix(),
            owner:uid
        }
    ).then(ref => {
        responce.data = ref
    }).catch((error)=>{
        responce.success = false;
        responce.error = error;
        delete responce.data;
        return 
    });
    return responce;
}
export const reGenSecret = async (product_id) =>{
    let responce = {
        success:true
    }
    await db.collection('products').doc(product_id).update(
        {
            secret:generateSecret(25)
        }
    ).then(ref => {
        responce.data = ref
    }).catch((error)=>{
        responce.success = false;
        responce.error = error;
        delete responce.data;
        return 
    });
    return responce;
}
export const updateProduct = async (product_id, form) => {
    let responce = {
        success:true
    }
    await db.collection('products').doc(product_id).update(
        {
            name:form.name,
            secondaryUrls:form.secondaryUrls
        }
    ).then(ref => {
        responce.data = ref
    }).catch((error)=>{
        responce.success = false;
        responce.error = error;
        delete responce.data;
        return 
    });
    return responce;
}
export const toggleActive = async (doc_id, state) => {
    let responce = {
        success:true
    }
    await db.collection('products').doc(doc_id).update({
        active:!state
    }).catch((error)=>{
        responce.success = false;
        responce.error = error;
        return 
    });
    return responce;
}
export const deleteProduct = async (product_id, context) => {
    let uri = '/deleteproduct/?productId='+product_id;
    let res = await axios.delete(process.env.REACT_APP_API_URL+uri, {headers:getHeaders(context.token)});
    return await res.data;
}
export const share = async (context, email, product_id) => {
    let uri = '/share';
    let res = await axios.post(process.env.REACT_APP_API_URL+uri, {email:email, product_id:product_id}, {headers:getHeaders(context.token)});
    return await res.data;
}
export const unshare = async (context, email, product_id) => {
    let uri = '/unshare';
    let res = await axios.post(process.env.REACT_APP_API_URL+uri, {email:email, product_id:product_id}, {headers:getHeaders(context.token)});
    return await res.data;
}
export const shared = async (context, product_id) => {
    let uri = '/shared?product_id='+product_id;
    let res = await axios.get(process.env.REACT_APP_API_URL+uri, {headers:getHeaders(context.token)});
    return await res.data;
}