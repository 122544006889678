import React, {Fragment} from 'react';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';

const MultiSelect = (props) =>{
    return (!props.simple)?(
        <Fragment>
            {(props.title)&&<label>{props.title}</label>}
            <Select
                onChange={props.handleChange}
                components={makeAnimated()}
                closeMenuOnSelect={false}
                options={props.dateSource}
                defaultValue={props.selected}
                isSearchable={true}
                isMulti
            />
        </Fragment>
    ):(
        <Fragment>
            <Select
                onChange={props.handleChange}
                components={makeAnimated()}
                closeMenuOnSelect={false}
                options={props.dateSource}
                defaultValue={props.selected}
                isSearchable={true}
                placeholder={props.title}
                isMulti
            />
        </Fragment>
    )
}
export  default MultiSelect;
