import {db} from '../firebase';
import {cidrToRange, convertIpToNumeric} from '../helpers/ip';

export const suppressNetwork = async (lead) => {
    let getstartandend = cidrToRange(lead.maxmind.traits.network)
    await db.collection('suppress').add({
        hostname:lead.maxmind.traits.organization,
        maxmind:lead.maxmind,
        origin_ip:lead.maxmind.traits.ip_address,
        rangeStart:(getstartandend !== null)?convertIpToNumeric(getstartandend[0]):0,
        rangeEnd:(getstartandend !== null)?convertIpToNumeric(getstartandend[1]):0,
        range:lead.maxmind.traits.network
    });
    return
}
export const suppressLead = async (product_id, lead) => {
    if(lead.formAmount !== null && lead.formAmount === 0){
        await db.collection('products').doc(product_id).collection('leads').doc(lead.id).update({
            networkSuppressed:true
        });
        let batch = db.batch();
        await db.collectionGroup('leads').where('maxmind.traits.organization', '==', lead.maxmind.traits.organization).get().then((snapshot)=>{
            if(!snapshot.empty){
                snapshot.forEach((item)=>{
                    let data = item.data();
                    data.id = item.id;
                    let Ref = db.collection('products').doc(data.productId).collection('leads').doc(data.id);
                    batch.set(Ref, {networkSuppressed:true});
                });
            }
            return batch.commit().then(() => {
                return {
                    success:true
                }
            });
        });
    }
    return
}
export const getSuppressed = async (product_id) => {
    let suppressed = [];
    if(product_id){
        suppressed = await db.collection('products').doc(product_id).collection('whitelist').orderBy('hostname').get().then((snapshot)=>{
            if(!snapshot.empty){
                let returnarray = [];
                snapshot.forEach((item)=>{
                    let data = item.data();
                    data.id = item.id;
                    returnarray.push(data);
                });
                return returnarray;
            }
            return [];
        });
    }else{
        suppressed = await db.collection('suppress').orderBy('hostname').get().then((snapshot)=>{
            if(!snapshot.empty){
                let returnarray = [];
                snapshot.forEach((item)=>{
                    let data = item.data();
                    data.id = item.id;
                    returnarray.push(data);
                });
                return returnarray;
            }
            return [];
        });
    }
    return suppressed;
}
export const removeFromWhitelist = async (product_id, id) => {
    let responce = await db.collection('products').doc(product_id).collection('whitelist').doc(id).delete().then((res)=>{
        return {success:true};
    }).catch((error)=>{
        return {
            success:false,
            error:error
        }
    });
    return responce;
}
export const addToWhitelist = async (product_id, hostname) => {
    ///check its not alread on list 
    let found = await db.collection('products').doc(product_id).collection('whitelist').get().then((snapshot)=>{
        let match = false;
        if(!snapshot.empty){
            snapshot.forEach(item => {
                let data = item.data();
                if(hostname === data.hostname){
                    match = true
                }
            });
        }
        return match;
    });
    if(!found)
    {
        await db.collection('products').doc(product_id).collection('whitelist').add({
            productId:product_id,
            hostname:hostname
        })
    }
    return found
    /// then add to list 
}
export const deletefromsuppression = async (id) => {
    let responce = await db.collection('suppress').doc(id).delete().then((res)=>{
        return {success:true};
    }).catch((error)=>{
        return {
            success:false,
            error:error
        }
    });
    return responce;
}