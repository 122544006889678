import React, {Fragment, useContext, useEffect, useState} from 'react';
import PageTop from "../Global/pagetop/";
import {Store} from '../../stores/Store';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faCalendar
} from '@fortawesome/free-solid-svg-icons';
import Loading from '../Global/Loading';
import MultiAreaChart from '../Global/MultiAreaChart';
import MultiPieChart from '../Global/MultiPieChart';
import {getStats} from '../../data/stats';
import moment from 'moment';
import StatsTable from './StatsTable';
import ModalAlert from '../Global/ModalAlert';
import Choosedays from './Choosedays';
const Stats = (props) => {
    const context = useContext(Store);
    const [stats ,setStats] = useState(null);
    const [totals ,setTotals] = useState(null);
    const [back ,setBack] = useState({label:'7 days', value:7});
    const [pagedata ,setPagedata] = useState(null);
    const [leadsData ,setLeadsData] = useState(null);
    const [loaded, setLoaded] = useState(false)
    useEffect(()=>{
        context.update({
            product_id:props.match.params.product_id
        });
        refresh();
    }, [])
    const setDate = (days) => {
        setBack(days);
    }
    useEffect(()=>{
        setLoaded(false);
        refresh();
    }, [back])
    useEffect(()=>{
        console.log('trigger stats effect');
        console.log(stats);
        if(stats !== null){
            console.log('instats');
            setTotals(getTotals());
            setPagedata(getPageData());
            setLeadsData(getLeadData());
            setLoaded(true);
        }
       
    }, [stats])
    const refresh = async () => {
        await getStats(props.match.params.product_id, back.value, context).then(async res=>{
            setStats(res.data);
        });
    }
    const getPageData = () => {
        let toppages = [];
        stats.forEach((statday)=>{
            /// loop the stats pushing each into new array if already exist add them together witht the previous 
            statday.pages.forEach(page => {
                let find = toppages.findIndex(toppageitem =>page.url === toppageitem.url);
                if(find !== -1){
                    toppages[find].number = toppages[find].number+page.number;
                }else{
                    toppages.push({
                    url:page.url,
                    number:page.number
                  })
                }
              });
        });
        let returnobj = toppages.sort((a, b) => (a.number > b.number) ? -1 : 1);
        let i = 1;
        returnobj.forEach((item, key)=>{
            item.key = i;
            i++;
        })
        return returnobj;
    }
    const getLeadData = () => {
        let data = [
            {
                "name": "Unidentified Visits",
                "value": 0
            },{
                "name": "Contact Form Leads",
                "value": 0
            },{
                "name": "Network Identified Leads",
                "value": 0
            },{
                "name": "Chat Leads",
                "value": 0
            }
        ];
        stats.forEach((item)=>{
            data[0].value += item.visits-((item.contacts?item.contacts:0)+((item.leads)?item.leads:0)+((item.chats)?item.chats:0));
            data[1].value += item.contacts;
            data[2].value += item.leads;
            data[3].value += item.chats;
        });
        return data;
    }
    const getTotals = () => {
        let returnobject = {
            totalLeads:0,
            totalVisits:0,
            totalPageViews:0,
            totalBounce:0,
            totalChats:0,
            totalNetwork:0,
            totalForms:0,
            totalBounceRate:0,
            totalLeadRate:0,
            totalAveragePages:0
        };
        stats.forEach((statday)=>{
            returnobject.totalLeads += statday.leads||0;
            returnobject.totalVisits += statday.visits||0;
            returnobject.totalPageViews += statday.page_views||0;
            returnobject.totalBounce += statday.bounce||0;
            returnobject.totalChats += statday.chats||0;
            returnobject.totalNetwork += statday.leads||0;
            returnobject.totalForms += statday.contacts||0;
        });
        returnobject.totalBounceRate = ((returnobject.totalBounce/returnobject.totalVisits)*100).toFixed(2);
        returnobject.totalLeadRate = ((returnobject.totalLeads/returnobject.totalVisits)*100).toFixed(2);
        returnobject.totalAveragePages = (returnobject.totalPageViews/returnobject.totalVisits).toFixed(2);
        return returnobject;
    }
    return (context.product && loaded)?(
        <Fragment>
            <PageTop name={'Analytics'} buttons={
                    <ModalAlert 
                        buttonClassName={"boxbutton floatright"} 
                        buttonType='none'
                        buttonIcon={<FontAwesomeIcon icon={faCalendar} />} 
                        title={'Filter'} 
                        content={<Choosedays days={back} handleChange={setDate} />}
                        size='lg'
                    />
                }
            />
            {(loaded)?<div className='inner'>
            <div className='row'>
                    <div className='col-6 col-md-4'>
                        <div className="card stats">
                            <div className="card-body">
                                <span className='statTitle'>Form Prospects</span>
                                {(totals !== null)?<span className='stat'>{totals.totalForms}</span>:<Loading />}
                            </div>
                        </div>
                    </div>
                    <div className='col-6 col-md-4'>
                        <div className="card stats">
                            <div className="card-body">
                                <span className='statTitle'>Chat Prospects</span>
                                {(totals !== null)?<span className='stat'>{totals.totalChats}</span>:<Loading />}
                            </div>
                        </div>
                    </div>
                    <div className='col-12 col-md-4'>
                        <div className="card stats">
                            <div className="card-body">
                                <span className='statTitle'>Network Identified Prospects</span>
                                {(totals !== null)?<span className='stat'>{totals.totalNetwork}</span>:<Loading />}
                            </div>
                        </div>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-6 col-md-3'>
                        <div className="card stats">
                            <div className="card-body">
                                <span className='statTitle'>Total Leads</span>
                                {(totals !== null)?<span className='stat'>{totals.totalLeads}</span>:<Loading />}
                            </div>
                        </div>
                    </div>
                    <div className='col-6 col-md-3'>
                        <div className="card stats">
                            <div className="card-body">
                                <span className='statTitle'>Total Visits</span>
                                {(totals !== null)?<span className='stat'>{totals.totalVisits}</span>:<Loading />}
                            </div>
                        </div>
                    </div>
                    <div className='col-6 col-md-3'>
                        <div className="card stats">
                            <div className="card-body">
                                <span className='statTitle'>Page Views</span>
                                {(totals !== null)?<span className='stat'>{totals.totalPageViews}</span>:<Loading />}
                            </div>
                        </div>
                    </div>
                    <div className='col-6 col-md-3'>
                        <div className="card  stats">
                            <div className="card-body">
                                <span className='statTitle'>Total Bounce</span>
                                {(totals !== null)?<span className='stat'>{totals.totalBounce}</span>:<Loading />}
                            </div>
                        </div>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-12 col-md-4'>
                        <div className="card stats">
                            <div className="card-body">
                                <span className='statTitle'>Lead Identification Rate</span>
                                {(totals !== null)?<span className='stat'>{totals.totalLeadRate}%</span>:<Loading />}
                            </div>
                        </div>
                    </div>
                    <div className='col-12 col-md-4'>
                        <div className="card stats">
                            <div className="card-body">
                                <span className='statTitle'>Average Page View</span>
                                {(totals !== null)?<span className='stat'>{totals.totalAveragePages}</span>:<Loading />}
                            </div>
                        </div>
                    </div>
                    <div className='col-12 col-md-4'>
                        <div className="card stats">
                            <div className="card-body">
                                <span className='statTitle'>Bounce Rate</span>
                                {(totals !== null)?<span className='stat'>{totals.totalBounceRate}%</span>:<Loading />}
                            </div>
                        </div>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-12'>
                        <div className="card">
                            <div className="card-header">
                                Daily Breakdown  
                            </div>
                            <div className="card-body">  
                                {(stats && stats !== null)?<MultiAreaChart data={
                                    stats.map((item)=>{
                                        //item.day-10800 takes 3 hours off to deal with the issue of time zones
                                        return {
                                            "Name": moment.unix(item.day-10800).format("DD/MM"),
                                            "Visits": item.visits,
                                            "Leads": item.leads,
                                            "Page Views": item.page_views,
                                            "Bounces": item.bounce
                                        }
                                    })} />:<Loading />}
                            </div>
                        </div>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-12 col-md-5'>
                        <div className="card">
                            <div className="card-header">
                                    Lead Breakdown
                            </div>
                            <div className="card-body">  
                                {(leadsData && leadsData !== null)?<MultiPieChart data={leadsData} />:<Loading />}
                            </div>
                        </div>
                    </div>
                    <div className='col-12 col-md-7'>
                        <div className="card">
                        <div className="card-header">
                                    Top Pages by Visit
                            </div>
                            <div className="card-body">
                                {(pagedata && pagedata !== null)?<StatsTable data={pagedata} />:<Loading />}
                            </div>
                        </div>
                    </div>
                </div>
            </div>:<Loading />}
        </Fragment>
    ):(
        <Fragment>
            <PageTop name={'Loading'} />
            <div className='inner'>
                <Loading />
            </div>
        </Fragment>
    );
}
export default Stats;
